import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { displayModal } from "../../../redux/actions/register.action";

let userInfo = localStorage.getItem("userInfo");
if (userInfo !== "undefined") {
	userInfo = JSON.parse(localStorage.getItem("userInfo"));
} else {
	localStorage.removeItem("userInfo");
	window.location.reload();
}

export default function ShareEarn() {
	const navigate = useNavigate();
	const [url, setUrl] = useState(null);
	const { account } = useSelector((state) => {
		return state;
	});

	const dispatch = useDispatch();
	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	}, []);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	useEffect(() => {
		if (account?.payload?.Data?.pixel_id) {
			const url = window.location.origin + "/" + account?.payload?.Data?.pixel_id;
			setUrl(url);
		}
	}, [account]);

	useEffect(() => {
		if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
			dispatch(displayModal("register", true));
		}
	}, []);

	const copyToClipboard = (url) => {
		let textField = document.createElement("textarea");
		textField.innerText = url;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		textField.remove();
		notification.success({
			message: "Copied to Clipboard!",
		});
	};

	const influencer = () => {
		navigate(`/${account?.payload?.Data?.pixel_id}`);
	};

	return (
		<>
			<div className="account-left-content">
				<div className="account-detail col-12 col-xl-6 col-lg-8 col-md-12">
					<div className="bcm-inf-main">
						<div className="pr-op-block pro-inf-block">
							<div className="inf-cret-main">
								<div className="inf-cret-left">
									<>
										<p>
											Earn a fee effortlessly by liking brand videos — no purchase necessary! Every liked video is automatically added to your ORME shop.
											<br />
											Connect your <b>ORME</b> shop with your Instagram account. Simply edit profile and add the unique link provided as an external link. Link in bio will redirect your followers to your ORME shop, allowing you to earn a fee on their purchases. Keep track of earnings and visibility on the dashboard. Once return period concludes, you can conveniently withdraw your earnings to your <b>PayPal</b> account.
										</p>
										<p>To maximize earnings, share the link via SMS, WhatsApp, and to other social platforms. The more you share, the more you can earn.</p>
									</>
								</div>
								{/* <div className="inf-cret-right mb-3">
                                    <img src={inficon} alt="" />
                                </div> */}
							</div>

							<div className="add-link-bio-infl">
								<div className="your-copy-link">
									<div className="item-a">
										<a target="_blank" rel="noreferrer" onClick={() => influencer()}>
											{url}
										</a>
									</div>
									<div className="item-b" onClick={() => copyToClipboard(url)}>
										Copy
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
