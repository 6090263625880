import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Mobile from "../../components/device/mobile";
import RegisterMbl from "../../pages/auth/registermbl";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const shareTracking = JSON.parse(localStorage.getItem("shareTracking"));

const ScrollToTop = (props) => {
	const location = useLocation();
	const [isMobile, setIsMobile] = useState(false);
	const [signUp, setSignUp] = useState(false);

	useEffect(() => {
		const validPaths = ["/", "/review", "/brands", "/contact-us"];
		// console.log(location);
		// if (location.pathname == "/deep-linking") {

		// 	setTimeout(()=>{
		// 	if (!document.hidden) {
		// 		window.location.href = "https://apps.apple.com/us/app/orme/id6448742926"
		// 	}
		// },2000)
		// }

		if (validPaths.includes(location.pathname) && userInfo && shareTracking?.event === "linkinbio") {
			localStorage.removeItem("shareTracking");
		}
	}, [location.pathname]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	useEffect(() => {
		var currentPath = location.pathname;

		const validPaths = ["/home2","/contact-us", "/privacy-policy", "/terms-service", "/community-guidelines", "/", "/brand-retailer", "/influencer-creators", "/register-partner", "/deep-linking"];

		if (validPaths.includes(currentPath)) {
			setIsMobile(false);
		} else {
			// Code for other paths if needed
			setIsMobile(/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
		}
	}, [location.pathname]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<>
			{props.children}
			{isMobile && (signUp ? <Mobile /> : <RegisterMbl setSignUp={setSignUp} />)}
		</>
	);
};

export default ScrollToTop;
