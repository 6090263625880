import { notification } from "antd";
import { Button } from "react-bootstrap";

export default function Notify(varintPrice, price1, product, onKeyChange,title) {
	console.log('product',product)
	const key = `open${Date.now()}`;
	const btn = (
		<Button
			type="primary"
			size="small"
			onClick={() => {
				notification.close(key);
				onKeyChange("4");
			}}
		>
			View Cart
		</Button>
	);
	notification.open({
		message: "ADDED TO CART",
		description: (
			<div className="added-cart">
				<div className="added-prod-image">
					<img src={product?.image?.src} />
				</div>
				<div className="added-prod-name">
					<div className="added-prod-title">
						<span className="added-prod-name-inr">{title  + ' '+ product?.data?.title}</span>
					</div>
					<div className="added-prod-price">
						<span>${varintPrice ? varintPrice : price1}</span>
					</div>
				</div>
			</div>
		),
		btn,
		key,
	});
}