import { NavLink } from "react-router-dom";
export default ({ children }) => {
	return (
		<>
			<div className="privacy_page_main">
				<div className="midd-width-ifti max-1140">
					<div className="privacy_content">
						<div className="row">
							<div className="col-lg-2">
								<h3 className="side-hd">HELP</h3>
								<ul className="side-list">
									<li>
										<NavLink to="/terms-service">Terms of Service</NavLink>
									</li>
									<li>
										<NavLink to="/privacy-policy">Privacy Policy</NavLink>
									</li>
									{/* <li>
										<NavLink to="/faqs">FAQ's</NavLink>
									</li> */}
									<li>
										<NavLink to="/community-guidelines">Community Guidelines</NavLink>
									</li>
									<li>
										<NavLink to="/contact-us">Contact Us</NavLink>
									</li>
								</ul>
							</div>
							<div className="col-lg-10">
								<div className="privacy_header">{children}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};