import { combineReducers } from "redux";
import category from "./category/subCategory.reducer";
import allBrand from "./brand/allBrand.reducer";
import featureBrand from "./brand/featureBrand.reducer";
import brandnameList from "./brand/brandlist.reducer";
import brandSkutitle from "./brand/skuTitle.reducer";
import parentCategory from "./category/parentCategory.reducer";
import popularCategory from "./category/popularCategory.reducer";
import subCategory from "./category/subCategory.reducer";
import multipleSubCategory from "./category/multipleSubCategory.reducer";
import categoryWiseBrand from "./brand/categoryWiseBrand.reducer";
import categoryWiseInfluencer from "./influencer/categoryWiseInfluencer";
import allInfluencer from "./influencer/allInfluencer.reducer";
import infReviewupload from "./influencer/reviewUpload.reducer";
import influencerReviews from "./influencer/influencerReviews.reducer";
import bioShopMenu from "./bioShop/bioShopMenu.reducer";
import bioShopAllPost from "./bioShop/bioShopAllPost.reducer";
import bioShopAllLink from "./bioShop/bioShopAllLink.reducer";
import bioShopCategoryPost from "./bioShop/bioShopCategoryPost.reducer";
import url from "./bioShop/bioShopAddToUrl.reducer";
import bioShopPostClick from "./bioShop/bioShopPostClick.reducer";
import bioShopViewCount from "./bioShop/bioShopViewcount.reducer";
import bioShopAddToFavourite from "./bioShop/bioShopAddToFavourite.reducer";
import login from "./login/login.reducer";
import showAuthModal from "./login/login.modal.reducer";
import country from "./country/country.reducer";
import state from "./state/state.reducer";
import city from "./city/city.reducer";
import customerRegister from "./register/customerRegister.reducer";
import brandRegister from "./register/brandRegister.reducer";
import homeBanner from "./banner/banner.reducer";
import categoryBanner from "./banner/categoryBanner.reducer";
import hotDeal from "./deal/hotDeal.reducer";
import favouriteBrand from "./brand/favouriteBrand.reducer";
import categoryFavouriteBrand from "./brand/categoryFavouriteBrand.reducer";
import categoryFeatureBrand from "./brand/categoryFeatureBrand.reducer";
import changePassword from "./changePassword/changePassword.reducer";
import selectedCountry from "./country/selectedCountry.reducer";
import showChangePasswordModal from "./changePassword/changePassword.modal.reducer";
import account from "./account/account.reducer";
import accountUpdate from "./account/accountUpdate.reducer";
import becomeInfluencer from "./becomeInfluencer/becomeInfluencer.reducer";
import favouriteList from "./favourite/favouriteList.redcuer";
import favouriteListRemove from "./favourite/favouriteListRemove.reducer";
import favouriteRemove from "./favourite/favouriteRemove.reducer";
import moderateInf from "./influencer/moderationInf.reducer";
import subcategories from "./BioSubCategory/BioSubCategory.redcuer";
import allCategory from "./category/allCategory.reducer";
import saveCategory from "./category/saveCategory.reducer";
import verifyCategory from "./category/verifyCategory.reducer";
//Brand Favourite List
import favouriteBrandList from "./favourite/favouriteBrandList.reducer";
import favouriteBrandRemove from "./favourite/favouriteBrandRemove.reducer";
import bioShopAddToFavouriteBrand from "./favourite/favouriteBrandAdd.reducer";
import favouriteInfluencerList from "./favourite/favouriteInfluencerList.reducer";
import forgetPassword from "./forgetPassword/forgetPassword.reducer";
import resetPassword from "./resetPassword/resetPassword.reducer";
//Purchase
// import purchaseReport from "./purchase/purchaseReport.reducer";
import reportBrand from "./brand/brandReport.reducer";
import purchaseReport from "./purchase/purchasecustomReport.reducer";
import getPrevEventLikes from "./liveEvent/getEventLikes.reducer";

//
import showRegModal from "./register/register.modal.reducer";
import openModal from "./register/openModal.reducer";
import commission from "./wallet/commission.reducer";
import limitWithdraw from "./wallet/limitWithdrawl.reducer";
import commissionEarn from "./wallet/commissionEarn.reducer";
import withDrawRequest from "./wallet/withdrawRequest.reducer";
import getAllWithDraw from "./wallet/getAllWithDrawRequest.reducer";
import brandWallet from "./wallet/brandWallet.reducer";
import contact from "./contact/contact.reducer";
import referralStats from "./sales/referralStats.reducer";
import purchaseSummary from "./purchase/purchaseSummary.reducer";
import summaryReferral from "./sales/referralSummary.reducer";
import saleContent from "./sales/contentSale.reducer";
import walletWithdrawamount from "./wallet/walletWithdraw.reducer";
import payoutCard from "./payment/payout.reducer";
//Sub Category
import subCategoryWiseBrand from "./brand/subCategoryWiseBrand.reducer";
import categoriesSetup from "./category/categoriesSetup";
//Live Event
import currentLiveEvent from "./liveEvent/liveEvent.reducer";
import liveEventId from "./liveEvent/liveEventById.reducer";
import allUpcomingEvent from "./liveShopping/allUpcomingEvent.reducer";
import upComingEvent from "./liveShopping/upComingEvent.reducer";
import allLiveEvent from "./liveShopping/allLiveEvent.reducer";
import liveEvent from "./liveShopping/liveEvent.reducer";
import allPublishEvent from "./liveEvent/getPublishEvent.reducer";
import eventViews from "./liveEvent/getView.reducer";
import eventLikes from "./liveEvent/getEventLikes.reducer";
import likeEvents from "./liveEvent/getLike.reducer";
import influencerSingle from "./influencer/singleInfluencer.reducer";
import allnewPublishEvent from "./liveEvent/getAllpublishEvent.reducer";
import newinfluencerReviews from "./influencer/newInfluencer.reducer";
import influecerPostDelete from "./influencer/delInfluencerPost";
import shareDelReview from "./influencer/delFavourite.reducer";
import infVideo from "./influencer/getAllInf.reducer";
import deleteReviews from "./influencer/deleteReviews.reducer";
import reviewSaved from "./save/privateSaved.reducer";
//import liveViews from "./liveEvent/liveeventViews.reducer";
//Shopify GetProduct
import shopifyProduct from "./shopify/shopify.getProduct.reducer";
import shopifyaddToCart from "./shopify/shopify.addToCart.reducer";
import getShopifyLiveCart from "./liveEvent/getShopifyLiveCart.reducer";
import checkoutProduct from "./shopify/shopify.getCheckout.reducer";
import removeCart from "./shopify/shopify.removeCart.reducer";
import shopifySetShippingAddress from "./shopify/shopify.setShippingAddress.reducer";
import shopifyGetShippingRates from "./shopify/shopify.getShippingRates.reducer";
import shopifySetShippingRates from "./shopify/shopify.setShippingRates.reducer";
import shopifyApplyPromoCode from "./shopify/shopify.applyPromoCode.reducer";
import shopifyRemovePromoCode from "./shopify/shopify.removePromoCode.reducer";
import shopifyCompleteCheckout from "./shopify/shopify.completeCheckout.reducer";
import shopifyUpdateAttribute from "./shopify/shopify.updateAttribute.reducer";
import emptyCart from "./shopify/shopify.emptyCart.reducer";
import paymentMake from "./payment/makePayment.reducer";
import influencerProf from "./influencer/influencerprof.reducer";
import influencers from "./influencer/getInfluencer.reducer";
import accountVerifyReducer from "./account/accountVerify.reducer";
//Payment
import addCreditCard from "./payment/addCreditCard.reducer";
import allCreditCard from "./payment/getAllCreditCard.reducer";
import defaultCard from "./payment/setDefaultCard.reducer";
import paymentList from "./payment/stripePaylist.reducer";
import trackingSale from "./sales/salesTracker.reducer";
import regPartner from "./register/partnerReg.reducer";
//Shipping
import addShippingAddress from "./shipping/createShippingAddress.reducer";
import allShippingAddress from "./shipping/getAllShippingAddress.reducer";
import setShippingAddress from "./shipping/setShippingAddress.reducer";
import upgradeShippingAddress from "./shipping/updatedShippingAddress.reducer";
import deleteShippingAddress from "./shipping/deleteShippingAddress.reducer";
import chatToken from "./chat/chatToken";
import getChat from "./chat/getChat";
import saveChat from "./chat/saveChat";
import chatReview from "./chat/reviewChat";
import dashboardPayloadSocial from "./dashboard/dashboard.reducer";
import dashboardPayloadEvent from "./dashboard/dashboard.event.reducer";
import otpLogged from "./signIn/otp.reducer";
//Search
import search from "./search/search.reducer";
//views
import prevEventviews from "./liveEvent/eventViews.reducer";
import influencerViews from "./liveEvent/getInfluencer.reducer";
import shareVidurl from "./bioShop/shareVid.reducer";
//Instagram
import getInstagramUrl from "./instagramConnection/getInstagramUrl.reducer";
import instagramData from "./instagramConnection/getInstagramData.reducer";
import updateAccessToken from "./instagramConnection/updateAccessToken.reducer";
import disconnectInstagram from "./instagramConnection/disconnectInstagram.reducer";
import refreshedInstagram from "./instagramConnection/refreshInstagram.reducer";
import instagramMedia from "./instagramConnection/getInstagramedia.reducer";
import instaNextmedia from "./instagramConnection/instaNextmedia.reducer";
import instaPublish from "./instagramConnection/instaPublish.reducer";
//
import galleryPosting from "./galleryPosts/galleryPostimg.reducer";
import schedulePosts from "./mediaGallery/schedulePost";
import campaign from "./mediaGallery/mediaSummary";
import gallery from "./mediaGallery/gallery";
import createdMedia from "./mediaGallery/postMedia.reducer";
import deletetedMedia from "./mediaGallery/deleteMedia.reducer";
import reportCategory from "./category/categoryReport.reducer";
//
import bioPosts from "./bioPost/bioPost.reducer";
import getEvent from "./event/getEvent.reducer";
import publishedPost from "./galleryPosts/publishPost.reducer";
import publishedShop from "./galleryPosts/publishShop.reducer";
import deleteaccount from "./account/deleteAccount.reducer";
import refreshedfacebook from "./facebookConnection/facebookConnect.reducer";
import retrievedPost from "./galleryPosts/retrievePost.reducer";
import revisedPost from "./galleryPosts/revisePost.reducer";
import removedPost from "./galleryPosts/removePost.reducer";
import reservedPost from "./galleryPosts/reservePost.reducer";
import receivedPost from "./galleryPosts/receivePost.reducer";
import subCategoryig from "./category/subCategoryig.reducer";
import salesSummary from "./sales/contentSummary.reducer";
import disPaypal from "./payment/paypalDisconnect.reducer";
import facebookAcessed from "./facebookConnection/facebookAccess.reducer";
import facebookaccessToken from "./facebookConnection/facebookaccessToken.reducer";
import facebookPage from "./facebookConnection/facebookPages.reducer";
import resetFacebook from "./facebookConnection/facebookReset.reducer";
import fbselectedPage from "./facebookConnection/facebookSelected.reducer";
import fbUser from "./facebookConnection/fbUser.reducer";
import requestToken from "./facebookConnection/tokenRequest.reducer";
import tokenFb from "./facebookConnection/facebookToken.reducer";
import facebookPublish from "./facebookConnection/fbPublish.reducer";
import messageReview from "./chat/reviewMessage";
import getReview from "./influencer/reviewGet.reducer";
import likeReview from "./influencer/reviewLike.reducer";
import walletgetOne from "./wallet/getOne.reducer";
import notifyedMe from "./notify/notifyingMe.reducer";
import userInfo from "./userInfo/userInfo.reducer";
import mobileDropdown from "./profileImageBasic/profileImageBasic.reducer";
import UpdateInterest from "./interest/update.reducer";
import newsFeeds from "./newsFeeds/newsFeeds.reducer";
import brand from "./brand/brand.reducer";
import followerBrand from "./brand/brandFollower.reducer";
import reviseStatusPost from "./galleryPosts/postReviseStatus.reducer";
import updateCart from "./shopify/shopify.updateCart.reducer";
import genderCategory from "./category/genderCategory.reducer";
//Interest
import updateInterest from "./interest/update.reducer";
import getInterest from "./interest/get.reducer";
// Followers
import followersData from "./followers/followers.reducer";
import createFollowers from "./followers/createFollowers.reducer";
import followersStatus from "./followers/followersStatus.reducer";
import unfollowUser from "./followers/unfollow.reducer";
import followingUser from "./followers/followingUser.reducer";
import chatLogs from "./chat/chatLogs";
import sharedViews from "./influencer/sharedReviews.reducer";
//feeds
import feedsCreated from "./feeds/feeds.reducer";
import countLiked from "./influencer/userCount.reducer";
import notifications from "./account/userNotification";
import updateNotification from "./account/updateuserNotification";
import reviewUploadNew from "./influencer/uploadReviewNew.reducer";
import feedData from "./feeds/feedData.reducer";

//Save
import savePost from "./save/save.reducer";
import getSave from "./save/getSave.reducer";
import getSaveReview from "./save/getSaveReview.reducer";
import saveReview from "./save/saveReview.reducer";
import eventSaved from "./save/getEventSaved.reducer";

// Product List
import productList from "./product/productList.reducer";

export default combineReducers({
	updateInterest,
	openModal,
	getInterest,
	addShippingAddress,
	allShippingAddress,
	allLiveEvent,
	addCreditCard,
	account,
	accountUpdate,
	allBrand,
	allInfluencer,
	allUpcomingEvent,
	upComingEvent,
	bioShopAddToFavourite,
	bioShopAddToFavouriteBrand,
	becomeInfluencer,
	bioShopAllLink,
	bioShopAllPost,
	bioShopCategoryPost,
	bioShopMenu,
	bioShopPostClick,
	bioShopViewCount,
	brandRegister,
	brandWallet,
	category,
	currentLiveEvent,
	chatLogs,
	commission,
	contact,
	commissionEarn,
	categoryBanner,
	categoryFavouriteBrand,
	categoryFeatureBrand,
	categoryWiseBrand,
	categoryWiseInfluencer,
	changePassword,
	city,
	country,
	customerRegister,
	favouriteBrand,
	featureBrand,
	favouriteList,
	favouriteListRemove,
	favouriteRemove,
	favouriteBrandList,
	favouriteInfluencerList,
	favouriteBrandRemove,
	forgetPassword,
	homeBanner,
	hotDeal,
	liveEvent,
	login,
	parentCategory,
	popularCategory,
	purchaseReport,
	selectedCountry,
	showAuthModal,
	defaultCard,
	state,
	subCategory,
	showChangePasswordModal,
	subCategoryWiseBrand,
	showRegModal,
	setShippingAddress,
	upgradeShippingAddress,
	deleteShippingAddress,
	url,
	withDrawRequest,
	reportBrand,
	allCreditCard,
	liveEventId,
	resetPassword,
	multipleSubCategory,
	shopifyProduct,
	shopifySetShippingAddress,
	shopifyaddToCart,
	shopifyGetShippingRates,
	shopifyApplyPromoCode,
	shopifySetShippingRates,
	getShopifyLiveCart,
	shopifyRemovePromoCode,
	shopifyCompleteCheckout,
	shopifyUpdateAttribute,
	checkoutProduct,
	removeCart,
	emptyCart,
	paymentList,
	paymentMake,
	getInstagramUrl,
	instagramData,
	instaPublish,
	updateAccessToken,
	disconnectInstagram,
	refreshedInstagram,
	subcategories,
	allCategory,
	saveCategory,
	verifyCategory,
	categoriesSetup,
	allPublishEvent,
	eventViews,
	eventLikes,
	likeEvents,
	myChatToken: chatToken,
	getChat,
	saveChat,
	dashboardPayloadSocial,
	dashboardPayloadEvent,
	getAllWithDraw,
	galleryPosting,
	refreshedfacebook,
	instagramMedia,
	instaNextmedia,
	retrievedPost,
	revisedPost,
	removedPost,
	reservedPost,
	publishedPost,
	publishedShop,
	receivedPost,
	subCategoryig,
	schedulePosts,
	campaign,
	gallery,
	createdMedia,
	deletetedMedia,
	bioPosts,
	getEvent,
	facebookAcessed,
	fbUser,
	facebookaccessToken,
	facebookPage,
	requestToken,
	resetFacebook,
	fbselectedPage,
	tokenFb,
	facebookPublish,
	brand,
	reviseStatusPost,
	userInfo,
	mobileDropdown,
	notifyedMe,
	influencerReviews,
	influencerSingle,
	followerBrand,
	updateCart,
	messageReview,
	chatReview,
	getReview,
	likeReview,
	referralStats,
	purchaseSummary,
	summaryReferral,
	reportCategory,
	genderCategory,
	otpLogged,
	prevEventviews,
	influencerViews,
	search,
	deleteaccount,
	UpdateInterest,
	influencerProf,
	influencers,
	trackingSale,
	saleContent,
	salesSummary,
	followersData,
	createFollowers,
	followersStatus,
	unfollowUser,
	followingUser,
	walletWithdrawamount,
	walletgetOne,
	payoutCard,
	disPaypal,
	newsFeeds,
	newinfluencerReviews,
	allnewPublishEvent,
	feedsCreated,
	notifications,
	updateNotification,
	shareVidurl,
	sharedViews,
	influecerPostDelete,
	shareDelReview,
	countLiked,
	infVideo,
	deleteReviews,
	brandSkutitle,
	brandnameList,
	moderateInf,
	infReviewupload,
	reviewUploadNew,
	getPrevEventLikes,
	regPartner,
	limitWithdraw,
	feedData,
	savePost,
	getSave,
	getSaveReview,
	saveReview,
	reviewSaved,
	eventSaved,
	productList,
	accountVerifyReducer,
});
