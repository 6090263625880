import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import avatarIcon from "../../../images/avatar.png";
import { getAllBrand } from "../../../redux/actions/brand.action";
import InfiniteScroll from "react-infinite-scroll-component";

const BrandList = ({ setDisplaySearch }) => {
	const dispatch = useDispatch();
	const [page, setPage] = useState(2);
	const { isLoading, hasMore, brandsData } = useSelector((state) => ({
		isLoading: state.allBrand?.loading,
		brandsData: state.allBrand?.payload?.data,
		hasMore: state.brand?.payload?.total !== state?.brand?.payload?.data?.length ? true : false,
	}));

	const loadMore = () => dispatch(getAllBrand(page, 6, "", "", true)).then(() => setPage(page + 1));



	return (
		<>
			<div className="search-hd-row">
				<h4 className="tab-hd">Brands</h4>
				<Link onClick={() => setDisplaySearch(false)} to="/brands" className="tab-view-more-link">
					View More
				</Link>
			</div>

			<div className="search-tab-scroll">
				<div id="brand-target" className="tab-product-list-main position-relative scrollbar-style-product">
					{isLoading ? (
						<div className="antd-loading-ift">
							<Spin size="medium" />
						</div>
					) : (
						<>
							{brandsData?.length > 0 ? (
								<InfiniteScroll scrollableTarget="brand-target" dataLength={brandsData?.length} next={loadMore} hasMore={hasMore}>
									{brandsData?.map((item, key) => (
										<Link onClick={() => setDisplaySearch(false)} key={key} to={`/${item?.pixel_id}`} className="tab-product-list-item reviews">
											<span className="review-image brand-style">
												<figure className="review-user-img" data-testid="image">
													<img loading="lazy" decoding="async" src={item?.profile_image_url ? item?.profile_image_url : avatarIcon} alt={item?.brand_name} />
												</figure>
											</span>

											<div className="tab-product-info">
												<div className="tab-product-name">{item?.brand_name}</div>
												<div className="info-1">{item?.pixel_id}</div>
											</div>

											<div className="tab-add-product-right">
												<button type="button" className="tab-add-product-button large-size">
													<FontAwesomeIcon icon={faArrowRight} />
												</button>
											</div>
										</Link>
									))}
								</InfiniteScroll>
							) : (
								<div className="antd-loading-ift">
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Brand found</span>} />
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default BrandList;
