import { SHOPIFY_CART_REQUEST, SHOPIFY_CART_SUCCESS, SHOPIFY_CART_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

// export const addToCarts = (brand_id, status, quantity, varId, item, token) => (dispatch) => {
// 	dispatch({ type: SHOPIFY_CART_REQUEST });
// 	return axios({
// 		method: "POST",
// 		url: `${BASEURL}checkout/addtocart`,
// 		headers: { Authorization: `Bearer ${token}` },

// 		data: {
//             lineItem: [{
//                 variantId: varId,
//                 quantity,
//                 customAttributes:item
//               }],
// 			brand: brand_id,
//             first_item: status,
// 		},
// 	})
// 		.then((response) => {
// 			return dispatch({
// 				type: SHOPIFY_CART_SUCCESS,
// 				payload: response.data,
// 			});
// 		})
// 		.catch((error) => {
// 			return dispatch({
// 				type: SHOPIFY_CART_ERROR,
// 				payload: error.response,
// 			});
// 		});
// };

export const addToCart = (brand_id, status, quantity, varId, item, token, title, variant, reviewData) => (dispatch) => {
	dispatch({ type: SHOPIFY_CART_REQUEST });


	return axios({
		method: "POST",
		url: `${BASEURL}checkout/addtocart`,
		headers: { Authorization: `Bearer ${token}` },

		data: {
			currencyCode: "USD",
			lineItem: {
				title,
				quantity,
				...(reviewData && { reviewInfo: reviewData }),
				variant: {
					...variant.data,
					selectedOptions: variant.selectedOptions,
					product: {
						id: variant.data?.product_id, //*
						handle: variant?.handle, //*
					},
					image: variant?.image,
				},
			},
			brand: brand_id,
			first_item: status,
		},
	})
		.then((response) => {
			return dispatch({
				type: SHOPIFY_CART_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SHOPIFY_CART_ERROR,
				payload: error.response,
			});
		});
};
