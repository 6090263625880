import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Shared Components
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import ScrollToTop from "../components/scrollTop/scrollTop";
import ProtectedRoute from "../components/protected/protected";

// Pages
import Home from "../pages/home/home";
import PublishedContainer from "../pages/publishedEvent/shop";
import Category from "../pages/category/category";
import SubCategory from "../pages/subCategory/subCategory";
import SubSubCategory from "../pages/subSubCategory/subSubCategory";
import Influencers from "../pages/Influencers/influencers";
import SubInfluencer from "../pages/subInfluener/subInfluener";
import ComingSoon from "../pages/comingSoon/comingSoon";
import PageNotFound from "../pages/404/404";
import AboutUsPage from "../pages/aboutUs/aboutUs";
import PrivacyPolicyPage from "../pages/help/privacyPolicy";
import TermsServicePage from "../pages/help/termsAndCondition";
import CommunityGuide from "../pages/help/communityGuidelines";
import ContactUsPage from "../pages/help/contact";
import FaqsPage from "../pages/help/faq";
import Logout from "../pages/logout/logout";
import Password from "../pages/password/password.js";
import Shows from "../pages/shows/shows";
import LiveEvent from "../pages/liveEvent/shop";
import UpComingEvent from "../pages/upComingEvent/upcomingEvent";
// import Saved from "../pages/saved/shows.js"
import InfluencerReviews from "../pages/reviews/shop";
import BrandOrInf from "../pages/brandorInf/brandorInf";
// BioShop Pages
import BrandEvents from "../pages/bioShop/liveEvents";
import InfReviews from "../pages/bioShop/reviews";

// Auth Pages
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import ChangePassword from "../pages/auth/changePassword";
import ResetPassword from "../pages/auth/resetPassword";
import EmailVerify from "../pages/emailVerify/emailVerify.js";
// Account Pages
import AccountSettings from "../pages/account/accountSettings"
import Dashboard from "../pages/account/dashboard";
import Account from "../pages/account/account";
import AccountDetail from "../pages/account/accountDetail";
import Wallet from "../pages/account/wallet";
import BrandWallet from "../pages/account/brandWallet";
import Earning from "../pages/account/earning";
import BecomeInfluencer from "../pages/account/becomeInfluencer";
import Purchase from "../pages/account/purchase";
import Payment from "../pages/account/payment";
import UploadVid from "../pages/account/uploadVid.js";
import ShippingAddress from "../pages/account/shippingAddress";
import ManageCategory from "../pages/account/ManageCategory";
import ReferralStats from "../pages/account/referralStats";
import MediaGallery from "../pages/account/mediaGallery";
import Interest from "../pages/account/interest";
import DeleteAccount from "../pages/account/deleteAccount";
import ReviewSale from "../pages/account/reviewSale";
import WithdrawRequest from "../pages/account/withdrawRequest";
// import Starter from "../pages/starter/starter";
import Starter2 from "../pages/starter2/starter2";
import Payout from "../pages/account/payout";
import Test from "../pages/test/test";
import ReviewList from "../pages/account/reviewList.js";
import BrandsRetailer from "../pages/brand/brandRetailer.js";
import InfluncerAndCreator from "../pages/brand/influencerAndCreator.js";
import RegisterPart from "../pages/register/register.js";
import ShareEarn from "../pages/account/shareearn.js";
import Saved from "../pages/account/saved.js"
import SavedEvent from "../pages/account/savedEvent.js"
const Router = () => {

  return (
    <>
      <BrowserRouter>
        <Header />

        <ScrollToTop>
          <Routes>
            <Route path="/:username" element={<BrandOrInf />} />
            <Route path="/" element={<Starter2 />} />
            {/* <Route path="/home2" element={<Starter2 />} /> */}

            {/* <Route path="/:username" element={<InfReviews name="review" />} /> */}
            <Route path="/reviews/:username" element={<BrandOrInf name="share" />} />



            <Route path="/published-events/:id" element={<PublishedContainer />} />
            <Route path="/brand-events/:username" element={<BrandEvents />} />

            <Route path="/brand-recorded/:username" element={<InfReviews name="recorded" />} />
            <Route path="/brand-live/:username" element={<InfReviews name="live" />} />
            <Route path="/brand-upcoming/:username" element={<InfReviews name="upcoming" />} />
            <Route path="/category" exact element={<Category />} />
            <Route path="/sub-category/:id" element={<SubCategory />} />
            <Route path="/sub-sub-category/:id/:category" element={<SubSubCategory />} />
            <Route path="/influencers" pageId="influencers-page" element={<Influencers />} />
            <Route path="/sub-influencer-category/:id" key={1} element={<SubInfluencer />} />
            <Route path="/sub-influencer-sub-category/:id/:category" key={2} element={<SubInfluencer />} />
            <Route path="/brand-retailer" element={<BrandsRetailer />} />
            <Route path="/influencer-creators" element={<InfluncerAndCreator />} />
            <Route path="/brands" element={<Home />} />
            <Route path="/email-verification" element={<EmailVerify />} />

            <Route path="/shows" element={<Shows />} />
            <Route path="/feeds" element={<Shows name="feeds" />} />
            <Route path="/review" element={<Shows name="review" />} />
            <Route path="/upcoming" element={<Shows name="upcoming" />} />
            <Route path="/live" element={<Shows name="live" />} />
            <Route path="/recorded" element={<Shows name="recorded" />} />
           
            <Route path="/newrecorded" element={<Shows name="newrecorded" />} />
            {/* <Route path="/saved" element={<Saved name="saved" />} /> */}
            {/* <Route path="/saved-events" element={<Saved name="savedevents" />} /> */}
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/password-reset/:id" element={<ResetPassword />} />
            <Route path="/logout" element={<Logout />} />

            <Route path="/saved" element={<ProtectedRoute><Saved /></ProtectedRoute>} />
            <Route path="/saved-events" element={<ProtectedRoute><SavedEvent /></ProtectedRoute>} />

            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/myaccount" element={<ProtectedRoute><AccountDetail /></ProtectedRoute>} />
            <Route path="/accountdetail" element={<ProtectedRoute><Account /></ProtectedRoute>} />
            <Route path="/mywallet" element={<ProtectedRoute><Wallet /></ProtectedRoute>} />
            <Route path="/brandwallet" element={<ProtectedRoute><BrandWallet /></ProtectedRoute>} />
            <Route path="/earned-commission" element={<ProtectedRoute><Earning /></ProtectedRoute>} />
            <Route path="/purchase" element={<ProtectedRoute><Purchase /></ProtectedRoute>} />
            <Route path="/withdraw-request" element={<ProtectedRoute><WithdrawRequest /></ProtectedRoute>} />
            <Route path="/become-influencer" element={<ProtectedRoute><BecomeInfluencer /></ProtectedRoute>} />

            <Route path="/share-earn" element={<ProtectedRoute><ShareEarn /></ProtectedRoute>} />
            <Route path="/account-settings" element={<ProtectedRoute><AccountSettings /></ProtectedRoute>} />
            <Route path="/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
            <Route path="/delete-account" element={<ProtectedRoute><DeleteAccount /></ProtectedRoute>} />
            <Route path="/mediagallery" element={<ProtectedRoute><MediaGallery /></ProtectedRoute>} />
            <Route path="/interest" element={<ProtectedRoute><Interest /></ProtectedRoute>} />
            <Route path="/shippingaddress" element={<ProtectedRoute><ShippingAddress /></ProtectedRoute>} />
            <Route path="/managecategory" element={<ProtectedRoute><ManageCategory /></ProtectedRoute>} />
            <Route path="/referralstats" element={<ProtectedRoute><ReferralStats /></ProtectedRoute>} />
            <Route path="/reviewsale" element={<ProtectedRoute><ReviewSale /></ProtectedRoute>} />
            <Route path="/payout" element={<ProtectedRoute><Payout /></ProtectedRoute>} />
            <Route path="/reviews-list" element={<ProtectedRoute><ReviewList /></ProtectedRoute>} />
            <Route path="/upload-video" element={<ProtectedRoute><UploadVid /></ProtectedRoute>} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-service" element={<TermsServicePage />} />
            <Route path="/community-guidelines" element={<CommunityGuide />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/faqs" element={<FaqsPage />} />
            <Route path="/test" element={<Test />} />
            <Route path="/live-event/:id/:username" element={<LiveEvent />} />
            <Route path="/upcoming-event/:id/:username" element={<UpComingEvent />} />
            <Route path="/reviews/:id/:brandId" element={<InfluencerReviews />} />
            <Route path="/reviews/:id/:brandId/:influencer_id" element={<InfluencerReviews />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/password" element={<Password />} />
            <Route path="/register-partner" element={<RegisterPart />} />
          </Routes>
        </ScrollToTop>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default Router;
