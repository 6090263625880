import React, { useEffect, useState } from "react";
// import { openLogin } from "../../../redux/actions/login.action";
import { addUrl, shareVideo } from "../../../redux/actions/bioShop.action";
import { Select, InputNumber, Space, Spin, Form, Empty } from "antd";
import ShortUrl from "../../../components/shortUrl/shortUrl";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ShippingAddressModal from "../../account/component/shippingAddress";
import { emptyCart } from "../../../redux/actions/shopifyRemoveCart.action";
import PaymentMethodModal from "../../account/component/payment";
import { openRegister, displayModal } from "../../../redux/actions/register.action";
import numeral from "numeral";
import Swal from "sweetalert2";
const { Option } = Select;

export default function Shop({ setBrandID, reviewId, price, addToBag, addBag, setLineItem, quantity, setQty, clear, shopifyProduct, stp1, stp2, stp3, setStp1, setStp2, setStp3, setClear, liveEventId, userInfo, eventId, lineItem, product, buy }) {
	const dispatch = useDispatch();
	const [form] = Form.useForm(); // Create a form instance using Form.useForm()
	const [showShare, setShowShare] = useState(false);
	const [refPercent, setRefPercent] = useState("");
	const [chosenProduct, setChosenProduct] = useState([]);
	const [allowAddToCart, setAllowAddToCart] = useState(true);

	useEffect(() => {
		if (chosenProduct?.inventory_quantity > 0) {
			setAllowAddToCart(false);
		} else {
			setAllowAddToCart(true);
		}
	}, [chosenProduct]);

	useEffect(() => {
		form.resetFields();
		setLineItem([]);
		setChosenProduct([]);

		const totalInventory = shopifyProduct?.payload?.message?.variants?.reduce((acc, variant) => acc + variant?.inventory_quantity, 0);

		if (totalInventory) {
			setChosenProduct({ inventory_quantity: totalInventory });
		}

		const newLineItems = [];

		shopifyProduct?.payload?.message?.options?.forEach((pro) => {
			if (pro?.values?.length === 1) {
				setFieldValue(pro?.name, pro?.values[0]);

				// Create a new line item object
				const newLineItem = { name: pro?.name, value: pro?.values[0] };

				// Push the new line item to the array
				newLineItems.push(newLineItem);
			}
		});

		// Set the updated line items after processing all options
		setLineItem(newLineItems);
	}, [shopifyProduct]);

	const setFieldValue = (fieldKey, fieldValue) => {
		const updatedValues = {};
		updatedValues[fieldKey] = fieldValue;
		form.setFieldsValue(updatedValues);
	};

	const handleKeyDown = (e) => {
		// Check if the key is not a number
		if ((e.key < "0" || e.key > "9") && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
			// Prevent the default action (i.e., prevent the key from being entered)
			e.preventDefault();
		}
		if (e?.target?.value === "") {
			setAllowAddToCart(true);
		} else {
			setAllowAddToCart(false);
		}
	};

	function findVariant(productData, criteria) {
		if (criteria?.length === shopifyProduct?.payload?.message?.options?.length) {
			let data = productData?.variants?.find((variant) => {
				return criteria.every((criterion) => {
					for (let i = 1; i <= productData.options.length; i++) {
						const optionKey = `option${i}`;
						const optionName = productData.options.find((opt) => opt.position === i)?.name;
						if (optionName === criterion.name && variant[optionKey]?.toLowerCase() === criterion.value?.toLowerCase()) {
							return true;
						}
					}
					return false;
				});
			});

			const cartVariantQuantity = getShopifyLiveCart?.payload?.message?.lineItems
				?.filter((item) => item.variant?.id === data?.id)
				.map((item) => item.quantity)
				.reduce((total, quantity) => total + quantity, 0);

			if (cartVariantQuantity && data.inventory_quantity >= 1) {
				const stock = cartVariantQuantity ? data?.inventory_quantity - cartVariantQuantity : 0;
				const newData = { ...data };
				newData.inventory_quantity = stock;
				setChosenProduct(newData);
			} else {
				setChosenProduct(data);
			}

			if (data) {
				return { data, selectedOptions: criteria, handle: productData.handle, image: productData?.image };
			}
		}
	}

	const onFinish = async (values) => {
		var userInfo = JSON.parse(localStorage.getItem("userInfo"));
		let brandId = eventId[0]?.brand[0]?._id ? eventId[0]?.brand[0]?._id : eventId[0]?.brand?._id;
		setBrandID(brandId);

		if (userInfo?.brand !== undefined && userInfo?.brand !== brandId) {
			Swal.fire({
				title: "Do you want to add this product?",
				text: "Items from a different brand are still in your cart. Proceeding will remove all previous items.",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#010b40",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes, proceed!",
			}).then((result) => {
				if (result.isConfirmed) {
					dispatch(emptyCart(userInfo?.brand, userInfo?.token)).then(() => {
						addToBag(product?.images[0]?.src, product?.title, product?.tags, clear, findVariant(product, lineItem));
					});
				}
			});

			return;
		}

		addToBag(product?.images[0]?.src, product?.title, product?.tags, clear, findVariant(product, lineItem));
	};
	const onFinishFailed = (errorInfo) => console.log("Failed:", errorInfo);
	const { shopifyaddToCart, influencerSingle, account, bioShopMenu, getShopifyLiveCart } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		if (lineItem?.length > 0) {
			findVariant(product, lineItem);
		}
		setQty(1);
	}, [lineItem, getShopifyLiveCart]);

	const share = (refPercent) => {
		let url = window.location.href;
		setRefPercent(refPercent);
		if (!userInfo) {
			dispatch(openRegister(true));
		} else {
			dispatch(addUrl(userInfo.token, shareAction(url))).then((res) => {
				dispatch(shareVideo(userInfo.token, shareAction(url)));
			});
			setShowShare(true);
			shareAction(url);
		}
	};

	const shareAction = (url) => {
		const newUrl = url;
		const newRedirectUrl = new URL(newUrl);
		newRedirectUrl.searchParams.set("pixel_id", userInfo?.pixel_id);
		newRedirectUrl.searchParams.set("publisher_id", userInfo?._id);
		newRedirectUrl.searchParams.set("advertiser_id", eventId[0]?.brand[0]?._id ? eventId[0]?.brand[0]?._id : eventId[0]?.brand?._id);
		newRedirectUrl.searchParams.set("category_id", eventId[0]?.category_id);
		newRedirectUrl.searchParams.set(reviewId ? "review_id" : "event_id", eventId[0]?._id);
		newRedirectUrl.searchParams.set("review_id", eventId[0]?._id);
		newRedirectUrl.searchParams.set("roiEvent", true);
		newRedirectUrl.searchParams.set("brand", eventId[0]?.brand[0]?._id ? eventId[0]?.brand[0]?._id : eventId[0]?.brand?._id);
		newRedirectUrl.searchParams.set("event", eventId[0]?.brand[0]?._id ? "eventshare" : "reviewshare");
		return newRedirectUrl.toString();
	};

	const stock = () => {
		if (chosenProduct?.inventory_quantity > 0)
			return (
				<>
					<span className="shp-price">
						Stock:{" "}
						<span style={{ color: "red" }}>
							{chosenProduct?.inventory_quantity}
							&nbsp;Units
						</span>
					</span>
				</>
			);
		else {
			return (
				<span className="shp-price">
					Stock: <span style={{ color: "red" }}>Out of Stock</span>
				</span>
			);
		}
	};

	return (
		<>
			<div className="prod-for-purchase">
				<h4>Products</h4>
				<span className="available-for-purchase">Products available for purchase</span>
				{!stp1 && (
					<span
						onClick={() => {
							setLineItem([]);

							if (stp2) {
								setStp1(true);
								setStp2(false);
								setStp3(false);
								setClear(false);
							} else if (stp3) {
								setStp1(false);
								setStp2(true);
								setStp3(false);
								setClear(false);
							}
						}}
						className="go-back"
					>
						<i className="fa fa-arrow-left" aria-hidden="true"></i> Back
					</span>
				)}
			</div>
			{/* Dynamic */}
			{stp1 && (
				<div className="pro-list-main">
					<div className="pro-list-inner">
						{!liveEventId.loading ? (
							<>
								{eventId &&
									eventId[0]?.products?.map((item, i) => {
										return (
											<>
												<div className="list-col">
													<div className="prod-box">
														<div className="prod-image">
															<img src={`${item?.mediaUrl}`} />
														</div>
														<div className="prod-name">
															<div className="prod-title">
																<span className="prod-name-inr">{item?.ProductName}</span>
															</div>
															<div className="prod-price">
																<span className="prod-inr-price">
																	<span>{numeral(item?.price).format("$0,0.0'")}</span>
																</span>
															</div>

															{window.location.href.includes("published-events") || window.location.href.includes("live-event") ? (
																<>
																	{eventId?.[0]?.influencer_id && eventId?.[0]?.influencer_id === account?.payload?.Data?._id && bioShopMenu?.payload?.message?.data?._id === eventId?.[0]?.influencer_id && eventId[0]?.brand?.[0]?.fee_structure?.content_creator_fee && eventId[0].brand[0].fee_structure.content_creator_fee !== "0" ? <span className="inf-fee">{eventId?.[0]?.brand?.[0]?.fee_structure?.content_creator_fee}% Creator Fee</span> : null}

																	{eventId?.[0]?.brand?.[0]?.fee_structure?.referral_fee ? (
																		eventId?.[0]?.brand?.[0]?.fee_structure?.referral_fee !== "0" && eventId?.[0]?.brand?.[0]?.fee_structure?.referral_fee !== "undefined" && eventId?.[0]?.brand?.[0]?.fee_structure?.referral_fee ? (
																			<div className="prod-inf-ref-fee">
																				<span className="inf-fee">{eventId[0]?.brand[0]?.fee_structure?.referral_fee}% Referral Fee</span>
																			</div>
																		) : (
																			""
																		)
																	) : (
																		""
																	)}

																	{eventId?.[0]?.brand?.[0]?.fee_structure?.shopper_cashback ? (
																		eventId?.[0]?.brand?.[0]?.fee_structure?.shopper_cashback !== "0" && eventId?.[0]?.brand[0]?.fee_structure?.shopper_cashback !== "undefined" && eventId?.[0]?.brand?.[0]?.fee_structure?.shopper_cashback ? (
																			<div className="prod-cash-back-fee">
																				<span className="inf-fee">{eventId?.[0]?.brand?.[0]?.fee_structure?.shopper_cashback}% Cashback</span>
																			</div>
																		) : (
																			""
																		)
																	) : (
																		""
																	)}
																</>
															) : (
																<>
																	<div className="fee-box-main d-flex justify-content-between align-items-end">
																		<div className="fee-box-left">
																			{eventId[0]?.brand?.fee_structure?.referral_fee ? (
																				eventId[0]?.brand?.fee_structure?.referral_fee !== "0" && eventId[0]?.brand?.fee_structure?.referral_fee !== "undefined" && eventId[0]?.brand?.fee_structure?.referral_fee ? (
																					<div className="prod-inf-ref-fee">
																						<span className="inf-fee">{eventId[0]?.brand?.fee_structure?.referral_fee}% Referral Fee</span>
																					</div>
																				) : (
																					""
																				)
																			) : (
																				""
																			)}

																			{eventId[0]?.brand?.fee_structure?.shopper_cashback ? (
																				eventId[0]?.brand?.fee_structure?.shopper_cashback !== "0" && eventId[0]?.brand?.fee_structure?.shopper_cashback !== "undefined" && eventId[0]?.brand?.fee_structure?.shopper_cashback ? (
																					<div className="prod-cash-back-fee">
																						<span className="inf-fee">{eventId[0]?.brand?.fee_structure?.shopper_cashback}% Cashback</span>
																					</div>
																				) : (
																					""
																				)
																			) : (
																				""
																			)}
																		</div>

																		<div className="fee-box-right">
																			{eventId[0]?.influencer_id === account?.payload?.Data?._id && eventId[0]?.influencer_id === bioShopMenu?.payload?.message?.data?._id && eventId[0]?.brand?.fee_structure?.content_creator_fee ? (
																				eventId[0]?.brand?.fee_structure?.content_creator_fee !== "0" && eventId[0]?.brand?.fee_structure?.content_creator_fee !== "undefined" && eventId[0]?.brand?.fee_structure?.content_creator_fee ? (
																					<div className="prod-creator-fee">
																						<span className="inf-fee">{eventId[0]?.brand?.fee_structure?.content_creator_fee}% Creator Fee </span>
																					</div>
																				) : (
																					""
																				)
																			) : (
																				""
																			)}
																		</div>
																	</div>
																</>
															)}

															{/* <>
                                {page === "review" ? (
                                  <>
                                    {eventId[0]?.influencer_id === account?.payload?.Data?._id && account?.payload?.Data?.account_type !== "customer" ? (
                                      <>
                                        {eventId[0]?.influencer_percent && eventId[0]?.influencer_percent !== "0" ? (
                                          <div className="prod-inf-ref-fee">
                                            <span>{eventId[0]?.influencer_percent}% Influencer Fee</span>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : eventId[0]?.referral_percent && eventId[0]?.referral_percent !== "0" && eventId[0]?.referral_percent !== "undefined" ? (
                                      <>
                                        {eventId[0]?.referral_percent ? (
                                          <div className="prod-inf-ref-fee">
                                            <span>{eventId[0]?.referral_percent}% Referral Fee</span>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {
                                      eventId[0]?.referral_percent !== "0" && eventId[0]?.referral_percent !== "undefined" && eventId[0]?.referral_percent ? (
                                        <div className="prod-inf-ref-fee">
                                          <span>{eventId[0]?.referral_percent}% Referral Fee</span>
                                        </div>
                                      ) : (
                                        ""
                                      )

                                    }

                                  </>
                                )}
                              </> */}
														</div>
														<div className="prod-cart-btn">
															<a
																target="_blank"
																onClick={(e) => {
																	if (!userInfo) {
																		dispatch(openRegister(true));
																	} else {
																		if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
																			dispatch(displayModal("register", true));
																		} else {
																			buy(item?.productId, eventId[0]?.brand[0]?._id ? eventId[0]?.brand[0]?._id : eventId[0]?.brand?._id, item?.price);
																		}
																	}
																}}
															>
																Buy Now
															</a>
															<a
																href="#"
																className="share-product-oncart"
																// onClick={() => {
																// 	share(item?.ProductUrl, item?.id);
																// }}
																onClick={() => {
																	// share(eventId[0]?.referral_percent);

																	if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
																		dispatch(displayModal("register", true));
																	} else {
																		share(eventId[0]?.brand[0]?.fee_structure?.referral_fee ? eventId[0]?.brand[0]?.fee_structure?.referral_fee : eventId[0]?.brand?.fee_structure?.referral_fee);
																	}
																}}
															>
																Share
															</a>
														</div>
													</div>
												</div>
											</>
										);
									})}
							</>
						) : (
							<>
								<div className="load-container">
									<Spin size="medium"></Spin>
								</div>
							</>
						)}
					</div>
				</div>
			)}

			{stp2 && (
				<div className="checkout-container">
					<div className="checkout-list-container">
						<div className="checkout-list-inner">
							{shopifyProduct?.loading || shopifyaddToCart?.loading || influencerSingle?.loading ? (
								<>
									<div className="load-container">
										<Spin size="medium"></Spin>
									</div>
								</>
							) : (
								<>
									{shopifyProduct?.payload?.data?.code === "PAYMENT_METHOD_ERROR" || shopifyProduct?.payload?.data?.code === "SHIPPING_ERROR" ? (
										<>
											{shopifyProduct?.payload?.data?.code === "PAYMENT_METHOD_ERROR" && (
												<div className="payment-from-front">
													<PaymentMethodModal cart={true} />
												</div>
											)}
											{shopifyProduct?.payload?.data?.code === "SHIPPING_ERROR" && (
												<div className="shipping-from-front">
													<ShippingAddressModal mode="add" page={"shop"} />
												</div>
											)}
										</>
									) : (
										<>
											{shopifyProduct?.payload?.message === null ? (
												<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
											) : (
												<Form
													form={form} // Assign the created form instance to the form prop
													name="add-to-bag"
													onFinish={onFinish}
													onFinishFailed={onFinishFailed}
													autoComplete="off"
													layout="vertical"
												>
													<div className="product-check-detail">
														<div className="prod-image">
															<div className="prod-image-left">
																<img src={`${product?.images[0]?.src}`} />
															</div>
															<div className="prod-title-price">
																<h6>{product?.title}</h6>
																<span className="shp-price">
																	Price:&nbsp;
																	{chosenProduct?.price?.length ? numeral(chosenProduct?.price).format("$0,0.0'") : numeral(price).format("$0,0.0'")}
																</span>
																{stock()}
																<span className="shp-price">
																	SKU:
																	{" " + product?.variants?.[0]?.sku}
																</span>
															</div>
														</div>
														<div className="prod-veriants">
															{product?.options?.map((item, key) => {
																return (
																	<div key={key} className="variant-column">
																		<Form.Item
																			name={item?.name}
																			label={item.name}
																			rules={[
																				{
																					required: true,
																					message: `Please insert ${item.name}`,
																				},
																			]}
																		>
																			<Select
																				optionFilterProp="children"
																				onChange={(value) => {
																					let lnItem = lineItem.filter((itm) => {
																						if (lineItem?.length > 0) return itm?.name !== item?.name;
																					});
																					setLineItem([...lnItem, { name: item?.name, value: value }]);
																				}}
																				placeholder={`Select ${item?.name}`}
																				size="large"
																				style={{ width: "100%", zIndex: 1033390 }}
																			>
																				{item?.values?.map((item) => {
																					return <Option value={item}>{item}</Option>;
																				})}
																			</Select>
																		</Form.Item>
																	</div>
																);
															})}

															<div className="variant-column">
																<Form.Item name={"qty"} label="Quantity">
																	<Space
																		direction="vertical"
																		style={{
																			width: "100%",
																		}}
																	>
																		<InputNumber
																			style={{
																				width: "100%",
																				border: "1px solid #000",
																			}}
																			onKeyUp={handleKeyDown} // Use the custom handler
																			size="large"
																			min={1}
																			value={quantity}
																			disabled={chosenProduct?.inventory_quantity > 0 ? false : true}
																			max={100000}
																			defaultValue={1}
																			onChange={(qty) => {
																				if (qty > chosenProduct?.inventory_quantity) {
																					setAllowAddToCart(true);
																					Swal.fire("Out of Stock!", "Quantity is Greater than Stock Quantity", "error");
																				} else {
																					setAllowAddToCart(false);
																				}

																				setQty(Math.floor(qty));
																			}}
																		/>
																	</Space>
																</Form.Item>
															</div>
															<div className="variant-column variant-gap">
																<div
																	dangerouslySetInnerHTML={{
																		__html: product?.body_html,
																	}}
																/>
															</div>

															<div className="checkout-buttons">
																<Button disabled={allowAddToCart} type="primary" htmlType="submit">
																	ADD TO CART
																</Button>
															</div>
														</div>
													</div>
												</Form>
											)}
										</>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			)}
			{/* Static */}
			{stp3 && (
				<div className="pro-list-main">
					<h2>Cart</h2>
					<div className="pro-list-inner">
						{addBag?.map((item) => {
							return (
								<div className="list-col">
									<div className="prod-box">
										<div className="prod-image">
											<img src={`${item?.source}`} />
										</div>
										<div className="prod-name">
											<div className="prod-title">
												<span className="prod-name-inr">{item?.title}</span>
												<span className="prod-veriants-inr"></span>
											</div>
											<div className="prod-price">
												<span className="prod-inr-price">
													<span>{numeral(item?.price).format("$0,0.0'")}</span>
												</span>
											</div>
											<div className="qty-rmv-btn">
												<Space>
													<InputNumber size="medium" min={1} max={100000} defaultValue={1} />
												</Space>
												<a href="javascript:void" className="remove-btn">
													Remove
												</a>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
					<div className="checkout-buttons">
						<Button>CHECKOUT</Button>
					</div>
				</div>
			)}
			<ShortUrl url={window.location.href} showShare={showShare} setShowShare={setShowShare} referral_percent={refPercent} />
		</>
	);
}
