import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { notification, Spin } from "antd";
export default function ({ showShare, setShowShare, referral_percent }) {
  const { url } = useSelector((state) => state);
  const Urls = url?.payload?.message;
  const copyToClipboard = (url) => {
    let textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    notification.success({ message: "Copied to Clipboard!" });
    setShowShare(false);
  };

  return (
    <Modal
      backdropClassName="custom-backdrop"
      show={showShare}
      onHide={() => {
        setShowShare(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Share</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="copy-popup-text">Share video with your friends, family, and followers to earn a fee. Copy below link and share via mail, text, and your social media channels.

          {referral_percent && referral_percent !== 0 && (
            <span>
              The referral fee for sharing this video is {referral_percent + "%"}
            </span>
          )}
        </p>
        {!url?.loading ? (
          <div className="your-copy-link">
            <div className="item-a">
              <a target="_blank" rel="noreferrer" href={Urls}>
                {Urls}
              </a>
            </div>
            <div onClick={() => copyToClipboard(Urls)} className="item-b">
              Copy
            </div>
          </div>
        ) : (
          <div>
            <Spin className="d-flex justify-content-center" size="medium" />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
