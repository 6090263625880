import React, { useState, useEffect } from "react";
import { DatePicker, Button, Form, Tabs, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping, faHandHoldingDollar, faDollarSign, faPercent, faMoneyBillTransfer, faListSquares, faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardEvent, getDashboardSocial } from "../../../redux/actions/dashboard.action";
import moment from "moment";
import numeral from "numeral";
import "../../../css/account-setup.scss";
const { TabPane } = Tabs;

const { RangePicker } = DatePicker;
const dateFormat = "MM-DD-YYYY";

export default function Dashboard() {
  const { dashboardPayloadEvent, account } = useSelector((state) => state);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  const [form] = Form.useForm();
  const [formone] = Form.useForm();
  const dispatch = useDispatch();

  const fromDate = moment().startOf("year").format("YYYY-MM-DD");
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const getSum = (a1, a2) => parseFloat(a1) + parseFloat(a2);

  // const [dateType, setDateType] = useState("mtd");

  // const { purchaseReport } = useSelector((state) => {
  // 	return state;
  // });

  useEffect(() => {
    if (userInfo.token) {
      dispatch(getDashboardEvent(startDate, endDate, userInfo?.token));
    }
  }, []);

  const onFinish = async () => {
    if (userInfo.token) dispatch(getDashboardEvent(startDate, endDate, userInfo?.token));
  };

  const onFinishFailed = (errorInfo) => {};

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = moment(dataString[0]).format("YYYY-MM-DD");
		const endDate = moment(dataString[1]).format("YYYY-MM-DD");

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const reset = () => {
    if (userInfo.token) dispatch(getDashboardEvent(fromDate, toDate, userInfo?.token));
    form.resetFields();
    setStartDate(fromDate);
    setEndDate(toDate);
  };

  const resetsocial = () => {
    if (userInfo.token) dispatch(getDashboardSocial(fromDate, toDate, userInfo?.token));
    formone.resetFields();
    setStartDate(fromDate);
    setEndDate(toDate);
  };
  
  const totalWithdraw =
  dashboardPayloadEvent?.payload?.message?.earnings?.available_to_withdraw[0]?.cashback_amount +
  dashboardPayloadEvent?.payload?.message?.earnings?.available_to_withdraw[0]?.creator_commission +
  dashboardPayloadEvent?.payload?.message?.earnings?.available_to_withdraw[0]
    ?.customer_commission -
    dashboardPayloadEvent?.payload?.message?.earnings?.withdraw_amount;

  return (
    <>
      <div className="account-left-content">
        <div className="favourite-brands-container">
          <div>
            <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
              <div className="new-dash-content container ml-0 p-0">
                <div className="row">
                  <div className="col-xl-3 col-md-6 col-12 mb-4">
                    <p>Select Start Date / End Date</p>
                    <Form.Item name="date">
                      <RangePicker
                        size="large"
                        className="w-100"
                        key={4}
                        value={startDate && endDate ? [moment(startDate), moment(endDate)] : []}
                        defaultValue={startDate && endDate ? [moment(startDate), moment(endDate)] : []}
                        allowClear={false}
                        ranges={{
                          "Last Year": [moment().subtract(1, "year").startOf("year"), moment().subtract(1, "year").endOf("year")],
                          "This Year": [moment().startOf("year"), moment()],
                          "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
                          "This Month": [moment().startOf("month"), moment().endOf("month")],
                          Today: [moment(), moment()],
                        }}
                        format={dateFormat}
                        onChange={dateRangePickerChanger}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end srh-btn mb-4">
                    <Button disabled={dashboardPayloadEvent?.loading} htmlType="submit" type="primary" size="large" className="m-width-100">
                      Search
                    </Button>
                    <Button
                      disabled={dashboardPayloadEvent?.loading}
                      onClick={() => {
                        reset();
                      }}
                      type="primary"
                      size="large"
                    >
                      Reset
                    </Button>
                  </div>
                </div>

                <div className="row dash-boxes mt-2">
                  <div className="col-12 mb-3">
                    <h2 className="title-hd">Purchases</h2>
                  </div>
                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon className="info-icon-main" icon={faListSquares} />
                      <div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.purchases?.[0]?.number_of_orders ? dashboardPayloadEvent?.payload?.message?.purchases?.[0]?.number_of_orders : "0").format("0")}</div>}</div>
                      <div className="total-info-hd">Number of Orders</div>
                    </div>
                  </div>

                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon className="info-icon-main" icon={faBagShopping} />
                      <div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.purchases?.[0]?.total_amount ? dashboardPayloadEvent?.payload?.message?.purchases?.[0]?.total_amount : "0.00").format("$0,0.0'")}</div>}</div>
                      <div className="total-info-hd">Total Amount</div>
                    </div>
                  </div>

                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon className="info-icon-main" icon={faHandHoldingDollar} />
                      <div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.purchases?.[0]?.cashback_amount).format("$0,0.0'")}</div>}</div>
                      <div className="total-info-hd">Total Cashback</div>
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <h2 className="title-hd">Referral Sales</h2>
                  </div>
                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon className="info-icon-main" icon={faListSquares} />
                      <div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.referral_sales?.[0]?.number_of_orders ? dashboardPayloadEvent?.payload?.message?.referral_sales?.[0]?.number_of_orders : "0").format("0")}</div>}</div>
                      <div className="total-info-hd">Number of Orders</div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon className="info-icon-main" icon={faPercent} />
                      <div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.referral_sales?.[0]?.total_amount ? dashboardPayloadEvent?.payload?.message?.referral_sales?.[0]?.total_amount : "0.00").format("$0,0.0'")}</div>}</div>
                      <div className="total-info-hd">Total Sales</div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon className="info-icon-main" icon={faHandHoldingDollar} />
                      <div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.referral_sales?.[0]?.customer_commission ? dashboardPayloadEvent?.payload?.message?.referral_sales?.[0]?.customer_commission : "0.00").format("$0,0.0'")}</div>}</div>
                      <div className="total-info-hd">Total Earnings</div>
                    </div>
                  </div>

                  {account?.payload?.Data?.account_type === "influencer" ? (
                    <>
                      <div className="col-12 mb-3">
                        <h2 className="title-hd">Creator Sales</h2>
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                        <div className="dashboard-info-box">
                          <FontAwesomeIcon className="info-icon-main" icon={faListSquares} />
                          <div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.content_sales?.[0]?.number_of_orders ? dashboardPayloadEvent?.payload?.message?.content_sales?.[0]?.number_of_orders : "0").format("0")}</div>}</div>
                          <div className="total-info-hd">Number of Orders</div>
                        </div>
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                        <div className="dashboard-info-box">
                          <FontAwesomeIcon className="info-icon-main" icon={faPercent} />
                          <div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.content_sales?.[0]?.total_amount ? dashboardPayloadEvent?.payload?.message?.content_sales?.[0]?.total_amount : "0.00").format("$0,0.0'")}</div>}</div>
                          <div className="total-info-hd">Total Sales</div>
                        </div>
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                        <div className="dashboard-info-box">
                          <FontAwesomeIcon className="info-icon-main" icon={faHandHoldingDollar} />
                          <div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.content_sales?.[0]?.creator_commission ? dashboardPayloadEvent?.payload?.message?.content_sales?.[0]?.creator_commission : "0.00").format("$0,0.0'")}</div>}</div>
                          <div className="total-info-hd">Total Earnings</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="col-12 mb-3">
                    <h2 className="title-hd">Earnings</h2>
                  </div>
                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon className="info-icon-main" icon={faHandHoldingDollar} />

                      <div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(totalWithdraw).format("$0,0.0'")}</div>}</div>
                      <div className="total-info-hd">Available to Withdraw</div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon className="info-icon-main" icon={faDollarSign} />
                      <div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.earnings?.pending_balance?.[0]?.customer_commission + dashboardPayloadEvent?.payload?.message?.earnings?.pending_balance?.[0]?.cashback_amount + dashboardPayloadEvent?.payload?.message?.earnings?.pending_balance?.[0]?.creator_commission).format("$0,0.0'")}</div>}</div>
                      <div className="total-info-hd">Pending Balance</div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon className="info-icon-main" icon={faSackDollar} />
                      <div className="value-info-hd">{dashboardPayloadEvent?.loading ? <Spin size="small" /> : <div>{numeral(dashboardPayloadEvent?.payload?.message?.earnings?.lifetime_earning?.[0]?.customer_commission + dashboardPayloadEvent?.payload?.message?.earnings?.lifetime_earning?.[0]?.cashback_amount + dashboardPayloadEvent?.payload?.message?.earnings?.lifetime_earning?.[0]?.creator_commission).format("$0,0.0'")}</div>}</div>
                      <div className="total-info-hd">Lifetime Earnings</div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
