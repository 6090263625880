
import { POST_REVIEW_DEL_REQUEST, POST_REVIEW_DEL_SUCCESS, POST_REVIEW_DEL_ERROR } from "../../types/types";
export default function  shareDelReview(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case POST_REVIEW_DEL_REQUEST:
			return {
				loading: true,
			};
		case POST_REVIEW_DEL_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case POST_REVIEW_DEL_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}
