import { GET_EVENT_LIKES_REQUEST, GET_EVENT_LIKES_SUCCESS, GET_EVENT_LIKES_ERROR, GET_LIKES_REQUEST, GET_LIKES_SUCCESS, GET_LIKES_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getEventLikes = (token, event_id) => (dispatch) => {
	dispatch({ type: GET_EVENT_LIKES_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}likes/save `,
		headers: { Authorization: `Bearer ${token}` },
		data: { event_id },
	})
		.then((response) => {
			return dispatch({
				type: GET_EVENT_LIKES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_EVENT_LIKES_ERROR,
				payload: error.response,
			});
		});
};

export const getLike = (event_id) => (dispatch) => {
	dispatch({ type: GET_LIKES_REQUEST });

	const headers = userInfo ? { Authorization: `Bearer ${userInfo.token}` } : {};

	return axios({
		method: "POST",
		url: `${BASEURL}likes/get/${event_id}`,
		headers: headers,
		data: { event_id },
	})
		.then((response) => {
			return dispatch({
				type: GET_LIKES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_LIKES_ERROR,
				payload: error.response,
			});
		});
};
