export default () => {
	return (
		<>
			<h3>Community Guidelines</h3>
            <p>(Last Updated: 10.25.2023)</p>
			{/* <h5>ORMELIVE</h5> */}
            <p>The purpose of these guidelines is to guarantee that every ORMELIVE ( "ORME" ) user experiences a positive and engaging environment. By taking part in our community, you pledge to follow these guidelines and make a positive, polite, and lively contribution to it.</p>
			{/* <h5>Community Guidelines</h5> */}
            <p>The Site and App allows you to upload your videos, text, images, or other materials (collectively "Content"). Only content that respects the rights of any natural or legal person—including those related to confidentiality, intellectual property, contracts, and other rights—may be uploaded. Thus, please ensure that you yourself own the Content you upload, and if the Content depicts or was co-created together with someone else, that you have obtained consent from any person or people featured in the Content, as well as consent to upload and distribute the Content from any potential owners of rights. Also, please note that this means that it is not recommended to promote or advertise goods or services not sold via ORME Site and App, such as but not including, but not restricted to, showcasing copyrighted content or trademarks in the Content you submit.</p>
            
            <h5> 1. Content and Interaction:</h5>
            <p>Content and/or Interactions that in our sole discretion displays, promotes, or amounts to the following is prohibited:</p>
            <ul className="list-simple">
                <li>Discrimination and expression of hate on basis of gender and gender identity, race, sexual orientation, disability, physical appearance, nationality, ethnicity, familial status, age, religion, belief, education, socio-economic status, or any other legally recognized ground of discrimination.</li>
                <li>Bullying, harassment, and sexual harassment include, but are not restricted to, offensive language, name-calling, and disparaging remarks regarding one's appearance.</li>
                <li>False impersonation and misinformation, including but not limited to assuming the identity of another person, making unfounded medical claims, and publishing inaccurate or deceptive information about specific people or protected groups.</li>
                <li>Scam, fraud, and other dishonest behaviour, including but not restricted to actions that trick individuals in order to obtain unauthorised financial or personal benefits.</li>
                <li>Nudity and sexual content include, but are not limited to, materials that show sexual activity and the partial or complete lack of clothing.</li>
                <li>Violence and graphic content include, but are not restricted to, any unambiguous and uncensored portrayal of different violent acts, including the use of weapons or bodily harm.</li>
                <li>Activities that depict or continue to perpetuate abuse, harm, endangerment, or exploitation of minors are examples of grooming, sexual activity, and other harmful activities involving minors. A minor is any person who is younger than 18 years of age.</li>
                <li>Illegal activity includes, but is not limited to, advertising illicit goods and services, such as drugs and weapons.</li>
                <li> Regulated activity  such as but not limited to promoting regulated products or services including alcohol or tobacco. Content displaying, promoting or amounting to possession or consumption of alcohol or tobacco by minors or in an otherwise harmful manner is prohibited.</li>
            </ul>

            <h5> 2. Referral and Review Incentives:</h5>
             Eligibility criteria: 
            <p>The referral and review fees are available to all users who share a video or a create review for the ORME community. ORME reserves the right to offer or remove incentive to any user. All users have access to the same fees.</p>
            <p>Removal of invectives includes, but is not limited to, the following:</p>
            <ul className="list-simple">
                <li> Content   posted by users must adhere to ORME’s Terms and Community Guidelines.</li>
                <li>Spamming lowers your account's visibility in the feed and decreases the likelihood that you will get more interaction.</li>
                <li> Fraudulent activity   
                can also lead to complete account removal:</li>
                <ul className="list-simple">
                    <li>Uploading existing user content and/or removing attribution.</li>
                    <li>Creating multiple accounts to receive additional incentives.</li>
                    <li>Making several accounts using various addresses or the same one.</li>
                    <li>Engaging in fraudulent activity related to any incentive program. Any attempts to misuse or manipulate these programs may lead to the suspension of your account and the loss of any related benefits.</li>
                </ul>
                <li> Content Violations:  </li>
                <ul>
                    <li>Posting and deleting the same piece of content repeatedly</li>
                    <li>Uploading content consisting of a blank or irrelevant videos that provides no information.</li>
                    <li>Using screenshots or images in place of required videos.</li>
                </ul>
            </ul>

            <h5>3. Compliance with Laws and Regulations:</h5>
            <p>Content does not violate local, national, or international laws, statutes, rules, permits, ordinances, or regulations while using the ORME platform.</p>
            <p>Content does not interfere with or disrupt the ORME platform, its servers, or the networks connected to the ORME platform.</p>
            <p>If you detect any violations of these Community Guidelines, or if you have any questions about these guidelines, or feel that your Content been mistakenly removed or want to challenge our decision to add, modify or remove your Content, please reach out to us <a href="mailto:support@ormelive.com"  style={{fontWeight:"normal"}}>support@ormelive.com</a>.</p>
        
			{/* <h5>How to Contact Us:</h5>
			<p>Please direct any questions or comments about this Policy to:</p>
			<p>
				ORME LIVE INC.
				<br />
				121 Varick Street, 4th Floor
				<br /> New York, NY 10013
				<br /> <a href="mailto:support@ormelive.com" style={{fontWeight:"normal"}}>support@ormelive.com</a>
			</p> */}
		</>
	);
};