// React and related imports
import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { Button } from "react-bootstrap";
// Redux actions
import { getBioShopMenu } from "../../../redux/actions/bioShop.action";

// Components
import FilterModal from "../../../components/HomeShows/FilterModal";
import Follower from "../../../components/follower/follower";
import { displayModal } from "../../../redux/actions/register.action";
// Styles and assets
import "react-multi-carousel/lib/styles.css";
import "../../../css/bioshop.scss";
import avatarIcon from "../../../images/avatar.png";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export default ({ socialStore, children, setFilterPost, filterPost }) => {
	const dispatch = useDispatch();
	const { bioShopMenu, account } = useSelector((state) => state);
	const url = window.location.href;

	const sharing = () => {
		if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
			dispatch(displayModal("register", true));
		} else {
			let textField = document.createElement("textarea");
			textField.innerText = url;
			document.body.appendChild(textField);
			textField.select();
			document.execCommand("copy");
			textField.remove();
			notification.success({
				message: "Copied to Clipboard!",
			});
		}
	};

	// React-router related
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	// States
	const [showFilter, setShowFilter] = useState(false);
	const [trending, setTrending] = useState(false);
	const path = location.pathname.slice(1, location.pathname.length);

	useEffect(() => {
		if (bioShopMenu?.length == 0 || bioShopMenu?.payload?.message?.data?.instagram_username !== params.username) {
			dispatch(getBioShopMenu(params.username, userInfo?.token)).then((res) => {});
		}
	}, [params.username]);

	useEffect(() => {
		if (window.location.search) {
			setTrending(true);
		} else {
			setTrending(false);
		}
	}, [window.location.search]);

	function ComponentBioShop() {
		return (
			<div className="bio_shop_body">
				<div className="bio_shop_header">
					<div className="bio_shop_header_left">
						<div className="brand-descrp">
							<div className="brand_logo">{bioShopMenu?.payload?.message?.data?.profile_image_url && bioShopMenu?.payload?.message?.data?.profile_image_url ? <img src={bioShopMenu?.payload?.message?.data?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}</div>
							<div className="logo-right">
								<div className="current-brand-name">{bioShopMenu?.payload?.message?.data?.name}</div>
								<Follower bioShopMenu={bioShopMenu} />
							</div>
						</div>
						{bioShopMenu?.payload?.message?.data?.promo !== "KB0" && bioShopMenu?.payload?.message?.data?.promo && <div className="brand_name">GET {bioShopMenu?.payload?.message?.data?.discount} OFF USING KBSHOP APP</div>}
					</div>

					{bioShopMenu?.payload?.message?.data?.pixel_id === account?.payload?.Data?.pixel_id && (
						<div className="bio_shop_header_right">
							<Button className="text-white bg-success" onClick={sharing}>
								Share
							</Button>
						</div>
					)}

					<div className="bio_shop_header_topmenu">
						<Button
							onClick={() => {
								setShowFilter(true);
							}}
						>
							Filters
						</Button>
						<Button
							className={path == `${params.username}` ? "active" : ""}
							onClick={() => {
								navigate(`/${params.username}`);
							}}
						>
							Shop
						</Button>

						<Button
							className={path == `reviews/${params.username}` ? "active" : ""}
							onClick={() => {
								navigate(`/reviews/${bioShopMenu?.payload?.message?.data?.pixel_id}`);
							}}
						>
							Reviews
						</Button>
					</div>
				</div>

				{bioShopMenu?.payload?.message?.data._id && children}
			</div>
		);
	}

	return (
		<>
			<div className={`${!socialStore ? "bio_shop_main_ift" : ""}`}>
				<div className={`${!socialStore ? "midd-width-ifti" : ""}`}>
					<div className={`${!socialStore ? "bio_container_main" : ""}`}>{ComponentBioShop()}</div>
				</div>
			</div>
			{bioShopMenu?.payload?.message?.data._id && <FilterModal createFeed={false} filterPost={filterPost} setFilterPost={setFilterPost} showFilter={showFilter} setShowFilter={setShowFilter} />}
		</>
	);
};
