import { STRIPE_PAYMENT_LIST_REQUEST, STRIPE_PAYMENT_LIST_SUCCESS, STRIPE_PAYMENT_LIST_ERROR } from "../../types/types";
export default function paymentList(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case STRIPE_PAYMENT_LIST_REQUEST:
      return {
        loading: true,
      };
    case STRIPE_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case STRIPE_PAYMENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    default:
      return state;
  }
}
