// React and related hooks imports
import React, { useLayoutEffect, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { createFeeds, getFeeds } from "../../redux/actions/feeds.action";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { getAllPublishedEvent, getAllLiveEvent, getAllUpComingEvent } from "../../redux/actions/liveShopping.action";
import { publishInfluencer } from "../../redux/actions/influencer.action";
import { getInterests } from "../../redux/actions/interest.action";

// Component and utility imports
import { Button } from "react-bootstrap";
import List from "./List";
import FilterModal from "./FilterModal";

// Style imports
import "../../css/live-events.scss";
import "../../css/influencers_reviews.scss";

export default function Shows({ name }) {
	let userInfo = localStorage.getItem("userInfo");
	if (userInfo !== "undefined") {
		userInfo = JSON.parse(localStorage.getItem("userInfo"));
	} else {
		localStorage.removeItem("userInfo");
		window.location.reload();
	}
	const navigate = useNavigate();
	let location = useLocation();
	const path = location.pathname.slice(1, location.pathname.length);

	const [currentShow, setCurrentShow] = useState(name);
	const [showFilter, setShowFilter] = useState(false);
	const [loadingSpinner, setLoadingSpinner] = useState(true);
	const [page, setPage] = useState(1);
	const [trending, setTrending] = useState(false);
	const [selectnew, setSelectnew] = useState(false);
	const queryParams = queryString.parse(window.location.search);
	const sortBy = queryParams.sortBy;
	const [origin, setOrigin] = useState("");

	const [dept, setDept] = useState("");

	const [filterPost, setFilterPost] = useState({
		genderCategoryId: null,
		categoryId: null,
		subCategoryId: [],
		sortBy: "",
		eventType: "",
		applyFilter: "all",
	});

	const dispatch = useDispatch();
	const { search, allLiveEvent, allUpcomingEvent, allPublishEvent, influencerReviews, feedData } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		if (window.location.search) {
			setTrending(true);
		} else {
			setTrending(false);
		}
	}, [window.location.search]);

	const [size, onChangeSize] = useState(0);

	useLayoutEffect(() => {
		const updateSize = () => {
			const width = window.innerWidth;
			if (width <= 576) {
				onChangeSize(4); // small screens (mobile)
			} else if (width > 576 && width <= 992) {
				onChangeSize(6); // medium screens (tablet)
			} else {
				onChangeSize(12); // large screens (desktop and above)
			}
		};

		// Attach the event listener to the window resize event
		window.addEventListener("resize", updateSize);

		// Call the updateSize function to set the initial size
		updateSize();

		// Clean up by removing the event listener when the component is unmounted
		return () => {
			window.removeEventListener("resize", updateSize);
		};
	}, []);

	useLayoutEffect(() => {
		setCurrentShow(name);
		setOrigin(path);
	}, [location]);

	useLayoutEffect(() => {
		setLoadingSpinner(true);
		if (userInfo?.token) {
			dispatch(getInterests(userInfo?.token)).then((res) => {
				const catId = res.payload.message.map(({ _id }) => _id) || [];
				getApiData(catId).then((res) => {
					if (!res?.payload?.success) {
						if (["feeds", "review", "recorded"].includes(currentShow)) {
							dispatch(createFeeds(userInfo?.token, true)).then((res) => {
								if (res?.payload?.success) {
									getApiData(catId).then(() => {
										setPage(2);
										setSelectnew(false);
										setLoadingSpinner(false);
									});
								}
							});
						}
					} else {
						setPage(2);
						setSelectnew(false);
						setLoadingSpinner(false);
					}
				});
			});
		} else {
			getApiData().then(() => {
				setPage(2);
				setLoadingSpinner(false);
			});
		}
	}, [currentShow, queryParams.sortBy]);

	useLayoutEffect(() => {
		if (filterPost.genderCategoryId != null && filterPost.categoryId != null) {
			setLoadingSpinner(true);
			getApiData().then(() => {
				setPage(2);
				setLoadingSpinner(false);
			});
		}
	}, [filterPost.genderCategoryId, filterPost.categoryId, location]);

	const apiMapping = {
		feeds: { apiCall: getFeeds, data: feedData },
		review: { apiCall: publishInfluencer, data: influencerReviews },
		upcoming: { apiCall: getAllUpComingEvent, data: allUpcomingEvent },
		live: { apiCall: getAllLiveEvent, data: allLiveEvent },
		recorded: { apiCall: getAllPublishedEvent, data: allPublishEvent },
	};

	function getApiData(deparmentId) {
		const department = deparmentId || filterPost.genderCategoryId;
		setDept(department);
		return dispatch(apiMapping[currentShow].apiCall(1, 12, department, filterPost.categoryId, "", undefined, sortBy, search.payload, false, userInfo?._id));
	}

	function loadMore() {
		dispatch(apiMapping[currentShow].apiCall(page, 12, filterPost.genderCategoryId, filterPost.categoryId, "", undefined, sortBy, search.payload, true, userInfo?._id)).then(() => setPage(page + 1));
	}

	function getData() {
		return apiMapping[currentShow].data;
	}

	return (
		<div>
			<div className="event-filter">
				<Button
					onClick={() => {
						setShowFilter(true);
					}}
				>
					Filters
				</Button>

				<Button
					className={path == "feeds" || path == "" ? "active" : ""}
					onClick={() => {
						navigate("/feeds");
					}}
				>
					Feed
				</Button>

				<Button
					className={path == "review" || path == "" ? "active" : ""}
					onClick={() => {
						navigate("/review");
					}}
				>
					Reviews
				</Button>

				<Button
					className={path == "recorded" ? "active" : ""}
					onClick={() => {
						navigate("/recorded");
					}}
				>
					Shows
				</Button>
				<Button
					className={path == "live" ? "active" : ""}
					onClick={() => {
						navigate("/live");
					}}
				>
					Live
				</Button>

				<Button
					className={path == "upcoming" ? "active" : ""}
					onClick={() => {
						navigate("/upcoming");
					}}
				>
					Upcoming
				</Button>
			</div>
			{loadingSpinner ? (
				<div className="all_influencers_reviews">
					<div className="event-container">
						<div className="influencers-col-box infl-box-gap">
							{new Array(size).fill().map((_, i) => {
								return <div className="event-col-box"></div>;
							})}
						</div>
					</div>
				</div>
			) : (
				<List category={filterPost.categoryId} department={dept} page={page - 1} origin={origin} name={currentShow} loading={getData()?.loading} loadMore={loadMore} hasMore={getData().payload?.message?.next?.page ? true : false} post={getData().payload?.message?.data} />
			)}
			<FilterModal createFeed={true} filterPost={filterPost} setFilterPost={setFilterPost} showFilter={showFilter} setShowFilter={setShowFilter} />
		</div>
	);
}
