// import { NEWALL_PUBLISH_EVENT_REQUEST, NEWALL_PUBLISH_EVENT_SUCCESS, NEWALL_PUBLISH_EVENT_ERROR, NEWALL_PUBLISH_EVENT_SUCCESS_LOADMORE } from "../../types/types";
// export default function allnewPublishEvent(state = {loading:true}, action) {
//   const { type, payload } = action;

//   switch (type) {
//     case NEWALL_PUBLISH_EVENT_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case NEWALL_PUBLISH_EVENT_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         payload: payload,
//       };
//     case NEWALL_PUBLISH_EVENT_SUCCESS_LOADMORE:
//       return {
//         ...state,
//         loading: false,
//         payload: {
//           ...state.payload.message,
//           message: {
//             ...payload.message,
//             data: [...state.payload.message.data, ...payload.message.data],
//           },
//         },
//       };
//     case NEWALL_PUBLISH_EVENT_ERROR:
//       return {
//         ...state,
//         loading: false,
//         payload: payload,
//       };

//     default:
//       return state;
//   }
// }


import { NEWALL_PUBLISH_EVENT_REQUEST, NEWALL_PUBLISH_EVENT_SUCCESS, NEWALL_PUBLISH_EVENT_ERROR, NEWALL_PUBLISH_EVENT_SUCCESS_LOADMORE } from "../../types/types";
export default function allnewPublishEvent(state = { loading: true }, action) {
	const { type, payload } = action;

	switch (type) {
		case NEWALL_PUBLISH_EVENT_REQUEST:
			return {
				...state,
				loading: true,
			};
		case NEWALL_PUBLISH_EVENT_SUCCESS:
			let page = 1;
			if(payload?.message?.next?.page){
			 page =	payload?.message?.next?.page - 1
			}
			payload?.message?.data.map((item) => {
				item.page = page;
			});
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case NEWALL_PUBLISH_EVENT_SUCCESS_LOADMORE:
			let nextPage = payload?.message?.next?.page - 1;
			if (payload?.message?.next?.page) {
				payload?.message?.data?.map((item) => {
					item.page = nextPage;
				});
			} else {
				payload?.message?.data?.map((item) => {
					item.page = state?.payload?.message?.next?.page;
				});
			}

			return {
				...state,
				loading: false,
				payload: {
					...state.payload.message,
					message: {
						...payload.message,
						data: [...state.payload.message.data, ...payload.message.data],
					},
				},
			};
		case NEWALL_PUBLISH_EVENT_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}
