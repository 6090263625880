import React from "react";
import "antd/dist/antd.css";
import { Form, Input, Button } from "antd";
import { NavLink } from "react-router-dom";
export default () => {
	return (
		<>
			<div className="password_page">
				<div className="password-header">
					<div className="container">
						<div className="p-h-left">
							<NavLink to="/">ORME</NavLink>
						</div>
						<div className="enter-using-ps">
							<span>
								<i className="fa fa-lock" aria-hidden="true"></i> Enter using password
							</span>
							<div className="enter-pass-box">
								<div className="arrow-up">
									<i className="fa fa-caret-up" aria-hidden="true"></i>
								</div>
								<h3>Enter using password</h3>
								<Form
									name="basic"
									initialValues={{
										remember: true,
									}}
									autoComplete="off"
								>
									<Form.Item
										name="username"
										className="enter-email"
										rules={[
											{
												required: true,
												message: "Please enter password",
											},
										]}
									>
										<Input placeholder="Password" type="password" size="large" />
									</Form.Item>

									<Form.Item>
										<Button type="primary" htmlType="submit" size="large">
											Enter
										</Button>
									</Form.Item>
								</Form>
							</div>
						</div>
					</div>
				</div>
				<div className="password-box">
					<div className="pswd-iner">
						<h3>Opening Soon</h3>
						<p>We are preparing something exciting & amazing for you.</p>
						<Form
							name="basic"
							initialValues={{
								remember: true,
							}}
							// onFinish={onFinish}
							// onFinishFailed={onFinishFailed}
							autoComplete="off"
						>
							<Form.Item
								name="username"
								className="enter-email"
								rules={[
									{
										required: true,
										message: "Please input your email!",
									},
								]}
							>
								<Input placeholder="Your email address" type="email" size="large" />
							</Form.Item>

							<Form.Item>
								<Button type="primary" htmlType="submit" size="large">
									Notify me
								</Button>
							</Form.Item>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};