import { GET_CREATOR_SALE_REQUEST, GET_CREATOR_SALE_SUCCESS, GET_CREATOR_SALE_ERROR , GET_CREATOR_SUMMARY_REQUEST , GET_CREATOR_SUMMARY_SUCCESS , GET_CREATOR_SUMMARY_ERROR} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getContentSale = (page, limit, source, startDate, endDate, category, genderCategory, subCategory, brand) => (dispatch) => {
  dispatch({ type: GET_CREATOR_SALE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}customer/reports/getcontentsalessummary?page=${page}&limit=${limit}`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      source: source,
      from_date: startDate,
      to_date: endDate,
      category: category,
      gen_category: genderCategory,
      sub_category: subCategory,
      brand: brand,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_CREATOR_SALE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_CREATOR_SALE_ERROR,
        payload: error.response,
      });
    });
};

export const summaryContent = (id, event) => (dispatch) => {
  dispatch({ type: GET_CREATOR_SUMMARY_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}customer/reports/getcontentsalesdetail`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      id,
      event,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_CREATOR_SUMMARY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_CREATOR_SUMMARY_ERROR,
        payload: error.response,
      });
    });
};


// export const saleSummary = (id, event) => (dispatch) => {
//   dispatch({ type: GET_REFERRAL_SUMMARY_REQUEST });
//   return axios({
//     method: "POST",
//     url: `${BASEURL}customer/reports/getreferralstatsdetail`,
//     headers: { Authorization: `Bearer ${userInfo?.token}` },
//     data: {
//       id,
//       event: event === "eventshop" || event === "eventshare" ? "event" : "review",
//     },
//   })
//     .then((response) => {
//       return dispatch({
//         type: GET_REFERRAL_SUMMARY_SUCCESS,
//         payload: response.data,
//       });
//     })
//     .catch((error) => {
//       return dispatch({
//         type: GET_REFERRAL_SUMMARY_ERROR,
//         payload: error.response,
//       });
//     });
// };