import React, { useEffect, useState, useRef } from "react";
import { Select, Drawer } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import bars from "../../images/bars.svg";
import logo from "../../images/orme-logo.svg";

import Register from "../../pages/auth/register";
import Login from "../../pages/auth/login";
import ForgetPassword from "../../pages/auth/forgetPassword";
import Search from "../search/search";
import { createBrowserHistory } from "history";
import { useNavigate } from "react-router-dom";
import { doSearch } from "../../redux/actions/search.action";
import { getCountry, getSelectedCountry } from "../../redux/actions/country.action";
import { openRegister } from "../../redux/actions/register.action";
import { getAccount } from "../../redux/actions/account.action";
import "antd/dist/antd.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshakeAlt, faHouse, faPeopleGroup, faQuestionCircle, faUsers, faUserTag } from "@fortawesome/free-solid-svg-icons";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Notify from "../notification/notification";
import CartDrawer from "../cartDrawer/cartDrawer";
import { redirectToAppOrStore } from "../../helper/general";

export const history = createBrowserHistory();
const { Option } = Select;

let userInfo = localStorage.getItem("userInfo");

if (userInfo !== "undefined") {
	userInfo = JSON.parse(localStorage.getItem("userInfo"));
} else {
	localStorage.removeItem("userInfo");
	window.location.reload();
}

export default function Header() {
	const path = window.location.pathname;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [countryList, setCountryList] = useState([]);
	const [showRegisterModal, setShowRegisterModal] = useState(false);
	const [showLoginModal, setLoginModal] = useState(false);
	const [visible, setVisible] = useState(false);
	const [sticky, setSticky] = useState(false);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [step, setStep] = useState("");

	const inputRef = useRef(null);

	const [scroll, setScroll] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 550);
		});
	}, []);

	useEffect(() => {
		dispatch(getCountry()).then((res) => {
			const selectCountries = [];
			res?.payload?.message?.map(({ name, code1 }) => {
				return selectCountries.push({ value: code1, label: name });
			});
			setCountryList(selectCountries);
		});
		dispatch(getSelectedCountry("US"));
	}, []);

	useEffect(() => {
		if (userInfo?.token && account?.length === 0) dispatch(getAccount(userInfo?.token));
	}, []);

	const [subMenuClass, setSubMenuClass] = useState("");
	const [visibleMenu, setVisibleMenu] = useState(false);

	const handleMenuClick = (e) => {
		if (e.key !== "2") {
			setVisibleMenu(false);
		}
	};

	const handleVisibleChange = (flag) => {
		setVisibleMenu(flag);
	};

	const { account, showAuthModal, showRegModal, openModal } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		setLoginModal(showAuthModal?.payload);
		setShowRegisterModal(false);
	}, [showAuthModal]);

	useEffect(() => {
		setLoginModal(showRegModal?.payload?.status);
		setStep(showRegModal?.payload?.step);
		setShowRegisterModal(true);
	}, [showRegModal]);

	useEffect(() => {
		setLoginModal(openModal?.payload?.status);
		setShowRegisterModal(openModal?.payload?.status);
	}, [openModal]);

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const changeCountry = (e) => {
		dispatch(getSelectedCountry(e));
	};

	const mobiflipslider = {
		className: "slider variable-width abc",
		dots: false,
		infinite: false,
		centerMode: false,
		slidesToScroll: 3,
		swipe: true,
		arrows: false,
		variableWidth: true,
	};

	const countrySelect = () => {
		return (
			<div className="country-select">
				<Select
					disabled
					defaultValue={"US"}
					className=""
					showSearch
					placeholder="Select Country"
					size="medium"
					optionFilterProp="children"
					onChange={(e) => {
						changeCountry(e);
					}}
					filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
				>
					{countryList.map((item) => (
						<Option value={item.value}>{item.label}</Option>
					))}
				</Select>
			</div>
		);
	};

	const logout = () => {
		localStorage.removeItem("userInfo");
		window.location.reload(false);
		navigate(`/`);
	};
	const menu = (
		<Menu
			onClick={handleMenuClick}
			items={
				userInfo?.account_type === "influencer"
					? [
							{
								label: (
									<NavLink to={"/dashboard"}>
										<span className="abc">
											My Account <i className="fa fa-cog" aria-hidden="true"></i>
										</span>
									</NavLink>
								),
								key: 0,
							},

							{
								label: (
									<span onClick={() => logout()}>
										Logout <i className="fa fa-sign-out" aria-hidden="true"></i>
									</span>
								),
								key: 2,
							},
					  ]
					: userInfo?.account_type === "brand"
					? [
							{
								label: (
									<NavLink to={"/dashboard"}>
										<span className="abc">
											My Account <i className="fa fa-cog" aria-hidden="true"></i>
										</span>
									</NavLink>
								),
								key: 0,
							},

							{
								label: (
									<span onClick={() => logout()}>
										Logout <i className="fa fa-sign-out" aria-hidden="true"></i>
									</span>
								),
								key: 2,
							},
					  ]
					: [
							{
								label: (
									<NavLink to={"/dashboard"}>
										<span className="abc">
											My Account <i className="fa fa-cog" aria-hidden="true"></i>
										</span>
									</NavLink>
								),
								key: 0,
							},

							{
								label: (
									<span onClick={() => logout()}>
										Logout <i className="fa fa-sign-out" aria-hidden="true"></i>
									</span>
								),
								key: 4,
							},
					  ]
			}
		/>
	);



	return (
		<>
			<div className={` c-portal-header ${sticky ? "sticky-shadow" : ""}`}>
				<div className="midd-width-ifti">
					<div className="p-h-inner">
						<div className="mobile-menu-ifti">
							<nav className="menuBar">
								<div className="menuCon">
									<span className="barsBtn" onClick={() => showDrawer()}>
										<img src={bars} alt="menu open icon"></img>
									</span>
									<Drawer placement="left" onClose={onClose} visible={visible} width={"100%"}>
										{!userInfo ? (
											<div className="sign-mobile-drawer d-none">
												<div className="sign-mb-btns">
													<span
														onClick={() => {
															dispatch(openRegister(true, "step2"));
														}}
													>
														Sign In
													</span>
												</div>
											</div>
										) : (
											<div className="my-account-mobile-menu">
												<div
													onClick={() => {
														if (subMenuClass) setSubMenuClass("");
														else setSubMenuClass("pro-ul-mob-open");
													}}
													className="customer-box"
												>
													<span className="cus-name-mob">{account?.payload?.Data?.name}</span>
													<span className="cus-id-mob"> {account?.payload?.Data?.pixel_id}</span>
													<i className="fa fa-cog" aria-hidden="true"></i>
												</div>
												<div className={`account-mob-menu ${subMenuClass}`}>
													<ul>
														<li>
															<NavLink to="/dashboard" onClick={() => onClose()}>
																<span>Dashboard</span>
															</NavLink>
														</li>
														<li>
															<NavLink to="/interest" onClick={() => onClose()}>
																<span>My Interest</span>
															</NavLink>
														</li>
														{userInfo?.account_type != "brand" && (
															<>
																<li>
																	<NavLink to="/purchase" onClick={() => onClose()}>
																		<span>Purchases</span>
																	</NavLink>
																</li>
																<li>
																	<NavLink to="myshop" onClick={() => onClose()}>
																		<span>My Shop</span>
																	</NavLink>
																</li>
																<li>
																	<NavLink to="/referralstats" onClick={() => onClose()}>
																		<span>Referral Sales</span>
																	</NavLink>
																</li>
																{account?.payload?.Data?.account_type === "influencer" ? (
																	<>
																		<li>
																			<NavLink to="/reviewsale" onClick={() => onClose()}>
																				<span>Creator Sales</span>
																			</NavLink>
																		</li>
																	</>
																) : (
																	<></>
																)}

																<li>
																	<NavLink to="/earned-commission" onClick={() => onClose()}>
																		<span>Earnings</span>
																	</NavLink>
																</li>
															</>
														)}
														<li>
															<NavLink to="/saved" onClick={() => onClose()}>
																<span>Saved</span>
															</NavLink>
														</li>
														<li>
															<NavLink to="/account-settings" onClick={() => onClose()}>
																<span>Account Settings</span>
															</NavLink>
														</li>
													</ul>
												</div>
											</div>
										)}

										<div className="dr-mobi-menu-ift">
											<ul>
												<li>
													<NavLink to="/" onClick={() => onClose()}>
														<FontAwesomeIcon icon={faHouse} /> Home
													</NavLink>
												</li>
												<li>
													<NavLink to="/brand-retailer" onClick={() => onClose()}>
														<FontAwesomeIcon icon={faUserTag} /> Brands
													</NavLink>
												</li>
												<li>
													<NavLink to="/influencer-creators" onClick={() => onClose()}>
														<FontAwesomeIcon icon={faUsers} /> Creators
													</NavLink>
												</li>

												<li>
													<NavLink to="/contact-us" onClick={() => onClose()}>
														<FontAwesomeIcon icon={faQuestionCircle} /> Help
													</NavLink>
												</li>

												{userInfo && (
													<li>
														<NavLink
															to="#"
															onClick={() => {
																onClose();
																logout();
															}}
														>
															<i className="fa fa-sign-out" aria-hidden="true"></i> Logout
														</NavLink>
													</li>
												)}
											</ul>
										</div>
									</Drawer>
								</div>
							</nav>
						</div>

						<div className="p-h-left">
							<NavLink to="/" {...(path === "/review" ? { reloadDocument: true } : {})}>
								<img className="logo" src={logo} alt="logo" />
							</NavLink>
							<div className="mobile-search-container">
								{path !== "/brand-retailer" && (
									<a
										//  className="get-the-app-btn"
										className={scroll ? "get-the-app-btn get-app-btn-mob" : "get-the-app-btn"}
										href="javascript:void"
										onClick={() => {
											redirectToAppOrStore();
										}}
									>
										Get the app
									</a>
								)}
								{/* {isMobileDevice && (
									<div>
										<Notify />
									</div>
								)} */}

								{/* {isMobileDevice && (
									<div className="mobile-search-box">
										<span
											className="mobile-search-icon"
											onClick={() => {
												setMobileSearch(true);
											}}
										>
											<img src={searchicon} alt="" />
										</span>
										<div className={`mobile-serach-div ${mobileSearch ? "mobile-search" : ""}`}>
											<div className="mobile-search-inner">
												<input
													className="form-control"
													ref={inputRef}
													autoFocus
													onKeyDown={handleKeyDown}
													onChange={(e) => {
														if (e.target.value === "") {
															dispatch(doSearch(e.target.value));
														}
														setSearchByTitle(e.target.value);
													}}
													type="search"
													placeholder="Search "
													id="example-search-input"
												/>
											</div>
											<span
												className="close-search"
												onClick={() => {
													setMobileSearch(false);
												}}
											>
												Close
											</span>
										</div>
									</div>
								)} */}
							</div>
						</div>
						<Search />
						{userInfo !== null ? (
							<div className="p-h-user">
								<Notify />
								<Dropdown onVisibleChange={handleVisibleChange} visible={visibleMenu} arrow placement="topRight" overlay={menu} trigger={["click"]}>
									<a onClick={(e) => e.preventDefault()}>
										<Space>
											<i className="fa fa-user-o fa-2x" aria-hidden="true"></i>
											<div className="userNameTop">
												<span>{account?.payload?.Data?.name}</span>
											</div>
											<DownOutlined />
										</Space>
									</a>
								</Dropdown>
								{countrySelect()} <CartDrawer />
							</div>
						) : (
							<div className="login-signup">
								<span
									className="tp-frm-btns join-now-btn remove-space-right"
									onClick={() => {
										navigate("/register-partner");
									}}
								>
									Join Now
								</span>

								<span
									className="tp-frm-btns sign-in-btn"
									onClick={() => {
										setLoginModal(true);
										setShowRegisterModal(true);
									}}
								>
									Sign In
								</span>

								{countrySelect()}
							</div>
						)}
					</div>
				</div>

				<div className="c-top-menu">
					<div className="midd-width-ifti">
						<div className="c-top-menu-inner">
							<ul>
								<li>
									<NavLink to="/" onClick={() => onClose()}>
										<FontAwesomeIcon icon={faHouse} /> Home
									</NavLink>
								</li>
								<li>
									<NavLink to="/feeds" {...(path === "/feeds" ? { reloadDocument: true } : {})} className={path == "/recorded" || path == "/live" || path == "/upcoming" || path == "/feeds" ? "active" : ""}>
										{" "}
										<i className="fa fa-video-camera" aria-hidden="true"></i> Market Place
									</NavLink>
								</li>
								<li>
									<NavLink to="/brands" className={path?.includes("brands") ? "active" : ""}>
										<i className="fa fa-tags" aria-hidden="true"></i> Brands
									</NavLink>
								</li>
								<li>
									<NavLink to="/influencers" onClick={() => onClose()}>
										<FontAwesomeIcon icon={faPeopleGroup} /> User Shop
									</NavLink>
								</li>

								<li>
									<a href="#" onClick={() => onClose()}>
										<FontAwesomeIcon icon={faHandshakeAlt} />
										&nbsp;Partner with us
									</a>
									<div className="top-submenu">
										<ul>
											<li>
												<NavLink to="/brand-retailer" onClick={() => onClose()}>
													Brands & Retailers
												</NavLink>
											</li>
											<li>
												<NavLink to="/influencer-creators" onClick={() => onClose()}>
													Influencers & Creators
												</NavLink>
											</li>
										</ul>
									</div>
								</li>

								<li>
									{/* <NavLink to="/contact-us" onClick={() => onClose()}>
                    <FontAwesomeIcon icon={faAddressBook} /> Contact Us
                  </NavLink> */}

									<a href="#" onClick={() => onClose()}>
										<FontAwesomeIcon icon={faQuestionCircle} />
										&nbsp;Help
									</a>

									<div className="top-submenu">
										<ul>
											<li>
												<NavLink to="/terms-service">Terms of Service</NavLink>
											</li>
											<li>
												<NavLink to="/privacy-policy">Privacy Policy</NavLink>
											</li>
											{/* <li>
										<NavLink to="/faqs">FAQ's</NavLink>
									</li> */}
											<li>
												<NavLink to="/community-guidelines">Community Guidelines</NavLink>
											</li>
											<li>
												<NavLink to="/contact-us">Contact Us</NavLink>
											</li>
										</ul>
									</div>
								</li>

								{userInfo?.account_type === "brand" && <div className="kblive"></div>}
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="mobile-front-menu">
				<div className="midd-width-ifti">
					<Slider {...mobiflipslider}>
						<div className="nav-link-front">
							<NavLink to="/" className={path === "/" ? "active" : ""}>
								Home
							</NavLink>
						</div>
						{/* <div className="nav-link-front">
							<NavLink to="/review" {...(path === "/review" ? { reloadDocument: true } : {})} className={path == "/recorded" || path == "/live" || path == "/upcoming" ? "active" : ""}>
								{" "}
								Market Place
							</NavLink>
						</div>
						<div className="nav-link-front">
							<NavLink to="/brands" className={path?.includes("brand") ? "active" : ""}>
								Brands
							</NavLink>
						</div>

						<div className="nav-link-front">
							<NavLink to="/influencers" className={path?.includes("influencers") ? "active" : ""}>
								Reviews
							</NavLink>
						</div> */}

						<div className="nav-link-front">
							<NavLink to="/brand-retailer">Brands</NavLink>
						</div>
						<div className="nav-link-front">
							<NavLink to="/influencer-creators">Creators</NavLink>
						</div>

						<div className="nav-link-front">
							<NavLink to="/contact-us">Help</NavLink>
						</div>
					</Slider>
				</div>
			</div>

			<Modal dialogClassName="signup-modal form-close-modal" size="md" backdropClassName="custom-backdrop" show={showLoginModal} centered animation={false} backdrop={true} keyboard={false}>
				<Modal.Header>
					<button
						type="button"
						className="close"
						onClick={() => {
							setLoginModal(false);
							if (!userInfo) {
								if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
									window.location.reload();
								}
							}
						}}
					>
						<span aria-hidden="true">×</span>
						<span className="sr-only">Close</span>
					</button>
				</Modal.Header>
				<Modal.Body>
					{showRegisterModal ? (
						<>
							<Register step={step} />
						</>
					) : (
						<>
							{forgotPassword ? (
								<>
									<ForgetPassword closeModal={setLoginModal} />
									<p className="already">
										<span
											onClick={() => {
												setForgotPassword(false);
											}}
										>
											Sign In
										</span>
									</p>
								</>
							) : (
								<>
									<Login />
									<p className="already">
										New to ORME?&nbsp;
										<span
											onClick={() => {
												setShowRegisterModal(true);
											}}
										>
											Create an Account
										</span>
									</p>
								</>
							)}
						</>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}
