import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import InfluencerShop from "../influencerProfile/InfluencerProfile";
import BrandShop from "../bioShop/reviews";
import { getBioShopMenu } from "../../redux/actions/bioShop.action";
import { salesTracker } from "../../redux/actions/salesTracker.action";
import { likeCount } from "../../redux/actions/influencer.action";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export default function ShopPage() {
	const [accountType, setAccountType] = useState("loading");

	const dispatch = useDispatch();
	const params = useParams();
	const currentPathname = useLocation().pathname;
	const navigate = useNavigate();
	const location = useLocation();


	// Detect if it's a mobile device
	const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || false;
	// Load bio shop menu and set account type


	useEffect(() => {
		dispatch(getBioShopMenu(params.username, userInfo?.token)).then((res) => {

			dispatch(likeCount(res?.payload?.message?.data?._id, res.payload.message.data.account_type))
			setAccountType(res.payload.message.data.account_type);
		});
	}, [params.username, userInfo?.token]);

	// Check if it's a WebView and attempt to open the app with a custom URL scheme
	useEffect(() => {
		function isWebView() {
			// Detect Android WebView
			let isAndroidWebView = window.navigator.userAgent.includes("wv") || "WebView" in window;

			// Detect iOS WebView
			let isiOSWebView = window.navigator.userAgent.includes("WebKit") && !window.navigator.userAgent.includes("Safari");

			return isAndroidWebView || isiOSWebView;
		}

		if (isWebView()) {
			window.location.href = `ormelive://${params?.username}`;
		}
	}, [navigate, location]);

	// Track sales data
	useEffect(() => {
		if (userInfo?.token) {
			let data = { publisher_pixel_id: params.username };
			dispatch(salesTracker(data, userInfo?.token)).then((res) => {
				if (!isMobileDevice) {
					localStorage.setItem(
						"shareTracking",
						JSON.stringify({
							tracker: res?.payload?.data?.tracker,
							event: "linkinbio",
						})
					);
				}
			});
		}

	}, [currentPathname]);

	// Render components based on account type and pathname
	if (accountType === "loading") return <Spin style={{ fontSize: 24 }} className="d-flex justify-content-center p-5" size="large" />;
	if (accountType === "influencer") {
		if (currentPathname.includes("/reviews")) {
			return <InfluencerShop name={"reviewInfluencer"} />;
		}
		else {
			return <InfluencerShop name={"share"} />;
		}
	}
	if (accountType === "brand") return <BrandShop name="review" />;
}
