import { GET_REFERRAL_STATS_REQUEST, GET_REFERRAL_STATS_SUCCESS, GET_REFERRAL_STATS_ERROR, GET_REFERRAL_SUMMARY_REQUEST, GET_REFERRAL_SUMMARY_SUCCESS, GET_REFERRAL_SUMMARY_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getReferralStats = (page, limit, source, startDate, endDate, category, genderCategory, subCategory, brand) => (dispatch) => {
  dispatch({ type: GET_REFERRAL_STATS_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}customer/reports/getreferralstatssummary?page=${page}&limit=${limit}`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      source: source,
      from_date: startDate,
      to_date: endDate,
      category: category,
      gen_category: genderCategory,
      sub_category: subCategory,
      brand: brand,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_REFERRAL_STATS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_REFERRAL_STATS_ERROR,
        payload: error.response,
      });
    });
};

export const referralSummary = (id, event) => (dispatch) => {
  dispatch({ type: GET_REFERRAL_SUMMARY_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}customer/reports/getreferralstatsdetail`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      id,
      event: event === "eventshop" || event === "eventshare" ? "event" : "review",
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_REFERRAL_SUMMARY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_REFERRAL_SUMMARY_ERROR,
        payload: error.response,
      });
    });
};
