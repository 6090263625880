// import { POST_ALL_INFLUENCER_REQUEST, POST_ALL_INFLUENCER_SUCCESS, POST_ALL_INFLUENCER_ERROR, POST_ALL_INFLUENCER_SUCCESS_LOADMORE } from "../../types/types";

// export default function influencerReviews(state = {loading:true}, action) {
//   const { type, payload } = action;
//   switch (type) {
//     case POST_ALL_INFLUENCER_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case POST_ALL_INFLUENCER_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         payload: payload,
//       };
//     case POST_ALL_INFLUENCER_SUCCESS_LOADMORE:
//       return {
//         ...state,
//         loading: false,
//         payload: {
//           ...state.payload.message,
//           message:{
//             ...payload.message,
//             data:[...state.payload.message.data,...payload.message.data]
//           }
//         },
//       };
//     case POST_ALL_INFLUENCER_ERROR:
//       return {
//         ...state,
//         loading: false,
//         payload: payload ? payload : [],
//       };

//     default:
//       return state;
//   }
// }

import { POST_ALL_INFLUENCER_REQUEST, POST_ALL_INFLUENCER_SUCCESS, POST_ALL_INFLUENCER_ERROR, POST_ALL_INFLUENCER_SUCCESS_LOADMORE } from "../../types/types";
export default function influencerReviews(state = { loading: true }, action) {
	const { type, payload } = action;

	switch (type) {
		case POST_ALL_INFLUENCER_REQUEST:
			return {
				...state,
				loading: true,
			};
		case POST_ALL_INFLUENCER_SUCCESS:
			let page = 1;
			if(payload?.message?.next?.page){
			 page =	payload?.message?.next?.page - 1
			}
			payload?.message?.data.map((item) => {
				item.page = page;
			});
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case POST_ALL_INFLUENCER_SUCCESS_LOADMORE:
			let nextPage = payload?.message?.next?.page - 1;
			if (payload?.message?.next?.page) {
				payload?.message?.data?.map((item) => {
					item.page = nextPage;
				});
			} else {
				payload?.message?.data?.map((item) => {
					item.page = state?.payload?.message?.next?.page;
				});
			}

			return {
				...state,
				loading: false,
				payload: {
					...state.payload.message,
					message: {
						...payload.message,
						data: [...state.payload.message.data, ...payload.message.data],
					},
				},
			};
		case POST_ALL_INFLUENCER_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}
