import { POST_LOGIN_REQUEST, POST_LOGIN_SUCCESS, POST_LOGIN_ERROR, POST_LOGIN_OPEN_REQUEST, POST_LOGIN_OPEN_SUCCESS } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const postLogin = (phone) => (dispatch) => {
	dispatch({ type: POST_LOGIN_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}auth/user/signin`,
		headers: {
			Accept: "application/json",
		},
		data: {
			// email: data.username,
			// password: data.password,
			phone: phone,
			brand: process.env.REACT_APP_BRAND_ID
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_LOGIN_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_LOGIN_ERROR,
				payload: error.response,
			});
		});
};

export const openLogin = (status) => (dispatch) => {
	return dispatch({
		type: POST_LOGIN_OPEN_SUCCESS,
		payload: status,
	});
};
