import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { Button, Form } from "antd";
import { DatePicker, Spin, Select, Empty } from "antd";
import { customerorderHistory, getpurchases } from "../../../redux/actions/purchase.action";
import { getBrand } from "../../../redux/actions/brand.action";
import { getCategory } from "../../../redux/actions/category.action";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import numeral from "numeral";
const { Option } = Select;

const { RangePicker } = DatePicker;

export default () => {
	const [form] = Form.useForm();
	const dateFormat = "MM-DD-YYYY";
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	const fromDate = moment().startOf("month").format("YYYY-MM-DD");
	const toDate = moment(new Date()).format("YYYY-MM-DD");

	const [startDate, setStartDate] = useState(fromDate);
	const [endDate, setEndDate] = useState(toDate);
	const [totalEvent, setTotalEvent] = useState();
	const [currentPage, setCurrentPage] = useState(0);
	const [source, setSource] = useState("all");
	const [category, setCategory] = useState("all");
	const [groupBy, setGroupBy] = useState("none");
	const [brand, setBrand] = useState("all");
	const [Subcategory, setSubCategory] = useState("all");
	const [genderCategory, setGenderCategory] = useState("all");
	const [brandId, setBrandId] = useState("");
	const [catId, setCatId] = useState("");
	const [modal, setModal] = useState(false);
	const [order, setOrder] = useState([]);
	const [submit, setSubmit] = useState("none");
	const [categoryList, setCategoryList] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [genderCatgory, setGenderCat] = useState([]);
	const [publicCatgory, setPublicCat] = useState([]);

	const [load, setLoad] = useState(false);

	const { purchaseReport, purchaseSummary } = useSelector((state) => {
		return state;
	});

	const limit = 15;
	//	const upperLimit = (currentPage + 1) * limit;
	const getSum = (a1, a2) => parseFloat(a1) * parseFloat(a2);

	//const balance = (b1, b2) => parseFloat(b1) + parseFloat(b2);

	useEffect(() => {
		setLoading(true);
		dispatch(getBrand()).then((res) => {
			setBrandList(res?.payload?.message);
		});
		dispatch(customerorderHistory(1, limit, source, startDate, endDate, category, genderCategory, Subcategory, brand, groupBy)).then((res) => {
			setTotalEvent(res?.payload?.message?.total_records);
			setLoading(false);
		});
	}, []);

	const dateRangePickerChanger = (value, dataString) => {
		const startDate = moment(dataString[0]).format("YYYY-MM-DD");
		const endDate = moment(dataString[1]).format("YYYY-MM-DD");
		setStartDate(startDate);
		setEndDate(endDate);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		setCurrentPage(0);
		dispatch(customerorderHistory(1, limit, source, startDate, endDate, category, genderCategory, Subcategory, brand, groupBy)).then((res) => {
			setLoading(false);
			setTotalEvent(res?.payload?.message?.total_records);
		});
		setSubmit(groupBy);
	};

	const handleSource = (e) => {
		setSource(e);
	};
	const handleGroupBy = (e) => {
		setGroupBy(e);
	};

	const handleBrand = (e) => {
		if (e === "all") {
			setCategoryList([]);
			setPublicCat([]);
			setCategory("all");
			setGenderCategory("all");
			setSubCategory("all");
		} else {
			setLoad(true);
			setCategoryList([]);
			setPublicCat([]);
			setCategory("all");
			setGenderCategory("all");
			setSubCategory("all");
			setGenderCat([]);

			setBrandId(e);
			dispatch(getCategory("genCategory", e)).then((res) => {
				if (res?.payload?.message) {
					setLoad(false);
					//setCategoryList(res?.payload?.message);
					setGenderCat(res?.payload?.message);
				}
			});
		}
		setBrand(e);
	};

	const handleGenCategory = (e) => {
		if (e === "all") {
			setCategoryList([]);
			setCategory("all");
			setPublicCat([]);
			setCategory("all");
			setGenderCategory("all");
			setSubCategory("all");
		} else {
			setLoad(true);
			setCatId(e);
			setCategoryList([]);
			setCategory("all");
			setGenderCategory("all");
			setSubCategory("all");
			setPublicCat([]);
			dispatch(getCategory("category", brandId, e)).then((res) => {
				if (res?.payload?.message) {
					setLoad(false);
					setPublicCat(res?.payload?.message);
				}
			});
		}

		setGenderCategory(e);
	};

	const handleCategory = (e) => {
		if (e === "all") {
			setCategoryList([]);
			setCategory("all");
			setSubCategory("all");
		} else {
			setLoad(true);
			setCategoryList([]);
			setSubCategory("all");
			dispatch(getCategory("subCategory", brandId, catId, e)).then((res) => {
				if (res?.payload?.message) {
					setLoad(false);
					setCategoryList(res?.payload?.message);
					console.log("category", res?.payload?.message);
				}
			});
		}
		setCategory(e);
	};

	const handleSubCategory = (e) => {
		setSubCategory(e);
	};

	const handlePageClick = (e) => {
		const page = e.selected;
		setCurrentPage(page);
		setLoading(true);
		dispatch(customerorderHistory(page + 1, limit, source, startDate, endDate, category, genderCategory, Subcategory, brand, groupBy)).then((res) => {
			setLoading(false);
			setTotalEvent(res?.payload?.message?.total_records);
		});
	};

	const handleReset = () => {
		setSource("all");
		setCategory("all");
		setGenderCategory("all");
		setBrand("all");
		setSubCategory("all");
		setCategoryList([]);
		setPublicCat([]);
		setGenderCat([]);
		setGroupBy("none");
		setSubmit("none");
		setLoading(true);
		setCurrentPage(0);
		setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
		setEndDate(moment(new Date()).format("YYYY-MM-DD"));
		dispatch(customerorderHistory(1, limit, "all", moment().startOf("month").format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD"), "all", "all")).then((res) => {
			setLoading(false);
			setTotalEvent(res?.payload?.message?.total_records);
		});
	};
	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	}, []);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	function Summary() {
		return (
			purchaseReport?.payload?.message?.summary.length > 0 && (
				<div className="sales-summery mb-3">
					<div className="row">
						<div className="col-lg-6 col-xl-4 mb-2">
							<div className="summarry-box">
								<h4 className="summary-hd">Summary</h4>
								<div className="col-12 summary-detail-box">
									<h4 className="count-title">Orders</h4>
									<h5 className="count">{purchaseReport?.payload?.message?.total_records ? purchaseReport?.payload?.message?.total_records : 0}</h5>
								</div>
								<div className="col-12 summary-detail-box">
									<h4 className="count-title">Total Amount</h4>
									<h5 className="count">{numeral(purchaseReport?.payload?.message?.summary?.[0]?.order_totalprice).format("$0,0.0'")}</h5>
								</div>
								<div className="col-12 summary-detail-box">
									<h4 className="count-title">Return Amount</h4>
									<h5 className="count">{numeral(purchaseReport?.payload?.message?.summary?.[0]?.returned_amount).format("$0,0.0'")}</h5>
								</div>
								<div className="col-12 summary-detail-box">
									<h4 className="count-title">Balance Amount</h4>
									<h5 className="count">{numeral(purchaseReport?.payload?.message?.summary?.[0]?.balance_amount).format("$0,0.0'")}</h5>
								</div>
								<div className="col-12 summary-detail-box">
									<h4 className="count-title">Cashback%</h4>
									<h5 className="count">{numeral((purchaseReport?.payload?.message?.summary[0]?.cashback_amount / purchaseReport?.payload?.message?.summary?.[0]?.order_totalprice) * 100).format("0,0.0'")}%</h5>
								</div>
								<div className="col-12 summary-detail-box">
									<h4 className="count-title">Cashback Amount</h4>
									<h5 className="count">{numeral(purchaseReport?.payload?.message?.summary[0]?.cashback_amount).format("$0,0.0'")}</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		);
	}

	function DefaultTable() {
		let data = purchaseReport?.payload?.message?.data;
		if (data) {
			return (
				<>
					<Table responsive className="transactions-box table">
						<thead className="table_heading">
							<tr>
								<th>S.#</th>
								<th>Order Date</th>
								<th>Order #</th>
								<th>Brand</th>
								<th>Department</th>
								<th>Category</th>
								<th>Sub Category</th>
								<th>Amount</th>
								<th>Return Amount</th>
								<th>Balance Amount</th>
								<th>Cashback%</th>
								<th>Cashback</th>
								<th>Source</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							{purchaseReport?.loading ? (
								<div className="antd-loading-ift">
									<Spin size="large"></Spin>
								</div>
							) : data?.length === 0 ? (
								<tr>
									<td colspan="12">
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									</td>
								</tr>
							) : (
								data.map((item, i) => {
									return (
										<tr>
											<td>{i + 1 + currentPage * 15}</td>
											<td>{moment(item?.created_date).format("MM-DD-YYYY")}</td>
											<td>
												<span
													className="open-modal"
													onClick={() => {
														setModal(true);
														setOrder(item);
														dispatch(getpurchases(item?.sales_tracker_id));
														// setOrderno(item?.transaction_id);
													}}
												>
													{item?.order_id}
												</span>
											</td>
											<td>{item?.brand?.name ? item?.brand?.name : item?.brand?.brand_name}</td>
											<td>{item?.gen_category?.name ? item?.gen_category?.name : "-"}</td>
											<td>{item?.category?.category_name ? item?.category?.category_name : "All"}</td>
											<td>{item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All"}</td>
											<td>{numeral(item?.order_totalprice).format("$0,0.0'")}</td>
											<td>{numeral(item?.returned_amount).format("$0,0.0'")}</td>
											<td>{numeral(item?.balance_amount).format("$0,0.0'")}</td>

											<td>{item?.cashback_amount_percent}%</td>

											<td>{numeral(item?.cashback_amount).format("$0,0.0'")}</td>
											<td>{item?.event === "reviewshop" ? "Review Purchase" : item?.event === "reviewshare" ? "Review Purchase" : "Event Purchase"}</td>
											<td>{item?.status ? item?.status.charAt(0).toUpperCase() + item?.status.slice(1) : "-"}</td>
										</tr>
									);
								})
							)}

							{purchaseReport?.payload?.message?.data?.length > 0 && (
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td>
										<b>Total</b>
									</td>
									<td>
										{" "}
										<b>{purchaseReport?.payload?.message?.data?.length + currentPage * 15}</b>
									</td>
									<td></td>
									<td></td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.order_totalprice ? b.order_totalprice : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.returned_amount ? b.returned_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>

									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.balance_amount ? b.balance_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>

									{/* <td>
                    <b>
                      {numeral(
                        data.reduce((a, b) => {
                          return (
                            Number(a) +
                            balance(
                              b?.order_totalprice,
                              b?.returned_amount ? b?.returned_amount : 0
                            )
                          );
                        }, 0)
                      ).format("$0,0.0'")}
                    </b>
                  </td> */}

									<td>
										<b>
											{numeral(
												data.reduce((a, b) => {
													return (
														Number(a) +
														(Number(b.cashback_amount ? b.cashback_amount : 0.0) /
															data.reduce((a, b) => {
																return Number(a) + Number(b.order_totalprice ? b.order_totalprice : 0.0);
															}, 0)) *
															100
													);
												}, 0)
											).format("0,0.0'")}
											%
										</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.cashback_amount ? b.cashback_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td></td>
									<td></td>
								</tr>
							)}
						</tbody>
					</Table>
				</>
			);
		}
	}

	function DateGroupTable() {
		let data = purchaseReport?.payload?.message?.data;
		if (data) {
			return (
				<>
					<Table responsive className="transactions-box table">
						<thead className="table_heading">
							<tr>
								<th>S.#</th>
								<th>Order Date</th>
								{/* <th>Order #</th>
             
                <th>Category</th> */}
								<th>Amount</th>
								<th>Return Amount</th>
								<th>Balance Amount</th>
								<th>Cashback</th>
								{/* <th>Source</th> */}
							</tr>
						</thead>
						<tbody>
							{purchaseReport?.loading ? (
								<div className="antd-loading-ift">
									<Spin size="large"></Spin>
								</div>
							) : data?.length === 0 ? (
								<tr>
									<td colspan="12">
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									</td>
								</tr>
							) : (
								data.map((item, i) => {
									return (
										<tr>
											<td>{i + 1 + currentPage * 15}</td>
											<td>{moment(item?.created_date).format("MM-DD-YYYY")}</td>
											{/* <td>
                        <span
                          className="open-modal"
                          onClick={() => {
                            setModal(true);
                            setOrder(item);
                            dispatch(getpurchases(item?.sales_tracker_id));
                          }}
                        >
                          {item?.order_id}
                        </span>
                      </td> */}

											{/* <td>{item?.category?.name}</td> */}

											<td>{numeral(item?.order_totalprice).format("$0,0.0'")}</td>
											<td>{numeral(item?.returned_amount).format("$0,0.0'")}</td>
											<td>{numeral(item?.balance_amount).format("$0,0.0'")}</td>
											<td>{numeral(item?.cashback_amount).format("$0,0.0'")}</td>
											{/* <td>{item?.event === "reviewshop" ? "Review Purchase" : item?.event === "reviewshare" ? "Review Purchase" : "Event Purchase"}</td> */}
										</tr>
									);
								})
							)}

							{purchaseReport?.payload?.message?.data?.length > 0 && (
								<tr>
									<td>
										<b>Total</b>
									</td>
									<td>
										{" "}
										<b>{purchaseReport?.payload?.message?.data?.length + currentPage * 15}</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.order_totalprice ? b.order_totalprice : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.returned_amount ? b.returned_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>

									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.balance_amount ? b.balance_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.cashback_amount ? b.cashback_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</>
			);
		}
	}

	function BrandGroupTable() {
		let data = purchaseReport?.payload?.message?.data;
		if (data) {
			return (
				<>
					<Table responsive className="transactions-box table">
						<thead className="table_heading">
							<tr>
								<th>S.#</th>
								{/* <th>Order Date</th> */}
								{/* <th>Order #</th>
                <th>Category</th> */}
								<th>Brand</th>
								<th>Amount</th>
								<th>Return Amount</th>
								<th>Balance Amount</th>
								<th>Cashback</th>
								{/* <th>Source</th> */}
							</tr>
						</thead>
						<tbody>
							{purchaseReport?.loading ? (
								<div className="antd-loading-ift">
									<Spin size="large"></Spin>
								</div>
							) : data?.length === 0 ? (
								<tr>
									<td colspan="12">
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									</td>
								</tr>
							) : (
								data.map((item, i) => {
									return (
										<tr>
											<td>{i + 1 + currentPage * 15}</td>
											{/* <td>{moment(item?.created_date).format("DD-MM-YYYY")}</td> */}
											{/* <td>
                        <span
                          className="open-modal"
                          onClick={() => {
                            setModal(true);
                            setOrder(item);
                            dispatch(getpurchases(item?.sales_tracker_id));
                          }}
                        >
                          {item?.order_id}
                        </span>
                      </td> */}
											<td>{item?.brand?.name ? item?.brand?.name : item?.brand?.brand_name}</td>

											{/* <td>{item?.category?.name}</td> */}

											<td>{numeral(item?.order_totalprice).format("$0,0.0'")}</td>
											<td>{numeral(item?.returned_amount).format("$0,0.0'")}</td>
											<td>{numeral(item?.balance_amount).format("$0,0.0'")}</td>
											<td>{numeral(item?.cashback_amount).format("$0,0.0'")}</td>
											{/* <td>{item?.event === "reviewshop" ? "Review Purchase" : item?.event === "reviewshare" ? "Review Purchase" : "Event Purchase"}</td> */}
										</tr>
									);
								})
							)}

							{purchaseReport?.payload?.message?.data?.length > 0 && (
								<tr>
									<td>
										<b>Total</b>
									</td>
									<td>
										{" "}
										<b>{purchaseReport?.payload?.message?.data?.length + currentPage * 15}</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.order_totalprice ? b.order_totalprice : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.returned_amount ? b.returned_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>

									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.balance_amount ? b.balance_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.cashback_amount ? b.cashback_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</>
			);
		}
	}

	function CategoryGroupTable() {
		let data = purchaseReport?.payload?.message?.data;
		if (data) {
			return (
				<>
					<Table responsive className="transactions-box table">
						<thead className="table_heading">
							<tr>
								<th>S.#</th>
								{/* <th>Order Date</th> */}
								{/* <th>Order #</th>*/}
								<th>Category</th>
								<th>Amount</th>
								<th>Return Amount</th>
								<th>Balance Amount</th>
								<th>Cashback</th>
								{/* <th>Source</th> */}
							</tr>
						</thead>
						<tbody>
							{purchaseReport?.loading ? (
								<div className="antd-loading-ift">
									<Spin size="large"></Spin>
								</div>
							) : data?.length === 0 ? (
								<tr>
									<td colspan="12">
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									</td>
								</tr>
							) : (
								data.map((item, i) => {
									return (
										<tr>
											<td>{i + 1 + currentPage * 15}</td>
											{/* <td>{moment(item?.created_date).format("DD-MM-YYYY")}</td> */}
											{/* <td>
                        <span
                          className="open-modal"
                          onClick={() => {
                            setModal(true);
                            setOrder(item);
                            dispatch(getpurchases(item?.sales_tracker_id));
                          }}
                        >
                          {item?.order_id}
                        </span>
                      </td> */}

											<td>{item?.category?.category_name ? item?.category?.category_name : "-"}</td>
											<td>{numeral(item?.order_totalprice).format("$0,0.0'")}</td>
											<td>{numeral(item?.returned_amount).format("$0,0.0'")}</td>
											<td>{numeral(item?.balance_amount).format("$0,0.0'")}</td>
											<td>{numeral(item?.cashback_amount).format("$0,0.0'")}</td>
											{/* <td>{item?.event === "reviewshop" ? "Review Purchase" : item?.event === "reviewshare" ? "Review Purchase" : "Event Purchase"}</td> */}
										</tr>
									);
								})
							)}

							{purchaseReport?.payload?.message?.data?.length > 0 && (
								<tr>
									<td>
										<b>Total</b>
									</td>
									<td>
										{" "}
										<b>{purchaseReport?.payload?.message?.data?.length + currentPage * 15}</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.order_totalprice ? b.order_totalprice : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.returned_amount ? b.returned_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>

									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.balance_amount ? b.balance_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.cashback_amount ? b.cashback_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</>
			);
		}
	}

	function GenderCategoryGroupTable() {
		let data = purchaseReport?.payload?.message?.data;
		if (data) {
			return (
				<>
					<Table responsive className="transactions-box table">
						<thead className="table_heading">
							<tr>
								<th>S.#</th>
								<th>Department</th>
								<th>Amount</th>
								<th>Return Amount</th>
								<th>Balance Amount</th>
								<th>Cashback</th>
							</tr>
						</thead>
						<tbody>
							{purchaseReport?.loading ? (
								<div className="antd-loading-ift">
									<Spin size="large"></Spin>
								</div>
							) : data?.length === 0 ? (
								<tr>
									<td colspan="12">
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									</td>
								</tr>
							) : (
								data.map((item, i) => {
									return (
										<tr>
											<td>{i + 1 + currentPage * 15}</td>
											<td>{item?.gen_category?.name ? item?.gen_category?.name : "-"}</td>
											<td>{numeral(item?.order_totalprice).format("$0,0.0'")}</td>
											<td>{numeral(item?.returned_amount).format("$0,0.0'")}</td>
											<td>{numeral(item?.balance_amount).format("$0,0.0'")}</td>
											<td>{numeral(item?.cashback_amount).format("$0,0.0'")}</td>
										</tr>
									);
								})
							)}

							{purchaseReport?.payload?.message?.data?.length > 0 && (
								<tr>
									<td>
										<b>Total</b>
									</td>
									<td>
										{" "}
										<b>{purchaseReport?.payload?.message?.data?.length + currentPage * 15}</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.order_totalprice ? b.order_totalprice : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.returned_amount ? b.returned_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>

									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.balance_amount ? b.balance_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.cashback_amount ? b.cashback_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</>
			);
		}
	}

	function SubCategoryGroupTable() {
		let data = purchaseReport?.payload?.message?.data;
		if (data) {
			return (
				<>
					<Table responsive className="transactions-box table">
						<thead className="table_heading">
							<tr>
								<th>S.#</th>
								<th>Sub Category</th>
								<th>Amount</th>
								<th>Return Amount</th>
								<th>Balance Amount</th>
								<th>Cashback</th>
							</tr>
						</thead>
						<tbody>
							{purchaseReport?.loading ? (
								<div className="antd-loading-ift">
									<Spin size="large"></Spin>
								</div>
							) : data?.length === 0 ? (
								<tr>
									<td colspan="12">
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									</td>
								</tr>
							) : (
								data.map((item, i) => {
									return (
										<tr>
											<td>{i + 1 + currentPage * 15}</td>
											<td>{item?.sub_category?.sub_category_name ? item?.sub_category?.sub_category_name : "All"}</td>
											<td>{numeral(item?.order_totalprice).format("$0,0.0'")}</td>
											<td>{numeral(item?.returned_amount).format("$0,0.0'")}</td>
											<td>{numeral(item?.balance_amount).format("$0,0.0'")}</td>
											<td>{numeral(item?.cashback_amount).format("$0,0.0'")}</td>
											{/* <td>{item?.event === "reviewshop" ? "Review Purchase" : item?.event === "reviewshare" ? "Review Purchase" : "Event Purchase"}</td> */}
										</tr>
									);
								})
							)}

							{purchaseReport?.payload?.message?.data?.length > 0 && (
								<tr>
									<td>
										<b>Total</b>
									</td>
									<td>
										{" "}
										<b>{purchaseReport?.payload?.message?.data?.length + currentPage * 15}</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.order_totalprice ? b.order_totalprice : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.returned_amount ? b.returned_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>

									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.balance_amount ? b.balance_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
									<td>
										<b>
											{" "}
											{numeral(
												data.reduce((a, b) => {
													return Number(a) + Number(b.cashback_amount ? b.cashback_amount : 0.0);
												}, 0)
											).format("$0,0.0'")}
										</b>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</>
			);
		}
	}

	return (
		<>
			<div className="account-left-content">
				<div className="purchase-content-main ">
					<Form form={form}>
						<div className="row w-20-desktop-main">
							<div className="col-xl-2 col-md-6 col-12 mb-20">
								<p>Select Start Date / End Date</p>
								<RangePicker
									size="large"
									className="w-100"
									key={4}
									value={startDate && endDate ? [moment(startDate), moment(endDate)] : []}
									allowClear={false}
									ranges={{
										"Last Year": [moment().subtract(1, "year").startOf("year"), moment().subtract(1, "year").endOf("year")],
										"This Year": [moment().startOf("year"), moment()],
										"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
										"This Month": [moment().startOf("month"), moment().endOf("month")],
										Today: [moment(), moment()],
									}}
									format={dateFormat}
									onChange={dateRangePickerChanger}
								/>
							</div>

							<div className="col-xl-2 col-md-6 col-12 mb-20">
								<p>Brand</p>
								<Select className="select-style dark-field w-100" placeholder="Select Brand" size="large" focus={false} onBlur={false} onChange={handleBrand} value={brand === "" ? null : brand}>
									<Option value={"all"}>All</Option>
									{brandList?.length > 0 &&
										brandList[0]?.brands.map((item, i) => {
											return <Option value={item?._id}>{item?.brand_name}</Option>;
										})}
								</Select>
							</div>

							<div className="col-xl-2 col-md-6 col-12 mb-20">
								<p>Department</p>
								<Select className="select-style dark-field w-100" placeholder="Select Gender" size="large" focus={false} onBlur={false} onChange={handleGenCategory} value={genderCategory === "" ? null : genderCategory}>
									<Option value={"all"}>All</Option>
									{genderCatgory?.length > 0 &&
										genderCatgory?.map((item) => {
											return <Option value={item?._id}>{item?.name}</Option>;
										})}
								</Select>
							</div>

							<div className="col-xl-2 col-md-6 col-12 mb-20">
								<p>Category</p>
								<Select className="select-style dark-field w-100" placeholder="Category" size="large" focus={false} onChange={handleCategory} onBlur={false} value={category === "" ? null : category}>
									<Option value={"all"}>All</Option>
									{publicCatgory?.length > 0 &&
										publicCatgory?.map((item) => {
											return <Option value={item?._id}>{item?.category_name}</Option>;
										})}
								</Select>
							</div>

							<div className="col-xl-2 col-md-6 col-12 mb-20">
								<p>Sub Category</p>
								<Select className="select-style dark-field w-100" placeholder="Sub Category" size="large" focus={false} onChange={handleSubCategory} onBlur={false} value={Subcategory === "" ? null : Subcategory}>
									<Option value={"all"}>All</Option>
									{load ? (
										<Option>
											<Spin size="small" />
										</Option>
									) : (
										categoryList?.length > 0 &&
										categoryList?.map((items) => {
											return <Option value={items?._id}>{items?.sub_category_name}</Option>;
										})
									)}
								</Select>
							</div>

							<div className="col-xl-2 col-md-6 col-12 mb-20">
								<p>Source</p>
								<Select className="select-style dark-field w-100" placeholder="Source" size="large" focus={false} onBlur={false} onChange={handleSource} value={source === "" ? null : source}>
									<Option value="all">All</Option>
									<Option value="event_sales">Event Purchase</Option>
									<Option value="review_sales">Review Purchase</Option>
								</Select>
							</div>

							<div className="col-xl-2 col-md-6 col-12 mb-20">
								<p>Group By</p>
								<Select className="select-style dark-field w-100" placeholder="Group By" size="large" focus={false} onBlur={false} onChange={handleGroupBy} value={groupBy === "" ? null : groupBy}>
									<Option value="none">None</Option>
									<Option value="date">Date</Option>
									<Option value="brand">Brand</Option>
									<Option value="genCategory">Department</Option>
									<Option value="category">Category</Option>
									<Option value="subCategory">Sub Category</Option>
								</Select>
							</div>

							<div className="col-xl-2 col-md-6 col-12 d-flex align-items-end mb-20">
								<Button onClick={handleSubmit} className="ant-btn ant-btn-default ant-btn-lg">
									Search
								</Button>
								<Button onClick={handleReset} className="ant-btn ant-btn-default ant-btn-lg">
									Reset
								</Button>
							</div>
						</div>
					</Form>

					{loading ? (
						<div className="antd-loading-ift">
							<tr>
								<Spin size="large" />
							</tr>
						</div>
					) : (
						<>
							{!purchaseReport?.payload?.message?.data?.length === 0 ? (
								<tr>
									<td colspan="5">
										<Empty
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
											image={Empty.PRESENTED_IMAGE_SIMPLE}
										/>
									</td>
								</tr>
							) : (
								<>
									<div className="purchase-data referral-data">
										{/* {DefaultTable()} */}
										{submit === "none" && <DefaultTable />}
										{submit === "date" && <DateGroupTable />}
										{submit === "category" && <CategoryGroupTable />}
										{submit === "brand" && <BrandGroupTable />}
										{submit === "genCategory" && <GenderCategoryGroupTable />}
										{submit === "subCategory" && <SubCategoryGroupTable />}

										{purchaseReport?.payload?.message?.data?.length ? <ReactPaginate nextLabel={<FontAwesomeIcon icon={faAngleRight} />} onPageChange={handlePageClick} pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7} pageCount={Math.ceil(totalEvent / limit)} forcePage={currentPage} previousLabel={<FontAwesomeIcon icon={faAngleLeft} />} pageClassName="page-item d-none" pageLinkClassName="page-link" previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link" breakLabel="..." breakClassName="page-item d-none" breakLinkClassName="page-link" containerClassName={"pagination custom-paginate"} activeClassName="active" renderOnZeroPageCount={null} /> : ""}
										<Summary />
										<br></br>
									</div>
								</>
							)}
						</>
					)}
				</div>
			</div>

			<Modal dialogClassName="code-activated-popup" size="lg" scrollable show={modal} onHide={() => setModal(false)} centered>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<h2 className="modal-hd1">Order Detail</h2>
					<div className="order-detail-title-area">
						<span className="order-num">Order #{order?.order_id}</span>
						<span className="order-date">{moment(order?.created_date).format("MMMM Do YYYY [from Online Store]")}</span>
					</div>
					{order?.transaction_id ? (
						<div>
							<b>Orme No:</b>
							<span> {order?.transaction_id}</span>
						</div>
					) : (
						<></>
					)}

					<Table responsive size="sm" className="transactions-box">
						<thead className="table_heading">
							<tr>
								<th>S.#</th>
								<th>Order Date</th>
								<th>SKU #</th>
								<th>Description</th>
								<th>Qty</th>
								<th>Price</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							{purchaseSummary?.loading ? (
								<>
									<tr>
										<td colspan={6}>
											<Spin
												style={{
													display: "flex",
													justifyContent: "center",
												}}
											/>
										</td>
									</tr>
								</>
							) : (
								<>
									{purchaseSummary?.payload?.message?.order_detail?.line_items.map((item, key) => {
										return (
											<tr key={key}>
												<td>{key + 1}</td>
												<td>{moment(order?.created_at).format("MM-DD-YYYY")}</td>
												<td>{item?.sku}</td>
												<td>{item?.title}</td>
												<td>{item?.quantity}</td>
												<td>{numeral(item?.price).format("$0,0.0'")}</td>
												<td>{numeral(getSum(item?.quantity, item?.price)).format("$0,0.0'")}</td>
											</tr>
										);
									})}
								</>
							)}
						</tbody>
					</Table>

					<div className="order-sum">
						<div className="paid-mid-row">
							<div className="left-txt multi-content">
								<div className="left-hd">Subtotal</div>
								<div className="left-info">{purchaseSummary?.payload?.message?.order_detail?.line_items?.length} item</div>
							</div>
							<div className="right-txt">{numeral(purchaseSummary?.payload?.message?.order_detail?.total_line_items_price).format("$0,0.0'")}</div>
						</div>

						<div className="paid-mid-row">
							<div className="left-txt multi-content">
								<div className="left-hd">Discount</div>
								{purchaseSummary?.payload?.message?.order_detail?.total_discounts ? numeral(purchaseSummary?.payload?.message?.order_detail?.total_discounts / purchaseSummary?.payload?.message?.order_detail?.total_line_items_price).format("0%") : ""}
							</div>
							{purchaseSummary?.payload?.message?.order_detail?.total_discounts > 0 ? (
								<>
									<div className="right-txt">-{numeral(purchaseSummary?.payload?.message?.order_detail?.total_discounts).format("$0,0.0'")}</div>
								</>
							) : (
								<div className="right-txt">{numeral(0).format("$0,0.0'")}</div>
							)}
						</div>

						<div className="paid-mid-row">
							<div className="left-txt multi-content">
								<div className="left-hd">Shipping</div>
							</div>
							<div className="right-txt">{numeral(purchaseSummary?.payload?.message?.order_detail?.total_shipping_price_set?.presentment_money?.amount).format("$0,0.0'")}</div>
						</div>

						<div className="paid-mid-row">
							<div className="left-txt multi-content">
								<div className="left-hd">Tax</div>
							</div>
							<div className="right-txt">{numeral(purchaseSummary?.payload?.message?.order_detail?.total_tax).format("$0,0.0'")}</div>
						</div>
						<div className="paid-mid-row">
							<div className="left-txt multi-content">
								<div className="left-hd fw-bold">Total Amount</div>
							</div>
							<div className="right-txt fw-bold">{numeral(purchaseSummary?.payload?.message?.order_detail?.total_price).format("$0,0.0'")}</div>
						</div>
					</div>

					{!purchaseSummary?.loading && purchaseSummary?.payload?.message?.order_return_detail && (
						<div style={{ marginTop: "20px" }}>
							<div className="order-detail-title-area">
								<div className="d-flex flex-column">
									<div className="order-num">Refund Detail</div>
								</div>
							</div>
							<Table responsive size="sm" className="transactions-box">
								<thead className="table_heading">
									<tr>
										<th>S#</th>
										<th>Order Date</th>
										<th>SKU #</th>
										<th>Description</th>
										<th>Qty</th>
										<th>Price</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									{!purchaseSummary?.loading &&
										purchaseSummary?.payload?.message?.order_return_detail?.refund_line_items?.map((item, key) => {
											return (
												<tr key={key}>
													<td>{key + 1}</td>
													<td>{moment.utc(order?.created_at).format("MM-DD-YYYY")}</td>
													<td>{item?.line_item?.sku}</td>
													<td>{item?.line_item?.title}</td>
													<td>{item?.quantity}</td>
													<td>{numeral(item?.line_item?.price).format("$0,0.0'")}</td>
													<td>{numeral(item?.subtotal).format("$0,0.0'")}</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
							{/* {console.log(purchaseSummary?.payload?.message?.order_return_detail?.refund_line_items, 'refunds')} */}

							<div className="order-sum">
								<>
									<div className="paid-mid-row">
										<div className="left-txt multi-content">
											<div className="left-hd">Subtotal</div>
											<div className="left-info">{purchaseSummary?.payload?.message?.order_return_detail?.refund_line_items?.length ? purchaseSummary?.payload?.message?.order_return_detail?.refund_line_items?.length : 0} item</div>
										</div>
										<div className="right-txt">
											{numeral(
												purchaseSummary?.payload?.message?.order_return_detail?.refund_line_items?.reduce((acc, item) => {
													return (acc = acc + item.subtotal);
												}, 0)
											).format("$0,0.0'")}
										</div>
									</div>
									<div className="paid-mid-row">
										<div className="left-txt multi-content">
											<div className="left-hd">Tax</div>
										</div>
										<div className="right-txt">
											{numeral(
												purchaseSummary?.payload?.message?.order_return_detail?.refund_line_items?.reduce((acc, item) => {
													return (acc = acc + item.total_tax);
												}, 0)
											).format("$0,0.0'")}
										</div>
									</div>
									<div className="paid-mid-row">
										<div className="left-txt multi-content">
											<div className="left-hd fw-bold">Total Amount</div>
										</div>
										<div className="right-txt fw-bold">
											{numeral(
												purchaseSummary?.payload?.message?.order_return_detail?.refund_line_items?.reduce((acc, item) => {
													return (acc = acc + item.subtotal);
												}, 0) +
													purchaseSummary?.payload?.message?.order_return_detail?.refund_line_items?.reduce((acc, item) => {
														return (acc = acc + item.total_tax);
													}, 0)
											).format("$0,0.0'")}
										</div>
									</div>
								</>
							</div>
						</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setModal(false)}>
						<i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go Back
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
