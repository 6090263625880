import { POST_SALES_TRACKER_REQUEST,  POST_SALES_TRACKER_SUCCESS,  POST_SALES_TRACKER_ERROR } from "../../types/types";
export default function trackingSale(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case  POST_SALES_TRACKER_REQUEST:
      return {
        loading: true,
      };
    case  POST_SALES_TRACKER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case  POST_SALES_TRACKER_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    default:
      return state;
  }
}
