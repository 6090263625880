import { FOLLOWERS_STATUS_REQUEST, FOLLOWERS_STATUS_SUCCESS, FOLLOWERS_STATUS_ERROR } from "../../types/types";
export default function followersStatus(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case FOLLOWERS_STATUS_REQUEST:
			return {
				loading: true,
			};
		case FOLLOWERS_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case FOLLOWERS_STATUS_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}
