import React from "react";
import Router from "./routing/routes";
import axios from "axios";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/custom.scss";
import "./css/customer_portal.scss";
import "./css/responsive.scss";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory({
	forceRefresh: true,
});

const App = () => {
	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			if (error.response?.status === 401) {
				localStorage.removeItem("userInfo");
				history.push("/");
				window.location.reload();
			} else {
				return Promise.reject(error);
			}
		}
	);

	return (
		<Provider store={store}>
			<Router />
		</Provider>
	);
	


};
export default App;
