import { useEffect, useState } from "react";
import Layout from "./component/layout";
import Paypal from "./component/paypal";
import Wallet from "./component/wallet";
import { useSelector } from "react-redux";
import { payoutWithdraw } from "../../redux/actions/payout.action";
import { getAccount } from "../../redux/actions/account.action";
import { useDispatch } from "react-redux";
import { Spin } from "antd";
import { CLIENTID, PAYPALLINK } from "../../config";

export default () => {
	const { account } = useSelector((state) => state);
	const [pageStatus, setPageStatus] = useState("paypal");
	const dispatch = useDispatch();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const authorizationCode = urlParams.get("code");
		if (!authorizationCode) {
			return;
		}

		let userInfo = localStorage.getItem("userInfo");
		if (userInfo !== "undefined") {
			userInfo = JSON.parse(localStorage.getItem("userInfo"));
		} else {
			localStorage.removeItem("userInfo");
			window.location.reload();
		}

		dispatch(payoutWithdraw(userInfo?.token, authorizationCode)).then(() => {
			dispatch(getAccount(userInfo?.token));
		});
	}, []);

	const handleLogin = () => {
		const domain = window.location.protocol + "//" + window.location.hostname;

		const redirectUri = `${domain}/payout`; // Replace with your actual redirect URL

		const loginUrl = `${PAYPALLINK}/webapps/auth/protocol/openidconnect/v1/authorize?client_id=${CLIENTID}&response_type=code&scope=openid%20profile%20email&redirect_uri=${redirectUri}`;
		
		window.location.href = loginUrl;
	};

	useEffect(() => {
		if (!account?.payload?.Data.hasOwnProperty("paypal_email")) {
			setPageStatus("paypal");
		} else {
			if (account?.payload?.Data?.paypal_email === "") {
				setPageStatus("Disconnect");
			} else {
				setPageStatus("Connected");
			}
		}
	}, [account]);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			document.body.classList.add("bioshop-body", "shopper-bioshop-body");

			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	return (
		<>
			<Layout title="Payout">
				{account?.loading ? (
					<div className="account-left-content">
						<div className="antd-loading-ift">
							<Spin size="large"></Spin>
						</div>
					</div>
				) : (
					<>{pageStatus === "paypal" && account?.payload?.Data ? <Paypal handleLogin={handleLogin} /> : <Wallet handleLogin={handleLogin} pageStatus={pageStatus} />}</>
				)}
			</Layout>
		</>
	);
};
