import React from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom"
import { Collapse } from "antd";
const { Panel } = Collapse;
export default () => {
	return (
		<>
			<h3>FAQs</h3>
			{/* <h5>For Brands</h5> */}
			<div className="faq-container-ift">
				<Collapse
					accordion
					expandIconPosition={"end"}
					// expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 90 : 0} />}
					expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
				>
					<Panel header="How do I become an Influencer on ORME?" key="1">
						<p>
							Every user on ORME is considered an Influencer. Simply sign up, start exploring, and sharing your favorite videos. You're already on your way to becoming an Influencer!
						</p>
					</Panel>
					<Panel header="How do I earn a Referral Fee?" key="2">
						<p>When you share videos on ORME and someone makes a purchase through your shared content, you earn a Referral Fee. It's that simple! The more you share, the more you can earn.</p>
					</Panel>
					<Panel header="What is a Creator Fee, and how can I earn it?" key="3">
						<p>
							If you create or upload a video, and a purchase is made through your content, you earn a Creator Fee. Your creativity pays off, literally!
						</p>

					</Panel>
					<Panel header="How does Cashback work on ORME?" key="4">
						<p>
							Every time you make a purchase on ORME, you receive Cashback. It's our way of giving back to our valued shoppers.
						</p>

					</Panel>
					<Panel header="What is the My Shop feature in the My Account section?" key="5">
					<p>"My Shop" is your personalized space on ORME. It includes a display of the videos you've uploaded, and there's a "Favorites" tab where all the videos you've shared are showcased.</p>
				</Panel>
				<Panel header="How can I share my shop link with others?" key="6">
					<p>In the "My Shop" section, you'll find a unique link to your shop. Share this link with others, and every purchase made from your "Favorites" earns you a Referral Fee.</p>
				</Panel>
				<Panel header="How do I earn a Creator Fee from My Shop?" key="7">
					<p>
						If someone makes a purchase from your shop's "Reviews" section, you earn a Creator Fee. Your influence in action!
					</p>
				</Panel>
				<Panel header="Can I track my earnings on ORME?" key="8">
					<p>Yes, you can easily track your earnings in the My Account section. It provides a detailed breakdown of Referral Fees, Creator Fees, and Cashback.</p>
				</Panel>
				<Panel header="How do I contact customer support if I have any issues or questions?" key="9">
					<p>If you have any questions or encounter issues, our customer support team is here to help. You can reach out through the Contact Us page or email at support@ormelive.com and we'll get back to you promptly.</p>
				</Panel>
			</Collapse>
		</div >
		{/* <h5 className="mt-4">For Influencers</h5>
			<div className="faq-container-ift">
				<Collapse
					accordion
					expandIconPosition={"end"}
					// expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 90 : 0} />}
					expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
				>
					<Panel header="How do I sign up as influencer?" key="1">
						<p>
							You can sign up on the app or the platform at <NavLink to="/">ORMELIVE.com</NavLink> and apply to become an influencer (if you meet the requirements of having at least 1,000 Instagram followers).
						</p>
					</Panel>
					<Panel header="After I join, is there any fees to pay?" key="2">
						<p>
							There are no contractual obligations. You can simply activate the brands which you like to promote and deactivate at any time. ORMELIVE is totally FREE and you get commissions on total sales referred
							to the brand, ranging upto 50%.
						</p>
					</Panel>
					<Panel header="What’s the typical process to participate in brand campaigns?" key="3">
						<p>
							Sign-in, select categories and apply to brands for participation. If a brand accepts your request, select the campaigns to promote. Campaigns get added to your very own digital shop. You can also
							connect your Instagram, upload your own photos and add links to posts. You can promote the link of your digital shop to your Instagram bio and social profiles/posts to start driving traffic and earn
							commissions.
						</p>
					</Panel>
					<Panel header="How will I get my commission paid?" key="4">
						<p>All earnings of commission on sales from your referral will be paid via PayPal or check.</p>
					</Panel>
					<Panel header="What features will be available to me as an Influencer?" key="5">
						<p>
							<strong>As an Influencer, you will:</strong>
						</p>
						<ul>
							<li>Get your own customizable digital shop</li>
							<li>Connect your Instagram and add links to your favorite posts</li>
							<li>Upload your own photos and make them clickable</li>
							<li>Curate your shop with your affiliate links from other networks like CJ, Amazon, Rakuten, eBay etc.</li>
							<li>Add and monitor your participation in brand campaigns</li>
							<li>Get real-time analytics on your ORMELIVE posts to see what’s working for you</li>
							<li>Track you earnings and performance of campaign participation</li>
							<li>Share your unique link across social channels and use it as a Link-in-Bio</li>
						</ul>
					</Panel>
					<Panel header="How can I communicate with brands?" key="7">
						<p>
							For all your queries email us at <a href="mailto:support@ORMELIVE.com">support@ORMELIVE.com</a> since direct contact with brands is not available.
						</p>
					</Panel>
				</Collapse>
			</div> */}
		</>
	);
};