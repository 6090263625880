import React from "react";
import Banner from "../../components/banner/banner";
import FeatureBrand from "../../components/brand/featureBrand";
import FavouriteBrand from "../../components/brand/favouriteBrand";
import AllBrand from "../../components/brand/allBrand";
import HotDeal from "../../components/deal/hotDeal";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory({
	forceRefresh: true,
});

export default function Home() {
	return (
		<React.Fragment>
			<Banner />
			<FeatureBrand />
			<FavouriteBrand />
			<HotDeal />
			<AllBrand heading="All Brands" />
		</React.Fragment>
	);
}
