import { GET_BRANDS_PRODUCTS_REQUEST, GET_BRANDS_PRODUCTS_SUCCESS, GET_BRANDS_PRODUCTS_ERROR } from "../../types/types";
export default function brandSkutitle(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_BRANDS_PRODUCTS_REQUEST:
            return {
                loading: true,
            };
        case GET_BRANDS_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };

        case GET_BRANDS_PRODUCTS_ERROR:
            return {
                ...state,
                loading: false,
                payload: payload ? payload : [],
            };

        default:
            return state;
    }
}
