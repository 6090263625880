import { ANDROIDAPP, APPLEAPP } from "../config";
import { useEffect } from "react";
export function redirectToAppOrStore() {
	const appleAppSchemeURL = "ormelive://"; // Replace with your iOS app scheme URL

	function redirectToStore(appStoreURL) {
		window.location.href = appStoreURL;
	}
	try {
		if (/iPad|iPhone|iPod/.test(navigator.platform)) {
			// Check if it's an iPad

			window.location.href = appleAppSchemeURL;

			// Set a timeout to check if the iOS app was opened within a specific time (e.g., 5 seconds)
			const timeoutMilliseconds = 4000; // 5 seconds
			const startTime = Date.now();

			const checkRedirection = () => {
				if (!document.hidden) {
					// The iOS app didn't open, so redirect to the App Store
					const elapsedTime = Date.now() - startTime;
					if (elapsedTime >= timeoutMilliseconds) {
						redirectToStore(APPLEAPP); // Redirect to the App Store
					} else {
						// Retry the redirection check after a short delay
						setTimeout(checkRedirection, 500); // Retry after 0.5 seconds
					}
				}
			};

			// // Start checking for redirection
			checkRedirection();
		} else if (/Android/.test(navigator.userAgent)) {
			// Attempt to open the Android app
			window.location.href = appleAppSchemeURL;

			// Set a timeout to check if the Android app was opened within a specific time (e.g., 5 seconds)
			const timeoutMilliseconds = 5000; // 5 seconds
			const startTime = Date.now();

			const checkRedirection = () => {
				if (!document.hidden) {
					// The Android app didn't open, so redirect to the Play Store
					const elapsedTime = Date.now() - startTime;
					if (elapsedTime >= timeoutMilliseconds) {
						redirectToStore(ANDROIDAPP); // Redirect to the Play Store
					} else {
						// Retry the redirection check after a short delay
						setTimeout(checkRedirection, 500); // Retry after 0.5 seconds
					}
				}
			};

			// Start checking for redirection
			checkRedirection();
		}
	} catch (error) {
		console.error("Error opening app: " + error.message);

		redirectToStore(APPLEAPP); // Handle the error for iOS, e.g., redirect to the App Store
		// For Android, you can handle the error similarly by redirecting to the Play Store.
	}
}

export function isIOS() {
	return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isAndroid() {
	return /Android/i.test(navigator.userAgent);
}

export function isMobile() {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function useScrollAnimation  (className)  {
	useEffect(() => {
	  const handleScroll = () => {
		const textElements = document.querySelectorAll(className);
		let delay = 0;
		const delayIncrement = 0.3;
  
		textElements.forEach((el) => {
		  const position = el.getBoundingClientRect();
  
		  if (position.top < window.innerHeight && position.bottom >= 0) {
			el.style.setProperty("--animation-delay", `${delay}s`);
			el.classList.add("animate-on-scroll");
			delay += delayIncrement;
		  } else {
			el.classList.remove("animate-on-scroll");
			el.style.removeProperty("--animation-delay");
		  }
		});
	  };
  
	  window.addEventListener("scroll", handleScroll);
  
	  return () => window.removeEventListener("scroll", handleScroll);
	}, [className]);
  };