import { ALL_PUBLISH_EVENT_REQUEST, ALL_PUBLISH_EVENT_SUCCESS, ALL_PUBLISH_EVENT_ERROR, ALL_PUBLISH_EVENT_SUCCESS_LOADMORE } from "../../types/types";
export default function allPublishEvent(state = { loading: true }, action) {
	const { type, payload } = action;

	switch (type) {
		case ALL_PUBLISH_EVENT_REQUEST:
			return {
				...state,
				loading: true,
			};
		case ALL_PUBLISH_EVENT_SUCCESS:
			let page = 1;
			if(payload?.message?.next?.page){
			 page =	payload?.message?.next?.page - 1
			}
			payload?.message?.data.map((item) => {
				item.page = page;
			});
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case ALL_PUBLISH_EVENT_SUCCESS_LOADMORE:
			let nextPage = payload?.message?.next?.page - 1;
			if (payload?.message?.next?.page) {
				payload?.message?.data?.map((item) => {
					item.page = nextPage;
				});
			} else {
				payload?.message?.data?.map((item) => {
					item.page = state?.payload?.message?.next?.page;
				});
			}

			return {
				...state,
				loading: false,
				payload: {
					...state.payload.message,
					message: {
						...payload.message,
						data: [...state.payload.message.data, ...payload.message.data],
					},
				},
			};
		case ALL_PUBLISH_EVENT_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}
