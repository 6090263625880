import Layout from "./component/layout";
import UploadVid from "./component/uploadVid";

export default () => {
	return (
		<>
			<Layout title="Review Upload">
				<UploadVid />
			</Layout>
		</>
	);
};
