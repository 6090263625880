import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Form, Input, notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { Button } from "react-bootstrap";

import { getShopifyCart } from "../../redux/actions/liveEvent.action";
import { applyPromoCode } from "../../redux/actions/shopify.applyPromo.action";

import FloatLabel from "../../components/floatLabel";

import "../../css/influencer-reviews.scss";

function PromoCodeForm({ brandId, setCart, setStatus, cart, activeKey, eventId }) {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const dispatch = useDispatch();
	const [currentForm] = Form.useForm();
	const [discountCode, updateDiscountCode] = useState("");
	const [errorOnPromo, updateErrorOnPromo] = useState("");
	useEffect(() => {
		if (userInfo) {
			if (activeKey === "4") {
				if (!cart?.discountApplications[0]?.applicable) {
					if (eventId[0]?.promo && eventId[0]?.promo !== "KB0") {
						handlePromoCodeSuccess({ promo: eventId[0]?.promo });
					}
				}
			}
		}
	}, [activeKey]);

	const handlePromoCodeSuccess = (formValues) => {
		let promo = formValues.promo;
		dispatch(applyPromoCode(brandId ? brandId : userInfo?.brand, promo, userInfo?.token)).then((response) => {
			if (response?.payload?.success === true) {
				updateDiscountCode("");
				updateErrorOnPromo("");
				dispatch(getShopifyCart(brandId, userInfo?.token)).then((cartResponse) => {
					if (cartResponse?.payload?.message?.discountApplications[0].applicable) {
						notification.open({
							message: "Promo Applied",
							icon: <SmileOutlined style={{ color: "#108ee9" }} />,
						});
						if (cartResponse?.payload?.message?.lineItems?.length > 0) {
							setStatus(false);
						} else {
							setStatus(true);
						}
						setCart(cartResponse?.payload?.message);
					} else {
						updateDiscountCode("");
						updateErrorOnPromo(`${promo} discount code isn’t available to you right now`);
					}
				});
			}
			if (response?.payload?.data?.success === false) {
				updateErrorOnPromo(response?.payload?.data?.message);
			}
			currentForm.resetFields();
		});
	};

	const handlePromoCodeFailure = () => {};

	return (
		<>
			<Form onFinish={handlePromoCodeSuccess} onFinishFailed={handlePromoCodeFailure} form={currentForm} autoComplete="off" size="large" name="user_login" className="login-form form-style2 position-relative" layout="vertical">
				<FloatLabel label="" name="promo" value={discountCode}>
					<Form.Item
						name="promo"
						rules={[
							{
								required: true,
								message: "Please insert promo/discount code",
							},
						]}
					>
						<Input
							placeholder="Promo Code"
							onChange={(e) => {
								if (e.target.value === "") {
									updateErrorOnPromo("");
								}
							}}
						/>
					</Form.Item>
				</FloatLabel>

				<Button type="primary" htmlType="submit">
					<i className="fa fa-arrow-right" aria-hidden="true"></i>
				</Button>
			</Form>
			{errorOnPromo && (
				<div className="error-wrong-coupon-code">
					<div role="alert" className="ant-form-item-explain-error">
						{errorOnPromo}
					</div>
				</div>
			)}
		</>
	);
}

export default PromoCodeForm;
