// React related imports
import React, { useEffect, useState, useRef } from "react";

// Libraries and third-party components
import { Spin, Empty, notification } from "antd";
import { ChatRoom, SendMessageRequest } from "amazon-ivs-chat-messaging";
import ScrollToBottom, { useScrollToBottom } from "react-scroll-to-bottom";

// Redux related imports
import { useDispatch, useSelector, connect } from "react-redux";
import { getChatLogs } from "../../../redux/actions/chat";
import { getAccount } from "../../../redux/actions/account.action";
import * as chatActions from "../../../redux/actions/chat";

// Styles
import "../../../css/chat.scss";

function Chat({ publishEvent, activeKey, eventId, createChatToken, currentTimestamp }) {
	const dispatch = useDispatch();
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const [value, setValue] = useState("");
	const [chatRoom, setChatRoom] = useState([]);
	const scrollToBottomMethod = useScrollToBottom();
	const [connected, setConntected] = useState(false);
	const [chat, setChat] = useState([]);
	const [isBan, setIsBan] = useState(true);

	const chatContainerRef = useRef(null);

	useEffect(() => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
		}
	}, [chat]);

	useEffect(() => {
		if (userInfo?.token && account?.length === 0) dispatch(getAccount(userInfo?.token));
	}, []);

	const { account, myChatToken } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		if (activeKey === "1") {
			createChatToken(eventId[0]?._id, userInfo._id, userInfo?.token).then((res) => {
				if (res?.payload?.data?.success == false) setIsBan(res?.payload?.data?.success);
			});
			dispatch(getChatLogs(eventId[0]?._id, userInfo?.token)).then((res) => {
				if (res?.payload?.success) {
					setChat(res.payload.message);
					setConntected(true);
				} else {
					notification.error({
						message: res.payload.data.message,
						className: "toast-error",
					});
				}
			});
		}
	}, [activeKey, eventId]);

	const scrollToBottom = () => {
		scrollToBottomMethod();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!value) return;
		setTimeout(() => {
			sendMessage(value);
			setValue("");
		}, 1000);
	};

	const handleChange = (e) => {
		setValue(e.target.value);
	};

	useEffect(() => {
		if (myChatToken?.token) {
			const room = new ChatRoom({
				regionOrUrl: "us-east-1",
				tokenProvider: () => ({
					token: myChatToken.token,
					sessionExpirationTime: new Date(myChatToken.sessionExpirationTime),
					tokenExpirationTime: new Date(myChatToken.tokenExpirationTime),
				}),
			});
			setChatRoom(room);
			// Connect to the chat room
		}
	}, [myChatToken]);

	useEffect(() => {
		// If chat room listeners are not available, do not continue
		if (!chatRoom.addListener) {
			return;
		}
		/**
		 * Called when room is establishing the initial connection or reestablishing
		 * connection after socket failure/token expiration/etc
		 */
		const unsubscribeOnConnecting = chatRoom.addListener("connecting", () => {});

		/** Called when connection has been established. */
		const unsubscribeOnConnected = chatRoom.addListener("connect", (a, b) => {
			setConntected(true);
			//   console.log('aadfasfdaa', a);
			//   console.log('bbbbbbbbbbbbbbbbb', b);
		});

		/** Called when a room has been disconnected. */
		const unsubscribeOnDisconnected = chatRoom.addListener("disconnect", () => {});

		/** Called when a chat message has been received. */
		const unsubscribeOnMessageReceived = chatRoom.addListener("message", (message) => {
			setChat((preState) => [
				...preState,
				{
					payload: {
						Attributes: {
							name: message?.attributes?.name,
						},
						Content: message?.content,
						Id: message?.id,
					},
				},
			]);

			scrollToBottom();
			/* Example message:
			 * {
			 *   id: "5OPsDdX18qcJ",
			 *   sender: { userId: "user1" },
			 *   content: "hello world",
			 *   sendTime: new Date("2022-10-11T12:46:41.723Z"),
			 *   requestId: "d1b511d8-d5ed-4346-b43f-49197c6e61de"
			 * }
			 */
		});

		/** Called when a chat event has been received. */
		const unsubscribeOnEventReceived = chatRoom.addListener("event", (event) => {
			/* Example event:
			 * {
			 *   id: "5OPsDdX18qcJ",
			 *   eventName: "customEvent,
			 *   sendTime: new Date("2022-10-11T12:46:41.723Z"),
			 *   requestId: "d1b511d8-d5ed-4346-b43f-49197c6e61de",
			 *   attributes: { "Custom Attribute": "Custom Attribute Value" }
			 * }
			 */
		});

		/** Called when `aws:DELETE_MESSAGE` system event has been received. */
		const unsubscribeOnMessageDelete = chatRoom.addListener("messageDelete", (deleteMessageEvent) => {
			setChat((chat) => chat.filter((m) => m?.payload?.Id !== deleteMessageEvent.messageId));
		});

		/** Called when `aws:DISCONNECT_USER` system event has been received. */
		const unsubscribeOnUserDisconnect = chatRoom.addListener("userDisconnect", (disconnectUserEvent) => {
			setIsBan(false);

			/* Example event payload:
			 * {
			 *   id: "AYk6xKitV4On",
			 *   userId": "R1BLTDN84zEO",
			 *   reason": "Spam",
			 *   sendTime": new Date("2022-10-11T12:56:41.113Z"),
			 *   requestId": "b379050a-2324-497b-9604-575cb5a9c5cd",
			 *   attributes": { UserId: "R1BLTDN84zEO", Reason: "Spam" }
			 * }
			 */
		});

		chatRoom.connect();
		return () => {
			unsubscribeOnConnected();
			unsubscribeOnDisconnected();
			unsubscribeOnUserDisconnect();
			unsubscribeOnConnecting();
			unsubscribeOnMessageReceived();
			unsubscribeOnEventReceived();
			unsubscribeOnMessageDelete();
		};
	}, [chatRoom]);

	const sendMessage = async (message) => {
		console.log("message", message);
		const content = `${message.replace(/\\/g, "\\\\").replace(/"/g, '\\"')}`;
		const request = new SendMessageRequest(content);
		try {
			await chatRoom.sendMessage({
				...request,
				Attributes: {
					name: account?.payload?.Data?.name,
					user_id: userInfo._id,
				},
			});
		} catch (error) {
			console.log("error", error);
			// handleError(error);
		}
	};

	if (!connected) {
		return (
			<div className="load-container-main">
				<div className="load-container">
					<Spin size="medium"></Spin>
				</div>
			</div>
		);
	}

	return (
		<div className="live-chat-comp">
			<div id="frame">
				<div className="content">
					{/* <div className="messages"> */}
					<ScrollToBottom className="messages" ref={chatContainerRef}>
						<ul className="message-ul">
							{!chat?.length > 0 ? (
								<div className="align-items-center justify-content-center brand-description">
									<Empty description={false} />
								</div>
							) : (
								chat?.map((it, key) => {
									return (
										<li key={key} className={`${it.accountType} chat-message ${!publishEvent || new Date(it?.event_timestamp).getTime() <= currentTimestamp + 2000 ? "d-block" : "d-none"}`} data-timestamp={it?.event_timestamp}>
											{/* <img src={it.avatar} alt="" /> */}
											<div className="message-box-main">
												{/* <p className="msg-content"></p> */}
												<div className="msg-content">
													<span className="user-name">{it?.payload?.Attributes?.name === "admin" ? "DL1961" : it?.payload?.Attributes?.name}</span>
													<span className="user-message">{it?.payload?.Content}</span>
												</div>
												{/* <div className="msg-date-main">
												<small className="msg-date">{it.datetime}</small>
											</div> */}
											</div>
										</li>
									);
								})
							)}
						</ul>
					</ScrollToBottom>
					{/* </div> */}

					{isBan && (
						<div className={`message-input ${publishEvent ? "d-none" : ""}`}>
							<div className="logged-in-name">
								Logged in as <span>{account?.payload?.Data?.first_name.charAt(0) + "." + account?.payload?.Data?.last_name}</span>
							</div>

							<div className={`wrap `}>
								<form onSubmit={handleSubmit}>
									<input value={value} type="text" placeholder="Write your messagess..." onChange={handleChange} />
									{/* <i className="fa fa-paperclip attachment" aria-hidden="true"></i> */}
									<button onSubmit={handleSubmit} className="submit">
										<i className="fa fa-paper-plane" aria-hidden="true"></i>
									</button>
								</form>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
export default connect(null, chatActions)(Chat);
