import { GET_LIKES_REQUEST, GET_LIKES_SUCCESS, GET_LIKES_ERROR } from "../../types/types";
export default function likeEvents(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LIKES_REQUEST:
      return {
        loading: true,
      };
    case GET_LIKES_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case GET_LIKES_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    default:
      return state;
  }
}
