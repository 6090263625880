import Layout from "./component/layout";
import Saved from "../saved/shows"

export default () => {
	return (
		<>
			<Layout title="Saved Reviews">
				<Saved name={'saved'} />
			</Layout>
		</>
	);
};
