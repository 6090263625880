import { useState } from "react";
import Layout from "../bioShop/component/layoutinfluencer";
import InfluencerReview from "../bioShop/component/influencerProfile";

export default ({ name }) => {

	const [filterPost, setFilterPost] = useState({
		categoryId: [],
		subCategoryId: "",
		sortBy: "",
		eventType: "",
		applyFilter: "all",
	});

	return (
		<>
			<Layout source="bioshop-review" filterPost={filterPost} setFilterPost={setFilterPost}>
				<InfluencerReview setFilterPost={setFilterPost} filterPost={filterPost} name={name} />
			</Layout>
		</>
	);
};
