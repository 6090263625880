import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Spin } from 'antd';
import { verifyAccount } from '../../redux/actions/account.action';
import { useDispatch, useSelector } from 'react-redux';
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
export default function EmailVerify() {
    const dispatch = useDispatch();
    const { accountVerifyReducer } = useSelector(state => state);
    const queryParams = queryString.parse(window.location.search);
    const code = queryParams.code;

    useEffect(() => {
        dispatch(verifyAccount(code))
            .then(res => {
                // Handle response
            })
            .catch(err => {
                // Handle error
            });
    }, [dispatch]);

    return (
        <Container className="d-flex justify-content-center align-items-center vh-100">
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Email Verification</Card.Title>
                            <Card.Text>
                                Please check your email to verify your account. If you didn't receive the email, you can resend it.
                            </Card.Text>
                            <div className="d-flex justify-content-center">
                                {accountVerifyReducer.loading ? (
                                    <Spin size="medium" />
                                ) : (
                                    <>
                                        {accountVerifyReducer?.payload?.success ?
                                            <>
                                                <p className='text-success fw-bold'>Success <faThumbsUp /></p>
                                            </>
                                            :
                                            <p className='text-danger fw-bold'>Failed</p>
                                        }
                                    </>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
