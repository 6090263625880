// External Libraries
import React, { useEffect, useState, useRef } from "react";
import { Tabs, Layout, Collapse } from "antd";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import numeral from "numeral";
import Swal from "sweetalert2";
import { useSelector, useDispatch, connect } from "react-redux";
import queryString from "query-string";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { salesTracker } from "../../redux/actions/salesTracker.action";
// Internal Libraries
import { isNullOrUndefined } from "url/util";
import { likesGet } from "../../redux/actions/influencer.action";
// Components
// import Video from "./video";
import Slider from "./components/slider";
import ShippingAddressModal from "../account/component/shippingAddress";
import Chat from "./components/chat";
import CartItem from "../../components/cart/cartItem";
import SubTotal from "../../components/cart/subTotal";
import ContinueShopping from "../../components/cart/continueShopping";
import Shipping from "../../components/cart/shipping";
import Detail from "../reviews/components/detail";
import Cart from "../liveEvent/component/cart";
import Shop from "../liveEvent/component/shop";
import Payment from "../../components/cart/payment";
import Notify from "../../components/cart/notify";
//import PromoCodeForm from "../../components/cart/promoCodeForm";

// Actions
import { publishInfluencer } from "../../redux/actions/influencer.action";
import { influencerProfilemain, sharedReviews } from "../../redux/actions/becomeInfluencer.action";
import { privateReviews } from "../../redux/actions/save.action";

import { getFeeds } from "../../redux/actions/feeds.action";
import { publishSingle } from "../../redux/actions/influencer.action";
import { getAllShippingAddress } from "../../redux/actions/shipping.action";
import { getShopifyCart } from "../../redux/actions/liveEvent.action";
import { setShippingAddress } from "../../redux/actions/shopify.setShippingAddress.action";
import { getProduct } from "../../redux/actions/shopify.getProduct.action";
import { addToCart } from "../../redux/actions/shopify.addToCart.action";
import { getEventViews, viewReviews } from "../../redux/actions/EventView.action";
import { setShippingRates } from "../../redux/actions/shopify.setShippingRates.action";
import { getReviewSaved } from "../../redux/actions/save.action";
import * as chatActions from "../../redux/actions/chat";
// import { openLogin } from "../../redux/actions/login.action";
import { openRegister } from "../../redux/actions/register.action";
// CSS
import "../../css/live-events.scss";
import "../../css/checkout.scss";
import "../../css/influencer-reviews.scss";

// Images
import closeicon from "../../images/brand-logos/close-icon.svg";
import basket from "../../images/brand-logos/basket.png";

const { Panel } = Collapse;
const { TabPane } = Tabs;
let soruce1;
let titles1;
let tag1;
let price1;
let brand_id1;
let addBag = [];
let lists_item = [];
let tag = "https://dl1961trial.myshopify.com/23973099/checkouts/3063eb9af1269e7d245bd55bee2630ab?key=e08d0841d1a89ad19c0b444d6055d007";
const { Sider, Content } = Layout;

const PublishEventnew = () => {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	let shareTracking = JSON.parse(localStorage?.getItem("shareTracking"));

	const [infliencerReviewData, setInfluencerReviewData] = useState([]);

	const dispatch = useDispatch();
	const { liveEventId, shopifyProduct, allShippingAddress, shopifyGetShippingRates } = useSelector((state) => {
		return state;
	});

	const params = useParams();
	const queryParams = queryString.parse(window.location.search);
	const event_id = params.id;
	const [like, setLike] = useState([]);
	const [mode, setMode] = useState("");
	const [isSaved, setIsSaved] = useState([]);
	const [nextPage, setNextPage] = useState(0);
	const [next, setNext] = useState("");

	const [previousPage, setPreviousPage] = useState(0);
	const [previous, setPrevious] = useState("");

	const [firstLoad, setFirstLoad] = useState(false);
	const [secondLoad, setSecondLoad] = useState(false);
	const [usedPages, setUsedPages] = useState([]);
	const [disablePrevious, setDisablePrevious] = useState(false);
	const [disableNext, setDisableNext] = useState(false);

	const [fetchDataAgain, setFetchDataAgain] = useState(false);
	const [lastRow, setLastRow] = useState([]);

	const limit = 12;

	const addPages = (num) => {
		setUsedPages((prevPages) => {
			// Check if the number already exists
			if (!prevPages.includes(num)) {
				// Return a new array with the new number
				return [...prevPages, num];
			}
			// If the number already exists, return the existing array
			return prevPages;
		});
	};

	function fetchInfluencerData(page = 1, limit) {
		let dispatchFunction;
		const defaultParams = [page, limit];
		if (params.influencer_id) {
			if (queryParams?.origin?.includes("review")) {
				dispatchFunction = influencerProfilemain;
			} else {
				dispatchFunction = sharedReviews;
			}

			defaultParams.push(null);
			defaultParams.push(null);
			defaultParams.push(null);
			defaultParams.push(params.influencer_id);
			defaultParams.push(queryParams?.sortBy);
		} else {
			if (queryParams?.origin?.includes("feeds")) {
				dispatchFunction = getFeeds;
			} else if (queryParams?.origin?.includes("saved")) {
				defaultParams.push(true);
				defaultParams.push(userInfo?._id);
				dispatchFunction = privateReviews;
			} else {
				dispatchFunction = publishInfluencer;
			}

			defaultParams.push(getQueryParamsData("department"));
			defaultParams.push(getQueryParamsData("category"));
			defaultParams.push(null);
			defaultParams.push(queryParams?.brand);
			defaultParams.push(queryParams?.sortBy);
			defaultParams.push(null);
			defaultParams.push(null);
			defaultParams.push(userInfo?._id);
		}

		return dispatch(dispatchFunction(...defaultParams)).then((res) => {
			return res;
		});
	}

	useEffect(() => {
		fetchInfluencerData(queryParams?.page, limit).then((res) => {
			const message = res?.payload?.message;
			const data = message?.data || [];
			const firstRow = data[0];
			//	const lastRow = data[data.length - 1];

			addPages(parseFloat(queryParams?.page));

			const handlePublish = (res1) => {
				setBrandID(res1?.payload?.message?.brand?._id);
				setEvent([{ ...res1?.payload?.message, recording_url: res1?.payload?.message?.media_url }]);
				setFirstLoad(true);
			};

			dispatch(publishSingle(event_id)).then((res1) => {
				handlePublish(res1);
				const newData = [...data, res1?.payload?.message];
				const uniqueData = newData.filter((item, index, array) => array.findIndex((otherItem) => otherItem?._id === item?._id) === index);
				const lastRow = uniqueData[uniqueData.length - 1];
				if (!message?.previous || !message?.next) {
					setLastRow(lastRow);
					if (queryParams?.page === "1" && firstRow?._id === event_id) setDisablePrevious(true);
					if (lastRow?._id === event_id && !message?.next) setDisableNext(true);
				}
				setInfluencerReviewData(uniqueData);
			});
			setNext(message?.next || "");
			setPrevious(message?.previous || "");

			if (message?.next) {
				setNextPage(message?.next?.page);
				if (data.slice(-2).some((item) => item?._id === event_id)) setFetchDataAgain("next");
			}

			if (message?.previous) {
				setPreviousPage(message?.previous?.page);
				if (data.slice(0, 2).some((item) => item._id === event_id)) setFetchDataAgain("previous");
			}
		});

		dispatch(likesGet(event_id)).then((res) => {
			if (res?.payload) setLike(res?.payload);
		});

		dispatch(getReviewSaved(event_id)).then((res) => {
			if (res?.payload) setIsSaved(res?.payload?.is_saved);
		});
	}, []);

	const getQueryParamsData = (param) => {
		if (queryParams[param] === "") {
			return null;
		}

		if (queryParams[param] === "null" || queryParams[param] === "undefined") {
			return null;
		}
		return queryParams[param]?.split(",");
	};

	useEffect(() => {
		if (infliencerReviewData?.length) {
			let page = parseFloat(queryParams?.page);
			let data = infliencerReviewData;

			const handleData = (key, newData) => {
				setSlideIndex(key);
				setTimeout(() => {
					slider.current.slickGoTo(key);
					const currentVideo = document.getElementById(`video-${key}`);
					if (currentVideo.paused) {
						currentVideo.play();
					}
				}, 100);
				setInfluencerReviewData(newData);
			};

			if (fetchDataAgain) {
				page += fetchDataAgain === "next" ? 1 : -1;
				if (page > 0) {
					fetchInfluencerData(page, limit).then((res) => {
						if (res?.payload?.message?.next) {
							if (!usedPages.includes(res?.payload?.message?.next?.page)) {
								setNext(res?.payload?.message?.next);
								setNextPage(res?.payload?.message?.next?.page);
							}
						}

						if (!res?.payload?.message?.previous || res?.payload?.message?.previous?.page === 1) {
							setPrevious("");
						}

						if (res?.payload?.message?.previous) {
							if (!res?.payload?.message?.next) {
								setLastRow(res?.payload?.message?.data[res?.payload?.message?.data.length - 1]);
							}

							if (!usedPages.includes(res?.payload?.message?.previous?.page)) {
								setPrevious(res?.payload?.message?.previous);
								setPreviousPage(res?.payload?.message?.previous?.page);
							}
						}

						addPages(page);
						const newData = fetchDataAgain === "next" ? [...data, ...res?.payload?.message?.data] : res?.payload?.message?.data.concat(data);
						const key = newData.findIndex((item) => item?._id === event_id);
						handleData(key, newData);
					});
				}
			} else if (fetchDataAgain === false) {
				const key = data.findIndex((item) => item?._id === event_id);
				handleData(key, data);
			}
		}
	}, [firstLoad]);

	function pauseAllVideos() {
		var videos = document.getElementsByTagName("video");
		for (var i = 0; i < videos.length; i++) {
			videos[i].pause();
		}
	}

	useEffect(() => {
		if (secondLoad) {
			setDisableButton(true);
			if (infliencerReviewData?.length) {
				if (lastRow?._id === secondLoad) {
					setDisableNext(true);
				}
				const key = infliencerReviewData.findIndex((item) => item?._id === secondLoad);
				pauseAllVideos();
				setSlideIndex(key);
				setTimeout(() => {
					slider.current.slickGoTo(key);
					const currentVideo = document.getElementById(`video-${key}`);
					currentVideo?.pause();
					if (currentVideo?.paused) {
						currentVideo.play();
					}
					setDisableButton(false);
				}, 100);
			}
		}
	}, [secondLoad]);

	const [height, setHeight] = useState(0);
	const ref = useRef();
	useEffect(() => {
		const resizeObserver = new ResizeObserver((event) => {
			setHeight(event[0].contentBoxSize[0].blockSize);
		});

		if (ref) {
			resizeObserver.observe(ref.current);
		}
	}, [ref]);

	const [eventId, setEvent] = useState([]);
	const [shippingAddressModal, setShippingAddressModal] = useState(false);
	const [activeKey, setActiveKey] = useState("3");
	const [collapseKey, setCollapseKey] = useState("2");
	const [cart, setCart] = useState();
	const [brandId, setBrandID] = useState("");
	const [checkout, setCheckout] = useState(false);
	const [checkStatus, setStatus] = useState(true);
	const [clear, setClear] = useState(false);
	const [product, setProduct] = useState();
	const [stp1, setStp1] = useState(true);
	const [stp2, setStp2] = useState(false);
	const [stp3, setStp3] = useState(false);
	const [lineItem, setLineItem] = useState([]);
	const [quantity, setQty] = useState(1);
	const [collapsed, setCollapsed] = useState(false);
	const [value, setValue] = useState();
	const [chatStatus, setChatStatus] = useState(false);
	const [successorder, setSuccessOrder] = useState(false);
	const [isShipTo, setIsShipTo] = useState("");
	const [shippingAddressId, setShippingAddressId] = useState("");
	const [updateShop, setUpdateShop] = useState(false);
	//SetCookies
	const [eventAttributes, setEventAttributes] = useState({ event: "reviewshop" });
	useEffect(() => {
		setEventAttributes({ brand: queryParams.brand, source: "reviewshop" });
		if (shareTracking) {
			let source = "reviewshop";

			if (queryParams?.origin?.includes("share")) {
				source = "reviewshare";
			}

			setEventAttributes({
				tracker: shareTracking?.tracker,
				brand: queryParams.brand,
				source: source ? source : "reviewshop",
				event_review_id: shareTracking?.event_review_id,
			});
		}

		if (queryParams?.publisher_id) {
			if (userInfo?.token) {
				if (!isMobile()) {
					const shareTracking = JSON.parse(localStorage.getItem("shareTracking"));
					dispatch(salesTracker({ publisher_id: queryParams?.publisher_id ? queryParams?.publisher_id : shareTracking.publisher_id }, userInfo?.token)).then((res) => {
						localStorage.setItem("shareTracking", JSON.stringify({ tracker: res?.payload?.data?.tracker, brand: queryParams.brand, source: queryParams.event, event_review_id: event_id, event: queryParams?.event, referral: window.location.href }));
						setEventAttributes({ tracker: res?.payload?.data?.tracker, brand: queryParams.brand, source: "reviewshare", event_review_id: event_id });
					});
				}
			} else {
				localStorage.setItem("shareTracking", JSON.stringify({ publisher_id: queryParams?.publisher_id, event: queryParams.event, event_review_id: event_id, referral: window.location.href }));
			}
		}
	}, []);

	useEffect(() => {
		document.body.classList.add("live-event-header-hide");
		return () => {
			document.body.classList.remove("live-event-header-hide");
		};
	}, []);

	useEffect(() => {
		if (queryParams?.pageback === "paymentBack") {
			onKeyChange("4");
			setCollapseKey("2");
		}
	}, []);

	useEffect(() => {
		dispatch(getEventViews(event_id, userInfo?.token)).then((res) => {
			dispatch(viewReviews(event_id));
		});
	}, []);

	useEffect(() => {
		if (window.innerWidth <= 1290) {
			setCollapsed(true);
		}
	}, []);

	useEffect(() => {
		if (event_id) {
			if (userInfo?.token) {
				dispatch(getAllShippingAddress(userInfo?.token));
			}
		}
	}, [event_id]);

	useEffect(() => {
		if (userInfo) {
			dispatch(getShopifyCart(userInfo?.brand ? userInfo?.brand : brandId, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
				}
				setCart(res?.payload?.message);
			});
		}
	}, []);

	useEffect(() => {
		if (userInfo && updateShop) {
			let addressId = allShippingAddress?.payload?.message.filter((item) => item?.is_default === true);
			setIsShipTo(addressId[0]._id);
			dispatch(setShippingAddress(userInfo?.brand ? userInfo.brand : brandId, userInfo?.token, addressId[0]._id)).then((res) => {
				if (res?.payload?.data?.success === false) {
					Swal.fire({
						icon: "error",
						title: res?.payload?.data?.message,
					});
				} else {
					dispatch(getShopifyCart(userInfo?.brand ? userInfo.brand : brandId, userInfo?.token)).then((res) => {
						if (res?.payload?.message?.lineItems?.length > 0) {
							setStatus(false);
						} else {
							setStatus(true);
						}
						setCart(res?.payload?.message);
					});
				}

				onChangeShippingMethod("", value ? value : shopifyGetShippingRates?.payload?.message?.data?.node?.availableShippingRates?.shippingRates[0]?.handle);
			});
		}
	}, [updateShop]);

	function isMobile() {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}

	const handleSuccessClick = () => {
		onKeyChange("3");
		if (stp2) {
			setStp1(true);
			setStp2(false);
			setStp3(false);
			setClear(false);
		} else if (stp3) {
			setStp1(false);
			setStp2(true);
			setStp3(false);
			setClear(false);
		}
	};

	const onKeyChange = (key, brand) => {
		setActiveKey(key);
		setCheckout(false);

		if (key == "1" && eventId && userInfo?._id) {
			setChatStatus(true);
		}

		if (key === "4") {
			setCollapseKey("2");
			if (userInfo) {
				setSuccessOrder(false);
				dispatch(getShopifyCart(userInfo.brand ? userInfo.brand : brand, userInfo?.token)).then((res) => {
					if (res?.payload?.message?.lineItems.length > 0) {
						setStatus(false);
					} else {
						setStatus(true);
					}
					setCart(res?.payload?.message);
				});
			}
		}
	};

	const onCollapseChange = (key) => {
		setCollapseKey(key);
	};

	const buy = (brand_id, Product_id, price) => {
		setStp1(false);
		setStp2(true);
		price1 = price;
		brand_id1 = Product_id;
		dispatch(getProduct(brand_id, Product_id, userInfo?.token)).then((res) => {
			setProduct(res?.payload?.message);
		});
	};

	const addToBag = async (src, title, tag, clear, list, status, brand) => {
		setStp1(false);
		setStp2(true);
		setClear(true);
		soruce1 = src;
		titles1 = title;
		tag1 = tag;

		if (list?.length > 1) {
			list?.selectedOptions.map((item) => {
				lists_item.push({
					name: item.name,
					value: item.value,
				});
			});
		}

		let prodId = list.hasOwnProperty("variants") ? list?.variants[0]?.id : list?.id;

		dispatch(addToCart(eventId[0]?.brand?._id, status ? status : checkStatus, quantity, prodId, lists_item, userInfo?.token, title, list, { review_id: infliencerReviewData?.[0]?._id, influencer_id: infliencerReviewData?.[0]?.influencer_id })).then((res) => {
			if (res?.payload?.data?.success === false) {
				Swal.fire("Something Wrong!", "Your input is wrong", "error");
				return;
			}
			userInfo.brand = eventId[0]?.brand?._id;
			setBrandID(eventId[0]?.brand?._id);
			userInfo.event = eventId[0];
			userInfo.eventAttributes = eventAttributes;
			localStorage.setItem("userInfo", JSON.stringify(userInfo));

			Notify(list?.data?.price, null, list, onKeyChange, title);

			if (stp2) {
				setStp1(true);
				setStp2(false);
				setStp3(false);
				setClear(false);
			} else if (stp3) {
				setStp1(false);
				setStp2(true);
				setStp3(false);
				setClear(false);
			}

			dispatch(getShopifyCart(eventId[0]?.brand?._id, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
				}
				setCart(res?.payload?.message);
			});

			lists_item = [];
			soruce1 = "";
			titles1 = "";
			tag1 = "";
			brand_id1 = "";
			setQty(1);
		});
	};

	if (!isNullOrUndefined(soruce1) && clear) {
		addBag.push({
			source: soruce1,
			title: titles1,
			price: price1,
		});
	}

	const TabsShopping = () => {
		return (
			<>
				<Tabs className="tabs-stage-one" defaultActiveKey="1" activeKey={activeKey} onChange={onKeyChange}>
					<TabPane tab="Comments" key="1">
						<div className="login-before-chat">
							{chatStatus ? (
								<Chat page="review" activeKey={activeKey} publishEvent={false} reviewId={eventId[0]?._id} />
							) : (
								<>
									<div className="before-login-inner">
										<h1>Login to start comments</h1>
										<Button onClick={(e) => dispatch(openRegister(true))}>Login</Button>
									</div>
								</>
							)}
						</div>
					</TabPane>
					<TabPane tab="Details" key="2" activeKey={"3"}>
						<Detail event={eventId} />
					</TabPane>
					<TabPane tab="Shop" key="3">
						<Shop cart={cart} setBrandID={setBrandID} quantity={quantity} price={price1} setLineItem={setLineItem} addBag={addBag} setQty={setQty} clear={clear} brandId={userInfo?.brand ? userInfo.brand : brandId} shopifyProduct={shopifyProduct} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} setClear={setClear} liveEventId={liveEventId} userInfo={userInfo} eventId={eventId} reviewId={eventId} lineItem={lineItem} product={product} buy={buy} addToBag={addToBag} page={"review"} />
					</TabPane>

					<TabPane
						tab={
							<div className="basket-icon">
								<span className="cart-count">{cart === undefined ? 0 : cart?.lineItems?.length}</span>
								<img src={basket} />
							</div>
						}
						key="4"
					>
						{successorder ? <ContinueShopping handleSuccessClick={handleSuccessClick} /> : checkout ? <CheckoutStep /> : <Cart setValue={setValue} page={queryParams?.pageback} onChangeShippingMethod={onChangeShippingMethod} promoCodeForm={<></>} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} brandId={userInfo?.brand ? userInfo.brand : brandId} setStatus={setStatus} setCart={setCart} setCheckout={setCheckout} cart={cart} />}
						{/* {successorder ? <ContinueShopping handleSuccessClick={handleSuccessClick} /> : checkout ? <CheckoutStep /> : <Cart page={queryParams?.pageback} onChangeShippingMethod={onChangeShippingMethod} promoCodeForm={true ? [] : <PromoCodeForm brandId={userInfo?.brand ? userInfo.brand : brandId} setCart={setCart} setStatus={setStatus} cart={cart} activeKey={activeKey} eventId={eventId} />} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} brandId={userInfo?.brand ? userInfo.brand : brandId} setStatus={setStatus} setCart={setCart} setCheckout={setCheckout} cart={cart} />} */}
					</TabPane>
				</Tabs>
			</>
		);
	};

	const CheckoutStep = () => {
		return (
			<>
				<div className="checkout-frame">
					<div className="order-summery">
						<Collapse className="position-relative" accordion defaultActiveKey={["1"]} activeKey={collapseKey} onChange={onCollapseChange}>
							<span className="total-price-top">{numeral(cart?.paymentDue?.amount).format("$0,0.0'")}</span>
							<Panel header="Order Summary" key="1">
								<div className="ordered-products">
									<CartItem brandId={userInfo?.brand ? userInfo.brand : brandId} setStatus={setStatus} setCart={setCart} cart={cart} setCheckout={setCheckout} />
								</div>
								{/* <div className="checkout-discount-section">{<PromoCodeForm brandId={userInfo?.brand ? userInfo.brand : brandId} setCart={setCart} setStatus={setStatus} cart={cart} activeKey={activeKey} eventId={eventId} />}</div> */}
								<div className="checkout-discount-section"></div>
								<SubTotal brandId={userInfo?.brand ? userInfo.brand : brandId} setStatus={setStatus} cart={cart} setCart={setCart} />
							</Panel>

							<Panel header="Shipping" key="2" className="accordion-style2">
								<Shipping setMode={setMode} isShipTo={isShipTo} setIsShipTo={setIsShipTo} value={value} onChangeShippingMethod={onChangeShippingMethod} setCart={setCart} setStatus={setStatus} brandId={userInfo?.brand ? userInfo.brand : brandId} setShippingAddressModal={setShippingAddressModal} setShippingAddressId={setShippingAddressId} cart={cart} setCollapseKey={setCollapseKey} />
							</Panel>

							<Panel header="Payment" key="3" className="accordion-style2 bg-white">
								<Payment page={"paymentBack"} setEventAttributes={setEventAttributes} eventAttributes={eventAttributes} eventId={eventId} brandId={userInfo?.brand ? userInfo.brand : brandId} setSuccessOrder={setSuccessOrder} setStatus={setStatus} setCart={setCart} />
							</Panel>
						</Collapse>

						<div className="customer-information"></div>
					</div>
				</div>
			</>
		);
	};

	const onChangeShippingMethod = (e, handled) => {
		var handle = handled ? handled : e.target.value;

		setValue(handle);

		dispatch(setShippingRates(userInfo?.brand ? userInfo.brand : brandId, handle, userInfo?.token)).then((res) => {
			dispatch(getShopifyCart(userInfo?.brand ? userInfo.brand : brandId, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
				}
				setCart(res?.payload?.message);
			});
		});
	};

	const [disableButton, setDisableButton] = useState(false);
	const slider = useRef();
	const [slideIndex, setSlideIndex] = useState(0);

	function LeftNavButton({ style }) {
		return (
			<button disabled={disableButton} onClick={goDown} style={{ ...style, display: "block" }} type="button" data-role="none" className={`slick-arrow slick-prev ${disablePrevious && "slick-disabled"}`}>
				Previous
			</button>
		);
	}

	const goUp = () => {
		const nextSlideIndex = slideIndex + 1;
		const nextVideoId = infliencerReviewData[nextSlideIndex]?._id;
		const currentSlideId = infliencerReviewData[slideIndex]?._id;

		//	alert(nextVideoId)

		setDisablePrevious(false);

		const handleStepsTransition = () => {
			if (stp2) {
				setStp1(true);
				setStp2(false);
			} else if (stp3) {
				setStp1(false);
				setStp2(true);
			}
			setStp3(false);
			setClear(false);
		};

		const handleVideoPlayPause = () => {
			const nextVideo = document.getElementById(`video-${nextSlideIndex}`);
			pauseAllVideos();
			if (nextVideo?.paused) {
				nextVideo.play();
			}
		};

		const fetchDataAndUpdate = (nextVideoId) => {
			dispatch(getEventViews(nextVideoId, userInfo?.token)).then((res) => {
				dispatch(viewReviews(nextVideoId));
			});

			dispatch(publishSingle(nextVideoId)).then((res) => {
				setEvent([{ ...res?.payload?.message, recording_url: res?.payload?.message?.media_url }]);
			});

			dispatch(likesGet(nextVideoId)).then((res) => {
				setLike(res?.payload);
			});

			dispatch(getReviewSaved(nextVideoId)).then((res) => {
				if (res?.payload) setIsSaved(res?.payload?.is_saved);
			});
		};

		setDisableButton(true);
		setLineItem([]);

		handleStepsTransition();

		let lastRowCurrent = { ...infliencerReviewData[infliencerReviewData?.length - 1] };
		if (lastRowCurrent?._id === currentSlideId) {
			if (next) {
				addPages(nextPage);
				if (!usedPages.includes(nextPage)) {
					setDisableButton(true);
					fetchInfluencerData(nextPage, limit).then((res) => {
						const newData = [...infliencerReviewData, ...res?.payload?.message?.data];
						if (res?.payload?.message?.previous?.page) {
							if (!res?.payload?.message?.next) {
								setLastRow(res?.payload?.message?.data[res?.payload?.message?.data.length - 1]);
							}
						}
						setSecondLoad(res?.payload?.message?.data[0]?._id);
						let uniqueData = newData.filter((item, index, array) => array.findIndex((otherItem) => otherItem?._id === item?._id) === index);
						setInfluencerReviewData(uniqueData);
						setPrevious(res?.payload?.message?.previous);
						setPreviousPage(res?.payload?.message?.previous?.page);
						setNext(res?.payload?.message?.next);
						setNextPage(res?.payload?.message?.next?.page);
						if (!res?.payload?.message?.next) setNext("");
					});
				}
			}
		} else {
			let key = infliencerReviewData?.findIndex((item) => item?._id === nextVideoId);
			setSlideIndex(key);
			slider.current.slickGoTo(key);
			//slider.current.slickNext();
			setTimeout(() => {
				handleVideoPlayPause();
				setDisableButton(false);
			}, 300);

			if ((previous && !next) || (previous === "" && next === "")) {
				if (lastRow?._id === nextVideoId) {
					setDisableNext(true);
					setSlideIndex(nextSlideIndex);
				}
			}
		}

		if (nextVideoId) {
			fetchDataAndUpdate(nextVideoId);
		}
	};

	const goDown = () => {
		const nextSlideIndex = slideIndex - 1;
		const nextVideoId = infliencerReviewData[nextSlideIndex]?._id;
		const currentSlideId = infliencerReviewData[slideIndex]?._id;
		//	alert(nextVideoId)
		setDisableNext(false);
		const handleStepsTransition = () => {
			if (stp2) {
				setStp1(true);
				setStp2(false);
			} else if (stp3) {
				setStp1(false);
				setStp2(true);
			}
			setStp3(false);
			setClear(false);
		};

		const handleVideoPlayPause = () => {
			const nextVideo = document.getElementById(`video-${nextSlideIndex}`);
			pauseAllVideos();
			if (nextVideo?.paused) {
				nextVideo.play();
			}
		};

		const fetchDataAndUpdate = (nextVideoId) => {
			dispatch(getEventViews(nextVideoId, userInfo?.token)).then((res) => {
				dispatch(viewReviews(nextVideoId));
			});

			dispatch(publishSingle(nextVideoId)).then((res) => {
				setEvent([{ ...res?.payload?.message, recording_url: res?.payload?.message?.media_url }]);
			});

			dispatch(likesGet(nextVideoId)).then((res) => {
				setLike(res?.payload);
			});

			dispatch(getReviewSaved(nextVideoId)).then((res) => {
				if (res?.payload) setIsSaved(res?.payload?.is_saved);
			});
		};

		setDisableButton(true);
		setLineItem([]);
		handleStepsTransition();
		if (previous === "" || previous === undefined) {
			if (slideIndex === 1) {
				setDisablePrevious(true);
			}
		}
		if (infliencerReviewData[0]?._id === currentSlideId) {
			if (previous) {
				addPages(previousPage);
				if (!usedPages.includes(previousPage)) {
					setDisableButton(true);
					fetchInfluencerData(previousPage, limit).then((res) => {
						const newData = [...res?.payload?.message?.data, ...infliencerReviewData];
						let lastRow = res?.payload?.message?.data[res?.payload?.message?.data.length - 1];

						let uniqueData = newData.filter((item, index, array) => array.findIndex((otherItem) => otherItem?._id === item?._id) === index);
						setInfluencerReviewData(uniqueData);

						if (lastRow?._id) {
							setSecondLoad(lastRow?._id);
						}

						if (!usedPages.includes(res?.payload?.message?.previous?.page)) {
							setPrevious(res?.payload?.message?.previous);
							setPreviousPage(res?.payload?.message?.previous?.page);
						}

						if (!usedPages.includes(res?.payload?.message?.next?.page)) {
							setNext(res?.payload?.message?.next);
							setNextPage(res?.payload?.message?.next?.page);
						}
						if (!res?.payload?.message?.previous) {
							setPrevious("");
						}
					});
				}
			}
		} else {
			const key = infliencerReviewData?.findIndex((item) => item?._id === nextVideoId);
			setSlideIndex(key);
			//				slider.current.slickNext();
			setTimeout(() => {
				handleVideoPlayPause();
				setDisableButton(false);
			}, 300);
			slider.current.slickGoTo(key);
			if ((previous && !next) || (previous === "" && next === "")) {
				if (lastRow?._id === nextVideoId) {
					//	setDisableNext(true);
					setSlideIndex(nextSlideIndex);
				}
			}
		}

		if (nextVideoId) {
			fetchDataAndUpdate(nextVideoId);
		}
	};

	function RightNavButton({ style }) {
		return (
			<button disabled={disableButton} onClick={goUp} style={{ ...style, display: "block" }} type="button" data-role="none" className={`slick-arrow slick-next ${disableNext ? "slick-disabled" : ""}`}>
				Next
			</button>
		);
	}

	const onSwipeEvent = (swipeDirection) => {
		if (swipeDirection === "up") {
			goUp();
		} else {
			goDown();
		}
	};

	const influencerreviewsslider = {
		className: "slider review-slider-height",
		dots: false,
		arrows: true,
		infinite: false,
		nextArrow: <RightNavButton />,
		prevArrow: <LeftNavButton />,
		centerMode: false,
		speed: 200, // try adjusting this value
		slidesToShow: 1,
		vertical: true,
		verticalSwiping: true,
		slidesToScroll: 1,
		initialSlide: infliencerReviewData?.findIndex((item) => item?._id == event_id),
	};

	return (
		<>
			<div ref={ref} className={`horizontal-camera-view ${eventId[0]?.orientation}`}>
				<Layout>
					<Layout className="site-layout">
						<Content
							className="site-layout-background"
							style={{
								margin: 0,
								padding: 0,
								minHeight: "calc(100vh - 40px)",
							}}
						>
							<Slider influencerreviewsslider={influencerreviewsslider} slider={slider} infliencerReviewData={infliencerReviewData} onSwipeEvent={onSwipeEvent} onKeyChange={onKeyChange} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} setCollapsed={setCollapsed} collapsed={collapsed} eventId={eventId} like={like} setLike={setLike} isSaved={isSaved} setIsSaved={setIsSaved} />
						</Content>
					</Layout>
					<Sider
						trigger={null}
						collapsible
						collapsed={collapsed}
						breakpoint="lg"
						collapsedWidth="0"
						onBreakpoint={(broken) => {
							console.log(broken);
						}}
						onCollapse={(collapsed, type) => {
							console.log(collapsed, type);
						}}
					>
						<div className="slide-pannel-new">
							{!collapsed && (
								<span className="close-slide-panel" onClick={() => setCollapsed(!collapsed)}>
									<i className="fa fa-angle-right" aria-hidden="true"></i>
								</span>
							)}
							{window.innerWidth > 768 && TabsShopping()}
						</div>
					</Sider>
				</Layout>
			</div>

			{height > 0 && (
				<div className="mobile-checkout-panel" style={{ paddingTop: height, height: `calc(100vh - 0px)` }}>
					{window.innerWidth <= 768 && TabsShopping()}
				</div>
			)}
			<Modal
				dialogClassName="modal-shipping-from-front"
				size="md"
				scrollable
				show={shippingAddressModal}
				onHide={() => {
					setMode("");
					setShippingAddressModal(false);
				}}
				centered
			>
				<span
					className="close-shipping-front"
					onClick={() => {
						setMode("");
						setShippingAddressModal(false);
					}}
				>
					<img src={closeicon} className="filter-gray" />
				</span>
				<Modal.Body>
					<ShippingAddressModal isShipTo={isShipTo} updateShop={updateShop} setUpdateShop={setUpdateShop} page={"review"} mode={mode} setCart={setCart} shippingAddressId={shippingAddressId} brandId={brandId} edit={true} setStatus={setStatus} />
				</Modal.Body>
			</Modal>
		</>
	);
};
export default connect(null, chatActions)(PublishEventnew);
