import { useState, useEffect } from "react";
import { Spin } from "antd";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { LoadingOutlined } from "@ant-design/icons";
import { getShopifyCart } from "../../../redux/actions/liveEvent.action";
import { setShippingAddress } from "../../../redux/actions/shopify.setShippingAddress.action";
import { getShippingRates } from "../../../redux/actions/shopify.getShippingRates.action";

import { stripePaylist } from "../../../redux/actions/payment.action";
import CartItem from "../../../components/cart/cartItem";
import SubTotal from "../../../components/cart/subTotal";
import closeicon from "../../../images/brand-logos/close-icon.svg";

import ShippingAddressModal from "../../account/component/shippingAddress";
// import {getCheckout} from "../../../redux/actions/shopify.getCheckout.action";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default function Cart({   onChangeShippingMethod, brandId, setStatus, cart, setCheckout, setCart, page }) {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const dispatch = useDispatch();
	const [shippingAddressModal, setShippingAddressModal] = useState(false);

	const { paymentList, checkoutProduct, shopifySetShippingAddress } = useSelector((state) => state);

	const recursiveShippingApiCall = async () => {
		const res = await dispatch(getShippingRates(brandId, userInfo?.token));
		const shippingRates = res?.payload?.message;

		if (!shippingRates) return recursiveShippingApiCall();

		if (!cart?.shippingLine?.title) {
			return onChangeShippingMethod("", cart?.shippingLine?._id ? cart?.shippingLine?._id:shippingRates[0]?._id);
		}
	};

	useEffect(() => {
		if (paymentList?.length === 0) {
			if (page) {
				checkoutButton();
			}
		}
	}, []);
	const checkoutButton = async () => {
		if (userInfo?.token) {
			dispatch(stripePaylist(userInfo?.token));
		}

		const res = await dispatch(setShippingAddress(brandId, userInfo.token));
		

		if (!res?.payload?.success) {
			return Swal.fire({
				icon: "error",
				title: res?.payload?.data?.message,
			}).then((result) => {
				if (result.isConfirmed) {
					setShippingAddressModal(true);
				}
			});
		}

		if (!cart?.shippingAddress) {
			const cartRes = await dispatch(getShopifyCart(brandId, userInfo?.token));
			const hasItems = cartRes?.payload?.message?.lineItems.length > 0;

			setStatus(!hasItems);
			setCart(cartRes?.payload?.message);
		}

		setCheckout(true);
		recursiveShippingApiCall();
	};

	return (
		<>
		{console.log('cart')}
			<div className="pro-list-main pro-list-main-cart">
				<h2>Cart </h2>
				<div className="pro-list-inner list-gap gap-new">
					{checkoutProduct?.loading ? (
						<div className="load-container">
							<Spin size="medium"></Spin>
						</div>
					) : cart?.lineItems?.length === 0 || cart === undefined ? (
						<p className="cart-empty">Cart is Empty</p>
					) : (
						<CartItem brandId={brandId} setStatus={setStatus} setCart={setCart} cart={cart} setCheckout={setCheckout} />
					)}
				</div>

				<div className="checkout-buttons">
					{!cart?.lineItems?.length || checkoutProduct?.loading || !cart ? null : (
						<>
							{!cart?.discountApplications?.[0]?.applicable && <div className="checkout-discount-section cart-checkout-discount-section"> {/* {promoCodeForm} */}</div>}
							<SubTotal brandId={brandId} setStatus={setStatus} cart={cart} setCart={setCart} />
							{shopifySetShippingAddress.loading ? (
								<Button>
									<Spin indicator={antIcon} />
								</Button>
							) : (
								<Button onClick={checkoutButton}>CHECKOUT</Button>
							)}
						</>
					)}
				</div>
			</div>

			<Modal
				dialogClassName="modal-shipping-from-front"
				size="md"
				scrollable
				show={shippingAddressModal}
				onHide={() => {
					setShippingAddressModal(false);
				}}
				centered
			>
				<span
					className="close-shipping-front"
					onClick={() => {
						setShippingAddressModal(false);
					}}
				>
					<img src={closeicon} className="filter-gray" />
				</span>
				<Modal.Body>
					<ShippingAddressModal />
				</Modal.Body>
			</Modal>
		</>
	);
}
