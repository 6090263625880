import Deleteaccount from "./component/deleteAccount";
import Layout from "./component/layout";
export default () => {
	return (
		<>
			<Layout title="Delete Account">
				<Deleteaccount />
			</Layout>
		</>
	);
};
