import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteAccount } from '../../../redux/actions/account.action';
import { getAccount } from "../../../redux/actions/account.action";
import { openPasswordModal } from "../../../redux/actions/changePassword.action";
import "../../../css/account-setup.scss";
import ChangePassword from "../../../pages/auth/changePassword";
import PasswordSetting from "../../../components/passwordSetting/passwordSetting";
import placeholderImage from "../../../images/placeholder-image.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import numeral from "numeral";
import {
    faUser,
    faUserTimes,
    faThumbTack,
    faMoneyCheck,
    faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { getBioShopMenu } from "../../../redux/actions/bioShop.action";
import { getFollowers, followingUser } from "../../../redux/actions/followers.action";
import { likeCount } from "../../../redux/actions/influencer.action";

export default ({ setShowShare }) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const dispatch = useDispatch();
    const params = useParams();
    const { account } = useSelector((state) => state);
    const navigate = useNavigate();
    const [followStatus, setFollowStatus] = useState(false);
    const [followers, setFollowers] = useState(0);
    const [followerData, setFollowerData] = useState([]);
    const [following, setFollowing] = useState(0);
    const [followingData, setFollowingData] = useState([])
    const { followersData, countLiked, bioShopMenu } = useSelector((state) => {
        return state;
    });

    useEffect(() => {
        if (userInfo?.token && account?.length === 0) dispatch(getAccount(userInfo?.token));
    }, []);

    useEffect(() => {
        dispatch(openPasswordModal(false));
    }, []);

    useEffect(() => {
        document.body.classList.add("bioshop-body", "shopper-bioshop-body");
    }, []);

    // This is componentWillUnmount
    useEffect(() => {
        return () => {
            //  componentWillUnmount.current = true;
            document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
        };
    }, []);

    const fullURL = window.location.href;
    const baseUrl = fullURL.split('/').slice(0, 3).join('/');
    const url = `${baseUrl}/${userInfo?.pixel_id}`;

    const copyToClipboard = (url) => {
        let textField = document.createElement("textarea");
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        notification.success({ message: "Copied to Clipboard!" });
        setShowShare(false);
    };

    useEffect(() => {
        const id = userInfo?._id;
        if (id) {
            dispatch(getFollowers(id, userInfo?._id)).then((res) => {
                setFollowers(res?.payload?.followers);
                setFollowerData(res?.payload?.followerData);
            });

            dispatch(followingUser(id, userInfo?._id)).then((res) => {
                setFollowing(res?.payload?.following);
                setFollowingData(res?.payload?.data);
            });
        }
    }, []);

    useEffect(() => {
        dispatch(getBioShopMenu(userInfo?.pixel_id, userInfo?.token)).then((res) => {
            dispatch(likeCount(res?.payload?.message?.data?._id, res.payload.message.data.account_type))
        });
    }, []);

    const capitalizeWords = (str) => {
        if (!str) return '';
        return str
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    const str = userInfo?.name;
    const capitalizedUserName = capitalizeWords(str);

    const handleReset = () => {
        // Generate HTML content for the SweetAlert modal.
        const profileImageHtml = account.payload.Data?.profile_image_url
            ? `<img src="${account.payload.Data.profile_image_url}" style="max-width: 100px; display: block; margin: 0 auto;">`
            : `<i className="fa fa-user-circle-o" aria-hidden="true" style="font-size: 4em; display: block; margin: 10 auto;"></i>`;

        const userNameHtml = str
            ? `<p style="text-align: center; margin-top: 20px;">${capitalizedUserName}</p>`
            : '';

        const mainTextHtml = `Are You Sure You Want to Delete Your Account? You Won't be Able To Revert This!`;
        const combinedHtml = `${profileImageHtml}${userNameHtml}<p>${mainTextHtml}</p>`;
        Swal.fire({
            title: '',
            html: combinedHtml,
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#010b40',
            confirmButtonText: 'Delete',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete();
            }
        });
    };

    // Dispatch the deleteAccount action and handle the response.
    const handleDelete = () => {
        dispatch(deleteAccount(userInfo?.token, userInfo?._id)).then(() => {
            localStorage.removeItem('userInfo');
            window.location.reload(false);
            navigate(`/`);
        });
    };

    return (
        <>
            <div className="account-left-content">
                <div className="account-detail account-settings col-12 col-xl-5 col-lg-8 col-md-12">
                    <div className="user-profile">
                        <div className="user-profile-image">
                            <div className="prof-img">
                                <span className="profile-pic">
                                    <img alt="..." src={account?.payload?.Data?.profile_image_url ? account?.payload?.Data?.profile_image_url : placeholderImage} style={{ width: "76px", height: "76px" }} />
                                </span>
                            </div>

                            <div class="brand-followers ">
                                <div className="count-detail">
                                    <strong>{numeral(followers && followers).format("0a")}</strong>
                                    <span>Followers</span>
                                </div>
                                <div className="count-detail">
                                    <strong>{numeral(following && following).format("0a")}</strong>
                                    <span> Following</span>
                                </div>
                                <div className="count-detail">
                                    <strong>{numeral(countLiked?.payload?.message).format("0a")} </strong>
                                    <span> Likes</span>
                                </div>

                            </div>


                        </div>
                        <div className="user-info-main">
                            <div className="user-left">
                                <div className="user-id">{account?.payload?.Data?.pixel_id}</div>
                                <div className="user-name">{account?.payload?.Data?.name}</div>
                                <div className="user-link">{url}</div>
                            </div>
                            <div className="user-right">
                                <Button
                                    className="copy-btn"
                                    onClick={() => {
                                        copyToClipboard(url)
                                    }}
                                >
                                    Copy
                                </Button>
                            </div>
                        </div>


                        <div className="user-menu-main">
                            <div className="user-menu-item">
                                <Link
                                    to="/accountDetail"
                                >
                                    <FontAwesomeIcon icon={faUser} />
                                    <span className="user-nav-text">Edit Profile</span>
                                </Link>
                            </div>

                            <div className="user-menu-item">
                                <Link
                                    to="/shippingaddress"
                                >
                                    <FontAwesomeIcon icon={faThumbTack} />
                                    <span className="user-nav-text">Shipping Information</span>
                                </Link>
                            </div>

                            <div className="user-menu-item">
                                <Link
                                    to="/payment"
                                >
                                    <FontAwesomeIcon icon={faMoneyCheck} />
                                    <span className="user-nav-text">Payment Method</span>
                                </Link>
                            </div>

                            <div className="user-menu-item">
                                <Link
                                    to="/payout"
                                >
                                    <FontAwesomeIcon icon={faWallet} />
                                    <span className="user-nav-text">Payout</span>
                                </Link>
                            </div>

                        </div>

                        <div className="">
                            <Button
                                className="red-button"
                                onClick={() => {
                                    handleReset()
                                }}
                            >
                                <FontAwesomeIcon icon={faUserTimes} /> Delete Account
                            </Button>
                        </div>


                    </div>
                    <div className="mt-2">
                        <PasswordSetting />
                        <ChangePassword />
                    </div>
                </div>
            </div>
        </>
    );
};
