import { PURCHASE_ORDER_REQUEST, PURCHASE_ORDER_SUCCESS, PURCHASE_ORDER_ERROR } from "../../types/types";
export default function purchaseReport(state = "", action) {
	const { type, payload } = action;
	switch (type) {
		case PURCHASE_ORDER_REQUEST:
			return {
				loading: true,
			};
		case PURCHASE_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case PURCHASE_ORDER_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}
