import {
    GET_FOLLOWING_REQUEST,
    GET_FOLLOWING_SUCCESS,
    GET_FOLLOWING_ERROR,
  } from "../../types/types";
  export default function followingUser(state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_FOLLOWING_REQUEST:
        return {
          loading: true,
        };
      case GET_FOLLOWING_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: payload,
        };
  
      case GET_FOLLOWING_ERROR:
        return {
          ...state,
          loading: false,
          payload: payload,
        };
  
      default:
        return state;
    }
  }