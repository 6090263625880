import React from "react";
import commerce from "../../images/commerce.jpg";
import getPaid from "../../images/get-paid-2.png";
import publicBannerInf from "../../images/public-banner-inf.png";
import { openRegister } from "../../redux/actions/register.action";
import { useDispatch } from "react-redux";
import { redirectToAppOrStore, isMobile, useScrollAnimation } from "../../helper/general";
import homebanner1 from "../../images/banners/home-banner11.jpg";

let userInfo = localStorage.getItem("userInfo");

export default function InfluncerAndCreator() {
	const dispatch = useDispatch();
	const isMobileDevice = isMobile() || false; // Call the function to determine if it's a mobile device
	useScrollAnimation(".animated-text");

	return (
		<>
			<div class="brand-sec-banner inf-banne new-style font-style" style={{ backgroundImage: `url(${homebanner1})` }}>
				<div class="brn-bnr-box-left">
					<div class="bnr-content">
						<div className="sub-hd white-h d-none d-lg-block">Become a creator and start earning with ORME</div>
						<h1>
							<span className="big-siz">
								LEVEL UP YOUR <br className="d-none d-lg-block" />
								CONTENT
							</span>
						</h1>
						<p>Your followers want to know about the brands you love, while brands want to collaborate with creators like you to reach new audiences.</p>
						<div className="banner-sub-hd d-none d-lg-block">Join ORME today to build profitable partnerships and get paid for your content.</div>

						{/* {isMobileDevice ?
              <div>
                <a
                  href="javascript:void"
                  onClick={() => {
                    redirectToAppOrStore()
                  }}
                >
                  Get the app
                </a>
              </div> :
              (

                <div>
                  <a
                    href="javascript:void"
                    onClick={() => {
                      dispatch(openRegister(true, "step1"));
                    }}
                  >
                    Join Now
                  </a>
                </div>
              )} */}

						{isMobileDevice ? (
							<div>
								<a
									href="javascript:void"
									onClick={() => {
										redirectToAppOrStore();
									}}
								>
									Get the app
								</a>
							</div>
						) : (
							<div>
								{!userInfo && (
									<a
										href="javascript:void"
										onClick={() => {
											dispatch(openRegister(true, "step1"));
										}}
									>
										Join Now
									</a>
								)}
							</div>
						)}
					</div>
				</div>
				<div class="brn-bnr-box-right">
					<img class="public-home-img" src={publicBannerInf} alt="" />
				</div>
			</div>

			<div class="bioshop-sec-main new-style-bio">
				<div class="mid-width">
					<div class="bioshop-container w-initial">
						<div class="bioshop-box full-width">
							<div class="bioshop-box-inner new-style-bio-inner max-initial max-full">
								<h2 className="text-center mb-50 animated-text ff-avenir">The ORME Difference</h2>

								<div className="row animated-text">
									<div className="col-lg-4 features-box text-center">
										<h3 className=" ff-avenir">Authentic</h3>
										<p className="feature-para ff-avenir-regular w-300 m-auto d-table mb-mobile-15">With access to hundreds of brands in our marketplace, you're guaranteed to find ones you'll truly want to share</p>
									</div>
									<div className="col-lg-4 features-box text-center">
										<h3 className=" ff-avenir">Independent</h3>
										<p className="feature-para ff-avenir-regular w-300 m-auto d-table mb-mobile-15">You're in control to pick what brands you partner with and to create unique content that speaks to your audience</p>
									</div>

									<div className="col-lg-4 features-box text-center">
										<h3 className=" ff-avenir">Unlimited Earning Potential</h3>
										<p className="feature-para ff-avenir-regular w-300 m-auto d-table">Share with your followers and get paid on every sale. The more you share, the more you earn.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="bioshop-sec-main dark">
				<div className="mid-width">
					<div className="bioshop-container w-initial">
						<div className="bioshop-box ">
							<img className="img-fluid" src={commerce} />
						</div>
						<div className="bioshop-box w-47 ml-5">
							<div className="bioshop-box-inner">
								<h2 className="animated-text ff-avenir">Your creativity. Your voice. Your content.</h2>
								<p className="mb-10 animated-text text-color-new ff-avenir-regular">Earn your value through authentic and innovative collaborations with brands that genuinely excite you.</p>

								{/* <NavLink to="/register-partner" className="roud-link mt-3">
                  Join Now
                </NavLink> */}

								{/* {!userInfo && (
                  <a
                    className="roud-link mt-3"
                    href="javascript:void"
                    onClick={() => {
                      dispatch(openRegister(true, "step1"));
                    }}
                  >
                    Join Now
                  </a>
                )} */}

								{/* 
                {isMobileDevice ?
                  <div>
                    <a
                      className="roud-link mt-3"
                      href="javascript:void"
                      onClick={() => {
                        redirectToAppOrStore()
                      }}
                    >
                      Get the app
                    </a>
                  </div> :
                  (

                    <div>
                      <a
                        className="roud-link mt-3"
                        href="javascript:void"
                        onClick={() => {
                          dispatch(openRegister(true, "step1"));
                        }}
                      >
                        Join Now
                      </a>
                    </div>

                  )} */}

								{isMobileDevice ? (
									<div className="animated-text">
										<a
											className="roud-link mt-3 "
											href="javascript:void"
											onClick={() => {
												redirectToAppOrStore();
											}}
										>
											Get the app
										</a>
									</div>
								) : (
									<div className="animated-text">
										{!userInfo && (
											<a
												className="roud-link mt-3"
												href="javascript:void"
												onClick={() => {
													dispatch(openRegister(true, "step1"));
												}}
											>
												Join Now
											</a>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="bioshop-sec-main  get-paid-style">
				<div class="mid-width">
					<div class="bioshop-container no-reverse w-initial">
						<div class="bioshop-box  d-flex justify-content-center">
							<img class="public-why-home-img" src={getPaid} alt="" />
						</div>
						<div class="bioshop-box">
							<div class="bioshop-box-inner get-paid-style-inner">
								<h2 className="mb-20 animated-text ff-avenir">Get paid for creating the content you love</h2>
								<div className="bio-para animated-text ff-avenir-regular">Download our app to create content wherever you are and start earning with your favorite brands today. Available on Google Play Store for Android and Apple App Store for iOS.</div>

								{/* <div class="bioshop-link">
                  <Link to="/register">Join Now</Link>
                </div> */}
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div class="bnr-section-2 bg-white d-inline-block">
        <div className="signup-border-content no-bg">
          <h2 className="mb-4 text-center">Start your earning journey today</h2>
          <NavLink to="/register-partner" className="roud-link ml-auto mr-auto mt-3 d-table">
            Join Now
          </NavLink>
        </div>
      </div> */}

			{!userInfo && (
				<div className="bnr-section-2 get-started mob-space new-cta-style">
					<div className="midd-width-ifti">
						<div className="one-mark">
							<h2 className="animated-text ff-avenir">Sign Up with ORMELIVE Today! </h2>

							{/* <a
                href="javascript:void"
                onClick={() => {
                  dispatch(openRegister(true, "step1"));
                }}
              >
                Get Started
              </a> */}

							{/* {isMobileDevice ?
                <a
                  href="javascript:void"
                  onClick={() => {
                    redirectToAppOrStore()
                  }}
                >
                  Get Started
                </a>
                :
                <>
                  <a
                    href="javascript:void"
                    onClick={() => {
                      dispatch(openRegister(true, "step1"));
                    }}
                  >
                    Get Started
                  </a>
                </>
              } */}

							{isMobileDevice ? (
								<>
									<a
										href="javascript:void(0)"
										onClick={() => {
											redirectToAppOrStore();
										}}
									>
										Get Started
									</a>
								</>
							) : !userInfo ? (
								<div className="animated-text">
									<a
										href="javascript:void(0)"
										onClick={() => {
											dispatch(openRegister(true, "step1"));
										}}
									>
										Get Started
									</a>
								</div>
							) : null}
						</div>
					</div>
				</div>
			)}
		</>
	);
}
