import { useSelector, useDispatch } from "react-redux";
import { Radio, Spin } from "antd";
import numeral from "numeral";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { setShippingAddress } from "../../redux/actions/shopify.setShippingAddress.action";
import { getShopifyCart } from "../../redux/actions/liveEvent.action";

export default function Shipping({ setMode, isShipTo, setIsShipTo, value, onChangeShippingMethod, setCart, setStatus, brandId, setShippingAddressModal, setShippingAddressId, cart, setCollapseKey }) {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const { account, allShippingAddress, shopifyGetShippingRates, shopifySetShippingAddress, shopifySetShippingRates } = useSelector((state) => {
		return state;
	});
	const dispatch = useDispatch();
	const ShipToAddress = () => {
		return (
			<span className="contact-2">
				{cart?.shippingAddress?.address1},{cart?.shippingAddress?.address2} {cart?.shippingAddress?.province} {cart?.shippingAddress?.city} {cart?.shippingAddress?.zip}, {cart?.shippingAddress?.country}
			</span>
		);
	};

	const onChangeAddress = (e, handled) => {
		var handle = handled ? handled : e.target.value;
		setIsShipTo(handle);
		if (userInfo) {
			dispatch(setShippingAddress(brandId, userInfo?.token, handle)).then((res) => {
				if (res?.payload?.data?.success === false) {
					Swal.fire({
						icon: "error",
						title: res?.payload?.data?.message,
					});
				} else {
					setIsShipTo(handle);

					onChangeShippingMethod("", value ? value : shopifyGetShippingRates?.payload?.message[0]._id);
					dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
						if (res?.payload?.message?.lineItems?.length > 0) {
							setStatus(false);
						} else {
							setStatus(true);
						}
						setCart(res?.payload?.message);
					});
				}
			});
		}
	};

	function getShippingMethodName(data, idToFind) {
		if (data) {
			const foundItem = data.find((item) => item?._id === idToFind);

			return foundItem ? foundItem.name : "No Method";
		}
	}

	return (
		<div className="customer-information">
			<div className="checkout-stage-two">
				<div className="select-shipping-rate">
					<h2>Addresses</h2>
					<div className="shipping-box style2 edit-radio">
						{shopifySetShippingAddress?.loading && <Spin size="medium"></Spin>}
						{allShippingAddress?.loading ? (
							<Spin size="medium"></Spin>
						) : (
							<>
								<div className="edit-ship-address">
									{allShippingAddress?.payload?.message?.map((item, key) => {
										return (
											<>
												<span
													key={key}
													onClick={() => {
														setShippingAddressModal(true);
														setShippingAddressId(item._id);
													}}
												>
													Edit
												</span>
											</>
										);
									})}
								</div>

								<Radio.Group onChange={onChangeAddress} value={isShipTo ? isShipTo : allShippingAddress?.payload?.message?.filter((item) => item?.is_default && item?._id)[0]?._id}>
									{allShippingAddress?.payload?.message?.map((item, key) => {
										return (
											<>
												<Radio key={key} value={item?._id}>
													<div className="ship-rates edit-address">
														<span>
															{item?.address1},&nbsp;
															{item?.address2}&nbsp;
															{item?.province}&nbsp;
															{item?.city}, {item?.zip},&nbsp;
															{item?.country}
														</span>
													</div>
												</Radio>
											</>
										);
									})}
								</Radio.Group>
							</>
						)}
					</div>
					<div className="mt-2 d-flex w-100 btn-style" style={{ justifyContent: "end" }}>
						<Button
							className="addressBTN primary-btn"
							onClick={() => {
								setMode("add");
								setShippingAddressModal(true);
								setShippingAddressId("");
							}}
						>
							Add Address
						</Button>
					</div>
				</div>

				<br></br>
				<div className="filed-detail">
					<div className="contact-email">
						<span className="contc-1">Contact</span>
						<span className="contact-2">{account?.payload?.Data?.email}</span>
					</div>
					{cart?.shippingAddress && (
						<div className="contact-email shipp-email">
							<span className="contc-1">Ship to</span>
							{ShipToAddress()}
							{/* <a href="#">Change</a> */}
						</div>
					)}
					{shopifySetShippingRates?.loading ? (
						<Spin size="large"></Spin>
					) : (
						cart?.shippingLine && (
							<div className="contact-email shipp-email">
								<span className="contc-1">Method</span>
								<span className="contact-2">{getShippingMethodName(shopifyGetShippingRates?.payload?.message, cart?.shippingLine?._id)}</span>
								{/* <a href="#">Change</a> */}
							</div>
						)
					)}
				</div>


				<div className="select-shipping-rate">
					<h2>Shipping Method</h2>
					<div className="shipping-box style2">
						{shopifyGetShippingRates?.loading ? (
							<Spin size="large"></Spin>
						) : (
							<Radio.Group onChange={onChangeShippingMethod} value={value ? value : shopifyGetShippingRates?.payload?.message[0]?._id}>
								{shopifyGetShippingRates?.payload?.message?.map((item, key) => {
									return (
										<Radio key={key} value={item?._id}>
											<div className="ship-rates">
												<span>{item?.name}</span>
											</div>
											<span className="ship-rates-right">{numeral(item?.amount).format("$0,0.0'")}</span>
										</Radio>
									);
								})}
							</Radio.Group>
						)}
					</div>
				</div>
				<div className="checkot-continue-btn">
					<Button
						onClick={() => {
							setCollapseKey("3");
						}}
					>
						Continue to Payment
					</Button>
				</div>
			</div>
		</div>
	);
}
