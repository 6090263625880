import { PAYOUT_CARD_REQUEST, PAYOUT_CARD_SUCCESS, PAYOUT_CARD_ERROR } from "../../types/types";
export default function payoutCard(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case PAYOUT_CARD_REQUEST:
			return {
				loading: true,
			};
		case PAYOUT_CARD_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case PAYOUT_CARD_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		default:
			return state;
	}
}
