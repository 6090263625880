import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "antd/dist/antd.css";
import "../../../css/account-setup.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping, faHeart, faArrowLeft, faGear, faPlusSquare, faBookmark, faFileInvoice, faMoneyBillTransfer, faCoins, faMoneyBillTrendUp, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { Layout, Menu } from "antd";
import React from "react";
import { displayModal } from "../../../redux/actions/register.action";

const { Header, Content, Sider } = Layout;

export default ({ children, title }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { account } = useSelector((state) => state);
	const [activeLink, setActiveLink] = useState("");
	const [activemenu, setActiveMenu] = useState(false);
	useEffect(() => {
		setActiveLink(window.location.pathname);
		setActiveMenu(false);
	}, []);

	let userInfo = localStorage.getItem("userInfo");

	if (userInfo !== "undefined") {
		userInfo = JSON.parse(localStorage.getItem("userInfo"));
	} else {
		localStorage.removeItem("userInfo");
		window.location.reload();
	}

	const url = "/" + account?.payload?.Data?.pixel_id;

	// This is componentWillUnmount
	const [chatWidth, setChatWidth] = useState(undefined);
	const [sidebarTop, setSidebarTop] = useState(undefined);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			document.body.classList.add("bioshop-body", "shopper-bioshop-body");

			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	useEffect(() => {
		const chatEl = document.querySelector(".socialsidebar").getBoundingClientRect();
		setChatWidth(chatEl.width);
		setSidebarTop(chatEl.top);
	}, []);

	useEffect(() => {
		if (!sidebarTop) return;

		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
	}, [sidebarTop]);

	const isSticky = () => {
		const chatEl = document.querySelector(".socialsidebar");
		const scrollTop = window.scrollY;
		if (scrollTop >= sidebarTop - 10) {
			chatEl.classList.add("is-sticky");
		} else {
			chatEl.classList.remove("is-sticky");
		}
	};

	// const rootSubmenuKeys = ["accounting", "settings", "ugc", "social-store-management"];
	let rootSubmenuKeys = ["social-store-management", "/become-influencer", "/reorder-socialstore", "/mediagallery", "/manageCategory", `/influencer-socialstore/${account?.payload?.Data?.instagram_username}/${account?.payload?.Data?.instagram_token}`];
	const [openKeys, setOpenKeys] = useState([]);

	const onOpenChange = (keys, e) => {
		console.log("keys1", keys);
		const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			console.log("keys2", keys);
			setOpenKeys(keys);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};

	return (
		<>
			<div className="account-container-main">
				<Layout className="ant-layout-has-sider">
					<Sider
						className="sidebar-main socialsidebar"
						width={250}
						breakpoint="xl"
						collapsedWidth="0"
						onBreakpoint={(broken) => {
							console.log(broken);
						}}
						onCollapse={(collapsed, type) => {
							console.log(collapsed, type);
						}}
					>
						<h5 className="wordspace-hd">Welcome</h5>
						<div className="workspace-menu">
							<div className="store-menu">
								<div className="workspace-icon">
									<i className="fa fa-user" aria-hidden="true"></i>
								</div>
								<div className="workspace-menu-item">
									<div className="store-name-txt">{account?.payload?.Data?.name}</div>
								</div>
							</div>
						</div>
						<Menu
							// theme="dark"

							openKeys={window.innerWidth > 1200 && rootSubmenuKeys.includes(activeLink) ? ["social-store-management"] : openKeys}
							onOpenChange={onOpenChange}
							mode="inline"
							popupClassName="test"
							popupOffset={0}
							className="menu-style-new"
							defaultSelectedKeys={location.pathname.split("/")[1]}
							theme="light"
						>
							<Menu.Item>
								<Link className={"/dashboard" === activeLink && `active`} to="/dashboard">
									<FontAwesomeIcon icon={faGear} />
									<span className="nav-text">Dashboard</span>
								</Link>
							</Menu.Item>
							<Menu.Item>
								<Link className={"/interest" === activeLink && `active`} to="/interest">
									<FontAwesomeIcon icon={faHeart} />
									<span className="nav-text">My Interest</span>
								</Link>
							</Menu.Item>
							<Menu.Item>
								<Link className={"/purchase" === activeLink && `active`} to="/purchase">
									<FontAwesomeIcon icon={faFileInvoice} />
									<span className="nav-text">Purchases</span>
								</Link>
							</Menu.Item>
							<Menu.Item>
								{account?.payload?.Data?.first_name && account?.payload?.Data?.last_name && account?.payload?.Data?.email ? (
									// Link for redirection when all properties are present
									<Link className={"/share-earn" === activeLink && `active`} to="/share-earn">
										<FontAwesomeIcon icon={faFileInvoice} />
										<span className="nav-text">Share & Earn</span>
									</Link>
								) : (
									// Link that doesn't redirect when any property is missing
									<a
										onClick={() => {
											dispatch(displayModal("register", true));
										}}
										href="#"
									>
										<FontAwesomeIcon icon={faFileInvoice} />
										<span className="nav-text">Share & Earn</span>
									</a>
								)}
							</Menu.Item>
							<Menu.Item>
								<Link className={url === activeLink && `active`} to={url}>
									<FontAwesomeIcon icon={faBagShopping} />
									<span className="nav-text">My Shop</span>
								</Link>
							</Menu.Item>
							<Menu.Item>
								<Link className={"/referralstats" === activeLink && `active`} to="/referralstats">
									<FontAwesomeIcon icon={faMoneyBillTransfer} />
									<span className="nav-text">Referral Sales</span>
								</Link>
							</Menu.Item>
							{account?.payload?.Data?.account_type === "influencer" ? (
								<>
									<Menu.Item>
										<Link className={"/reviewsale" === activeLink && `active`} to="/reviewsale">
											<FontAwesomeIcon icon={faMoneyBillTrendUp} />
											<span className="nav-text">Creator Sales</span>
										</Link>
									</Menu.Item>
								</>
							) : (
								<></>
							)}

							<Menu.Item>
								<Link className={("/earned-commission" === activeLink && `active`) || ("/mywallet" === activeLink && `active`)} to="/earned-commission">
									<FontAwesomeIcon icon={faCoins} />
									<span className="nav-text">Earnings</span>
								</Link>
							</Menu.Item>

							<Menu.Item>
								<Link className={("/saved-events" === activeLink && `active`) || ("/saved" === activeLink && `active`)} to="/saved">
									<FontAwesomeIcon icon={faBookmark} />
									<span className="nav-text">Saved</span>
								</Link>
							</Menu.Item>

							<Menu.Item>
								<Link
									// className={"/account-settings" === activeLink && `active`}
									className={("/account-settings" === activeLink && `active`) || ("/shippingaddress" === activeLink && `active`) || ("/accountDetail" === activeLink && `active`) || ("/payment" === activeLink && `active`) || ("/payout" === activeLink && `active`) || ("/wallet" === activeLink && `active`)}
									to="/account-settings"
								>
									<FontAwesomeIcon icon={faCircleUser} />
									<span className="nav-text">Account Settings</span>
								</Link>
							</Menu.Item>
							<Menu.Item>
								<Link to="/reviews-list" className={("/reviews-list" === activeLink && `active`) || ("/upload-video" === activeLink && `active`)}>
									<FontAwesomeIcon icon={faPlusSquare} />
									<span className="nav-text">Create </span>
								</Link>
							</Menu.Item>

							{/* <Menu.Item>
                <Link
                  className={
                    ("/myaccount" === activeLink && `active`) ||
                    ("/accountDetail" === activeLink && `active`)
                  }
                  to="/myaccount"
                >
                  <FontAwesomeIcon icon={faUser} />
                  <span className="nav-text">Profile</span>
                </Link>
              </Menu.Item>

              <Menu.Item>
                <Link
                  className={"/shippingaddress" === activeLink && `active`}
                  to="/shippingaddress"
                >
                  <FontAwesomeIcon icon={faThumbTack} />
                  <span className="nav-text">Shipping Information</span>
                </Link>
              </Menu.Item>


              <Menu.Item>
                <Link
                  className={"/payment" === activeLink && `active`}
                  to="/payment"
                >
                  <FontAwesomeIcon icon={faMoneyCheck} />
                  <span className="nav-text">Payment Method</span>
                </Link>
              </Menu.Item>

              <Menu.Item>
                <Link
                  className={"/payout" === activeLink && `active`}
                  to="/payout"
                >
                  <FontAwesomeIcon icon={faWallet} />
                  <span className="nav-text">Payout</span>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link
                  className={"/delete-account" === activeLink && `active`}
                  to="/delete-account"
                >
                  <FontAwesomeIcon icon={faUserTimes} />
                  <span className="nav-text">Delete Account</span>
                </Link>
              </Menu.Item> */}

							<Menu.Item>
								<Link to="/">
									<FontAwesomeIcon icon={faArrowLeft} />
									<span className="nav-text">Exit</span>
								</Link>
							</Menu.Item>
						</Menu>
					</Sider>
					<Layout className="layout-content">
						<Header
							className="site-layout-sub-header-background"
							style={{
								padding: 0,
							}}
						>
							<div className="header-title">{title}</div>
						</Header>
						<Content style={{ margin: "0" }}>
							<div className="site-layout-background">
								<div className="account-inr-container">{children}</div>
							</div>
						</Content>
					</Layout>
				</Layout>
			</div>
		</>
	);
};
