import { SHOPIFY_UPDATE_CART_REQUEST, SHOPIFY_UPDATE_CART_SUCCESS, SHOPIFY_UPDATE_CART_ERROR } from "../../types/types";
export default function updateCart(state = "", action) {
  const { type, payload } = action;
  switch (type) {
    case SHOPIFY_UPDATE_CART_REQUEST:
      return {
        loading: true,
        success: true,
      };
    case SHOPIFY_UPDATE_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        payload: payload,
      };

    case SHOPIFY_UPDATE_CART_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        payload: payload,
      };

    default:
      return state;
  }
}
