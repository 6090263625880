import React, { useState, useEffect } from "react";
import { Button } from "antd";
import "../../../css/account-setup.scss";
import PayPallogo from "../../../images/paypal-logo.jpg";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faArrowLeft, faArrowRight, faLessThan, faUser } from "@fortawesome/free-solid-svg-icons";

export default function Paypal({handleLogin}) {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);


  return (
    <>
      <div className="account-left-content">
      <div className="">
          <Button
            className="back-button mb-3"
            onClick={() => {
              navigate("/account-settings");
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} /> Back
          </Button>
        </div>
        <div className="account-detail col-12 col-xl-4 col-lg-8 col-md-12">
          <div className="bcm-inf-main">
            <div className="paypal-connect-box btn-style">
              <img className="paypal-logo" src={PayPallogo} alt="" />
              <p className="pb-3">
                Please click the button below to be redirected to PayPal's site
                where you can securely log in and connect your account.
              </p>
              <Button className="connect-btn" onClick={handleLogin}>
                Connect
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
