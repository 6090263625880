import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { openRegister } from "../../redux/actions/register.action";
import "../../css/about_us.scss";
import about1 from "../../images/about-sec1.jpg";
import about2 from "../../images/about-sec2.jpg";
import aboutbanner from "../../images/about-banner.jpg";
import playbutton from "../../images/play-button.png";
import avatarIcon from "../../images/avatar.png";

import { Modal } from "react-bootstrap";

export default () => {
  let userInfo = localStorage.getItem("userInfo");
  const dispatch = useDispatch();
  const [videoPopup, setVideoPopup] = useState(false);
  return (
    <>
      <div className="brand-sec-banner about-sec-banner">
        <div className="brn-bnr-box-left">
          <div className="bnr-content">
            <h1>
              <span>ABOUT US</span>
            </h1>
            <p>ORMELIVE is not just a shopping platform. It’s a whole ecosystem. We’ve built a platform that can meet the needs of any brand, shopper, or influencer.</p>
            {/* <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
            {userInfo ? (
              <></>
            ) : (
              // <NavLink to={"/brands"}>Get Started</NavLink>
              <a
                href="javascript:void"
                onClick={() => {
                  dispatch(openRegister(true, "step2"));
                }}
              >
                Join Now
              </a>
            )}
          </div>
        </div>
        <div className="brn-bnr-box-right">
          <img className="about-img" src={aboutbanner} alt="" />
        </div>
      </div>

      <div className="bioshop-sec-main get-paid">
        <div className="midd-width-ifti">
          <div className="bioshop-container not-reverse">
            <div className="bioshop-box">
              <img className="img-fluid" src={about1} />
            </div>
            <div className="bioshop-box flex-end">
              <div className="bioshop-box-inner">
                <h2>Our Values</h2>
                <p className="mb-0">We at ORMELIVE believe in fair business practices, socially responsible individuals, and a general concept of “doing the right thing.” It is our goal to hire like-minded professionals and grow a positive, socially altruistic workplace. Additionally, we operate with a customer-first mentality and are always prepared to go the extra mile for the brands.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bioshop-sec-main">
        <div className="midd-width-ifti">
          <div className="bioshop-container">
            <div className="bioshop-box">
              <div className="bioshop-box-inner">
                <h2>Our Goal</h2>
                <p className="mb-0">We aim to create transformational growth through the power of technology in brands, influencers, and shoppers. And we are in business to help all of them through one platform.</p>
              </div>
            </div>
            <div className="bioshop-box">
              <img className="img-fluid" src={about2} />
            </div>
          </div>
        </div>
      </div>

      <div className="meet-out-team d-none">
        <div className="midd-width-ifti">
          <div className="team-wrapper">
            <div className="team-header">
              <h2>Meet our Team</h2>
              <p>ORME is driven by a relentless desire to connect brands, influencers, and shoppers together for mutual success. We are privileged to work with many of the world’s most recognized brands and our publisher network is rich with opportunity.</p>
            </div>
            <div className="team-boxes-main">
              <div className="team-box">
                <div className="team-icon">
                  <img className="img-fluid" src={avatarIcon} />
                </div>
                <div className="team-content">
                  <h2>Name</h2>
                  <p>Designation</p>
                </div>
              </div>
              <div className="team-box">
                <div className="team-icon">
                  <img className="img-fluid" src={avatarIcon} />
                </div>
                <div className="team-content">
                  <h2>Name</h2>
                  <p>Designation</p>
                </div>
              </div>
              <div className="team-box">
                <div className="team-icon">
                  <img className="img-fluid" src={avatarIcon} />
                </div>
                <div className="team-content">
                  <h2>Name</h2>
                  <p>Designation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="who-we-are-main d-none">
        <div className="midd-width-ifti">
          <div className="who-we-are-content">
            <h2>Who we are</h2>

            <div className="play-button">
              <div className="request-loader">
                <a
                  onClick={() => {
                    setVideoPopup(true);
                  }}
                  href="javascript:void"
                >
                  <i aria-hidden="true" className="fa fa-play"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {userInfo ? (
        <></>
      ) : (
        <>
          <div className="bnr-section-2 get-started">
            <div className="midd-width-ifti">
              <div className="one-mark">
                <h2>Sign Up with ORMELIVE Today! </h2>

                <a
                  href="javascript:void"
                  onClick={() => {
                    dispatch(openRegister(true, "step2"));
                  }}
                >
                  Join Now
                </a>
              </div>
            </div>
          </div>
        </>
      )}

      <Modal dialogClassName="signup-modal form-close-modal video-modal" size="lg" backdropClassName="custom-backdrop" show={videoPopup} onHide={() => setVideoPopup(false)} centered animation={false} backdrop={true} keyboard={false}>
        <Modal.Header>
          <button
            type="button"
            className="close"
            onClick={() => {
              setVideoPopup(false);
            }}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <iframe width="100%" height="400" src="https://www.youtube.com/embed/xcJtL7QggTI" title="Sony 4K Demo: Another World" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};
