import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import "../../css/influencers.scss";
import FilterModal from "../../components/HomeShows/FilterModal";
import List from "../../components/influencers/List";
import { getInfluencers } from "../../redux/actions/becomeInfluencer.action";
import { getInterests } from "../../redux/actions/interest.action";

export default function Influencers( ) {
	let userInfo = localStorage.getItem("userInfo");
	const [currentShow, setCurrentShow] = useState("influencers");
	const [showFilter, setShowFilter] = useState(false);
	const [loadingSpinner, setLoadingSpinner] = useState(true);
	const [page, setPage] = useState(1);
	const queryParams = queryString.parse(window.location.search);
	const sortBy = queryParams.sortBy;
	const navigate = useNavigate();
	let location = useLocation();
	const [trending, setTrending] = useState(false);

	useEffect(() => {
		// ComponentDidMount
		const root = document.getElementById('root');
		if (root) {
		  root.classList.add('footer-fixed');
		}
	
		// Equivalent to componentWillUnmount
		return () => {
		  if (root) {
			root.classList.remove('footer-fixed');
		  }
		};
	  }, []); 

	const [filterPost, setFilterPost] = useState({
		categoryId: "",
		subCategoryId: "",
		postType: "image,campaign,video",
		sortBy: "",
		applyFilter: "all",
	});

	const ref = useRef(null);
	const [size, onChangeSize] = useState(0);
	useLayoutEffect(() => {
		const width = ref?.current?.offsetWidth;
		if (width <= 576) {
			onChangeSize(4); // small screens (mobile)
		} else if (width > 576 && width <= 992) {
			onChangeSize(6); // medium screens (tablet)
		} else {
			onChangeSize(12); // large screens (desktop and above)
		}
	}, [ref]);

	const loader = <div className="influencers-grid">{new Array(size).fill(<div className="influencer-column loader-bg"></div>)}</div>;
	const dispatch = useDispatch();
	const { search, influencers } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		if (window.location.search) {
			setTrending(true);
		} else {
			setTrending(false);
		}
	}, [window.location.search]);

	useLayoutEffect(() => {
		setLoadingSpinner(true);
		if (userInfo?.token) {
			dispatch(getInterests(userInfo?.token)).then((res) => {
				const catId = res.payload.message.map(({ _id }) => _id) || [];
				getApiData(catId).then(() => {
					setPage(2);
					setLoadingSpinner(false);
				});
			});
		} else {
			getApiData().then(() => {
				setPage(2);
				setLoadingSpinner(false);
			});
		}
	}, [queryParams.sortBy, search.payload]);

	useLayoutEffect(() => {
		if (filterPost.genderCategoryId != null && filterPost.categoryId != null) {
			setLoadingSpinner(true);
			getApiData().then(() => {
				setPage(2);
				setLoadingSpinner(false);
			});
		}
	}, [filterPost.genderCategoryId, filterPost.categoryId]);

	const apiMapping = {
		influencers: { apiCall: getInfluencers, data: influencers },
	};

	function getApiData(deparmentId) {
		const department = deparmentId || filterPost.genderCategoryId;
		return dispatch(apiMapping[currentShow].apiCall(1, 18, department, filterPost.categoryId, "", undefined, sortBy, search.payload));
	}

	function loadMore() {
		dispatch(apiMapping[currentShow].apiCall(page, 18, filterPost.genderCategoryId, filterPost.categoryId, "", undefined, sortBy, search.payload, true)).then(() => setPage(page + 1));
	}

	function getData() {
		return apiMapping[currentShow].data;
	}

	return (
		<>
			<div className="live-event-main">
				<div className="midd-width-ifti">
					<div className="live-shows">
						<div className="event-filter">
							<Button
								onClick={() => {
									setShowFilter(true);
								}}
							>
								Filters
							</Button>

							{/* <Button
								className={trending ? "active" : ""}
								onClick={() => {
									if (trending) {
										navigate(`${location.pathname}`);
									} else {
										navigate(`${location.pathname}?sortBy=top_influencers`);
									}
								}}
							>
								Top Influencers
							</Button> */}
						</div>

						{loadingSpinner ? loader : <List loading={getData()?.loading} loadMore={loadMore} hasMore={getData().payload?.message?.total_records != getData().payload?.message?.data?.length ? true : false} post={getData().payload?.message?.data} />}
					</div>
				</div>
			</div>
			<FilterModal filterPost={filterPost} setFilterPost={setFilterPost} showFilter={showFilter} setShowFilter={setShowFilter} />
		</>
	);
}
