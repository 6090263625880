import { SHOPIFY_UPDATE_ATTRIBUTE_REQUEST, SHOPIFY_UPDATE_ATTRIBUTE_SUCCESS, SHOPIFY_UPDATE_ATTRIBUTE_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const shareTracking = JSON.parse(localStorage.getItem("shareTracking"));

export const updateShopifyAttribute = (brand, event, data, token) => (dispatch) => {
	dispatch({ type: SHOPIFY_UPDATE_ATTRIBUTE_REQUEST });
	let data1;
	switch (true) {
		case ["reviewshare"].includes(data.source):
			data1 = {
				brand: brand,
				review: event,
				tracker: data?.tracker,
				source: data.source,
			};

			break;

		case ["reviewshop"].includes(data.source):
			data1 = {
				brand: brand,
				review: event,
				source: data.source,
				tracker: data?.tracker,
			};

			break;

		case ["eventshare"].includes(data.source):
			data1 = {
				brand: brand,
				event: event,
				tracker: data?.tracker,
				source: data.source,
			};
			break;

		case ["eventshop"].includes(data.source):
			data1 = {
				brand: brand,
				event: event,
				source: data.source,
				tracker: data?.tracker,
			};

			break;
		default:
			data1 = {
				brand: brand,
				event: event,
				source: data.source,
			};
			break;
	}
	return axios({
		method: "POST",
		url: `${BASEURL}checkout/updateattribute`,
		headers: { Authorization: `Bearer ${token}` },
		data: data1,
	})
		.then((response) => {
			return dispatch({
				type: SHOPIFY_UPDATE_ATTRIBUTE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SHOPIFY_UPDATE_ATTRIBUTE_ERROR,
				payload: error.response,
			});
		});
};
