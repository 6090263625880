import { POST_VERIFICATION_REQUEST, POST_VERIFICATION_SUCCESS, POST_VERIFICATION_ERROR } from "../../types/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case POST_VERIFICATION_REQUEST:
			return {
				loading: true,
			};
		case POST_VERIFICATION_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case POST_VERIFICATION_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		default:
			return state;
	}
}