import React, { useState } from "react";
import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserHistory } from "history";
import { Button } from "react-bootstrap";
import closeicon from "../../images/brand-logos/close-icon.svg";
import HlsPlayer from "../../components/hlsPlayer/hlsPlayer";
import ShortUrl from "../../components/shortUrl/shortUrl";
// import { openLogin } from "../../redux/actions/login.action";
import { openRegister, displayModal } from "../../redux/actions/register.action";
import { likesSave } from "../../redux/actions/influencer.action";
import { addUrl, shareVideo } from "../../redux/actions/bioShop.action";
import Heart from "../../components/hearts/hearts";
import MultipleHeart from "../../components/hearts/multipleHearts";
import { useNavigate, useParams } from "react-router-dom";
import HeartLike from "../../components/hearts/heartLike";
import "../../css/hearts.scss";
import avatarIcon from "../../images/avatar.png";
import { EyeOutlined } from "@ant-design/icons";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { getSave } from "../../redux/actions/save.action";

export const history = createBrowserHistory();

export default (props) => {
	const params = useParams();
	let userInfo = JSON.parse(localStorage.getItem("userInfo"));
	let id = props.id;
	const dispatch = useDispatch();
	const queryParams = queryString.parse(window.location.search);

	const navigate = useNavigate();
	const { account, influencerViews } = useSelector((state) => {
		return state;
	});

	const [showShare, setShowShare] = useState(false);
	const [isStopped, setIsStopped] = useState(true);
	const Navigates = (num) => {
		if (props.collapsed == true) {
			props.setCollapsed(!props.collapsed);
		}
		props.onKeyChange(num);
	};

	const share = () => {
		let fullUrl = window.location.href;
		let url = fullUrl.split("/").slice(0, 4).join("/");

		if (!userInfo) {
			dispatch(openRegister(true));
		} else {
			if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
				dispatch(displayModal("register", true));
			} else {
				dispatch(addUrl(userInfo.token, shareAction(url))).then(() => {
					dispatch(shareVideo(userInfo.token, shareAction(url)));
				});
				setShowShare(true);
				shareAction(url);
			}
		}
	};

	const shareAction = (url) => {
		const newUrl = `${url}/${props?.item?._id}/${props?.item?.brand?._id}` + (params?.influencer_id ? `/${params?.influencer_id}` : "");
		const newRedirectUrl = new URL(newUrl);

		newRedirectUrl.searchParams.set("publisher_id", userInfo?._id);
		if (props?.item?.page) {
			newRedirectUrl.searchParams.set("page", props?.item?.page);
		}
		newRedirectUrl.searchParams.set("origin", queryParams?.origin);
		newRedirectUrl.searchParams.set("department", queryParams?.department);
		newRedirectUrl.searchParams.set("category", queryParams?.category);
		newRedirectUrl.searchParams.set("advertiser_id", props?.item?.influencer_id);
		newRedirectUrl.searchParams.set("review_id", id[0]?._id);
		newRedirectUrl.searchParams.set("event", "reviewshare");
		return newRedirectUrl.toString();
	};

	const getUrlExtension = (url) => {
		const fileName = url.split("/").pop();
		const fileExtension = fileName.split(".").pop();
		return fileExtension;
	};

	return (
		<>
			<div className="lv-cntr App">
				<div className="brand-detail-overlay">
					<div className="brand-detail">
						<div className="author-name">
							<span className="auth-image">{props?.item?.influencer?.profile_image_url ? <img alt="" src={props?.item?.influencer?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}</span>
							<div className="auth-detail">
								<span className="auth-title">{props?.item?.title}</span>
								<div className="brand-followers-live-event">
									<span className="event-name">by {props?.item?.influencer?.name}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="live-txt"> Reviews</div>

					{influencerViews?.payload?.message ? (
						<>
							<div className="live-audiance">
								<EyeOutlined /> {influencerViews?.payload?.message}
							</div>
						</>
					) : (
						<></>
					)}

					{props?.item?.promoted && props?.item?.is_promoted === true && (
						<>
							<div className="promoted">
								<FontAwesomeIcon className="promote-icon" icon={faBullhorn} />
								Promoted
							</div>
							{/* <div>Promoted</div> */}
						</>
					)}
				</div>

				<div className="close-live-event">
					<a
						href="#"
						onClick={() => {
							if (queryParams?.pagelink) {
								navigate("/reviews-list");
							} else if (queryParams?.origin) {
								navigate(`/${queryParams.origin}`);
							} else {
								navigate("/");
							}
						}}
						className="close-video"
					>
						<img src={closeicon} className="filter-gray" />
					</a>
				</div>

				<div className="drawer-buttons-outer drawer-for-recorded-events">
					<div className="dr-1 dr-detail">
						<Tooltip placement="left" title="Comments">
							<Button
								onClick={() => {
									Navigates("1");
								}}
							>
								<i className="fa fa-comment"></i>
							</Button>
						</Tooltip>
					</div>
					<div className="dr-1 dr-detail">
						<Tooltip placement="left" title="Details">
							<Button
								onClick={() => {
									Navigates("2");
								}}
							>
								<i className="fa fa-info" aria-hidden="true"></i>
							</Button>
						</Tooltip>
					</div>
					<div className="dr-1 dr-shop">
						<Tooltip placement="left" title="Shop">
							<Button
								onClick={() => {
									Navigates("3");
								}}
							>
								<i className="fa fa-dollar" aria-hidden="true"></i>
							</Button>
						</Tooltip>
					</div>
					<div className="dr-1 dr-cart">
						<Tooltip placement="left" title="Cart">
							<Button
								onClick={() => {
									Navigates("4");
								}}
							>
								<i className="fa fa-shopping-bag" aria-hidden="true"></i>
							</Button>
						</Tooltip>
					</div>
					<div className="dr-1 dr-share">
						<Tooltip placement="left" title="Share">
							<Button
								onClick={() => {
									share();
								}}
							>
								<i className="fa fa-share-alt" aria-hidden="true"></i>
							</Button>
						</Tooltip>
					</div>

					<div className={`dr-1 dr-share ${props?.isSaved ? "dr-share-count" : ""}`}>
						<Tooltip placement="left" title="Save">
							<Button
								onClick={() => {
									if (!userInfo) {
										dispatch(openRegister(true));
									} else {
										if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
											dispatch(displayModal("register", true));
										} else {
											if (id[0]?._id) {
												dispatch(getSave(id[0]?._id)).then((res) => {
													props.setIsSaved(res?.payload?.is_saved);
												});
											}
										}
									}
								}}
							>
								<i className="fa fa-bookmark" aria-hidden="true"></i>
							</Button>
						</Tooltip>
					</div>

					<div className={`dr-1 ${props?.like?.is_like ? "dr-like-count" : ""}`}>
						<Button
							onClick={() => {
								if (!userInfo) {
									dispatch(openRegister(true));
								} else {
									if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
										dispatch(displayModal("register", true));
									} else {
										dispatch(likesSave(userInfo?.token, props?.item?._id)).then((res) => {
											if (props?.like?.is_like) {
												props.setLike(res.payload);
											} else {
												props.setLike(res.payload);
											}
										});
									}
									HeartLike();
								}
							}}
						>
							<i className="fa fa-heart" aria-hidden="true"></i>
						</Button>
						<span className="count-number">{props?.like.message}</span>
					</div>
				</div>
				<div className="emoji-overlay"></div>
				<div className="custom-hearts">
					<Heart isStopped={isStopped} />
				</div>
				<div className="custom-hearts">
					<MultipleHeart />
				</div>

				{props?.item?.media_url && (
					<div className="MiniPlayer">
						<div className="MiniPlayer-videoBox">
							<div className={`MinPlayer-video infl-review-video-height large ${props?.item.orientation === "portrait" && "full-video-height"}`}>
								{getUrlExtension(props?.item?.media_url) === "m3u8" ? (
									<HlsPlayer key={props?.item?.media_url} autoPlay={false} id={`video-${props.index}`} streamUrl={props?.item?.media_url} />
								) : (
									<>
										<video key={props?.item?.media_url} id={`video-${props.index}`} controlsList="nodownload" preload="metadata" webkit-playsInline playsInline controls loop width="100%" height="100%">
											<source src={props?.item?.media_url} type="video/mp4"></source>
										</video>
									</>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
			<ShortUrl url={window.location.href} showShare={showShare} setShowShare={setShowShare} referral_percent={props?.item?.brand?.fee_structure?.referral_fee} />
			{/* <ShortUrl url={window.location.href} showShare={showShare} setShowShare={setShowShare} referral_percent={props?.item?.referral_percent} /> */}
		</>
	);
};
