import { OPEN_MODAL_SUCCESS, POST_REGISTER_OPEN_SUCCESS, POST_REGISTER_REQUEST, POST_REGISTER_SUCCESS, POST_REGISTER_ERROR, POST_BRAND_REGISTER_REQUEST, POST_BRAND_REGISTER_SUCCESS, POST_BRAND_REGISTER_ERROR, POST_PARTNER_REQUEST, POST_PARTNER_SUCCESS, POST_PARTNER_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getBrandRegister = (data) => (dispatch) => {
	dispatch({ type: POST_BRAND_REGISTER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}signup/user`,
		headers: {
			Accept: "application/json",
		},
		data: {
			account_type: "brand",
			name: data?.name,
			email: data.email,
			gender: data.gender,
			country: data.country,
			state: data.state,
			city: data.city,
			password: data.password,
			device: "web",
			referred_by: data.refferedBy,
			zip: 1,
			coming_from_app: false,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_BRAND_REGISTER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_BRAND_REGISTER_ERROR,
				payload: error.response,
			});
		});
};

export const openRegister = (status, step) => (dispatch) => {
	return dispatch({
		type: POST_REGISTER_OPEN_SUCCESS,
		payload: { status: status, step: step },
	});
};

export const displayModal = (name, status) => (dispatch) => {
	return dispatch({
		type: OPEN_MODAL_SUCCESS,
		payload: { name, status },
	});
};

export const getCustomerRegister = (data) => (dispatch) => {
	dispatch({ type: POST_REGISTER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}auth/user/authorize`,
		headers: {
			Accept: "application/json",
		},
		data: {
			name: data?.firstName + " " + data.lastName,
			nick_name: data.nickName,
			first_name: data.firstName,
			last_name: data.lastName,
			email: data.email,
			zip: 1,
			gender: data.gender,
			country: data.country,
			state: data.state,
			city: data.city,
			password: data.password,
			device: "web",
			coming_from_app: false,
			brand: process.env.REACT_APP_BRAND_ID,
			source: "ormelive.com",
			phone: data.phone,
			url: data?.url,
			url_type: data.urlType,
			publisher_pixel_id: data?.publisher_pixel_id,
			publisher_id: data?.publisher_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_REGISTER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_REGISTER_ERROR,
				payload: error.response,
			});
		});
};

export const partnerRegister = (data) => (dispatch) => {
	dispatch({ type: POST_PARTNER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}auth/brand/signup`,
		headers: {
			Accept: "application/json",
		},
		data: {
			account_type: "brand",
			name: data.name,
			website_address: data.websiteaddress,
			email: data.email,
			phone: data.phone,
			gender: data.gender,
			country: data.country,
			state: data.state,
			city: data.city,
			password: data.password,
			device: "web",
			referred_by: data.refferedBy,
			zip: data.zip,
			coming_from_app: false,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_PARTNER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_PARTNER_ERROR,
				payload: error.response,
			});
		});
};