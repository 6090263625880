import {
	POST_REVIEW_MESSAGE_REQUEST,
	POST_REVIEW_MESSAGE_SUCCESS,
	POST_REVIEW_MESSAGE_ERROR
} from "../../types/types";
export default function messageReview(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case POST_REVIEW_MESSAGE_REQUEST:
			return {
				loading: true,
			};
		case POST_REVIEW_MESSAGE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case POST_REVIEW_MESSAGE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}