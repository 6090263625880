import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { getSkutitle } from "../../../redux/actions/brand.action";
import { BASEURL } from "../../../config";

const ProductsFilter = (props) => {
	const [sku, setSku] = useState();
	const brandId = props.brandId;
	const [brandData, setBrandData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const userInfo = JSON.parse(localStorage.getItem("userInfo")) || [];

	const dispatch = useDispatch();

	// Fetch SKUs when brandId changes
	useEffect(() => {
		if (brandId) {
			setSku([]);
			props.setSku([]);
			fetchSkusForBrand(brandId).then((res) => {
				setBrandData(res);
			});
		}
	}, [brandId]);
	useEffect(() => {
		if (props.sku) {
			setSku(props.sku);
		}
	}, [props.sku])

	const fetchSkusForBrand = async (brandId) => {
		setIsLoading(true)
		try {
			const res = await dispatch(getSkutitle(brandId, ""));
			if (res?.payload?.message) {
				setIsLoading(false)
				return res.payload.message.map(({ _source }) => {
					const productUrl = "https://" + _source?.domain + "/products/" + _source?.handle;
					let description = _source?.body_html ? _source?.body_html.replace(/<\/?[^>]+(>|$)/g, "") : "";
					return {
						value: _source?.variants[0]?.sku,
						label: _source?.variants[0]?.sku,
						price: _source?.variants[0]?.price,
						title: _source?.title,
						id: _source?.id,
						ProductName: _source?.title,
						ProductSku: _source?.variants[0]?.sku,
						ProductUrl: productUrl,
						mediaUrl: _source?.image?.src,
						productAmount: _source?.variants[0]?.price,
						productDesc: description,
						productOpt: _source?.options,
						variants: _source?.variants,
						productUrl: "https://" + _source?.domain + "/products/" + _source?.handle,
						description: _source?.body_html ? _source?.body_html.replace(/<\/?[^>]+(>|$)/g, "") : "",
						is_active: true,
						tags: _source?.tags,
					};
				});
			}
		
		} catch (error) {
			console.error("Error fetching SKUs:", error);
			return [];
		}
	};

	const smartSearchFilter = async (value) => {

		if (value.length > 2) {
			try {
				const response = await axios.post(
					`${BASEURL}shopify/searchBySkuAndTitle`,
					{
						sku: value.trim(),
						brand_id: brandId,
					},
					{ headers: { Authorization: `Bearer ${userInfo?.token}` } }
				);

				const skuData = response.data.message;
				setIsLoading(false)
				if (skuData.length === 0) {
					notification.error({
						message: "No Product Found",
						className: "toast-error",
					});
				}

				

				const newSkuArr = skuData.map(({ _source }) => {
					const productUrl = "https://" + _source?.domain + "/products/" + _source?.handle;
					let description = _source?.body_html ? _source?.body_html.replace(/<\/?[^>]+(>|$)/g, "") : "";

					return {
						value: _source?.variants[0]?.sku,
						label: _source?.variants[0]?.sku,
						price: _source?.variants[0]?.price,
						title: _source?.title,
						id: _source?.id,
						ProductName: _source?.title,
						ProductSku: _source?.variants[0]?.sku,
						ProductUrl: productUrl,
						mediaUrl: _source?.image?.src,
						productAmount: _source?.variants[0]?.price,
						productDesc: description,
						productOpt: _source?.options,
						variants: _source?.variants,
						productUrl: "https://" + _source?.domain + "/products/" + _source?.handle,
						description: _source?.body_html ? _source?.body_html.replace(/<\/?[^>]+(>|$)/g, "") : "",
						is_active: true,
						tags: _source?.tags,
					};
				});

				return newSkuArr;
			} catch (error) {
				console.log(error);
			}
		}
	};
	const handleMultiSelect = (e, options) => {
		// Handling the removal of a selected product
		if (options.action === "remove-value") {
			// Update the state and props with the removed product
			const updatedSkus = sku.filter((item) => item.ProductSku !== options.removedValue.ProductSku);
			setSku(updatedSkus);
			props.setSku(updatedSkus);
		}

		else if (options.action === "clear") {
			setSku([]);
			props.setSku([]);
		}

		// Handling the addition of a new product
		if (options.action === "select-option") {
			const newProduct = e[e.length - 1]; // The product that was just selected

			// Check if the product is already added in the sku state
			const isProductAlreadyAdded = sku.some((product) => product.ProductSku === newProduct.ProductSku);

			if (isProductAlreadyAdded) {
				// Show an error notification if the product is already added
				notification.error({
					message: "Product is Already Added",
					className: "toast-error",
				});
			} else {
				// Add the new product to the sku state only if it's not already added
				const updatedSkus = [...sku, newProduct];
				setSku(updatedSkus);

				props.setSku(updatedSkus);
			}
		}
	};

	const handleOnPaste = async (e) => {
		const getData = e.clipboardData.getData("text");
		await smartSearchFilter(getData);
	};

	const formatOptionLabel = ({ label, title }) => (
		<div style={{ display: "flex" }}>
			<div>{label}</div>&nbsp;-&nbsp;
			<div style={{ marginLeft: "10px" }}>{title}</div>&nbsp;&nbsp;
		</div>
	);

	const loadOptions = async (input, callback) => {
		setIsLoading(true)
		if (input) {
			let arrsku = await smartSearchFilter(input);
			const result = arrsku.map((item) => {
				return {
					value: `${item.value}`,
					label: `${item.label}`,
					price: `${item.price}`,
					title: `${item.title}`,
					id: item?.id,
					ProductName: item?.title,
					ProductSku: item?.ProductSku,
					ProductUrl: item.ProductUrl,
					mediaUrl: item?.mediaUrl,
					productAmount: item?.productAmount,
					productDesc: item.productDesc,
					productOpt: item?.productOpt,
					variants: item?.variants,
					is_active: true,
					tags: item?.tags,
				};
			});
			callback(result);
			setIsLoading(false)
		} else {
			setIsLoading(false)
			callback(brandData); // Directly use brandData for options
		}
	};


	return (
		<React.Fragment>
			<div className="border border-dark rounded-0" onPaste={(e) => handleOnPaste(e)}>
				<AsyncSelect
					isDisabled={!brandId}
					isMulti
					cacheOptions
					defaultOptions={brandData}
					loadOptions={loadOptions}
					placeholder="Search Product"
					name={"productsku"}
					value={sku}
					onChange={(e, options) => handleMultiSelect(e, options)}
					formatOptionLabel={formatOptionLabel}
					isLoading={isLoading}
				/>
			</div>
		</React.Fragment>
	);
};
export default ProductsFilter;
