import React from "react";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, message, Menu } from "antd";

// DropdownMenu Component
const DropdownMenu = () => {
	const handleMenuClick = (e) => {
		message.info("Click on menu item.");
		console.log("click", e);
	};

	const dropdownItems = [
		{
			label: "Edit",
			key: "1",
			icon: <EditOutlined />,
		},
		{
			label: "Delete",
			key: "2",
			icon: <DeleteOutlined />,
		},
		{
			label: "Report",
			key: "3",
			icon: <ExclamationCircleOutlined />,
		},
	];

	const menu = (
		<Menu onClick={handleMenuClick}>
			{dropdownItems.map((item) => (
				<Menu.Item key={item.key} icon={item.icon}>
					{item.label}
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<Dropdown overlay={menu}>
			<Button style={{ border: "none", background: "transparent", color: "black" }}>⋮</Button>
		</Dropdown>
	);
};
export default DropdownMenu