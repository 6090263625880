import { Button, Form, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import SmsValidate from "./smsValidate";
import { postLogin } from "../../redux/actions/login.action";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { createBrowserHistory } from "history";
import "antd/dist/antd.css";
import { Spin } from "antd";

export const history = createBrowserHistory({
	forceRefresh: true,
});

export default function Login({}) {
	const dispatch = useDispatch();
	const { login } = useSelector((state) => {
		return state;
	});
	const [phone, setPhone] = useState();
	const [userId, setUserId] = useState("");
	const [flag, setFlag] = useState("");
	const [errorCheck, setErrorCheck] = useState("");

	const onFinish = () => {
		dispatch(postLogin(phone)).then((res) => {
			if (res?.payload?.success) {
				setFlag(true);
				setUserId(res?.payload?.data?.user_id);
			} else {
				setErrorCheck(res?.payload?.data?.message);
			}
		});
	};

	const allowedCountries = ["US", "PK"];

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<>
			{flag ? (
				<SmsValidate phone={phone} userId={userId} />
			) : (
				<>
					<div className="login-signup-ift">
						<Form
							name="basic"
							labelCol={{
								span: 8,
							}}
							wrapperCol={{
								span: 24,
							}}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete="off"
						>
							<h2>Sign In</h2>
							<p className="text-danger">{errorCheck}</p>

							<Form.Item
								name="phone"
								rules={[
									{
										required: true,
										message: "Please input your phone number!",
									},
								]}
							>
								<PhoneInput limitMaxLength={14} countries={allowedCountries} autoFocus className="flag-country " international countryCallingCodeEditable={false} defaultCountry="US" value={phone} onChange={setPhone} />
							</Form.Item>

							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								{login?.loading ? (
									<Button type="primary" size="large" block loading>
										<Spin />
									</Button>
								) : (
									<Button type="primary" htmlType="submit" size="large" block>
										Submit
									</Button>
								)}
							</Form.Item>
						</Form>
					</div>
				</>
			)}
		</>
	);
}
