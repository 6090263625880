import moment from "moment";
import React from "react";
import { NavLink } from "react-router-dom";
import avatarIcon from "../../images/avatar.png";
import { useSelector } from "react-redux";
import { dateInDays } from "../../helper/date";
import queryString from "query-string";
import ActionDropDown from "../../components/action/actionDropdown";

export default function Item({ category, department, item, name, origin }) {
	const queryParams = queryString.parse(window.location.search);
	const { account, bioShopMenu } = useSelector((state) => {
		return state;
	});
	let userInfo = localStorage.getItem("userInfo");
	if (userInfo !== "undefined") {
		userInfo = JSON.parse(localStorage.getItem("userInfo"));
	} else {
		localStorage.removeItem("userInfo");
		window.location.reload();
	}

	switch (name) {
		case "feeds":
			return (
				<div className="event-col-box">
					<NavLink to={`/reviews/${item._id}/${item.brand?._id}?page=${item?.page}&origin=${origin}&brand=${origin !== "feeds" ? item?.brand?._id : ""}&department=${department}&category=${category}&username${queryParams.sortBy ? `&sortBy=${queryParams.sortBy}` : ``}`} className="live-vid-link">
						<div className="vid-item">
							<img src={item?.banner} />
							<div className="vid-item-detail">
								<div className="vid-time">
									<div className="left-area">
										<span>{moment(item?.created_date).utc().format("MM-DD-YYYY")}</span>
									</div>

									{item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
								</div>
								<div className="vid-title">
									<span className="v-title">{item?.title}</span>
									<div className="author-name justify-content-between">
										<div className="auth-left">
											<span className="auth-image">
												{item?.influencer?.profile_image_url ? <img alt="" src={item?.influencer?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}
												<img alt="" src={item?.influencer?.profile_image_url} />
											</span>
											<span className="auth-title">{item?.influencer?.name}</span>
										</div>
										<div className="auth-right">
											{item?.influencer_id === account?.payload?.Data?._id && bioShopMenu?.payload?.message?.data?._id === item?.influencer_id && item?.brand?.fee_structure?.content_creator_fee ? item?.brand?.fee_structure?.content_creator_fee !== "0" && item?.brand?.fee_structure?.content_creator_fee !== "undefined" && item?.brand?.fee_structure?.content_creator_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.content_creator_fee}% Creator Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.referral_fee ? item?.brand?.fee_structure?.referral_fee !== "0" && item?.brand?.fee_structure?.referral_fee !== "undefined" && item?.brand?.fee_structure?.referral_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.referral_fee}% Referral Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.shopper_cashback ? item?.brand?.fee_structure?.shopper_cashback !== "0" && item?.brand?.fee_structure?.shopper_cashback !== "undefined" && item?.brand?.fee_structure?.shopper_cashback ? <span className="inf-fee">{item?.brand?.fee_structure?.shopper_cashback}% Cashback</span> : "" : ""}

										</div>
									</div>
								</div>
							</div>
						</div>
					</NavLink>
				</div>
			);
			break;

		case "saved":
			return (
				<div className="event-col-box">
					<NavLink to={`/reviews/${item?._id}/${item?.brand?._id}?page=${item?.page}&origin=${origin}&brand=${origin !== "feeds" ? item?.brand?._id : ""}&department=${department}&category=${category}&username${queryParams.sortBy ? `&sortBy=${queryParams.sortBy}` : ``}`} className="live-vid-link">
						<div className="vid-item">
							<img src={item?.banner} />
							<div className="vid-item-detail">
								<div className="vid-time">
									<span>{item?.created_date && moment(item?.created_date).utc().format("MM-DD-YYYY")}</span>
									<div className="left-area">
										<ActionDropDown type="saved" id={item?._id} />
									</div>
									{item?.discount !== "0%" && item?.discount ? <span className="off-percentage">{item?.discount} Off</span> : ""}
								</div>
								<div className="vid-title">
									<span className="v-title">{item?.title}</span>
									<div className="author-name justify-content-between">
										<div className="auth-left">
											<span className="auth-image">
												{item?.influencer?.profile_image_url ? <img alt="" src={item?.influencer?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}
												<img alt="" src={item?.influencer?.profile_image_url} />
											</span>
											<span className="auth-title">{item?.influencer?.name}</span>
										</div>
										<div className="auth-right">
											{item?.influencer_id === account?.payload?.Data?._id && bioShopMenu?.payload?.message?.data?._id === item?.influencer_id && item?.brand?.fee_structure?.content_creator_fee ? item?.brand?.fee_structure?.content_creator_fee !== "0" && item?.brand?.fee_structure?.content_creator_fee !== "undefined" && item?.brand?.fee_structure?.content_creator_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.content_creator_fee}% Creator Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.referral_fee ? item?.brand?.fee_structure?.referral_fee !== "0" && item?.brand?.fee_structure?.referral_fee !== "undefined" && item?.brand?.fee_structure?.referral_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.referral_fee}% Referral Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.shopper_cashback ? item?.brand?.fee_structure?.shopper_cashback !== "0" && item?.brand?.fee_structure?.shopper_cashback !== "undefined" && item?.brand?.fee_structure?.shopper_cashback ? <span className="inf-fee">{item?.brand?.fee_structure?.shopper_cashback}% Cashback</span> : "" : ""}
										</div>
									</div>
								</div>
							</div>
						</div>
					</NavLink>
				</div>
			);
			break;

		case "savedevents":
			return (
				<div className="event-col-box">
					<NavLink to={`/published-events/${item?._id}?page=${item?.page}&origin=${origin}&brand=${origin !== "recorded" ? item?.brand?._id : ""}&department=${department}&category=${category}&username${queryParams.sortBy ? `&sortBy=${queryParams.sortBy}` : ``}`} className="live-vid-link">
						<div className="vid-item">
							<img src={item?.banner} />
							<div className="vid-item-detail">
								<div className="vid-time">
									<span>{item?.created_at && moment(item?.created_at).utc().format("MM-DD-YYYY")}</span>
									<div className="left-area">
										<ActionDropDown type="saved-event" id={item?._id} />
									</div>
									{item?.discount !== "0%" && item?.discount ? <span className="off-percentage">{item?.discount} Off</span> : ""}
								</div>
								<div className="vid-title">
									<span className="v-title">{item?.title}</span>
									<div className="author-name justify-content-between">

										<div className="auth-left">
											<span className="auth-image">
												{item?.brand?.profile_image_url ? <img alt="" src={item?.brand?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}
											</span>
											<span className="auth-title">{item?.brand?.brand_name}</span>
										</div>
										<div className="auth-right">
											{item?.influencer_id === account?.payload?.Data?._id && bioShopMenu?.payload?.message?.data?._id === item?.influencer_id && item?.brand?.fee_structure?.content_creator_fee ? item?.brand?.fee_structure?.content_creator_fee !== "0" && item?.brand?.fee_structure?.content_creator_fee !== "undefined" && item?.brand?.fee_structure?.content_creator_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.content_creator_fee}% Creator Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.referral_fee ? item?.brand?.fee_structure?.referral_fee !== "0" && item?.brand?.fee_structure?.referral_fee !== "undefined" && item?.brand?.fee_structure?.referral_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.referral_fee}% Referral Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.shopper_cashback ? item?.brand?.fee_structure?.shopper_cashback !== "0" && item?.brand?.fee_structure?.shopper_cashback !== "undefined" && item?.brand?.fee_structure?.shopper_cashback ? <span className="inf-fee">{item?.brand?.fee_structure?.shopper_cashback}% Cashback</span> : "" : ""}
										</div>
									</div>
								</div>
							</div>
						</div>
					</NavLink>
				</div>
			);
			break;

		case "review":
			return (
				<div className="event-col-box">
					<NavLink to={`/reviews/${item._id}/${item.brand?._id}?page=${item?.page}&origin=${origin}&brand=${origin !== "review" ? item?.brand?._id : ""}&department=${department}&category=${category}&username${queryParams.sortBy ? `&sortBy=${queryParams.sortBy}` : ``}`} className="live-vid-link">
						<div className="vid-item">
							<img src={item?.banner} />
							<div className="vid-item-detail">
								<div className="vid-time">
									<div className="left-area">
										<span>{moment(item?.created_date).utc().format("MM-DD-YYYY")}</span>
									</div>

									{item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
								</div>
								<div className="vid-title">
									<span className="v-title">{item?.title}</span>
									<div className="author-name justify-content-between">
										<div className="auth-left">
											<span className="auth-image">
												{item?.influencer?.profile_image_url ? <img alt="" src={item?.influencer?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}
												<img alt="" src={item?.influencer?.profile_image_url} />
											</span>
											<span className="auth-title">{item?.influencer?.name}</span>
										</div>
										<div className="auth-right">
											{item?.influencer_id === account?.payload?.Data?._id && bioShopMenu?.payload?.message?.data?._id === item?.influencer_id && item?.brand?.fee_structure?.content_creator_fee ? item?.brand?.fee_structure?.content_creator_fee !== "0" && item?.brand?.fee_structure?.content_creator_fee !== "undefined" && item?.brand?.fee_structure?.content_creator_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.content_creator_fee}% Creator Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.referral_fee ? item?.brand?.fee_structure?.referral_fee !== "0" && item?.brand?.fee_structure?.referral_fee !== "undefined" && item?.brand?.fee_structure?.referral_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.referral_fee}% Referral Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.shopper_cashback ? item?.brand?.fee_structure?.shopper_cashback !== "0" && item?.brand?.fee_structure?.shopper_cashback !== "undefined" && item?.brand?.fee_structure?.shopper_cashback ? <span className="inf-fee">{item?.brand?.fee_structure?.shopper_cashback}% Cashback</span> : "" : ""}
										</div>
									</div>
								</div>
							</div>
						</div>
					</NavLink>
				</div>
			);
			break;
		case "share":
			return (
				<div className="event-col-box">
					<NavLink to={`/reviews/${item?._id}/${item?.brand?._id}/${bioShopMenu?.payload?.message?.data?._id}?origin=${origin}&page=${item?.page}&brand=${origin !== "review" ? item?.brand?._id : ""}&department=${department}&category=${category}&username${queryParams.sortBy ? `&sortBy=${queryParams.sortBy}` : ``}`} className="live-vid-link">
						<div className="vid-item">
							<img src={item?.banner} />
							<div className="vid-item-detail">
								<div className="vid-time">
									<span>{item?.created_date && moment(item?.created_date).utc().format("MM-DD-YYYY")}</span>
									{item?.discount !== "0%" && item?.discount ? <span className="off-percentage">{item?.discount} Off</span> : ""}

									{bioShopMenu?.payload?.message?.data?.pixel_id === account?.payload?.Data?.pixel_id && (
										<div className="left-area">
											<ActionDropDown type="share" id={item?._id} />
										</div>
									)}
								</div>

								<div className="vid-title">
									<span className="v-title">{item?.title}</span>

									<div className="author-name justify-content-between">
										<div className="auth-left">
											<span className="auth-image">
												{item?.influencer?.profile_image_url ? <img alt="" src={item?.influencer?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}
												<img alt="" src={item?.influencer?.profile_image_url} />
											</span>
											<span className="auth-title">{item?.influencer?.name}</span>
										</div>
										<div className="auth-right">
											{/* {item?.influencer_id === account?.payload?.Data?._id && account?.payload?.Data?.account_type !== "customer" ? <>{item?.influencer_percent ? <span className="inf-fee">{item?.influencer_percent}% Influencer Fee</span> : <></>}</> : item?.referral_percent && item.referral_percent !== "0" && item.referral_percent !== "undefined" ? <>{item?.referral_percent ? <span className="ref-fee">{item?.referral_percent}% Referral Fee</span> : <></>}</> : ""} */}
											{item?.influencer_id === account?.payload?.Data?._id || (bioShopMenu?.payload?.message?.data?._id === item?.influencer_id && item?.brand?.fee_structure?.content_creator_fee) ? item?.brand?.fee_structure?.content_creator_fee !== "0" && item?.brand?.fee_structure?.content_creator_fee !== "undefined" && item?.brand?.fee_structure?.content_creator_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.content_creator_fee}% Creator Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.referral_fee ? item?.brand?.fee_structure?.referral_fee !== "0" && item?.brand?.fee_structure?.referral_fee !== "undefined" && item?.brand?.fee_structure?.referral_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.referral_fee}% Referral Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.shopper_cashback ? item?.brand?.fee_structure?.shopper_cashback !== "0" && item?.brand?.fee_structure?.shopper_cashback !== "undefined" && item?.brand?.fee_structure?.shopper_cashback ? <span className="inf-fee">{item?.brand?.fee_structure?.shopper_cashback}% Cashback</span> : "" : ""}
											{/* 
                      {account?.payload?.Data?.account_type !== "customer" ? item?.influencer_percent !== "0" && item.influencer_percent !== "undefined" && item.influencer_percent ? <span className="inf-fee">{item?.influencer_percent}% Influencer Fee</span> : "" : ""}

                      {account?.payload?.Data?.account_type !== "influencer" ? item?.referral_percent !== "0" && item.referral_percent !== "undefined" && item.referral_percent ? <span className="ref-fee">{item?.referral_percent}% Referral Fee</span> : "" : ""} */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</NavLink>
				</div>
			);
			break;
		case "upcoming":
			return (
				<div className="event-col-box">
					<NavLink to={`/upcoming-event/${item?._id}/${item.instagram_username ? item.instagram_username : item.pixel_id}?origin=${origin}&department=${department}&category=${category}${queryParams.sortBy ? `&sortBy=${queryParams.sortBy}` : ``} `} className="live-vid-link">
						<div className={`vid-item ${item?.default_banner ? "default-image" : ""}`}>
							<img alt="" src={item?.banner} />
							<div className="vid-item-detail">
								<div className="vid-time">
									<span>in {dateInDays(item?.start_date)}</span>
									{item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
								</div>
								<div className="vid-title">
									<span className="v-title">{item?.title}</span>
									<div className="author-name justify-content-between">
										<div className="auth-left">
											<span className="auth-image">{item?.brand_profile ? <img alt="" src={item?.brand_profile} /> : <img className="img-fluid" src={avatarIcon} />}</span>
											<span className="auth-title">{item?.brand_name}</span>
										</div>
										<div className="auth-right">
											{/* 

											{/* {item?.influencer_id === account?.payload?.Data?._id ? <span className="inf-fee">{item?.fee_structure?.content_creator_fee}% Creator Fee</span> : ""} */}
											{item?.fee_structure?.referral_fee ? item?.fee_structure?.referral_fee !== "0" && item?.fee_structure?.referral_fee !== "undefined" && item?.fee_structure?.referral_fee ? <span className="inf-fee">{item?.fee_structure?.referral_fee}% Referral Fee</span> : "" : ""}
											{item?.fee_structure?.shopper_cashback ? item?.fee_structure?.shopper_cashback !== "0" && item?.fee_structure?.shopper_cashback !== "undefined" && item?.fee_structure?.shopper_cashback ? <span className="inf-fee">{item?.fee_structure?.shopper_cashback}% Cashback</span> : "" : ""}{" "}
										</div>
									</div>
								</div>
							</div>
						</div>
					</NavLink>
				</div>
			);
			break;
		case "live":
			return (
				<div className="event-col-box">
					<NavLink to={`/live-event/${item._id}/${item.instagram_username ? item.instagram_username : item.pixel_id}?origin=${origin}&username${queryParams.sortBy ? `&sortBy=${queryParams.sortBy}` : ``}`} className="live-vid-link">
						<div className={`vid-item ${item.default_banner ? "default-image" : ""} `}>
							<img alt="" src={item?.banner} />
							<div className="vid-item-detail">
								<div className="vid-time">
									<span className="live-now">Live Now</span>
									{item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
								</div>
								<div className="vid-title">
									<span className="v-title">{item?.title}</span>
									<div className="author-name justify-content-between">
										<div className="auth-left">
											<span className="auth-image">{item?.brand_profile ? <img alt="" src={item?.brand_profile} /> : <img className="img-fluid" src={avatarIcon} />}</span>
											<span className="auth-title">{item?.brand_name}</span>
										</div>
										<div className="auth-right">
											{/* {account?.payload?.Data?.account_type !== "customer" ? item?.influencer_percent !== "0" && item.influencer_percent !== "undefined" && item.influencer_percent ? <span className="inf-fee">{item?.influencer_percent}% Influencer Fee</span> : "" : ""} */}
											{/* {item?.referral_percent !== "0" && item.referral_percent !== "undefined" && item.referral_percent ? <span className="ref-fee">{item?.referral_percent}% Referral Fee</span> : ""} */}

											{item?.influencer_id === account?.payload?.Data?._id && item?.brand?.fee_structure?.content_creator_fee ? item?.brand?.fee_structure?.content_creator_fee !== "0" && item?.brand?.fee_structure?.content_creator_fee !== "undefined" && item?.brand?.fee_structure?.content_creator_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.content_creator_fee}% Creator Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.referral_fee ? item?.brand?.fee_structure?.referral_fee !== "0" && item?.brand?.fee_structure?.referral_fee !== "undefined" && item?.brand?.fee_structure?.referral_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.referral_fee}% Referral Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.shopper_cashback ? item?.brand?.fee_structure?.shopper_cashback !== "0" && item?.brand?.fee_structure?.shopper_cashback !== "undefined" && item?.brand?.fee_structure?.shopper_cashback ? <span className="inf-fee">{item?.brand?.fee_structure?.shopper_cashback}% Cashback</span> : "" : ""}
										</div>
									</div>
								</div>
							</div>
						</div>
					</NavLink>
				</div>
			);
			break;
		case "reviewInfluencer":
			return (
				<div className="event-col-box">
					<NavLink to={`/reviews/${item._id}/${item.brand?._id}/${item?.influencer_id}?origin=${origin}&department=${department}&category=${category}&brand=${origin !== "review" ? item?.brand?._id : ""}&username${queryParams.sortBy ? `&sortBy=${queryParams.sortBy}` : ``}`} className="live-vid-link">
						<div className="vid-item">
							<img src={item?.banner} />
							<div className="vid-item-detail">
								<div className="vid-time">
									<span>{moment(item?.created_date).utc().format("MM-DD-YYYY")}</span>
									{item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
									{bioShopMenu?.payload?.message?.data?.pixel_id === account?.payload?.Data?.pixel_id && (
										<div className="left-area">
											<ActionDropDown type="reviewInfluencer" id={item?._id} />
										</div>
									)}
								</div>
								<div className="vid-title">
									<span className="v-title">{item?.title}</span>
									<div className="author-name justify-content-between">
										<div className="auth-left">
											<span className="auth-image">
												{item?.influencer?.profile_image_url ? <img alt="" src={item?.influencer?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}
												<img alt="" src={item?.influencer?.profile_image_url} />
											</span>
											<span className="auth-title">{item?.influencer?.name}</span>
										</div>
										<div className="auth-right">


											{item?.influencer_id === account?.payload?.Data?._id || item?.brand?.fee_structure?.content_creator_fee ? item?.brand?.fee_structure?.content_creator_fee !== "0" && item?.brand?.fee_structure?.content_creator_fee !== "undefined" && item?.brand?.fee_structure?.content_creator_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.content_creator_fee}% Creator Fee</span> : "" : ""}

											{item?.brand?.fee_structure?.referral_fee ? item?.brand?.fee_structure?.referral_fee !== "0" && item?.brand?.fee_structure?.referral_fee !== "undefined" && item?.brand?.fee_structure?.referral_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.referral_fee}% Referral Fee</span> : "" : ""}

											{item?.brand?.fee_structure?.shopper_cashback ? item?.brand?.fee_structure?.shopper_cashback !== "0" && item?.brand?.fee_structure?.shopper_cashback !== "undefined" && item?.brand?.fee_structure?.shopper_cashback ? <span className="inf-fee">{item?.brand?.fee_structure?.shopper_cashback}% Cashback</span> : "" : ""}

										</div>
									</div>
								</div>
							</div>
						</div>
					</NavLink>
				</div>
			);
			break;
		case "recorded":
			return (
				<div className="event-col-box">
					<NavLink to={`/published-events/${item?._id}?page=${item?.page}&origin=${origin}&brand=${origin !== "recorded" ? item?.brand?._id : ""}&department=${department}&category=${category}&username${queryParams.sortBy ? `&sortBy=${queryParams.sortBy}` : ``}`} className="live-vid-link">
						<div className={`vid-item ${item?.default_banner ? "default-image" : ""}`}>
							<img alt="" src={item?.banner} />
							<div className="vid-item-detail">
								<div className="vid-time">
									<span>{moment(item?.start_date).utc().format("MM-DD-YYYY")}</span>
									{item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
								</div>
								<div className="vid-title">
									<span className="v-title">{item?.title}</span>
									<div className="author-name justify-content-between">
										<div className="auth-left">
											<span className="auth-image">{item?.brand_profile ? <img alt="" src={item?.brand_profile} /> : <img className="img-fluid" src={avatarIcon} />}</span>
											<span className="auth-title">{item?.brand_name}</span>
										</div>
										<div className="auth-right">
											{account?.payload?.Data?._id && item?.influencer_id === account?.payload?.Data?._id && bioShopMenu?.payload?.message?.data?._id === item?.influencer_id ? item?.brand?.fee_structure?.content_creator_fee !== "0" && item?.brand?.fee_structure?.content_creator_fee !== "undefined" && item?.brand?.fee_structure?.content_creator_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.content_creator_fee}% Creator Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.referral_fee ? item?.brand?.fee_structure?.referral_fee !== "0" && item?.brand?.fee_structure?.referral_fee !== "undefined" && item?.brand?.fee_structure?.referral_fee ? <span className="inf-fee">{item?.brand?.fee_structure?.referral_fee}% Referral Fee</span> : "" : ""}
											{item?.brand?.fee_structure?.shopper_cashback ? item?.brand?.fee_structure?.shopper_cashback !== "0" && item?.brand?.fee_structure?.shopper_cashback !== "undefined" && item?.brand?.fee_structure?.shopper_cashback ? <span className="inf-fee">{item?.brand?.fee_structure?.shopper_cashback}% Cashback</span> : "" : ""}

										</div>
									</div>
								</div>
							</div>
						</div>
					</NavLink>
				</div>
			);
			break;
	}
}
