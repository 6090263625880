import { useState,useEffect } from "react";
import Layout from "./component/layoutStreaming";
import Reviews from "./component/reviews";
// import RegisterMbl from "../../pages/auth/registermbl";
export default ({name}) => {
	const [filterPost, setFilterPost] = useState({
		categoryId: [],
		subCategoryId: "",
		sortBy: "",
		eventType: "",
		applyFilter: "all",
	});

	// const [isMobile, setIsMobile] = useState(false);
	// const [signUp, setSignUp] = useState(false);
	

	// useEffect(() => {
	// 	setIsMobile(/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
	// }, []);
	return (
		<>
			<Layout source='bioshop-review' filterPost={filterPost} setFilterPost={setFilterPost}>
				<Reviews setFilterPost={setFilterPost} filterPost={filterPost}  name={name}  />
			</Layout>
			{/* {isMobile ? signUp ? <Mobile /> : <RegisterMbl setSignUp={setSignUp} /> : ""} */}

		</>
	);
};
