import { GET_FOLLOWERS_REQUEST, GET_FOLLOWERS_SUCCESS, GET_FOLLOWERS_ERROR, CREATE_FOLLOWERS_REQUEST, CREATE_FOLLOWERS_SUCCESS, CREATE_FOLLOWERS_ERROR, FOLLOWERS_STATUS_REQUEST, FOLLOWERS_STATUS_SUCCESS, FOLLOWERS_STATUS_ERROR, GET_UNFOLLOW_REQUEST, GET_UNFOLLOW_SUCCESS, GET_UNFOLLOW_ERROR, GET_FOLLOWING_REQUEST, GET_FOLLOWING_SUCCESS, GET_FOLLOWING_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getFollowers =
	(user_id, follower_id, page = 1, limit = 10) =>
	(dispatch) => {
		dispatch({ type: GET_FOLLOWERS_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}public/follower/getAll?page=${page}&limit=${limit}`,
			data: { user_id, follower_id },
			headers: {
				Accept: "application/json",
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_FOLLOWERS_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_FOLLOWERS_ERROR,
					payload: error.response,
				});
			});
	};

export const makeFollowers = (user_id, follower_id) => (dispatch) => {
	dispatch({ type: CREATE_FOLLOWERS_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}public/follower/createFollower`,
		data: {
			user_id: user_id,
			follower_id: follower_id,
		},
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: CREATE_FOLLOWERS_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: CREATE_FOLLOWERS_ERROR,
				payload: error.response,
			});
		});
};

export const statusFollowers = (user_id, follower_id) => (dispatch) => {
	dispatch({ type: FOLLOWERS_STATUS_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}public/follower/status`,
		data: {
			user_id: user_id,
			follower_id: follower_id,
		},
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: FOLLOWERS_STATUS_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: FOLLOWERS_STATUS_ERROR,
				payload: error.response,
			});
		});
};

export const unfollow = (user_id, follower_id) => (dispatch) => {
	dispatch({ type: GET_UNFOLLOW_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}public/follower/unfollow`,
		data: {
			user_id: user_id,
			follower_id: follower_id,
		},
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_UNFOLLOW_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_UNFOLLOW_ERROR,
				payload: error.response,
			});
		});
};

export const followingUser = (follower_id, user_id,page=1,limit=10) => (dispatch) => {
	dispatch({ type: GET_FOLLOWING_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}public/follower/following?page=${page}&limit=${limit}`,
		data: {
			follower_id,
			user_id,
		},
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_FOLLOWING_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_FOLLOWING_ERROR,
				payload: error.response,
			});
		});
};
