import React, { useState, useRef, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tabs } from "antd";
import {
  SearchOutlined,
  CloseOutlined,
  AppleOutlined,
  AndroidOutlined,
  WindowsOutlined,
} from "@ant-design/icons";
import {  faPeopleGroup} from "@fortawesome/free-solid-svg-icons";

// Redux actions
import { getInfluencers } from "../../redux/actions/becomeInfluencer.action";
import { getAllBrand } from "../../redux/actions/brand.action";
import { getProductList } from "../../redux/actions/product.action";
import { getAllPublishedEvent } from "../../redux/actions/liveShopping.action";

import { doSearch } from "../../redux/actions/search.action";
// Helper functions
import outSideClick from "../../helper/outSideClick";
// Component imports
// import Products from "./list/product";
import Reviews from "./list/reviews";
import Brands from "./list/brands";
import Shows from "./list/shows";
// Styles
import "../../css/search.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import querypara
const { TabPane } = Tabs;

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export default function Search() {
  const divRef = useRef();
  const [displaySearch, setDisplaySearch] = useState(false);
  const [searchByTitle, setSearchByTitle] = useState("");

  // State to track if API has been called for a tab
  const [apiCalledForTab, setApiCalledForTab] = useState({
    1: false,
    2: false,
    3: true,
  });
  // State to track the last search title for each tab
  const [lastSearchTitles, setLastSearchTitles] = useState({
    1: "",
    2: "",
    3: "",
  });

  const [activeKey, setActiveKey] = useState("3");
  const [debouncedValue, setDebouncedValue] = useState("");
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  useEffect(() => {
    if (displaySearch) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [displaySearch]);

  const location = useLocation(); // This hook gives you the current location object

  // Determine the active key based on the current path
  useEffect(() => {
    switch (location.pathname) {
      case "/brands":
        setActiveKey("3");
        break;
      case "/influencers":
        setActiveKey("2");
        break;
      case "/recorded":
        setActiveKey("4");
        break;
      // Add more cases if needed
      default:
        // No default action; keep the current active key or set it to some default value
        break;
    }
  }, [location]);

  const handler = useCallback(() => {
    setDisplaySearch(false);
  }, []);

  outSideClick(divRef, handler);
  // Debounce function

  // Debounced change handler
  const debouncedSearchHandler = useCallback(
    debounce((value) => {
      setDebouncedValue(value);
      // Here, you can add the logic that should run after the user stops typing
    }, 1000),
    []
  );

  useEffect(() => {
    fetchContextSpecificSuggestions(activeKey, searchByTitle);
  }, [debouncedValue]);
  useEffect(() => {
    if (displaySearch) {
      inputRef.current.focus();
    }
  }, [displaySearch]);

  async function fetchContextSpecificSuggestions(key, query) {
    try {
      switch (key) {
        case "1":
          dispatch(getProductList(1, 6, query));
          break;
        case "2":
          dispatch(getInfluencers(1, 6, "", "", "", undefined, null, query));
          break;
        case "3":
          dispatch(getAllBrand(1, 6, query, ""));
          break;

        case "4":
          dispatch(
            getAllPublishedEvent(
              1,
              12,
              "",
              "",
              "",
              undefined,
              "",
              query,
              false,
              userInfo?._id
            )
          );
          break;

        default:
        // Optional: handle other cases or do nothing
      }
    } catch (error) {
      console.error("Error while retrieving suggestions:", error);
    }
  }

  const onChangeTitle = (event) => {
    setDisplaySearch(true);

    if (event.key === "Enter" || event.keyCode === 13) {
      alert("");
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setDisplaySearch(false);
    dispatch(doSearch(searchByTitle));
  };

  const handleTabChange = (key) => {
    setActiveKey(key);

    const hasApiBeenCalled = apiCalledForTab[key];
    const hasSearchTitleChanged = searchByTitle !== lastSearchTitles[key];

    if (!hasApiBeenCalled || hasSearchTitleChanged) {
      // Update the states
      setApiCalledForTab((prev) => ({ ...prev, [key]: true }));
      setLastSearchTitles((prevTitles) => ({
        ...prevTitles,
        [key]: searchByTitle,
      }));

      // API call logic
      fetchContextSpecificSuggestions(key, searchByTitle);
    }
  };

  return (
    <>
      {displaySearch && <div className="search-backdrop"></div>}
      <div
        ref={divRef}
        className="p-h-middle input-group col-sm-8 col-lg-5 col-xl-6 search-dropdown-main"
      >
        {displaySearch && (
          <div className="search-dropdown-wrapper">
            <Tabs
              className="search-tabs"
              defaultActiveKey={"3"}
              activeKey={activeKey}
              onChange={handleTabChange}
            >
              <TabPane
                tab={
                  <span className="tab-box">
                    <i className="fa fa-tags"></i>
                    Brands
                  </span>
                }
                key="3"
              >
                <Brands setDisplaySearch={setDisplaySearch} />
              </TabPane>
              <TabPane
                tab={
                  <span className="tab-box">
                    <FontAwesomeIcon icon={faPeopleGroup} /> User Shop
                  </span>
                }
                key="2"
              >
                <Reviews setDisplaySearch={setDisplaySearch} />
              </TabPane>
              <TabPane
                tab={
                  <span className="tab-box">
                    <i className="fa fa-tv"></i> Shows
                  </span>
                }
                key="4"
              >
                <Shows setDisplaySearch={setDisplaySearch} />
              </TabPane>
            </Tabs>
          </div>
        )}
        <input
          className="form-control search-field  py-2"
          ref={inputRef}
          onFocus={() => {
            setDisplaySearch(true);
          }}
          value={searchByTitle}
          onChange={(e) => {
            onChangeTitle(e.target.value);
            debouncedSearchHandler(e.target.value);
            if (e.target.value === "") dispatch(doSearch(e.target.value));
            setSearchByTitle(e.target.value);
          }}
          type="search"
          // placeholder="Products - Reviews - Brands"
          placeholder=""
          id="example-search-input"
        />

        {displaySearch && (
          <span className="input-group-append">
            <button
              onClick={() => {
                setDisplaySearch(false);
                dispatch(doSearch(searchByTitle));
              }}
              className="btn btn-outline-secondary"
              type="button"
            >
              Search
            </button>
          </span>
        )}

        {searchByTitle.length > 0 && (
          <button
            onClick={() => {
              setSearchByTitle("");
              setDebouncedValue("");
            }}
            className="search-cross-button"
          >
            <CloseOutlined />
          </button>
        )}
        <SearchOutlined className="search-zoom-icon" />
        {!displaySearch && !searchByTitle && (
          <div
            onClick={() => {
              setDisplaySearch(true);
            }}
            className="multi-line-search-text"
          >
            <div className="placeholder-text1">Looking for?</div>
            <div className="placeholder-text2">Brands - User Shop - Shows</div>
          </div>
        )}
      </div>
    </>
  );
}
