import Layout from "./component/layout";
import ReviewSale from "./component/reviewsale";

export default () => {
	return (
		<>
			<Layout title="Creator Sales">
				<ReviewSale />
			</Layout>
		</>
	);
};
