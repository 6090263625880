import { Link } from "react-router-dom";

export default () => {
	return (
		<>
			<div>

				<h3>Privacy Policy</h3>
				<p>(Last Updated: 10.25.2023)</p>

				<h5>Introduction</h5>
				<p>At ORME LIVE Inc, (<strong>"ORMELIVE", "ORME"</strong>) we appreciate the trust you place in us when you choose to visit our Site <Link to="/"> https://ormelive.com </Link> (the "<strong> Site</strong>"), and the mobile application (the "<strong> App</strong>"), use the services we offer on our Site (the "<strong> Services</strong>"), including uploading of videos, text, images, or other materials (collectively "<strong>Content</strong>"), and purchase products (“Products”) through the Services.  We want you to know we take your privacy and protection of personal data very seriously. We are providing this Privacy Policy (the “Policy”) to tell you about who we are, what personal data we collect from you and about you, and what we do with your personal data, all while you use the Site or otherwise interact with us. The Policy also explains your rights under the law, and how you can contact us and the necessary authorities to enforce those rights. We ask that you please read it carefully. </p>
				<p>In this Policy, “we” and “our” means ORMELIVE and “you” means any person who visits ourSite or emails us.  For the avoidance of doubt, all references to the “Site” in this Policy also includes any Services offered through the Site.</p>
				<p>Click on the following links to go directly to the corresponding sections of this Policy:</p>
				<h5>Collecting your Personal Data</h5>
				<p>We collect different types of personal data from you for a number of different reasons as described in the chart below. </p>
				<div className="table-responsive table-simple pt-0">
					<table className="table table-bordered">
						<thead>
							<tr>
								<th>Category of personal data</th>
								<th>What we use it for (the “purpose” of processing)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Contact information, including your name, email address, and phone number</td>
								<td>To communicate with you</td>
							</tr>
							<tr>
								<td>Account information, including your name email address, and business name and address</td>
								<td>To create an account for you, to communicate with you, and to provide you with certain Services (creating and publishing Videos)</td>
							</tr>
							<tr>
								<td>Video chat information, including any personal data you submit during a Video via our “Chat” function</td>
								<td>Transfer it to the Merchant running the Video</td>
							</tr>
							<tr>
								<td>Information, including any personal data you submit via our “Contact Us” function on our Site</td>
								<td>Respond to your inquiry</td>
							</tr>
							<tr>
								<td>Billing information, including your credit card holder name, number, expiration date, CVV number, and billing address</td>
								<td>To allow you to pay for the Fees for use of Services, if paying Fees online</td>
							</tr>
							<tr>
								<td>Analytics information, including analytics identifiers</td>
								<td>Transfer it to the Merchant publishing the Video</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>Sometimes we collect personal data about you from third parties as described in the below chart. </p>
				<div className="table-responsive table-simple pt-0">
					<table className="table table-bordered">
						<thead>
							<tr>
								<th>Category of Personal data</th>
								<th>Who we get the data from</th>
								<th>What we use it for (the “purpose” of processing)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Account Information, when you sign up via an E-Commerce Platform, such as your name, email address, and business name and address</td>
								<td>The E-Commerce Platform through which you are accessing the Services</td>
								<td>To offer you the Services</td>
							</tr>
						</tbody>
					</table>
				</div>
				<h5>A Note about “Sensitive” Personal Data</h5>
				<p>We do not collect any “Sensitive Personal data” as defined under the General Data Protection Regulation (“GDPR”), unless you voluntarily submit it through the chat function during any Video or on the Site, which we discourage.</p>
				<h5>Sharing your Information</h5>
				<p>We will not sell your personal data to any third party for money. We may share your personal data under certain circumstances to trusted third parties that help us bring you our Services. Please see the chart below for more information about our sharing practices. </p>
				<p>Some of our trusted third-parties may be based outside your home jurisdiction. If you are in the European Economic Area — please see the “Users outside of the United States” section in this Policy for more information including on how we safeguard your personal data when this occurs.</p>
				<div className="table-responsive table-simple pt-0">
					<table className="table table-bordered">
						<thead>
							<tr>
								<th>Personal data category</th>
								<th>Who we transfer it to</th>
								<th>What they do with it</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Contact information</td>
								<td>Companies that provide email/SMS services, such as Trello and SendGrid</td>
								<td>Send you emails/SMS</td>
							</tr>
							<tr>
								<td>Account information (including contact information)</td>
								<td>Companies providing technical infrastructure for the Services, such as Microsoft Azure, AWS, and Google Cloud Platform</td>
								<td>Control your logging in to the Services so they can be provided to you, and record-keeping</td>
							</tr>
							<tr>
								<td>Billing information</td>
								<td>Payment processing companies, such as Stripe and PayPal </td>
								<td>Process your payments for the fees you pay for the Services, when paying online</td>
							</tr>
							<tr>
								<td>Video chat information</td>
								<td>The Merchant you are interacting with in any Video</td>
								<td>Respond to your queries and chat with you</td>
							</tr>
							<tr>
								<td>Advertising identifiers</td>
								<td>Companies that provide online advertising networks, such as Google and Facebook, and as further detailed in the Advertising section below</td>
								<td>Show you ads for ORMELIVE and the Services when you are on the internet</td>
							</tr>
							<tr>
								<td>Analytics identifiers</td>
								<td className="word-wrap">Companies that provide data analytics, such as
									<a className="highlight-txt d-inline-block" href="https://www.google.com/analytics/" target="_blank">Google Analytics</a></td>
								<td>Provide us with analytics as to how the Services are used, both on the Website and during Videos, and to trace fraudulent activities</td>
							</tr>
							<tr>
								<td>ORMELIVE analytics identifiers</td>
								<td>The Merchant publishing the Video</td>
								<td>Analysis as to how the Video performed</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>We require our vendors and service providers to maintain the confidentiality of all information that we share with them and to use such information only in accordance with the obligations set forth in the agreements we have with them.</p>
				<p>In addition, we may disclose your personal data to law enforcement agencies, government/regulatory bodies, and content protection organizations to: </p>
				<ul className="list-simple list-style-none">
					<li>protect our legal rights, privacy or safety, and that of our subsidiaries, affiliates, employees, agents, contractors, or other individuals;</li>
					<li>protect the safety and security of visitors to our online services or other properties;</li>
					<li>protect against fraud or other illegal activity or for risk management purposes; or </li>
					<li>comply with the law including with subpoenas, search warrants, court orders, and other legal process.</li>
				</ul>
				<h5>Tracking Technologies (“cookies”)</h5>
				<p>Like most websites, we use tracking technologies, such as “cookies”, tags, pixels and web beacons.  Cookies are small text files placed on your computer or device when you visit a website or use an online service, in order to track use of the site or service and to improve the user experience by storing certain data on your computer or device.  Specifically, we use cookies and related technologies for the following functions:</p>
				<ul className="list-simple">
					<li>To enable your account sign-in</li>
					<li>functioning of the Videos;</li>
					<li>to provide general internal and user analytics during Videos and on the Site and to conduct research to improve the content of the Site using analytics programs;</li>
					<li>To facilitate online advertising; and</li>
					<li>To assist in identifying possible fraudulent activities.</li>
				</ul>
				<p>If for any reason you do not wish to take advantage of cookies, you can set your browser to refuse cookies or delete them after they have been stored, although this will disable some essential features our Site.  </p>
				<h5>To Unsubscribe</h5>
				<p>To unsubscribe from our marketing emails, please email us at <a className="highlight-txt" href="mailto:support@ormelive.com" target="_blank">support@ormelive.com</a>, or simply click the unsubscribe button found at the bottom of any of the emails that we send you.  Please allow us at least ten (10) business days from when the request was received to complete your unsubscribe request.</p>
				<h5>Online Advertising and Analytics</h5>
				<p>We work with third-party network advertisers, analytics service providers, and other vendors to deliver our online advertisements to you and to provide us with information regarding your use of our Site and the effectiveness of our advertisements.   </p>
				<p>Our third-party service providers may set and access their own tracking technologies on your device, such as cookies and web beacons, and may collect or access information about you, such as your device's IP address, your browser type, your operating system, date and time information, such as when you receive, open, or click a link in an email message from us, and other technical information about your device.   Cookies and web beacons, including those set by third-party advertisers, may be used to target advertisements, prevent you from seeing the same advertisements too many times, and conduct research regarding the usefulness of certain advertisements to you. We may share certain information such as device identifiers, usage patterns, transaction information, and de-identified information with third-party vendors for advertising and analytics purposes. </p>
				<p>Specifically, ORMELIVE uses the remarketing features of interest-based advertising of Google AdWords, Facebook Custom Audience, LinkedIn Ad, and Instagram Ads.  These service providers use specific cookies to allow them to serve ads to you around the web.  For more information on how to opt-out of receiving advertisements, please see the chart below.</p>

				<div className="table-responsive table-simple">
					<table className="table table-bordered">
						<thead>
							<tr>
								<th>Third-Party Advertiser</th>
								<th>How to opt-out</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Google AdWords</td>
								<td>You can opt-out by deleting the appropriate Google cookie through your browser, though this may not be permanent.  Alternatively, you can
									<a className="highlight-txt" href="https://adssettings.google.com/" target="_blank"> adjust your Google ad settings</a> or use the <a className="highlight-txt" href="http://optout.aboutads.info/" target="_blank">WebChoices online opt-out tool</a>.</td>
							</tr>
							<tr>
								<td>Facebook Custom Audience</td>
								<td>You can opt-out by <a className="highlight-txt" href="https://www.facebook.com/ads/preferences" target="_blank">adjusting your Ad Preferences settings</a> while logged in to Facebook.</td>
							</tr>
							<tr>
								<td>LinkedIn Ad</td>
								<td>You can opt-out by <a className="highlight-txt" href="https://www.linkedin.com/help/linkedin/answer/90274/manage-your-linkedin-ads-settings" target="_blank">following LinkedIn's instructions</a> or using the <a className="highlight-txt" href="http://optout.aboutads.info/" target="_blank">WebChoices online opt-out tool</a></td>
							</tr>
							<tr>
								<td>Instagram Ads</td>
								<td>You can block or opt out of Instagram Ads by <a className="highlight-txt" href="https://help.instagram.com/615366948510230" target="_blank">following Instagram's instructions</a></td>
							</tr>
						</tbody>
					</table>
				</div>
				<h5>Do Not Track Signals</h5>

				<p>When a user turns on the Do Not Track preference in their web browser, the browser sends a message to Sites requesting them not to track the user. At this time, we do not respond to Do Not Track browser settings or signals. For information about Do Not Track, please visit: <a className="highlight-txt" href="http://www.allaboutdnt.org/" target="_blank">www.allaboutdnt.org</a>.</p>
				<h5>Security</h5>
				<p>We implement a variety of commercially reasonable security measures to maintain the safety of your personal data.  We take appropriate physical, electronic and administrative steps to maintain the security of personal data we collect.  Unfortunately, however, no data transmission over the Internet can be guaranteed to be 100% secure.  Accordingly, while we use commercially reasonable means to protect your personal data, we cannot guarantee or warrant the security of any information you transmit to us.</p>
				<h5>Sale or Transfer of All or Part Of our Business or Assets</h5>
				<p>Your personal data may be transferred in the event we sell or transfer all or a part of our business or assets.  We will make commercially reasonable efforts to provide you with reasonable notice of such transfer, or as otherwise required by law. </p>
				<h5>Third Party Links</h5>
				<p>Our Site may include links to third party websites whose privacy practices may differ from ours. If you choose to purchase products or services through a third-party site, or otherwise access a third-party site, the information collected by the third party is governed by that third party’s privacy policy.  We encourage you to review the privacy policies of any other websites you visit.</p>
				<h5>Children's Privacy</h5>
				<p>Our Site is not intended for children. ORMELIVE does not knowingly collect any Personal data or data from children under the age of thirteen, and will dispose of any such information if we become aware that it has been provided to us.  If you believe that your child under the age of 13 has submitted personal data to us in connection with our Site, please contact us at <a className="highlight-txt" href="mailto:support@ormelive.com" target="_blank">support@ormelive.com</a> so that we may take steps to delete this information. </p>
				<h5>Changes to This Policy</h5>
				<p>We may amend this Policy from time to time. If we change our Policy, we will post the updated Policy on this page with an updated date. Under certain circumstances, we may notify you of amendments through additional means, such as posting a notice on our Site homepage or contacting you via email.</p>
				<h5>Control Over your Data </h5>
				<p>We provide our Site users with control over their personal data.  You may: </p>
				<ul className="list-simple">
					<li>Request access to the personal data we have collected about you;</li>
					<li>Request deletion of your personal data;</li>
					<li>Request disclosure of the categories of personal data we collect, the purposes for which we collect, sell or disclose that information, and the categories of third parties with whom we share the information; and</li>
					<li>opt-out of the sale of your personal data.  </li>
				</ul>
				<p>To make any of the above requests, please submit a request to us via email [] or via phone [].  We may need to request additional information from you in order to verify your identity.  We will begin processing your request once we are able to verify your identity and we will respond to your request within 45 days of its receipt, or we will notify you if we require more time to respond. If we cannot verify your identity or authority to make the request, we will not respond to your request. If we find that your verifiable consumer request is excessive, repetitive, or manifestly unfounded, we may charge a reasonable fee to respond to your request.  We will not discriminate against you for requesting any of the rights outlined above.</p>

				<h5>Users Outside of The United States</h5>
				<p>ORMELIVE stores its information, including your personal data, and hosts its Site in the United States.   If you are located outside of the United States or are using our Site from outside the United States, please be aware that your personal data will be transferred to, stored, and processed in the United States where our servers are located and our central database is operated. If you are located in the EEA, we only transfer your personal data to companies in the United States that have signed the standard contractual clauses and informed us that they are GDPR-compliant.  The laws that apply to the use and protection of personal data in the United States or other countries or jurisdictions in which we transfer or process personal data may be different than the laws and protections in your country. By voluntarily providing us with your personal data and/or using our Site, you consent to the terms of this Policy and the collection, use, maintenance, transfer to and processing of your personal data in the United States or other countries or territories, and, unless otherwise stated in this Policy, we use this consent as the legal basis for that data transfer.  If you require further information about this you can request it from <a className="highlight-txt" href="mailto:support@ormelive.com">support@ormelive.com</a>.</p>
				<h5>GDPR DISCLOSURE/EEA RESIDENTS <br />
					LEGAL BASIS FOR PROCESSING YOUR PERSONAL DATA</h5>
				<p>Under the GDPR, ORMELIVE is a “data controller”. That means we collect personal data directly from you and determine the purpose and means of “processing” that data. “Processing” is a broad term that means collection, use, storage, transfer or any other action related to your personal data; it is used in this Policy in that way.</p>
				<p>We process your personal data only if we have a legal basis to do so, including:</p>
				<ul className="list-simple list-style-none">
					<li>1.	To comply with our legal and regulatory obligations, such as keeping our Site safe and secure and preventing and detecting fraud and abuse;</li>
					<li>2.	For the performance of our contract with you, such as when you place an online order;</li>
					<li>3.	For our legitimate interests or those of a third party, including sending emails to you about our products, services, promotions, offers, news, and events and improving our products and Site; or</li>
					<li>4.	Where you have given consent to our specific use, such as when you sign up to receive emails about new products and sales.</li>
				</ul>
				<h5>Your Rights in Relation to Your Personal Data</h5>
				<p>ORMELIVE acknowledges that if you are an individual located in the EEA, you have certain rights that you may exercise with respect to the personal data that we possess.  For example, you may:</p>
				<ul>
					<li>Request access to your personal data and certain information in relation to its processing;</li>
					<li>Request rectification of your personal data;</li>
					<li>Request the erasure of your personal data;</li>
					<li>Request the restriction of processing of your personal data;</li>
					<li>Object to the processing of your personal data.</li>
				</ul>
				<p>If you contact us to exercise on of the rights enumerated above, we may need to request specific information from you to help us confirm your identity and ensure your right to exercise your rights.  We employ this security measure to ensure that your personal data is not disclosed to any person who lacks the right to receive it. </p>
				<p>You also have the right to receive your personal data in a structured, commonly used and machine-readable format and in certain cases, to have us transmit your personal data to another controller. </p>
				<p>If you want to exercise one of these rights please contact us at or write to us at <a className="highlight-txt" href="mailto:support@ormelive.com" target="_blank">support@ormelive.com</a> or write to us at 121 Varick Street, New York, 10013 US. You also have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK supervisory authority for data protection issues, or, as the case may be, any other competent supervisory authority of an EU member state.</p>
				<h5>Right To Withdraw Consent</h5>
				<p>Where you have provided consent to ORMELIVE to collect and process your personal data, you may withdraw it at any time, without affecting the lawfulness of the processing that was carried out prior to withdrawing it.  To withdraw your consent, please contact <a className="highlight-txt" href="mailto:support@ormelive.com" target="_blank">support@ormelive.com</a>.  Once we have received notification that you have withdrawn your consent, we will no longer process your information for the purpose(s) to which you originally consented unless there are compelling legitimate grounds for further processing that override your interests, rights and freedoms or for the establishment, exercise or defense of legal claims.  Whenever you withdraw consent, you acknowledge and accept that this may have a negative influence on the quality of our Site.  </p>
				<h5>Fees</h5>
				<p>We reserve the right to charge a reasonable fee if your request to exercise one of your rights is unreasonable or excessive.  Alternatively, we may refuse to comply with the request in such case.</p>
				<h5>No Automated Decision Making</h5>
				<p>We do not apply automated decision making or profiling techniques.</p>
				<h5>Third-party Online Advertising</h5>
				<p>As discussed in this Policy, we work with certain third-party service providers in connection with our online advertising.  Our third-party service providers may set cookies or other tracking technologies in connection with providing advertising related services to us. The European Digital Advertising Alliance (“EDAA”) has developed a guide to online behavioral advertising and has developed an opt-out page to manage online behavioral advertising preferences with participating EDAA member companies available at <a className="highlight-txt" href="http://www.youronlinechoices.com/" target="_blank">www.YourOnlineChoices.com</a>.</p>
				<h5>Changes To This Privacy Policy</h5>
				<p>We encourage you to review our Policy not just the first time you visit our Site, but periodically afterward because we may modify our Policy from time to time.  The date of the last update of the Policy will always be posted below. If we make any substantive changes to our Policy in the future with regard to how we use your personal data, we will incorporate those changes here.  </p>
				<h5>Retaining Your Personal Data</h5>
				<p>We retain your personal data only for as long as necessary for the purposes it was retained, such as to provide services to you. In some instances, we may retain data for longer periods in order to comply with applicable laws and otherwise as necessary to allow us to conduct our business. </p>
				<h5>Contact Us</h5>
				<p>If you have any questions about this Policy or the security of your personal data on our Site, please contact us at <a className="highlight-txt" href="mailto:support@ormelive.com" >support@ormelive.com</a>.</p>
				<p>This Policy was last updated on October 25, 2023.</p>

			</div>

		</>
	);
};