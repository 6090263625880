import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import avatarIcon from "../../../images/avatar.png";
import { getAllPublishedEvent } from "../../../redux/actions/liveShopping.action";
import InfiniteScroll from "react-infinite-scroll-component";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

const Shows = ({ setDisplaySearch }) => {
	const dispatch = useDispatch();
	const [page, setPage] = useState(2);
	const { isLoading, hasMore, shows } = useSelector((state) => ({
		isLoading: state?.allPublishEvent?.loading,
		shows: state?.allPublishEvent?.payload?.message.data,
		hasMore: state?.allPublishEvent?.payload?.message?.total_records !== state.allPublishEvent?.payload?.message.data?.length ? true : false,
	}));

	const loadMore = () => dispatch(getAllPublishedEvent(page, 12, "", "", "", undefined, "", "", true, userInfo?._id)).then(() => setPage(page + 1));

 

	return (
		<>
			<div className="search-hd-row">
				<h4 className="tab-hd">Shows</h4>
				<Link onClick={() => setDisplaySearch(false)} to="/recorded" className="tab-view-more-link">
					View More
				</Link>
			</div>

			<div className="search-tab-scroll">
				<div id="shows-target" className="tab-product-list-main position-relative scrollbar-style-product">
					{isLoading ? (
						<div className="antd-loading-ift">
							<Spin size="medium" />
						</div>
					) : (
						<>
							{shows?.length > 0 ? (
								<InfiniteScroll scrollableTarget="shows-target" dataLength={shows?.length} next={loadMore} hasMore={hasMore}>
									{shows?.map((item, key) => (
										<Link onClick={() => setDisplaySearch(false)} key={key} to={`/published-events/${item?._id}?page=${item?.page}&origin=recorded&brand=&department=null&category=null&username`} className="tab-product-list-item reviews">
											<span className="review-image brand-style">
												<figure className="review-user-img" data-testid="image">
													<img loading="lazy" decoding="async" src={item?.banner ? item?.banner : avatarIcon} alt={item?.brand_name} />
												</figure>
											</span>

											<div className="tab-product-info">
												<div className="tab-product-name">{item?.title}</div>
												<div className="info-1">{item?.brand_name}</div>
											</div>

											<div className="tab-add-product-right">
												<button type="button" className="tab-add-product-button large-size">
													<FontAwesomeIcon icon={faArrowRight} />
												</button>
											</div>
										</Link>
									))}
								</InfiniteScroll>
							) : (
								<div className="antd-loading-ift">
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Brand found</span>} />
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default Shows;