import React, { useLayoutEffect, useRef, useState } from "react";
import Item from "./Item";
import InfiniteScroll from "react-infinite-scroll-component";

const noData = (
	<div className="no_data_main">
		<div className="midd-width-ifti">
			<div className="no_data_content">
				<div className="error_header">
					<h3>No Data Available</h3>
				</div>
			</div>
		</div>
	</div>
);

export default function List({ post = [], loadMore, hasMore }) {
	const ref = useRef(null);
	
	const [size, onChangeSize] = useState(0);

	useLayoutEffect(() => {
		const updateSize = () => {
			const width = window.innerWidth;
			if (width <= 576) {
				onChangeSize(4); // small screens (mobile)
			} else if (width > 576 && width <= 992) {
				onChangeSize(6); // medium screens (tablet)
			} else {
				onChangeSize(12); // large screens (desktop and above)
			}
		};
	
		// Attach the event listener to the window resize event
		window.addEventListener('resize', updateSize);
	
		// Call the updateSize function to set the initial size
		updateSize();
	
		// Clean up by removing the event listener when the component is unmounted
		return () => {
			window.removeEventListener('resize', updateSize);
		};
	}, []);
	const loader = <div className="influencers-grid">{new Array(size).fill(<div className="influencer-column loader-bg"></div>)}</div>;
	return (
		<div ref={ref}>
			{post.length ? (
				<InfiniteScroll dataLength={post.length} next={loadMore} hasMore={hasMore} loader={loader}>
					<div className="influencers-grid">
						{post.map((item) => (
							<Item item={item} />
						))}
					</div>
				</InfiniteScroll>
			) : (
				noData
			)}
		</div>
	);
}
