import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { getParentCategories, getGenderCategory } from "../../redux/actions/category.action";
import { getInterests, updateInterests } from "../../redux/actions/interest.action";
import { useDispatch } from "react-redux";
import closeicon from "../../images/brand-logos/close-icon.svg";
import { useLocation } from "react-router-dom";
import { createFeeds } from "../../redux/actions/feeds.action";

export default function FilterModal({ filterPost, setFilterPost, showFilter, setShowFilter, createFeed }) {
	let userInfo = localStorage.getItem("userInfo");
	if (userInfo !== "undefined") {
		userInfo = JSON.parse(localStorage.getItem("userInfo"));
	} else {
		localStorage.removeItem("userInfo");
		window.location.reload();
	}
	let location = useLocation();
	const dispatch = useDispatch();
	const [allCategory, setAllCategory] = useState([]);
	const [categoryId, setCategoryId] = useState([]);
	const [genCategoryId, setGenCategoryId] = useState([]);
	const [genderCategory, setGenderCategory] = useState([]);
	const [selectnew, setSelectnew] = useState(false);

	useEffect(() => {
		resetCategory();
	}, [location]);

	useEffect(() => {
		const fetchGenderCategory = async () => {
			const response = await dispatch(getGenderCategory());
			if (response.payload.data) setGenderCategory(response.payload.data);
		};

		const fetchInterests = async () => {
			if (userInfo?.token) {
				const res = await dispatch(getInterests(userInfo?.token));
				let catId = res.payload.message.map(({ _id }) => _id) || [];
				if (catId.length > 0) {
					setFilterPost({ ...filterPost, genderCategoryId: catId, sortBy: "", parentId: catId, applyFilter: false });
					const res = await dispatch(getParentCategories(catId));
					if (res.payload.data) setAllCategory(res.payload.data);
					setGenCategoryId(catId);
				}
			}
		};
		fetchGenderCategory();
		if (createFeed) {
			fetchInterests();
		}
	}, []);

	const resetAll = () => {
		setGenCategoryId([]);
		setCategoryId([]);
		setFilterPost({
			...filterPost,
			categoryId: [],
			genderCategoryId: [],
			subCategoryId: [],
			sortBy: "",
			eventType: "",
			parentId: [],
			applyFilter: Math.random(),
		});
		setAllCategory([]);
	};

	const saveFilters = () => {
		if (userInfo?.token && createFeed) {
			dispatch(updateInterests(userInfo?.token, genCategoryId)).then((res) => {
				dispatch(createFeeds(userInfo?.token, selectnew ? true : false)).then((res) => {
					setSelectnew(true);
					setFilterPost({ ...filterPost, genderCategoryId: genCategoryId, categoryId: categoryId, applyFilter: Math.random() });
					setShowFilter(false);
				});
			});
		} else {
			setFilterPost({ ...filterPost, genderCategoryId: genCategoryId, categoryId: categoryId, applyFilter: Math.random() });
			setShowFilter(false);
		}
	};

	const resetAllButton = () => {
		setFilterPost({ ...filterPost, subCategoryId: [], sortBy: "", eventType: "", applyFilter: false });
		setGenCategoryId([]);
		setAllCategory([]);
		setCategoryId([]);
	};

	const handleButtonClick = (item) => {
		let allCategories = [];
		setSelectnew(true);
		if (genCategoryId?.includes(item?._id)) {
			let catId = genCategoryId.filter((items) => items !== item?._id);
			if (catId?.length === 0) {
				resetAll();
			} else {
				allCategories = catId;
				setGenCategoryId(allCategories);
				updateFilterAndFetchCategories(allCategories);
			}
		} else {
			allCategories = [...genCategoryId, item?._id];
			setGenCategoryId(allCategories);
			updateFilterAndFetchCategories(allCategories);
		}
	};

	const updateFilterAndFetchCategories = (allCategories) => {
		setFilterPost({ ...filterPost, sortBy: "", parentId: allCategories, applyFilter: false });
		dispatch(getParentCategories(allCategories)).then((res) => {
			if (res?.payload?.data) setAllCategory(res.payload.data);
		});
	};

	const resetCategory = () => setCategoryId([]);

	// const handleCategoryClick = (item) => {
	// 	const itemPresent = categoryId?.includes(item?._id);
	// 	const newCategoryId = itemPresent ? categoryId?.filter((id) => id !== item?._id) : [...categoryId, item?._id];
	// 	setCategoryId(newCategoryId.length === 0 ? resetCategory() : newCategoryId);
	// };

	const handleCategoryClick = (item) => {
		let allCategories = [];
		if (categoryId?.includes(item?._id)) {
			let catId = categoryId.filter((items) => items !== item?._id);
			if (catId?.length === 0) {
				resetCategory();
			} else {
				allCategories = catId;
				setCategoryId(allCategories);
			}
		} else {
			allCategories = [...categoryId, item?._id];
			setCategoryId(allCategories);
		}
	};

	return (
		<Modal
			backdropClassName="custom-backdrop"
			dialogClassName="filter-modal-box"
			show={showFilter}
			scrollable
			onHide={() => {
				setShowFilter(false);
			}}
			centered
		>
			<span
				className="filter-close"
				onClick={() => {
					setShowFilter(false);
				}}
			>
				<img src={closeicon} className="filter-gray" />
			</span>

			<Modal.Body>
				<div className="filter-container">
					<h3>Select Department</h3>
					<div className="filter-box">
						<Button onClick={resetAllButton} className={genCategoryId?.length === 0 && "active"} variant="primary">
							All
						</Button>
						{genderCategory?.length > 0 &&
							genderCategory.map((item) => (
								<Button className={genCategoryId.includes(item?._id) && "active"} onClick={() => handleButtonClick(item)} variant="primary">
									{item?.name}
								</Button>
							))}
					</div>
				</div>

				{allCategory?.length > 0 && (
					<div className="filter-container">
						<h3>Select Categories</h3>
						<div className="filter-box">
							<Button onClick={resetCategory} className={categoryId?.length === 0 && "active"} variant="primary">
								All
							</Button>
							{allCategory?.map((item) => (
								<Button className={categoryId?.includes(item?._id) && "active"} onClick={() => handleCategoryClick(item)} variant="primary">
									{item?.category_name}
								</Button>
							))}
						</div>
					</div>
				)}
			</Modal.Body>

			<Modal.Footer>
				<Button onClick={resetAll} variant="secondary">
					Reset all
				</Button>
				<Button onClick={saveFilters} variant="primary">
					Apply
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
