import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import ormelogo from "../../images/orme-logo.svg";

export default function Mobile() {
  const [isModalActive, setIsModalActive] = useState(false);
  const [platformUrl, setPlatformUrl] = useState(null);

  const closeModal = () => {
    setIsModalActive(false);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.indexOf("android") > -1;
    const isiOS = /iphone|ipod/.test(userAgent);

    if (isAndroid) {
      setPlatformUrl("https://play.google.com/store/apps/details?id=com.orme");
      setIsModalActive(true);
    } else if (isiOS) {
      setPlatformUrl("https://apps.apple.com/us/app/orme/id6448742926");
      setIsModalActive(true);
    } else {
      closeModal();
    }
  }, []);

  return (
    <>
      {isModalActive && (
        <Modal className="modal-generic modal-500 download-popup" maskClosable={false} centered visible={isModalActive} onCancel={closeModal} closeIcon={<FontAwesomeIcon icon={faXmark} />} footer={false}>
          <div className="popup-logo">
            <img src={ormelogo} />
          </div>
          <p className="popup-para">Download Mobile App Now</p>
          <Button
            className="default-btn m-auto d-block"
            onClick={() => {
              window.open(platformUrl);
            }}
          >
            Open ORME
          </Button>
          {/* <div class="your-copy-link">
            <div class="item-a"></div>
            <div class="item-b">Download</div>
          </div> */}
        </Modal>
      )}
    </>
  );
}
