import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { Collapse } from "antd";
import { redirectToAppOrStore, isAndroid, isMobile, isIOS, useScrollAnimation } from "../../helper/general";
import { APPLEAPP, ANDROIDAPP } from "../../config";
const { Panel } = Collapse;

export default () => {
	const isMobileDevice = isMobile() || false; // Call the function to determine if it's a mobile device

	const handleMobileClick = (url) => {
		if (isMobile()) {
			redirectToAppOrStore();
		} else {
			window.location.href = url;
		}
	};
	return (
		<>
			<div className="brd_footer_main">
				<div className="midd-width-ifti">
					<div className="footer-new-design">
						<div className="brd-footer-top">
							<div className="box-apps brd-footer-box">
								<h4>Download Our App</h4>
								<ul>
									{(!isMobileDevice || isIOS()) && (
										<li>
											<a onClick={() => handleMobileClick(APPLEAPP)} href="javascript:void(0)">
												<i className="fa fa-apple" aria-hidden="true"></i> Download for iOS
											</a>
										</li>
									)}

									{(!isMobileDevice || isAndroid()) && (
										<li>
											<a onClick={() => handleMobileClick(ANDROIDAPP)} href="javascript:void(0)">
												<i className="fa fa-android" aria-hidden="true"></i> Download for Android
											</a>
										</li>
									)}
								</ul>
							</div>
							<div className="brd-footer-box">
								<h4>Help</h4>
								<ul>
									{/* <li>
                    <NavLink to="/retailer-brand">Retailers & Brands</NavLink>
                  </li>
                  <li>
                    <NavLink to="/influencer-creator">Influencers & Creators</NavLink>
                  </li> */}
									{/* <li>
                    <NavLink to="/about-us">About Us</NavLink>
                  </li> */}
									<li>
										<NavLink to="/terms-service">Terms of Service</NavLink>
									</li>
									<li>
										<NavLink to="/privacy-policy">Privacy Policy</NavLink>
									</li>
									<li>
										<NavLink to="/community-guidelines">Community Guidelines</NavLink>
									</li>
									<li>
										<NavLink to="/contact-us">Contact Us</NavLink>
									</li>
								</ul>
							</div>
							<div className="brd-footer-box">
								<h4>Partner with us</h4>
								<ul>
									<li>
										<NavLink to="/brand-retailer">Brands & Retailers</NavLink>
									</li>
									<li>
										<NavLink to="/influencer-creators">Influencers & Creators</NavLink>
									</li>
								</ul>
							</div>
							{/* <div className="brd-footer-box">
                <h4>popular Categories</h4>
                <ul>
                  <li>
                    <a href="javascript:void">Accessories</a>
                  </li>
                  <li>
                    <a href="javascript:void" >Bags</a>
                  </li>
                  <li>
                    <a href="javascript:void" >Beauty</a>
                  </li>
                  <li>
                    <a href="javascript:void">Clothing</a>
                  </li>
                  <li>
                    <a href="javascript:void">Electronics</a>
                  </li>
                  <li>
                    <a href="javascript:void" >Home Decor</a>
                  </li>
                  <li>
                    <a href="javascript:void">Jewelry</a>
                  </li>
                  <li>
                    <a href="javascript:void" >Shoes</a>
                  </li>
                  <li>
                    <a href="javascript:void" >Sports</a>
                  </li>
                  <li>
                    <a href="javascript:void">Travel</a>
                  </li>
                </ul>
              </div> */}
							{/* <div className="brd-footer-box">
                <h4>Promotions</h4>
                <ul>
                  <li>
                    <a href="javascript:void">Black Friday</a>
                  </li>
                  <li>
                    <a href="javascript:void">Cyber Monday</a>
                  </li>
                  <li>
                    <a href="javascript:void">Boxing Day</a>
                  </li>
                  <li>
                    <a href="javascript:void">Memorial Day</a>
                  </li>
                  <li>
                    <a href="javascript:void">Daily Deals</a>
                  </li>
                </ul>
              </div> */}
						</div>
						<div className="brd_footer">
							<div className="brd-copyright">© 2023 ORME LIVE Inc.</div>
							<div className="brd-right">
								{/* <div className="brd-social">
                  <span className="fa-stack">
                    <a href="https://www.instagram.com/konnectbio" target="_blank">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-instagram fa-stack-1x"></i>
                    </a>
                  </span>
                  <span className="fa-stack">
                    <a href="https://www.linkedin.com/company/konnectbio-inc/" target="_blank">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-linkedin fa-stack-1x"></i>
                    </a>
                  </span>
                  <span className="fa-stack">
                    <a href="https://www.facebook.com/konnectbio" target="_blank">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-facebook fa-stack-1x"></i>
                    </a>
                  </span>
                  <span className="fa-stack">
                    <a href="https://www.youtube.com/channel/UCoEkTdhPjoAD2NTs0legNwg" target="_blank">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-youtube-play fa-stack-1x"></i>
                    </a>
                  </span>
                </div> */}
								{/* <div className="brd-social">
										<span className="fa-stack">
											<a href="javascript:void">
												<i className="fa fa-circle fa-stack-2x"></i>
												<i className="fa fa-instagram fa-stack-1x"></i>
											</a>
										</span>
										<span className="fa-stack">
											<a href="javascript:void">
												<i className="fa fa-circle fa-stack-2x"></i>
												<i className="fa fa-linkedin fa-stack-1x"></i>
											</a>
										</span>
										<span className="fa-stack">
											<a href="javascript:void">
												<i className="fa fa-circle fa-stack-2x"></i>
												<i className="fa fa-facebook fa-stack-1x"></i>
											</a>
										</span>
										<span className="fa-stack">
											<a href="javascript:void">
												<i className="fa fa-circle fa-stack-2x"></i>
												<i className="fa fa-youtube-play fa-stack-1x"></i>
											</a>
										</span>
									</div> */}
							</div>
							{/* <div className="f-right-temporary">
								<div className="terms-link-temporary">
									<ul>
										<li>
											<NavLink to="/terms-service">Terms of Service</NavLink>
										</li>
										<li>
											<NavLink to="/privacy-policy">Privacy Policy</NavLink>
										</li>
										<li>
											<NavLink to="/contact-us">Contact Us</NavLink>
										</li>
									</ul>
								</div>
								
							</div> */}
						</div>
					</div>
				</div>
			</div>

			<div className="mobile_footer_ifti">
				<Collapse accordion expandIconPosition={"end"} defaultActiveKey={["1"]} expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}>
					<Panel header="Help" key="1">
						<div className="brd-footer-box">
							<ul>
								{/* <li>
                  <NavLink to="/influencer-creator">Influencers & Creators</NavLink>
                </li>
                <li>
                  <NavLink to="/retailer-brand">Retailers & Brands</NavLink>
                </li> */}
								{/* <li>
                  <NavLink to="/about-us">About Us</NavLink>
                </li> */}
								<li>
									<NavLink to="/terms-service">Terms of Service</NavLink>
								</li>
								<li>
									<NavLink to="/privacy-policy">Privacy Policy</NavLink>
								</li>
								<li>
									<NavLink to="/community-guidelines">Community Guidelines</NavLink>
								</li>
								<li>
									<NavLink to="/brand-retailer">Brands</NavLink>
								</li>
								<li>
									<NavLink to="/influencer-creators">Creators</NavLink>
								</li>
								<li>
									<NavLink to="/contact-us">Contact Us</NavLink>
								</li>
							</ul>
						</div>
					</Panel>
					<Panel header="Partner with us" key="3">
						<div className="brd-footer-box">
							<ul>
								<li>
									<NavLink to="/brand-retailer">Brands & Retailers</NavLink>
								</li>
								<li>
									<NavLink to="/influencer-creators">Influencers & Creators</NavLink>
								</li>
							</ul>
						</div>
					</Panel>
					{/* <Panel header="popular Categories" key="3">
            <div className="brd-footer-box">
              <ul>
                <li>
                  <NavLink>Accessories</NavLink>
                </li>
                <li>
                  <NavLink>Bags</NavLink>
                </li>
                <li>
                  <NavLink>Beauty</NavLink>
                </li>
                <li>
                  <NavLink>Clothing</NavLink>
                </li>
                <li>
                  <NavLink>Electronics</NavLink>
                </li>
                <li>
                  <NavLink>Home Decor</NavLink>
                </li>
                <li>
                  <NavLink>Jewelry</NavLink>
                </li>
                <li>
                  <NavLink>Shoes</NavLink>
                </li>
                <li>
                  <NavLink>Sports</NavLink>
                </li>
                <li>
                  <NavLink>Travel</NavLink>
                </li>
              </ul>
            </div>
          </Panel> */}
					<Panel header="Download Our App" key="4">
						<div className="brd-footer-box">
							<ul>
								{(!isMobileDevice || isIOS()) && (
									<li>
										<a onClick={() => handleMobileClick(APPLEAPP)} href="javascript:void(0)">
											<i className="fa fa-apple" aria-hidden="true"></i> Download for iOS
										</a>
									</li>
								)}

								{(!isMobileDevice || isAndroid()) && (
									<li>
										<a onClick={() => handleMobileClick(ANDROIDAPP)} href="javascript:void(0)">
											<i className="fa fa-android" aria-hidden="true"></i> Download for Android
										</a>
									</li>
								)}
							</ul>
						</div>
					</Panel>
					{/* <Panel header="Promotions" key="5">
            <div className="brd-footer-box">
              <ul>
                <li>
                  <a href="javascript:void">Black Friday</a>
                </li>
                <li>
                  <a href="javascript:void">Cyber Monday</a>
                </li>
                <li>
                  <a href="javascript:void">Boxing Day</a>
                </li>
                <li>
                  <a href="javascript:void">Memorial Day</a>
                </li>
                <li>
                  <a href="javascript:void">Daily Deals</a>
                </li>
              </ul>
            </div>
          </Panel> */}
				</Collapse>

				<div className="brd_footer">
					<div className="brd-right">
						<div className="brd-social">
							{/* <span className="fa-stack">
                <a href="https://www.instagram.com/konnectbio" target="_blank">
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-instagram fa-stack-1x"></i>
                </a>
              </span>
              <span className="fa-stack">
                <a href="https://www.linkedin.com/company/konnectbio-inc/" target="_blank">
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-linkedin fa-stack-1x"></i>
                </a>
              </span>
              <span className="fa-stack">
                <a href="https://www.facebook.com/konnectbio" target="_blank">
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-facebook fa-stack-1x"></i>
                </a>
              </span>
              <span className="fa-stack">
                <a href="https://www.youtube.com/channel/UCoEkTdhPjoAD2NTs0legNwg" target="_blank">
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-youtube-play fa-stack-1x"></i>
                </a>
              </span> */}
						</div>
						{/* <div className="brd-social">
							<span className="fa-stack">
								<a href="javascript:void">
									<i className="fa fa-circle fa-stack-2x"></i>
									<i className="fa fa-instagram fa-stack-1x"></i>
								</a>
							</span>
							<span className="fa-stack">
								<a href="javascript:void">
									<i className="fa fa-circle fa-stack-2x"></i>
									<i className="fa fa-linkedin fa-stack-1x"></i>
								</a>
							</span>
							<span className="fa-stack">
								<a href="javascript:void">
									<i className="fa fa-circle fa-stack-2x"></i>
									<i className="fa fa-facebook fa-stack-1x"></i>
								</a>
							</span>
							<span className="fa-stack">
								<a href="javascript:void">
									<i className="fa fa-circle fa-stack-2x"></i>
									<i className="fa fa-youtube-play fa-stack-1x"></i>
								</a>
							</span>
						</div> */}
					</div>
					<div className="brd-copyright">© 2023 ORME LIVE Inc.</div>
				</div>
			</div>
		</>
	);
};
