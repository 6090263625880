import React, { useEffect, useState, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getAllLiveEvent } from "../../redux/actions/liveShopping.action";
import avatarIcon from "../../images/avatar.png";

export default function LiveEvent({ genderCategoryId, categoryId, subCategoryId, source, sortBy, origin, applyFilter, eventType }) {
  const params = useParams();
  const dispatch = useDispatch();
  const [count, setCount] = useState(2);
  const [data, setData] = useState([]);
  const [hide, setHide] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [lastData, setLastData] = useState([]);
  const [initialDataFetch, setInitialDataFetch] = useState(false);

  const sliderRef = useRef();
  const { allLiveEvent, account, search, brand, bioShopMenu } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    if (applyFilter) {
      if (eventType && eventType !== "liveEvents") {
        setHide(true);
      } else {
        setHide(false);
      }
    }

    if (applyFilter === undefined || applyFilter) {
      setInitialDataFetch(true);
      dispatch(getAllLiveEvent(1, 10, genderCategoryId, categoryId, subCategoryId, source === "bioshop" ? bioShopMenu?.payload?.message?.data._id : brand?.payload?.message, sortBy, search?.payload)).then((res) => {
        setInitialDataFetch(false);
        if (res?.payload?.message?.data) setData(res?.payload?.message?.data);
        setLastData(res?.payload?.message?.data.slice(-5));
      });
    }
  }, [applyFilter, search]);

  let userInfo = localStorage.getItem("userInfo");

  if (userInfo !== "undefined") {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } else {
    localStorage.removeItem("userInfo");
    window.location.reload();
  }

  const liveEvent = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 6,
    swipe: false,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className={"slick-arrow slick-next"} />,
    prevArrow: <SamplePrevArrow className={"slick-arrow slick-next"} />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          swipe: true,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          swipe: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3.2,
          infinite: false,
          swipe: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          swipe: true,
          infinite: false,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        className={className}
        onClick={() => {
          setSlideIndex(() => slideIndex + 1);
          onClick();

          if (allLiveEvent?.payload?.message?.next) {
            if (slideIndex + 6 === data?.length) {
              setCount(() => count + 1);
              dispatch(getAllLiveEvent(count, 10, genderCategoryId, categoryId, subCategoryId, source === "bioshop" ? bioShopMenu?.payload?.message?.data._id : brand?.payload?.message, sortBy))
                .then((res) => {
                  if (res?.payload?.message?.data) {
                    setLastData(res?.payload?.message?.data.slice(-5));
                    setData([...data, ...res?.payload?.message?.data]);
                  }
                })
                .then(() => {
                  sliderRef.current.slickGoTo(slideIndex + 1);
                });
            }
          }
        }}
      ></button>
    );
  }
  
  const loadingSetting = {
    className: "slider variable-width",
    dots: false,
    infinite: lastData?.length > 6 ? true : false,
    centerMode: false,
    slidesToShow: 6,
    swipe: false,
    nextArrow: <SampleNextArrow className={"slick-arrow slick-next"} />,
    prevArrow: <SamplePrevArrow className={"slick-arrow slick-next"} />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          swipe: true,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          swipe: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3.2,
          infinite: false,
          swipe: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          swipe: true,
          infinite: false,
        },
      },
    ],
  };

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        className={className}
        onClick={() => {
          if (slideIndex !== 0) {
            setSlideIndex(() => slideIndex - 1);
          }
          onClick();
        }}
      ></button>
    );
  }

  const onSwipeEvent = (swipeDirection) => {
    if (swipeDirection === "left") {
      if (allLiveEvent?.payload?.message?.next) {
        dispatch(getAllLiveEvent(count, 6, genderCategoryId, categoryId, subCategoryId, source === "bioshop" ? bioShopMenu?.payload?.message?.data._id : brand?.payload?.message, sortBy))
          .then((res) => {
            setData([...data, ...res?.payload?.message?.data]);
          })
          .then(() => {
            sliderRef.current.slickGoTo(1);
          });
      }
    }
  };

  return (
    // <>
    //   <div
    //     className={`event-container ${!allLiveEvent?.loading && data?.length === 0 && "d-none"}

    //   ${hide ? "d-none" : ""}
    //   `}
    //   >
    //     <div className="event-header">
    //       <h3>Live Events</h3>
    //     </div>{" "}

    //     {allLiveEvent?.loading ? (
    //       <Slider onSwipe={onSwipeEvent} {...liveEvent}>
    //         {new Array(6).fill().map((_, i) => {
    //           return <div className="event-col-box"></div>;
    //         })}
    //       </Slider>
    //     ) : data?.length > 0 ? (
    //       <Slider onSwipe={onSwipeEvent} {...liveEvent}>
    //         {data?.map((item) => {
    //           return (
    //             <>
    //               <div className="event-col-box">
    //                 <NavLink to={`/live-event/${item._id}/${item.instagram_username ? item.instagram_username : item.pixel_id}?origin=${origin}&username=${params.username}`} className="live-vid-link">
    //                   <div className={`vid-item ${item.default_banner ? "default-image" : ""} `}>
    //                     <img alt="" src={item?.banner} />
    //                     <div className="vid-item-detail">
    //                       <div className="vid-time">
    //                         <span className="live-now">Live Now</span>
    //                         {item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
    //                       </div>
    //                       <div className="vid-title">
    //                         <span className="v-title">{item?.title}</span>
    //                         <div className="author-name justify-content-between">
    //                           <div className="auth-left">
    //                             <span className="auth-image">{item?.brand_profile ? <img alt="" src={item?.brand_profile} /> : <img className="img-fluid" src={avatarIcon} />}</span>
    //                             <span className="auth-title">{item?.brand_name}</span>
    //                           </div>
    //                           <div className="auth-right">
    //                             {userInfo && account?.payload?.Data?.account_type !== "customer" ? item?.influencer_percent !== "0" && item.influencer_percent ? <span className="inf-fee">{item?.influencer_percent}% Influencer Fee</span> : "" : ""}
    //                             {userInfo?.account_type !== "influencer" ? item?.referral_percent !== "0" && item.referral_percent ? <span className="ref-fee">{item?.referral_percent}% Referral Fee</span> : "" : ""}
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </NavLink>
    //               </div>
    //             </>
    //           );
    //         })}
    //       </Slider>
    //     ) : (
    //       ""
    //     )}
    //   </div>
    // </>

    <>
      <div
        className={`event-container ${!allLiveEvent?.loading && data?.length === 0 && "d-none"}
      
      ${hide ? "d-none" : ""}
      `}
      >
        <div className="event-header">
          <h3>Live Events</h3>
        </div>{" "}
        {allLiveEvent?.loading ? (
          <>
            <>
              {initialDataFetch ? (
                <Slider {...liveEvent}>
                  {new Array(6).fill().map((_, i) => {
                    return <div className="event-col-box"></div>;
                  })}
                </Slider>
              ) : (
                <>
                  {lastData.length > 0 && (
                    <Slider {...loadingSetting} ref={sliderRef}>
                      {lastData?.map((item) => {
                        return (
                          <>
                            <div className="event-col-box">
                              <NavLink to={`/live-event/${item._id}/${item.instagram_username ? item.instagram_username : item.pixel_id}?origin=${origin}&username=${params.username}`} className="live-vid-link">
                                <div className={`vid-item ${item.default_banner ? "default-image" : ""} `}>
                                  <img alt="" src={item?.banner} />
                                  <div className="vid-item-detail">
                                    <div className="vid-time">
                                      <span className="live-now">Live Now</span>
                                      {item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
                                    </div>
                                    <div className="vid-title">
                                      <span className="v-title">{item?.title}</span>
                                      <div className="author-name justify-content-between">
                                        <div className="auth-left">
                                          <span className="auth-image">{item?.brand_profile ? <img alt="" src={item?.brand_profile} /> : <img className="img-fluid" src={avatarIcon} />}</span>
                                          <span className="auth-title">{item?.brand_name}</span>
                                        </div>
                                        <div className="auth-right">
                                          {userInfo && account?.payload?.Data?.account_type !== "customer" ? item?.influencer_percent !== "0" && item.influencer_percent ? <span className="inf-fee">{item?.influencer_percent}% Influencer Fee</span> : "" : ""}
                                          {userInfo?.account_type !== "influencer" ? item?.referral_percent !== "0" && item.referral_percent ? <span className="ref-fee">{item?.referral_percent}% Referral Fee</span> : "" : ""}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </NavLink>
                            </div>
                          </>
                        );
                      })}
                      <div className="event-col-box"></div>
                    </Slider>
                  )}
                </>
              )}
            </>
          </>
        ) : (
          data?.length > 0 && (
            <Slider {...liveEvent} ref={sliderRef}>
              {data?.map((item) => {
                return (
                  <div className="event-col-box">
                  <NavLink to={`/live-event/${item._id}/${item.instagram_username ? item.instagram_username : item.pixel_id}?origin=${origin}&username=${params.username}`} className="live-vid-link">
                    <div className={`vid-item ${item.default_banner ? "default-image" : ""} `}>
                      <img alt="" src={item?.banner} />
                      <div className="vid-item-detail">
                        <div className="vid-time">
                          <span className="live-now">Live Now</span>
                          {item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
                        </div>
                        <div className="vid-title">
                          <span className="v-title">{item?.title}</span>
                          <div className="author-name justify-content-between">
                            <div className="auth-left">
                              <span className="auth-image">{item?.brand_profile ? <img alt="" src={item?.brand_profile} /> : <img className="img-fluid" src={avatarIcon} />}</span>
                              <span className="auth-title">{item?.brand_name}</span>
                            </div>
                            <div className="auth-right">
                              {userInfo && account?.payload?.Data?.account_type !== "customer" ? item?.influencer_percent !== "0" && item.influencer_percent ? <span className="inf-fee">{item?.influencer_percent}% Influencer Fee</span> : "" : ""}
                              {userInfo?.account_type !== "influencer" ? item?.referral_percent !== "0" && item.referral_percent ? <span className="ref-fee">{item?.referral_percent}% Referral Fee</span> : "" : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                );
              })}
            </Slider>
          )
        )}
      </div>
    </>
  );
}
