import { ADD_CREDIT_CARD_REQUEST, ADD_CREDIT_CARD_SUCCESS, ADD_CREDIT_CARD_ERROR, GET_ALL_CREDIT_CARD_REQUEST, GET_ALL_CREDIT_CARD_SUCCESS, GET_ALL_CREDIT_CARD_ERROR, SET_DEFAULT_CREDIT_CARD_REQUEST, SET_DEFAULT_CREDIT_CARD_SUCCESS, SET_DEFAULT_CREDIT_CARD_ERROR, STRIPE_PAYMENT_METHOD_REQUEST, STRIPE_PAYMENT_METHOD_SUCCESS, STRIPE_PAYMENT_METHOD_ERROR, STRIPE_PAYMENT_LIST_REQUEST, STRIPE_PAYMENT_LIST_SUCCESS, STRIPE_PAYMENT_LIST_ERROR, STRIPE_PAYMENT_UPDATE_REQUEST, STRIPE_PAYMENT_UPDATE_SUCCESS, STRIPE_PAYMENT_UPDATE_ERROR, STRIPE_PAYMENT_DETACH_REQUEST, STRIPE_PAYMENT_DETACH_SUCCESS, STRIPE_PAYMENT_DETACH_ERROR, STRIPE_MAKE_PAYMENT_REQUEST , STRIPE_MAKE_PAYMENT_SUCCESS , STRIPE_MAKE_PAYMENT_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const createCreditCard = (card_number, expiry, cvc, zipCode, token) => (dispatch) => {
  dispatch({ type: ADD_CREDIT_CARD_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}users/cards/createnewcard`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      card_number,
      expiry,
      cvc,
      zipCode,
    },
  })
    .then((response) => {
      return dispatch({
        type: ADD_CREDIT_CARD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_CREDIT_CARD_ERROR,
        payload: error.response,
      });
    });
};

export const getAllCreditCard = (token) => (dispatch) => {
  dispatch({ type: GET_ALL_CREDIT_CARD_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}users/cards/getallcards`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_CREDIT_CARD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_CREDIT_CARD_ERROR,
        payload: error.response,
      });
    });
};

export const updateDefaultCard = (token, id) => (dispatch) => {
  dispatch({ type: SET_DEFAULT_CREDIT_CARD_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}stripe/setdefaultpaymentmethod`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      id: id,
    },
  })
    .then((response) => {
      return dispatch({
        type: SET_DEFAULT_CREDIT_CARD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SET_DEFAULT_CREDIT_CARD_ERROR,
        payload: error.response,
      });
    });
};

export const stripepayment = (token,redirection_url) => (dispatch) => {
  dispatch({ type: STRIPE_PAYMENT_METHOD_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}stripe/makepaymentmethod`,
    headers: { Authorization: `Bearer ${token}` },
    data:{redirection_url:redirection_url}
  })
    .then((response) => {
      return dispatch({
        type: STRIPE_PAYMENT_METHOD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: STRIPE_PAYMENT_METHOD_ERROR,
        payload: error.response,
      });
    });
};

export const stripePaylist = (token) => (dispatch) => {
  dispatch({ type: STRIPE_PAYMENT_LIST_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}stripe/paymentmethods`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return dispatch({
        type: STRIPE_PAYMENT_LIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: STRIPE_PAYMENT_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const updatePayment = (token, id, month, year) => (dispatch) => {
  dispatch({ type: STRIPE_PAYMENT_UPDATE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}stripe/updatepaymentmethod`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      id: id,
      month: month,
      year: year,
    },
  })
    .then((response) => {
      return dispatch({
        type: STRIPE_PAYMENT_UPDATE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: STRIPE_PAYMENT_UPDATE_ERROR,
        payload: error.response,
      });
    });
};

export const deletePayment = (token, id) => (dispatch) => {
  dispatch({ type: STRIPE_PAYMENT_DETACH_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}stripe/detachpaymentmethod`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      id: id,
    },
  })
    .then((response) => {
      return dispatch({
        type: STRIPE_PAYMENT_DETACH_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: STRIPE_PAYMENT_DETACH_ERROR,
        payload: error.response,
      });
    });
};

export const makePayment = (token, id,brand) => (dispatch) => {
  dispatch({ type: STRIPE_MAKE_PAYMENT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}stripe/pay/makepaymentandinsertorder`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      id: id,
      brand:brand
    },
  })
    .then((response) => {
      return dispatch({
        type: STRIPE_MAKE_PAYMENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: STRIPE_MAKE_PAYMENT_ERROR,
        payload: error.response,
      });
    });
};
