import { GET_BRANDS_LIST_REQUEST, GET_BRANDS_LIST_SUCCESS, GET_BRANDS_LIST_ERROR } from "../../types/types";
export default function brandnameList(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_BRANDS_LIST_REQUEST:
            return {
                loading: true,
            };
        case GET_BRANDS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };

        case GET_BRANDS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                payload: payload ? payload : [],
            };

        default:
            return state;
    }
}
