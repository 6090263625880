import { POST_REVIEW_GET_REQUEST, POST_REVIEW_GET_SUCCESS, POST_REVIEW_GET_ERROR } from "../../types/types";

export default function getReview(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case POST_REVIEW_GET_REQUEST:
      return {
        loading: true,
      };
    case POST_REVIEW_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case POST_REVIEW_GET_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload ? payload : [],
      };

    default:
      return state;
  }
}
