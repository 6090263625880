import React from "react";
import Slider from "react-slick";
import Video from "../video";

const Slide = ({ slider, influencerreviewsslider, infliencerReviewData, onSwipeEvent, onKeyChange, stp1, stp2, stp3, setStp1, setStp2, setStp3, setCollapsed, collapsed, eventId, like, setLike , isSaved, setIsSaved }) => {

	return (
		<div className="infl-rev-main-container infl-rev-new-layout">
			<div className="rev-container">
				<div className="rev-video-container">
					<div className="rev-video-box-main">
						{infliencerReviewData?.length && (
							<Slider onSwipe={onSwipeEvent} ref={(c) => (slider.current = c)} {...influencerreviewsslider}>
								{infliencerReviewData?.map((item, index) => {
									return <Video key={index} onKeyChange={onKeyChange} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} setCollapsed={setCollapsed} collapsed={collapsed} index={index} item={item} id={eventId} like={like} setLike={setLike} 
									isSaved={isSaved}
									setIsSaved={setIsSaved} />;
								})}
							</Slider>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Slide;
