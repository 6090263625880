import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Button,
  Form,
  Empty,
  Spin,
  Select,
  InputNumber,
  Input,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingDollar,
  faMoneyBillTransfer,
  faFileInvoiceDollar,
  faSackDollar,
} from "@fortawesome/free-solid-svg-icons";
import { getCommissionReport } from "../../../redux/actions/wallet.action";
import { getBrand } from "../../../redux/actions/brand.action";
import { getCategory } from "../../../redux/actions/category.action";
import { requestWithdraw } from "../../../redux/actions/wallet.action";
import { getAccount } from "../../../redux/actions/account.action";
import Table from "react-bootstrap/Table";
// import wallet1 from "../../../images/brand-logos/commission-discounts.svg";
import numeral from "numeral";
import { Modal } from "react-bootstrap";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function Commission() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [form] = Form.useForm();
  const dateFormat = "MM-DD-YYYY";
  const { commission, account } = useSelector((state) => state);
  const fromDate = moment().startOf("year").format("YYYY-MM-DD");
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const [category, setCategory] = useState("all");
  const [source, setSource] = useState("all");
  const [brand, setBrand] = useState("all");
  const [Subcategory, setSubCategory] = useState("all");
  const [genderCategory, setGenderCategory] = useState("all");
  const [brandId, setBrandId] = useState("");
  const [catId, setCatId] = useState("");
  const [earningType, setEarningType] = useState("all");
  const [sortBy, setSortBy] = useState("date");
  const [groupBy, setGroupBy] = useState("none");
  const [submit, setSubmit] = useState("none");
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [genderCatgory, setGenderCat] = useState([]);
  const [publicCatgory, setPublicCat] = useState([]);
  const [load, setLoad] = useState(false);
  const [modal, setModal] = useState(false);
  const [inputValue, setInputValue] = useState(); // State to store the input value

  useEffect(() => {
    //dispatch(getEarnCommission(userInfo?.token, startDate, endDate));
    dispatch(getBrand()).then((res) => {
      setBrandList(res?.payload?.message);
    });
    dispatch(
      getCommissionReport(
        userInfo?.token,
        startDate,
        endDate,
        category,
        genderCategory,
        Subcategory,
        brand
      )
    );
  }, []);

  const handleBrand = (e) => {
    if (e === "all") {
      setCategoryList([]);
      setPublicCat([]);
      setCategory("all");
      setGenderCategory("all");
      setSubCategory("all");
    } else {
      setLoad(true);
      setCategoryList([]);
      setPublicCat([]);
      setCategory("all");
      setGenderCategory("all");
      setSubCategory("all");
      setGenderCat([]);

      setBrandId(e);
      dispatch(getCategory("genCategory", e)).then((res) => {
        if (res?.payload?.message) {
          setLoad(false);
          //setCategoryList(res?.payload?.message);
          setGenderCat(res?.payload?.message);
        }
      });
    }
    setBrand(e);
  };

  const totalWithdraw =
    commission?.payload?.message?.available_to_withdraw[0]?.cashback_amount +
    commission?.payload?.message?.available_to_withdraw[0]?.creator_commission +
    commission?.payload?.message?.available_to_withdraw[0]
      ?.customer_commission -
    commission?.payload?.message?.withdraw_amount;

  const handleSubmit = () => {
    if (inputValue <= totalWithdraw) {
      Swal.fire({
        title: "Withdraw Amount",
        text: `Are you sure you want to Withdraw $${inputValue} ?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#010b40",
        cancelButtonColor: "#d33",
        imageAlt: "facebook image",
        reverseButtons: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(requestWithdraw(userInfo?.token, inputValue)).then((res) => {
            Swal.fire({
              icon: "success",
              title: res?.payload?.message,
            });
          });
          setModal(false);
          dispatch(
            getCommissionReport(
              userInfo?.token,
              startDate,
              endDate,
              category,
              genderCategory,
              Subcategory,
              brand
            )
          );
        } else {
          setModal(false);
        }
      });

      setInputValue();
    } else if (inputValue > totalWithdraw) {
      Swal.fire({
        icon: "error",
        title: "Provide valid amount.",
      });
    }
  };

  const handleGenCategory = (e) => {
    if (e === "all") {
      setCategoryList([]);
      setCategory("all");
      setPublicCat([]);
      setCategory("all");
      setGenderCategory("all");
      setSubCategory("all");
    } else {
      setLoad(true);
      setCatId(e);
      setCategoryList([]);
      setCategory("all");
      setGenderCategory("all");
      setSubCategory("all");
      setPublicCat([]);
      dispatch(getCategory("category", brandId, e)).then((res) => {
        if (res?.payload?.message) {
          setLoad(false);
          setPublicCat(res?.payload?.message);
        }
      });
    }

    setGenderCategory(e);
  };

  const handleCategory = (e) => {
    console.log("e", e);
    if (e === "all") {
      setCategoryList([]);
      setCategory("all");
      setSubCategory("all");
    } else {
      setLoad(true);
      setCategoryList([]);
      setSubCategory("all");
      dispatch(getCategory("subCategory", brandId, catId, e)).then((res) => {
        if (res?.payload?.message) {
          setLoad(false);
          setCategoryList(res?.payload?.message);
          console.log("category", res?.payload?.message);
        }
      });
    }
    setCategory(e);
  };

  const handleSubCategory = (e) => {
    setSubCategory(e);
  };

  const handleReset = () => {
    setSource("all");
    setBrand("all");
    setCategory("all");
    setGenderCategory("all");
    setBrand("all");
    setSubCategory("all");
    setCategoryList([]);
    setPublicCat([]);
    setGenderCat([]);
    // setGroupBy("none");
    setSubmit("none");
    setStartDate(fromDate);
    setEndDate(toDate);
    dispatch(
      getCommissionReport(
        userInfo?.token,
        fromDate,
        toDate,
        "all",
        "all",
        "all",
        "all"
      )
    );
  };

  const onFinish = async () => {
    if (userInfo.token) {
      // setSubmit(groupBy);
      dispatch(
        getCommissionReport(
          userInfo?.token,
          startDate,
          endDate,
          category,
          genderCategory,
          Subcategory,
          brand
        )
      );
    }
  };

  const checkPaypal = () => {
    if (
      account?.payload?.Data?.paypal_email &&
      account?.payload?.Data?.paypal_email !== ""
    ) {
      navigate(`/mywallet`);
    } else {
      navigate(`/payout`);
    }
  };

  const onFinishFailed = (errorInfo) => console.log("Failed:", errorInfo);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = moment(dataString[0]).format("YYYY-MM-DD");
		const endDate = moment(dataString[1]).format("YYYY-MM-DD");
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  return (
    <div className="account-left-content">
      <div className="favourite-brands-container">
        <div>
          <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
            <div className="new-dash-content  ml-0 p-0">
              <div className="row w-20-desktop-main">
                <div className="col-xl-2 col-md-6 col-12 mb-4">
                  <p>Select Start Date / End Date</p>
                  <RangePicker
                    size="large"
                    className="w-100"
                    key={4}
                    value={
                      startDate && endDate
                        ? [moment(startDate), moment(endDate)]
                        : []
                    }
                    allowClear={false}
                    ranges={{
                      "Last Year": [
                        moment().subtract(1, "year").startOf("year"),
                        moment().subtract(1, "year").endOf("year"),
                      ],
                      "This Year": [moment().startOf("year"), moment()],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      Today: [moment(), moment()],
                    }}
                    format={dateFormat}
                    onChange={dateRangePickerChanger}
                  />
                </div>

                <div className="col-xl-2 col-md-6 col-12 mb-20">
                  <p>Brand</p>
                  <Select
                    className="select-style dark-field w-100"
                    placeholder="Select Brand"
                    size="large"
                    focus={false}
                    onBlur={false}
                    onChange={handleBrand}
                    value={brand === "" ? null : brand}
                  >
                    <Option value={"all"}>All</Option>
                    {brandList?.length > 0 &&
                      brandList[0]?.brands.map((item, i) => {
                        return (
                          <Option value={item?._id}>{item?.brand_name}</Option>
                        );
                      })}
                  </Select>
                </div>

                <div className="col-xl-2 col-md-6 col-12 mb-20">
                  <p>Department</p>
                  <Select
                    className="select-style dark-field w-100"
                    placeholder="Select Gender"
                    size="large"
                    focus={false}
                    onBlur={false}
                    onChange={handleGenCategory}
                    value={genderCategory === "" ? null : genderCategory}
                  >
                    <Option value={"all"}>All</Option>
                    {genderCatgory?.length > 0 &&
                      genderCatgory?.map((item) => {
                        return <Option value={item?._id}>{item?.name}</Option>;
                      })}
                  </Select>
                </div>

                <div className="col-xl-2 col-md-6 col-12 mb-20">
                  <p>Category</p>
                  <Select
                    className="select-style dark-field w-100"
                    placeholder="Category"
                    size="large"
                    focus={false}
                    onChange={handleCategory}
                    onBlur={false}
                    value={category === "" ? null : category}
                  >
                    <Option value={"all"}>All</Option>
                    {publicCatgory?.length > 0 &&
                      publicCatgory?.map((item) => {
                        return (
                          <Option value={item?._id}>
                            {item?.category_name}
                          </Option>
                        );
                      })}
                  </Select>
                </div>

                <div className="col-xl-2 col-md-6 col-12 mb-20">
                  <p>Sub Category</p>
                  <Select
                    className="select-style dark-field w-100"
                    placeholder="Sub Category"
                    size="large"
                    focus={false}
                    onChange={handleSubCategory}
                    onBlur={false}
                    value={Subcategory === "" ? null : Subcategory}
                  >
                    <Option value={"all"}>All</Option>
                    {load ? (
                      <Option>
                        <Spin size="small" />
                      </Option>
                    ) : (
                      categoryList?.length > 0 &&
                      categoryList?.map((items) => {
                        return (
                          <Option value={items?._id}>
                            {items?.sub_category_name}
                          </Option>
                        );
                      })
                    )}
                  </Select>
                </div>

                <div className="col-xl-2 col-md-6 col-12 d-flex align-items-end srh-btn mb-4">
                  <Button
                    htmlType="submit"
                    className="ant-btn ant-btn-default ant-btn-lg"
                  >
                    Search
                  </Button>
                  <Button
                    onClick={handleReset}
                    className="ant-btn ant-btn-default ant-btn-lg"
                  >
                    Reset
                  </Button>
                </div>
              </div>
              <div className="container ml-0 p-0">
                <div className="row dash-boxes mt-2">
                  <div className="col-12 mb-3">
                    <h2 className="title-hd">Lifetime Earnings</h2>
                  </div>
                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon
                        className="info-icon-main"
                        icon={faHandHoldingDollar}
                      />
                      <div className="value-info-hd">
                        {commission?.loading ? (
                          <Spin size="small" />
                        ) : (
                          <div>
                            {numeral(
                              commission?.payload?.message
                                ?.lifetime_earning?.[0]?.customer_commission
                            ).format("$0,0.0'")}
                          </div>
                        )}
                      </div>
                      <div className="total-info-hd">TOTAL Referral FEE</div>
                    </div>
                  </div>

                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon
                        className="info-icon-main"
                        icon={faHandHoldingDollar}
                      />
                      <div className="value-info-hd">
                        {commission?.loading ? (
                          <Spin size="small" />
                        ) : (
                          <div>
                            {numeral(
                              commission?.payload?.message
                                ?.lifetime_earning?.[0]?.cashback_amount
                            ).format("$0,0.0'")}
                          </div>
                        )}
                      </div>
                      <div className="total-info-hd">TOTAL CASHBACK</div>
                    </div>
                  </div>

                  {account?.payload?.Data?.account_type === "influencer" ? (
                    <>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                        <div className="dashboard-info-box">
                          <FontAwesomeIcon
                            className="info-icon-main"
                            icon={faHandHoldingDollar}
                          />
                          <div className="value-info-hd">
                            {commission?.loading ? (
                              <Spin size="small" />
                            ) : (
                              <div>
                                {numeral(
                                  commission?.payload?.message
                                    ?.lifetime_earning?.[0]?.creator_commission
                                ).format("$0,0.0'")}
                              </div>
                            )}
                          </div>
                          <div className="total-info-hd">Total Review Fee</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon
                        className="info-icon-main"
                        icon={faHandHoldingDollar}
                      />
                      <div className="value-info-hd">
                        {commission?.loading ? (
                          <Spin size="small" />
                        ) : (
                          <div>
                            {numeral(
                              commission?.payload?.message
                                ?.lifetime_earning?.[0]?.cashback_amount +
                                commission?.payload?.message
                                  ?.lifetime_earning?.[0]?.customer_commission +
                                (commission?.payload?.message
                                  ?.lifetime_earning?.[0]?.creator_commission
                                  ? commission?.payload?.message
                                      ?.lifetime_earning?.[0]
                                      ?.creator_commission
                                  : 0)
                            ).format("$0,0.0'")}
                          </div>
                        )}
                      </div>
                      <div className="total-info-hd">TOTAL EARNINGS</div>
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <h2 className="title-hd">Pending Balance</h2>
                  </div>

                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon
                        className="info-icon-main"
                        icon={faHandHoldingDollar}
                      />
                      <div className="value-info-hd">
                        {commission?.loading ? (
                          <Spin size="small" />
                        ) : (
                          <div>
                            {numeral(
                              commission?.payload?.message?.pending_balance?.[0]
                                ?.customer_commission
                            ).format("$0,0.0'")}
                          </div>
                        )}
                      </div>
                      <div className="total-info-hd">TOTAL Referral FEE</div>
                    </div>
                  </div>

                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon
                        className="info-icon-main"
                        icon={faHandHoldingDollar}
                      />
                      <div className="value-info-hd">
                        {commission?.loading ? (
                          <Spin size="small" />
                        ) : (
                          <div>
                            {numeral(
                              commission?.payload?.message?.pending_balance?.[0]
                                ?.cashback_amount
                            ).format("$0,0.0'")}
                          </div>
                        )}
                      </div>
                      <div className="total-info-hd">TOTAL CASHBACK</div>
                    </div>
                  </div>

                  {account?.payload?.Data?.account_type === "influencer" ? (
                    <>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                        <div className="dashboard-info-box">
                          <FontAwesomeIcon
                            className="info-icon-main"
                            icon={faHandHoldingDollar}
                          />
                          <div className="value-info-hd">
                            {commission?.loading ? (
                              <Spin size="small" />
                            ) : (
                              <div>
                                {numeral(
                                  commission?.payload?.message
                                    ?.pending_balance[0]?.creator_commission
                                ).format("$0,0.0'")}
                              </div>
                            )}
                          </div>
                          <div className="total-info-hd">Total Review Fee</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box">
                      <FontAwesomeIcon
                        className="info-icon-main"
                        icon={faHandHoldingDollar}
                      />
                      <div className="value-info-hd">
                        {commission?.loading ? (
                          <Spin size="small" />
                        ) : (
                          <div>
                            {numeral(
                              commission?.payload?.message?.pending_balance?.[0]
                                ?.cashback_amount +
                                commission?.payload?.message
                                  ?.pending_balance?.[0]?.customer_commission +
                                (commission?.payload?.message
                                  ?.pending_balance[0]?.creator_commission
                                  ? commission?.payload?.message
                                      ?.pending_balance[0]?.creator_commission
                                  : 0)
                            ).format("$0,0.0'")}
                          </div>
                        )}
                      </div>
                      <div className="total-info-hd">TOTAL EARNINGS</div>
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <h2 className="title-hd">Available to Withdraw</h2>
                  </div>
                  <div className="col-12 col-xl-3 col-lg-3 col-md-6">
                    <div className="dashboard-info-box btn-style2">
                      <FontAwesomeIcon
                        className="info-icon-main"
                        icon={faMoneyBillTransfer}
                      />
                      <div className="value-info-hd">
                        {commission?.loading ? (
                          <Spin size="small" />
                        ) : (
                          <div>{numeral(totalWithdraw).format("$0,0.0'")}</div>
                        )}
                      </div>
                      <div className="total-info-hd">AVAILABLE TO WITHDRAW</div>

                      <Button
                        className="primary-btn mt-2"
                        onClick={() => {
                          checkPaypal();
                        }}
                      >
                        Withdraw Amount
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>

        <Modal
          dialogClassName="code-activated-popup"
          size="md"
          scrollable
          show={modal}
          onHide={() => setModal(false)}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Form
              className="modal-form-style"
              form={form}
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 24,
              }}
              autoComplete="off"
            >
              <h3>Withdraw Amount</h3>
              <div className="d-flex w-100 pt-3">
                <InputNumber
                  className="flex-grow-1"
                  addonBefore="$"
                  min={1}
                  value={inputValue}
                  onChange={(value) => setInputValue(value)}
                  size="large"
                  formatter={(value) => `${value}`}
                  parser={(value) => value.replace(".", "")}
                  placeholder="Enter Withdraw Amount"
                  type="number"
                />
                <Button
                  onClick={handleSubmit}
                  className="submit-btn-style "
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
