import { Button } from "react-bootstrap";

export default function ContinueShopping({ handleSuccessClick }) {
	return (
		<div class="container order-confirmation">
			<div class="row">
				<div class="col-md-12 text-center">
					<h1>
						<i class="bi bi-check-circle-fill text-success"></i> Order Confirmation
					</h1>
					<p>We have received your order and it is currently being processed. A confirmation email with your order details will be sent to you shortly.</p>

					<Button onClick={handleSuccessClick} className="btn btn-primary">
						Continue Shopping
					</Button>
				</div>
			</div>
		</div>
	);
}
