import { GET_INFLUENCER_PROFILE_REQUEST, GET_INFLUENCER_PROFILE_SUCCESS, GET_INFLUENCER_PROFILE_ERROR, GET_INFLUENCER_PROFILE_LOADMORE } from "../../types/types";

export default function influencerProf(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_INFLUENCER_PROFILE_REQUEST:
      return {
        loading: true,
      };
    case GET_INFLUENCER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

      case GET_INFLUENCER_PROFILE_LOADMORE:
      return {
        ...state,
        loading: false,
        payload: {
          ...state.payload.message,
          message:{
            ...payload.message,
            data:[...state.payload.message.data,...payload.message.data]
          }
        },
      };
    case GET_INFLUENCER_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
