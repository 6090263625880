// React and related hooks imports
import React, { useLayoutEffect, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { privateReviews, savedEvents } from "../../redux/actions/save.action";

// Component and utility imports
import { Button } from "react-bootstrap";
import List from "../../components/HomeShows/List"

// Style imports
import "../../css/live-events.scss";
import "../../css/influencers_reviews.scss";

export default function Shows({ name }) {
    let userInfo = localStorage.getItem("userInfo");
    if (userInfo !== "undefined") {
        userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } else {
        localStorage.removeItem("userInfo");
        window.location.reload();
    }
    const navigate = useNavigate();
    let location = useLocation();
    const path = location.pathname.slice(1, location.pathname.length);

    const [currentShow, setCurrentShow] = useState(name);
    const [loadingSpinner, setLoadingSpinner] = useState(true);
    const [page, setPage] = useState(1);
    const [trending, setTrending] = useState(false);
    const queryParams = queryString.parse(window.location.search);
    const [origin, setOrigin] = useState("");

    const [dept, setDept] = useState("");

    const [filterPost, setFilterPost] = useState({
        genderCategoryId: null,
        categoryId: null,
        subCategoryId: [],
        sortBy: "",
        eventType: "",
        applyFilter: "all",
    });

    const dispatch = useDispatch();
    const {  reviewSaved, eventSaved } = useSelector((state) => {
        return state;
    });

    useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	}, []);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

    useEffect(() => {
        if (window.location.search) {
            setTrending(true);
        } else {
            setTrending(false);
        }
    }, [window.location.search]);

    const [size, onChangeSize] = useState(0);

    useLayoutEffect(() => {
        const updateSize = () => {
            const width = window.innerWidth;
            if (width <= 576) {
                onChangeSize(4); // small screens (mobile)
            } else if (width > 576 && width <= 992) {
                onChangeSize(6); // medium screens (tablet)
            } else {
                onChangeSize(12); // large screens (desktop and above)
            }
        };

        // Attach the event listener to the window resize event
        window.addEventListener("resize", updateSize);

        // Call the updateSize function to set the initial size
        updateSize();

        // Clean up by removing the event listener when the component is unmounted
        return () => {
            window.removeEventListener("resize", updateSize);
        };
    }, []);

    useLayoutEffect(() => {
        setCurrentShow(name);
        setOrigin(path);
    }, [location]);

    useLayoutEffect(() => {
        setLoadingSpinner(true);

        getApiData().then(() => {
            setPage(2);
            setLoadingSpinner(false);
        });

    }, [currentShow, queryParams.sortBy]);

    const apiMapping = {
        saved: { apiCall: privateReviews, data: reviewSaved },
        savedevents: { apiCall: savedEvents, data: eventSaved }
    };

    function getApiData() {
        return dispatch(apiMapping[currentShow].apiCall(1, 12, false, userInfo?._id));
    }

    function loadMore() {
        dispatch(apiMapping[currentShow].apiCall(page, 12, true, userInfo?._id)).then(() => setPage(page + 1));
    }

    function getData() {
        return apiMapping[currentShow]?.data;
    }

    return (
        <div className="live-event-main">
            <div className="midd-width-ift">
                <div className="live-shows">
                    <div>
                        <div className="event-filter mt-0">
                    
                            <Button
                                className={path == "saved" || path == "" ? "active" : ""}
                                onClick={() => {
                                    navigate("/saved");
                                }}
                            >
                                Saved Reviews
                            </Button>

                            <Button
                                className={path == "saved-events" || path == "" ? "active" : ""}
                                onClick={() => {
                                    navigate("/saved-events");
                                }}
                            >
                                Saved Shows
                            </Button>
                        </div>
                        {loadingSpinner ? (
                            <div className="all_influencers_reviews">
                                <div className="event-container">
                                    <div className="influencers-col-box infl-box-gap">
                                        {new Array(size).fill().map((_, i) => {
                                            return <div className="event-col-box"></div>;
                                        })}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <List category={filterPost.categoryId} department={dept} page={page - 1} origin={origin} name={currentShow} loading={getData()?.loading} loadMore={loadMore} hasMore={getData()?.payload?.message?.next?.page ? true : false} post={getData()?.payload?.message?.data} />
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
}