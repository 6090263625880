import { GET_CHAT_TOKEN, GET_CHAT_REQUEST, GET_CHAT_SUCCESS, GET_CHAT_ERROR, SAVE_CHAT_REQUEST, SAVE_CHAT_SUCCESS, SAVE_CHAT_ERROR, POST_REVIEW_MESSAGE_REQUEST, POST_REVIEW_MESSAGE_SUCCESS, POST_REVIEW_MESSAGE_ERROR, POST_REVIEW_CHAT_REQUEST, POST_REVIEW_CHAT_SUCCESS, POST_REVIEW_CHAT_ERROR, GET_CHAT_LOGS_REQUEST, GET_CHAT_LOGS_SUCCESS, GET_CHAT_LOGS_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const createChatToken = (event_id, user_id, token) => (dispatch) => {
	return axios({
		method: "POST",
		url: `${BASEURL}chat/gettoken`,
		data: { event_id },
		headers: { Authorization: `Bearer ${token}` },
		// headers: {
		// 	Accept: "application/json",
		// },
	})
		.then((response) => {
			return dispatch({
				type: GET_CHAT_TOKEN,
				payload: response.data?.message,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_CHAT_ERROR,
				payload: error.response,
			});
		});
};

export const getChat = (event_id, token) => (dispatch) => {
	dispatch({ type: GET_CHAT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}chat/messages`,
		headers: { Authorization: `Bearer ${token}` },
		data: { event_id },
	})
		.then((response) => {
			return dispatch({
				type: GET_CHAT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_CHAT_ERROR,
				payload: error.response,
			});
		});
};

export const saveChat = (event_id, message, token) => (dispatch) => {
	dispatch({ type: SAVE_CHAT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}chat/save`,
		headers: { Authorization: `Bearer ${token}` },
		data: { event_id, message },
	})
		.then((response) => {
			return dispatch({
				type: SAVE_CHAT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SAVE_CHAT_ERROR,
				payload: error.response,
			});
		});
};

export const getChatLogs = (event_id, token) => (dispatch) => {
	dispatch({ type: GET_CHAT_LOGS_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}chat/logs`,
		headers: { Authorization: `Bearer ${token}` },
		data: { event_id },
	})
		.then((response) => {
			return dispatch({
				type: GET_CHAT_LOGS_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_CHAT_LOGS_ERROR,
				payload: error.response,
			});
		});
};

export const getRefchat = (review_id, token) => (dispatch) => {
	dispatch({ type: POST_REVIEW_MESSAGE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}reviewchat/messages?page=1&limit=1000`,
		headers: { Authorization: `Bearer ${token}` },
		data: { review_id },
	})
		.then((response) => {
			return dispatch({
				type: POST_REVIEW_MESSAGE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_REVIEW_MESSAGE_ERROR,
				payload: error.response,
			});
		});
};

export const saveRefchat = (review_id, message, token) => (dispatch) => {
	dispatch({ type: POST_REVIEW_CHAT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}reviewchat/save`,
		headers: { Authorization: `Bearer ${token}` },
		data: { review_id, message },
	})
		.then((response) => {
			return dispatch({
				type: POST_REVIEW_CHAT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_REVIEW_CHAT_ERROR,
				payload: error.response,
			});
		});
};
