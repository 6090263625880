import Layout from "./component/layout";
import AccountDetail from "./component/accountDetail";
export default () => {
	return (
		<>
			<Layout title="Profile">
				<AccountDetail />
			</Layout>
		</>
	);
};
