import { BRAND_LIVE_EVENT_REQUEST, BRAND_LIVE_EVENT_SUCCESS, BRAND_LIVE_EVENT_ERROR, LIVE_EVENT_REQUEST, LIVE_EVENT_SUCCESS, LIVE_EVENT_ERROR, ALL_LIVE_EVENT_REQUEST, ALL_LIVE_EVENT_SUCCESS, ALL_LIVE_EVENT_ERROR, ALL_UPCOMING_EVENT_REQUEST, ALL_UPCOMING_EVENT_SUCCESS, ALL_UPCOMING_EVENT_ERROR, UPCOMING_EVENT_REQUEST, UPCOMING_EVENT_SUCCESS, UPCOMING_EVENT_ERROR, ALL_PUBLISH_EVENT_REQUEST, ALL_PUBLISH_EVENT_SUCCESS, ALL_PUBLISH_EVENT_ERROR, ALL_PUBLISH_EVENT_SUCCESS_LOADMORE, ALL_LIVE_EVENT_SUCCESS_MORE, ALL_UPCOMING_EVENT_SUCCESS_MORE, NEWALL_PUBLISH_EVENT_SUCCESS_LOADMORE, NEWALL_PUBLISH_EVENT_SUCCESS, NEWALL_PUBLISH_EVENT_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getAllUpComingEvent =
	(page = 1, limit = 25, gen_Ids = [], cat_Ids = [], sub_cat_Ids = [], brand, sort_by, title, loadMore) =>
	(dispatch) => {
		// dispatch({ type: ALL_UPCOMING_EVENT_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}public/events/upcoming?page=${page}&limit=${limit}`,
			headers: {
				Accept: "application/json",
			},
			data: {
				gen_Ids: gen_Ids,
				cat_Ids: cat_Ids,
				sub_cat_Ids: sub_cat_Ids,
				brand: brand == "1" ? "" : brand,
				sort_by: sort_by,
				title: title,
			},
		})
			.then((response) => {
				if (loadMore) {
					return dispatch({
						type: ALL_UPCOMING_EVENT_SUCCESS_MORE,
						payload: response.data,
					});
				} else {
					return dispatch({
						type: ALL_UPCOMING_EVENT_SUCCESS,
						payload: response.data,
					});
				}
			})
			.catch((error) => {
				return dispatch({
					type: ALL_UPCOMING_EVENT_ERROR,
					payload: error.response,
				});
			});
	};

export const getAllLiveEvent =
	(page = 1, limit = 25, gen_Ids = [], cat_Ids = [], sub_cat_Ids = [], brand, sort_by, title, loadMore) =>
	(dispatch) => {
		// dispatch({ type: ALL_LIVE_EVENT_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}public/events/live?page=${page}&limit=${limit}`,
			headers: {
				Accept: "application/json",
			},
			data: {
				gen_Ids: gen_Ids,
				cat_Ids: cat_Ids,
				sub_cat_Ids: sub_cat_Ids,
				brand: brand == "1" ? "" : brand,
				sort_by: sort_by ? sort_by + "s" : "",
				title: title,
			},
		})
			.then((response) => {
				if (loadMore) {
					return dispatch({
						type: ALL_LIVE_EVENT_SUCCESS_MORE,
						payload: response.data,
					});
				} else {
					return dispatch({
						type: ALL_LIVE_EVENT_SUCCESS,
						payload: response.data,
					});
				}
			})
			.catch((error) => {
				return dispatch({
					type: ALL_LIVE_EVENT_ERROR,
					payload: error.response,
				});
			});
	};

export const getAllPublishedEvent =
	(page = 1, limit = 25, gen_Ids = [], cat_Ids = [], sub_cat_Ids = [], brand, sort_by, title, loadMore, user_id) =>
	(dispatch) => {
		let endPoint = "public/events/published";
		if (!loadMore) {
			dispatch({ type: ALL_PUBLISH_EVENT_REQUEST });
		}
		if (brand || !userInfo) {
			endPoint = "public/events/published";
		}

		if (userInfo) {
			endPoint = "private/events/published";
		}

		return axios({
			method: "POST",
			url: `${BASEURL}${endPoint}?page=${page}&limit=${limit}`,
			headers: { Authorization: `Bearer ${userInfo?.token}` },
			data: { gen_Ids: gen_Ids, cat_Ids: cat_Ids, sub_cat_Ids: "", brand: brand == "1" ? "" : brand, sort_by: sort_by ? sort_by + "s" : "", title: title, user_id: user_id },
		})
			.then((response) => {
				if (loadMore) {
					return dispatch({
						type: ALL_PUBLISH_EVENT_SUCCESS_LOADMORE,
						payload: response.data,
					});
				} else {
					return dispatch({
						type: ALL_PUBLISH_EVENT_SUCCESS,
						payload: response.data,
					});
				}
			})
			.catch((error) => {
				return dispatch({
					type: ALL_PUBLISH_EVENT_ERROR,
					payload: error.response,
				});
			});
	};

export const getnewAllPublishedEvent =
	(page = 1, limit = 25, gen_Ids = [], cat_Ids = [], sub_cat_Ids = [], brand, sort_by, title, loadMore, user_id) =>
	(dispatch) => {
		// dispatch({ type: ALL_PUBLISH_EVENT_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}public/events/newpublished?page=${page}&limit=${limit}`,
			headers: {
				Accept: "application/json",
			},
			data: { gen_Ids: gen_Ids, cat_Ids: cat_Ids, sub_cat_Ids: "", brand: brand == "1" ? "" : brand, sort_by: sort_by ? sort_by + "s" : "", title: title, user_id: user_id },
		})
			.then((response) => {
				if (loadMore) {
					return dispatch({
						type: NEWALL_PUBLISH_EVENT_SUCCESS_LOADMORE,
						payload: response.data,
					});
				} else {
					return dispatch({
						type: NEWALL_PUBLISH_EVENT_SUCCESS,
						payload: response.data,
					});
				}
			})
			.catch((error) => {
				return dispatch({
					type: NEWALL_PUBLISH_EVENT_ERROR,
					payload: error.response,
				});
			});
	};

// 	export const getnewAllPublishedEvent =
//   (page = 1, limit = 25, [] , [] , [] , [], [] , [], loadMore, user_id) =>
//   (dispatch) => {
//     // dispatch({ type: ALL_PUBLISH_EVENT_REQUEST });
//     return axios({
//       method: "POST",
//       url: `${BASEURL}public/events/newpublished?page=${page}&limit=${limit}`,
//       headers: {
//         Accept: "application/json",
//       },
//       data: { user_id: user_id},
//     })
//       .then((response) => {
//         if (loadMore) {
//           return dispatch({
//             type: NEWALL_PUBLISH_EVENT_SUCCESS_LOADMORE,
//             payload: response.data,
//           });
//         } else {
//           return dispatch({
//             type: NEWALL_PUBLISH_EVENT_SUCCESS,
//             payload: response.data,
//           });
//         }
//       })
//       .catch((error) => {
//         return dispatch({
//           type: NEWALL_PUBLISH_EVENT_ERROR,
//           payload: error.response,
//         });
//       });
//   };

export const getBrandLiveEvent =
	(page = 1, limit = 25, username, sort_by) =>
	(dispatch) => {
		dispatch({ type: BRAND_LIVE_EVENT_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}public/events/getbrandevents?page=${page}&limit=${limit}`,
			headers: {
				Accept: "application/json",
			},
			data: {
				username: username,
				sort_by: sort_by,
			},
		})
			.then((response) => {
				return dispatch({
					type: BRAND_LIVE_EVENT_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: BRAND_LIVE_EVENT_ERROR,
					payload: error.response,
				});
			});
	};

export const getLiveEvent = (id) => (dispatch) => {
	dispatch({ type: LIVE_EVENT_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}events/getscheduleevent/${id}`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: LIVE_EVENT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: LIVE_EVENT_ERROR,
				payload: error.response,
			});
		});
};

export const getUpComingEvent = (id) => (dispatch) => {
	dispatch({ type: UPCOMING_EVENT_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}public/events/getoneupcomingevent/${id}`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: UPCOMING_EVENT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPCOMING_EVENT_ERROR,
				payload: error.response,
			});
		});
};
