import {

  CREATE_FEEDS_REQUEST,
  CREATE_FEEDS_SUCCESS,
  CREATE_FEEDS_ERROR,
  GET_FEED_SUCCESS,
  GET_FEED_REQUEST,
  GET_FEED_ERROR,
  GET_FEED_SUCCESS_LOADMORE
} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
var userInfo = JSON.parse(localStorage.getItem("userInfo"));



export const createFeeds = (token , interest_change) => (dispatch) => {
dispatch({ type: CREATE_FEEDS_REQUEST });
return axios({
  method: "POST",
  url: `${BASEURL}users/feed/create`,
  headers: { Authorization: `Bearer ${userInfo?.token}`},
  data: {
    interest_change
  }
})
  .then((response) => {
    return dispatch({
      type: CREATE_FEEDS_SUCCESS,
      payload: response.data,
    });
  })
  .catch((error) => {
    return dispatch({
      type: CREATE_FEEDS_ERROR,
      payload: error.response,
    });
  });
};

export const getFeeds =
	(page, limit, gen_Ids = [], cat_Ids = [], sub_cat_Ids = [], brand, sort_by, title, loadMore, user_id) =>
	(dispatch) => {
    
		let endPoint = "public/reviews/published";

		if (userInfo) {
			endPoint = "private/reviews/feed";
		}

		return axios({
			method: "POST",
			url: `${BASEURL}${endPoint}?page=${page}&limit=${limit}`,
			headers: { Authorization: `Bearer ${userInfo?.token}` },
			data: { gen_Ids: gen_Ids, cat_Ids: cat_Ids, sub_cat_Ids: sub_cat_Ids, brand: brand == "1" ? "" : brand, sort_by: sort_by, title, user_id: user_id },
		})
			.then((response) => {
				if (loadMore) {
					return dispatch({
						type: GET_FEED_SUCCESS_LOADMORE,
						payload: response.data,
					});
				} else {
					return dispatch({
						type: GET_FEED_SUCCESS,
						payload: response.data,
					});
				}
			})
			.catch((error) => {
				return dispatch({
					type: GET_FEED_ERROR,
					payload: error.response,
				});
			});
	};