// App.js
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input } from "antd";
import { Col, Row } from "react-bootstrap";
import { updateAccount } from "../../redux/actions/account.action";

export default function RegisterNew() {
	const dispatch = useDispatch();

	const { account } = useSelector((state) => state);

	const onFinish = (data) => {
		data["first_name"] = data?.firstName;
		data["last_name"] = data?.lastName;
		data["email"] = data?.email;
		data["is_new"] = true;

		dispatch(updateAccount({ ...data, token: account?.payload?.Data?.token })).then(() => {
			window.location.reload();
		});
	};

	const inputRef1 = useRef(null);
	const inputRef2 = useRef(null);
	const inputRef3 = useRef(null);

	const handleKeyDown = (event, inputRef, nextInputRef, nextPlaceholder) => {
		if (event.key === "Enter") {
			event.preventDefault();
			inputRef.current.placeholder = nextPlaceholder;
			nextInputRef.current.focus();
		}
	};

	return (
		<>
			<h1>Welcome to ORME!</h1>
			<p>Please complete user information. After saving, a verification mail will be sent to your inbox.</p>
			<Form onFinish={onFinish}>
				<Row>
					<Col xs={12}>
						<Form.Item
							name="firstName"
							rules={[
								{
									required: true,
									message: "Please Enter First Name",
								},
							]}
						>
							<Input placeholder="First Name" size="large" ref={inputRef1} onKeyDown={(event) => handleKeyDown(event, inputRef1, inputRef2, "Last Name")} />
						</Form.Item>
					</Col>

					<Col xs={12}>
						<Form.Item
							name="lastName"
							rules={[
								{
									required: true,
									message: "Please Enter Last Name",
								},
							]}
						>
							<Input placeholder="Last Name" size="large" ref={inputRef2} onKeyDown={(event) => handleKeyDown(event, inputRef2, inputRef3, "Email")} />
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item
							name={["email"]}
							rules={[
								{
									type: "email",
									required: true,
									message: "Please Enter Email",
								},
							]}
						>
							<Input placeholder="Email" size="large" ref={inputRef3} />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className="d-flex justify-content-between">
							<Button type="primary" htmlType="submit" size="large" block>
								Save
							</Button>
						</div>
					</Col>
				</Row>
			</Form>
		</>
	);
}
