import { ALL_LIVE_EVENT_REQUEST, ALL_LIVE_EVENT_SUCCESS, ALL_LIVE_EVENT_ERROR, ALL_LIVE_EVENT_SUCCESS_MORE } from "../../types/types";
export default function allLiveEvent(state = {loading:true}, action) {
  const { type, payload } = action;

  switch (type) {
    case ALL_LIVE_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_LIVE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case ALL_LIVE_EVENT_SUCCESS_MORE:
      return {
        ...state,
        loading: false,
        payload: {
          ...state.payload.message,
          message: {
            ...payload.message,
            data: [...state.payload.message.data, ...payload.message.data],
          },
        },
      };
    case ALL_LIVE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    default:
      return state;
  }
}
