import { GET_ALL_BRAND_REQUEST, GET_ALL_BRAND_SUCCESS, GET_ALL_BRAND_ERROR, GET_ALL_BRAND_LOADMORE } from "../../types/types";

export default function brand(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_ALL_BRAND_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_ALL_BRAND_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ALL_BRAND_LOADMORE:
			return {
				...state,
				loading: false,
				payload: {
					total: state.payload.count,
					data: [...state?.payload?.data, ...payload?.data],
				},
			};
		case GET_ALL_BRAND_ERROR:
			return {
				...state,
				loading: false,
				payload: payload ? payload : [],
			};

		default:
			return state;
	}
}
