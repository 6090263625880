import React, { useState, useEffect } from "react";
import { Spin, Select, Input, Checkbox, Button, Form, Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { createShippingAddress, getAllShippingAddress, updateDefaultShippingAddress, updatedShippingAddress, deleteShipAddress } from "../../../redux/actions/shipping.action";
import { getShopifyCart } from "../../../redux/actions/liveEvent.action";
import { getCountry } from "../../../redux/actions/country.action";
// import { getAccount } from "../../../redux/actions/account.action";
import { getState } from "../../../redux/actions/state.action";
import { getCity } from "../../../redux/actions/city.action";
import { getAccount, updateAccount } from "../../../redux/actions/account.action";

import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import "../../../css/account-setup.scss";
import FloatLabel from "../../../components/floatLabel";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { ADDRESSKEY } from "../../../config";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function ShippingAddress({ isShipTo, shippingAddressId, setUpdateShop, setCart, brandId, setStatus, page, mode }) {
	const navigate = useNavigate();
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const { account, addShippingAddress, allShippingAddress, setShippingAddress, deleteShippingAddress, upgradeShippingAddress } = useSelector((state) => state);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [addAddress, setAddAddress] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [address, setAddress] = useState("");
	const [apartment, setApartment] = useState("");
	const [country, setCountry] = useState("");
	const [state, setState] = useState("");
	const [city, setCity] = useState("");
	const [company, setCompany] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [phone, setPhone] = useState("");
	const [countryList, setCountryList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [editSingleId, setEditSingleId] = useState("");
	const [cityCode, setCityCode] = useState("");
	const [autoAdd, setAutoAdd] = useState("");
	const [resetKey, setResetKey] = useState(0);
	// const new_autoAdd = autoAdd.split(',')
	// console.log(new_autoAdd, 'aaaa');
	// console.log(new_autoAdd[1], 'data');

	const resetAutocomplete = () => {
		setAddress(""); // Clear the address state
		setResetKey((prevKey) => prevKey + 1); // Increment the key to force re-render
	};

	let addressId = allShippingAddress?.payload?.message?.filter((item) => item?.is_default === true);

	useEffect(() => {
		if (shippingAddressId === undefined) {
			dispatch(getAllShippingAddress(userInfo?.token));
		}
		getCountryFunc();
	}, []);

	useEffect(() => {
		if (mode === "add") {
			setAddAddress(true);
			form.resetFields();
			onReset(true);
		}

		if (mode === "view") {
			setAddAddress(false);
		}
	}, [mode]);

	const onReset = () => {
		form.setFieldsValue({
			firstName: account?.payload?.Data?.first_name,
			lastName: account?.payload?.Data?.last_name,
		});
		setFirstName(account?.payload?.Data?.first_name);
		setLastName(account?.payload?.Data?.last_name);
		setAddress();
		setApartment();
		setCountry();
		setCity();
		setPhone();
		setZipCode();
		setCompany();
		setEditSingleId();
		setState();
	};

	useEffect(() => {
		if (shippingAddressId) {
			setEditSingleId(shippingAddressId);
			setAddAddress(true);
		}
	}, [shippingAddressId]);

	useEffect(() => {
		if (editSingleId) {
			let singleShippingAddress = allShippingAddress?.payload?.message.find((item) => item?._id === editSingleId);

			const updateFormFields = () => {
				form.setFieldsValue({
					firstName: singleShippingAddress?.firstName || account?.payload?.Data?.first_name,
					lastName: singleShippingAddress?.lastName || account?.payload?.Data?.last_name,
					address: singleShippingAddress?.address1,
					apartment: singleShippingAddress?.address2,
					city: singleShippingAddress?.city,
					country: singleShippingAddress?.country,
					zip: singleShippingAddress?.zip,
					company: singleShippingAddress?.company,
					phone: singleShippingAddress?.phone,
					state: singleShippingAddress?.province,
				});

				setFirstName(singleShippingAddress?.firstName || account?.payload?.Data?.first_name);
				setLastName(singleShippingAddress?.lastName || account?.payload?.Data?.last_name);
				setAddress(singleShippingAddress?.address1);
				setApartment(singleShippingAddress?.address2);
				setCountry(singleShippingAddress?.country);
				setCity(singleShippingAddress?.city);
				setPhone(singleShippingAddress?.phone);
				setZipCode(singleShippingAddress?.zip);
				setCompany(singleShippingAddress?.company);
				setState(singleShippingAddress?.province);
			};

			const fetchStatesAndCities = async () => {
				const stateResponse = await dispatch(getState("US"));
				if (stateResponse?.payload?.message) {
					const selectStates = stateResponse.payload.message.map(({ name, isoCode }) => ({
						value: isoCode,
						label: name,
					}));
					setStateList(selectStates);
				}

				const cityResponse = await dispatch(getCity("US", singleShippingAddress?.province));
				if (cityResponse?.payload?.message) {
					const selectCities = cityResponse.payload.message.map(({ name }) => ({
						value: name,
						label: name,
					}));
					setCityList(selectCities);
				}
			};

			updateFormFields();
			fetchStatesAndCities();
		}
	}, [editSingleId]);

	const onFinish = async (values) => {
		values["address"] = address;
		if (editSingleId) {
			dispatch(updatedShippingAddress({ ...values, address_id: editSingleId }, userInfo?.token)).then(() => {
				dispatch(getAllShippingAddress(userInfo?.token)).then(() => {
					if (page) {
						setUpdateShop(addressId[0]?._id);
					} else {
						dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
							if (res?.payload?.message?.lineItems?.length > 0) {
								setStatus(false);
							} else {
								setStatus(true);
							}
							setCart(res?.payload?.message);
						});
					}
				});
				form.resetFields();
				setAddAddress(false);
				window.scrollTo(0, 0);
				Swal.fire({
					icon: "success",
					title: "Shipping address updated",
				});
			});
		} else {
			if (userInfo?.token) {
				dispatch(createShippingAddress(values, userInfo?.token)).then(() => {
					if (page === "shop") {
						if (!account?.payload?.Data?.first_name && !account?.payload?.Data?.last_name) {
							dispatch(
								updateAccount({
									first_name: values?.firstName,
									last_name: values?.lastName,
									token: userInfo?.token,
								})
							).then(() => {
								dispatch(getAccount(userInfo?.token));
							});
						}
					}
					dispatch(getAllShippingAddress(userInfo?.token));
					form.resetFields();
					setAddAddress(false);
					window.scrollTo(0, 0);
					Swal.fire({
						icon: "success",
						title: "Shipping address added ",
					});
				});
			}
		}
	};
	const onFinishFailed = (errorInfo) => console.log("Failed:", errorInfo);
	const getCountryFunc = () => {
		if (country?.length === 0) {
			dispatch(getCountry()).then((res) => {
				const selectCountries = [];
				res?.payload?.message?.map(({ name, code1 }) => {
					return selectCountries.push({ value: code1, label: name });
				});
				setCountryList(selectCountries);
			});
		}
	};

	const getStateFunc = (country) => {
		return dispatch(getState("US")).then((res) => {
			const selectStates = [];
			res?.payload?.message?.map(({ name, isoCode }) => {
				return selectStates.push({ value: isoCode, label: name });
			});
			setStateList(selectStates);
			setCityList([]);
		});
	};
	const getCityFunc = (country, state) => {
		if ((country, state)) {
			return dispatch(getCity(country, state)).then((res) => {
				const selectStates = [];
				res?.payload?.message?.map(({ name }) => {
					return selectStates.push({ value: name, label: name });
				});
				setCityList(selectStates);
			});
		} else {
			return dispatch(getCity("US", cityCode)).then((res) => {
				const selectStates = [];
				res?.payload?.message?.map(({ name }) => {
					return selectStates.push({ value: name, label: name });
				});
				setCityList(selectStates);
			});
		}
	};

	const changeCountry = (value) => {
		setCountry(value);
		getStateFunc("US");
		form.resetFields(["state"]);
		form.resetFields(["city"]);
	};
	const changeState = (value) => {
		setState(value);
		getCityFunc("US", value);
		form.resetFields(["city"]);
	};

	const changeCity = (value) => setCity(value);

	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	}, []);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	return (
		<>
			<div className="account-left-content">
				<div className="plus-btn col-xl-6 col-lg-12 col-md-12 p-0 d-flex justify-content-between">
					{!page && (
						<div className="">
							<Button
								className="back-button 0"
								onClick={() => {
									navigate("/account-settings");
								}}
							>
								<FontAwesomeIcon icon={faAngleLeft} /> Back
							</Button>
						</div>
					)}
					{!page && (
						<Button
							onClick={() => {
								resetAutocomplete();
								setAddAddress(true);
								form.resetFields();
								onReset(true);
							}}
							className="plus-address"
						>
							<i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add Address
						</Button>
					)}
				</div>

				<div className="account-detail col-12 col-xl-6 col-lg-12 col-md-12">
					<div className="card-information-store">
						<>
							{allShippingAddress?.payload?.data?.success === false && !addAddress && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
							{allShippingAddress?.loading || setShippingAddress?.loading ? <Spin className="d-flex justify-content-center mt-4" size="large" /> : ""}
							{!addAddress && (
								<div className={`address-list`}>
									{!allShippingAddress?.loading &&
										allShippingAddress?.payload?.message?.map((item, key) => {
											return item.is_default ? (
												<div key={key} className="address-box dft-box">
													<div className="ship-address-iner">
														<h4>Default</h4>
														<span>{item?.address1}</span>
														<span>{item?.address2}</span>
														<span>
															{item?.city}, {item.province} {item?.zip}
														</span>
														<span>United States</span>
													</div>
													<div className="ship-btns">
														<Button
															onClick={() => {
																setAddAddress(true);
																setEditSingleId(item?._id);
															}}
														>
															Edit
														</Button>
													</div>
												</div>
											) : (
												<div key={key} className="address-box">
													<div className="ship-address-iner">
														<span>{item?.address1}</span>
														<span>{item?.address2}</span>
														<span>
															{item?.city}, {item.province} {item?.zip}
														</span>
														<span>United States</span>
													</div>
													<div className="ship-btns">
														<Button
															onClick={() => {
																setAddAddress(true);
																setEditSingleId(item?._id);
															}}
														>
															Edit
														</Button>
														{deleteShippingAddress.loading ? (
															<Button>
																{" "}
																<Spin indicator={antIcon} />
															</Button>
														) : (
															<Button
																onClick={() => {
																	dispatch(deleteShipAddress(item?._id, userInfo.token)).then(() => {
																		dispatch(getAllShippingAddress(userInfo?.token));
																		window.scrollTo(0, 0);
																		Swal.fire({
																			icon: "success",
																			title: "Shipping address deleted",
																		});
																	});
																}}
															>
																Delete
															</Button>
														)}
													</div>
													<Checkbox
														onChange={() => {
															dispatch(updateDefaultShippingAddress(item._id, userInfo.token)).then(() => {
																window.scrollTo(0, 0);
																Swal.fire({
																	icon: "success",
																	title: "Default address updated",
																}).then(() => {
																	dispatch(getAllShippingAddress(userInfo?.token)).then(() => {
																		dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
																			if (page) {
																				setUpdateShop(addressId[0]?._id);
																			}

																			if (res?.payload?.message?.lineItems?.length > 0) {
																				setStatus(false);
																			} else {
																				setStatus(true);
																			}
																			setCart(res?.payload?.message);
																		});
																	});
																});
															});
														}}
													>
														Set as default address
													</Checkbox>
												</div>
											);
										})}
								</div>
							)}
						</>

						<Form onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" form={form} size="large" name="user_login" className={`login-form ${addAddress ? "d-block" : "d-none"}`} layout="vertical">
							<div className="billing-address-store">
								<div className="cd-detail-box cd-detail-box-gap">
									<div className="cd-2col">
										<FloatLabel label="First Name" name="firstName" value={firstName}>
											<Form.Item
												name="firstName"
												rules={[
													{
														required: true,
														message: "Please insert your first name",
													},
												]}
											>
												<Input value={firstName} size="large" onChange={(e) => setFirstName(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
									<div className="cd-2col">
										<FloatLabel label="Last Name" name="lastName" value={lastName}>
											<Form.Item
												name="lastName"
												rules={[
													{
														required: true,
														message: "Please insert your last name",
													},
												]}
											>
												<Input value={lastName} size="large" onChange={(e) => setLastName(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
								</div>
								<div className="cd-detail-box spacing">
									<ReactGoogleAutocomplete
										key={resetKey}
										apiKey={ADDRESSKEY}
										defaultValue={address}
										onPlaceSelected={(place) => {
											setAutoAdd(place?.formatted_address);
											const addressComponents = place.address_components;
											const street = addressComponents.find((c) => c.types.includes("route"))?.long_name;
											const city = addressComponents.find((c) => c.types.includes("locality"))?.long_name;
											const state = addressComponents.find((c) => c.types.includes("administrative_area_level_1"))?.long_name;
											const country = addressComponents.find((c) => c.types.includes("country"))?.long_name;
											const zipCode = addressComponents.find((c) => c.types.includes("postal_code"))?.long_name;
											setAddress(`${street}, ${city}, ${state}, ${country}`);
											const countryShortName = place.address_components.find((component) => {
												return component.types.includes("country");
											}).short_name;
											const stateShortName = place.address_components.find((component) => {
												return component.types.includes("administrative_area_level_1");
											}).short_name;
											const cityShortName = place.address_components.find((component) => {
												return component.types.includes("locality");
											}).short_name;
											const updateFormFields = () => {
												changeCountry();
												changeState(stateShortName);
												form.setFieldsValue({
													country: countryShortName,
													city: cityShortName,
													zip: zipCode,
													state: stateShortName,
												});
												setCountry(countryShortName);
												setCity(cityShortName);
												setZipCode(zipCode);
												setState(stateShortName);
											};
											updateFormFields();
										}}
										options={{
											types: ["address"],
											componentRestrictions: { country: "us" }, // Restrict to a specific country if needed
										}}
										placeholder="Enter Address"
										size="large"
										style={{ width: "100%", height: "40px", border: "1px solid #b9bdbb" }} // Adjust the style as needed
									/>
								</div>
								<div className="cd-detail-box">
									<FloatLabel label="Apartment, suite, etc. (optional)" name="apartmet" value={apartment}>
										<Form.Item name="apartment">
											<Input value={apartment} size="large" onChange={(e) => setApartment(e.target.value)} />
										</Form.Item>
									</FloatLabel>
								</div>
								<div className="cd-detail-box cd-detail-box-gap">
									<div className="cd-2col">
										<FloatLabel label="Select Country" name="country" value={country}>
											<Form.Item
												name="country"
												rules={[
													{
														required: true,
														message: "Please insert country",
													},
												]}
											>
												<Select showSearch placeholder="Select Country" size="large" optionFilterProp="children" onChange={changeCountry} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
													{countryList?.map((item) => (
														<Option value={item.value}>{item.label}</Option>
													))}
												</Select>
											</Form.Item>
										</FloatLabel>
									</div>
									<div className="cd-2col">
										<FloatLabel label="Select State" name="state" value={state}>
											<Form.Item
												name="state"
												rules={[
													{
														required: true,
														message: "Please insert state",
													},
												]}
											>
												<Select showSearch placeholder="Select State" optionFilterProp="children" size="large" onChange={changeState} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
													{stateList?.map((item) => (
														<Option value={item?.value}>{item?.label}</Option>
													))}
												</Select>
											</Form.Item>
										</FloatLabel>
									</div>
								</div>
								<div className="cd-detail-box cd-detail-box-gap">
									<div className="cd-2col">
										<FloatLabel label="Select City" name="city" value={city}>
											<Form.Item
												name="city"
												rules={[
													{
														required: true,
														message: "Please insert city",
													},
												]}
											>
												<Select onChange={changeCity} showSearch placeholder="Select City" size="large" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
													{cityList?.map((item) => (
														<Option value={item.value}>{item.label}</Option>
													))}
												</Select>
											</Form.Item>
										</FloatLabel>
									</div>
									<div className="cd-2col">
										<FloatLabel label="Zip Code" name="zipcode" value={zipCode}>
											<Form.Item
												name="zip"
												type="number"
												rules={[
													{
														required: true,
														message: "Please insert zip code",
													},
													{
														message: "Please enter valid zip code",
														pattern: new RegExp(/^[0-9]+$/),
													},
												]}
											>
												<Input value={zipCode} maxlength={6} size="large" onChange={(e) => setZipCode(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
								</div>
								<div className="cd-detail-box cd-detail-box-gap">
									<div className="cd-2col">
										<FloatLabel label="Company (optional)" name="state" value={company}>
											<Form.Item name="company">
												<Input value={company} size="large" onChange={(e) => setCompany(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
									<div className="cd-2col">
										<FloatLabel label="Phone" name="phone" value={phone} type="number">
											<Form.Item
												name="phone"
												rules={[
													{
														required: true,
														message: "Please insert phone number",
													},
													{
														message: "Please enter valid phone number",
														pattern: new RegExp(/^[+]?\d+$/),
													},
												]}
											>
												<Input maxLength={15} value={phone} size="large" onChange={(e) => setPhone(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
								</div>
								<div className="cd-detail-box cd-detail-box-gap">
									{addShippingAddress?.loading || upgradeShippingAddress.loading ? (
										<Button>
											{" "}
											<Spin indicator={antIcon} />
										</Button>
									) : (
										<Button htmlType="submit">Save</Button>
									)}
									<Button
										onClick={() => {
											setAddAddress(false);
										}}
										htmlType="button"
									>
										Cancel
									</Button>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}
