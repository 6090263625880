import {  GET_USER_NOTIFICATION_SUCCESS,GET_USER_NOTIFICATION_ERROR,GET_USER_NOTIFICATION_REQUEST,UPDATE_USER_NOTIFICATION_REQUEST,UPDATE_USER_NOTIFICATION_ERROR,UPDATE_USER_NOTIFICATION_SUCCESS } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
// var userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getUserNotifications = (token,page,limit) => (dispatch) => {
  dispatch({ type: GET_USER_NOTIFICATION_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}follower/getFollowingNotification?page=${page}&limit=${limit}`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return dispatch({
        type: GET_USER_NOTIFICATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_USER_NOTIFICATION_ERROR,
        payload: error.response,
      });
    });
};

export const updateUserNotification = (token) => (dispatch) => {
  dispatch({ type: UPDATE_USER_NOTIFICATION_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}follower/updateNotification`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return dispatch({
        type: UPDATE_USER_NOTIFICATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_USER_NOTIFICATION_ERROR,
        payload: error.response,
      });
    });
};