import { GET_ACCOUNT_REQUEST, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_ERROR, GET_UPDATE_ACCOUNT_REQUEST, GET_UPDATE_ACCOUNT_SUCCESS, GET_UPDATE_ACCOUNT_ERROR, GET_DELETE_ACCOUNT_REQUEST, GET_DELETE_ACCOUNT_SUCCESS, GET_DELETE_ACCOUNT_ERROR, POST_VERIFICATION_REQUEST, POST_VERIFICATION_SUCCESS, POST_VERIFICATION_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
// var userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getAccount = (token) => (dispatch) => {
	dispatch({ type: GET_ACCOUNT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}auth/user/validate`,
		headers: { Authorization: `Bearer ${token}` },
	})
		.then((response) => {
			return dispatch({
				type: GET_ACCOUNT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ACCOUNT_ERROR,
				payload: error.response,
			});
		});
};

export const updateAccount =
	({ token, is_new, first_name, last_name, nick_name, phone, email }) =>
	(dispatch) => {
		dispatch({ type: GET_UPDATE_ACCOUNT_REQUEST });
		return axios({
			method: "PUT",
			url: `${BASEURL}users/revise/updateuserpofile/customer`,
			headers: { Authorization: `Bearer ${token}` },
			data: {
				first_name,
				last_name,
				nick_name,
				is_new,
				phone,
				email,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_UPDATE_ACCOUNT_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_UPDATE_ACCOUNT_ERROR,
					payload: error.response,
				});
			});
	};

export const deleteAccount = (token, userid) => (dispatch) => {
	dispatch({ type: GET_DELETE_ACCOUNT_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}users/revise/deactivate/${userid}`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			userid: userid,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_DELETE_ACCOUNT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_DELETE_ACCOUNT_ERROR,
				payload: error.response,
			});
		});
};

export const verifyAccount = (token) => (dispatch) => {
	dispatch({ type: POST_VERIFICATION_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}public/user/verifyEmail`,
		//headers: { Authorization: `Bearer ${token}` },
		data: {
			token,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_VERIFICATION_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_VERIFICATION_ERROR,
				payload: error.response,
			});
		});
};
