import { useSelector } from "react-redux";
import BrandLiveEvent from "../../../components/liveEvent/liveEvent";
import BrandUpcomingEvent from "../../../components/upComingEvent/upComingEvent";
import BrandPublishedEvent from "../../../components/publishEvent/publishEvent";
import { Empty } from "antd";

export default ({ filterPost }) => {
  const { allLiveEvent, allUpcomingEvent, allPublishEvent, bioShopMenu } = useSelector((state) => {
    return state;
  });

  return (
    <>
      <div className="live-event-main">
        <div className="bioshop-live-event-container">
          <div className="live-shows">
            {allLiveEvent?.payload?.message?.data.length === 0 && allUpcomingEvent?.payload?.message?.data.length === 0 && allPublishEvent?.payload?.message?.data.length === 0 && (
              <div className="align-items-center justify-content-center">
                <div className="no_data_main">
                  <div className="midd-width-ifti">
                    <div className="no_data_content">
                      <div className="error_header">
                        <h3>No Data Available</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {bioShopMenu?.payload?.message?.data._id && <BrandLiveEvent origin={"bioshop"} genderCategoryId={filterPost?.genderCategoryId} categoryId={filterPost.categoryId} subCategoryId={filterPost.subCategoryId} sortBy={filterPost?.sortBy} eventType={filterPost?.eventType} applyFilter={filterPost?.applyFilter} source="bioshop"/>}
            {bioShopMenu?.payload?.message?.data._id && <BrandUpcomingEvent origin={"bioshop"} genderCategoryId={filterPost?.genderCategoryId} categoryId={filterPost.categoryId} subCategoryId={filterPost.subCategoryId} sortBy={filterPost?.sortBy} eventType={filterPost?.eventType} applyFilter={filterPost?.applyFilter} source="bioshop"/>}
            {bioShopMenu?.payload?.message?.data._id && <BrandPublishedEvent origin={"bioshop"} genderCategoryId={filterPost?.genderCategoryId} categoryId={filterPost.categoryId} subCategoryId={filterPost.subCategoryId} sortBy={filterPost?.sortBy} eventType={filterPost?.eventType} applyFilter={filterPost?.applyFilter} source="bioshop"/>}
          </div>
        </div>
      </div>
    </>
  );
};
