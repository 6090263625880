import { Spin } from "antd";
import { useSelector } from "react-redux";
// import { getShopifyCart } from "../../redux/actions/liveEvent.action";
// import { removePromoCode } from "../../redux/actions/shopify.removePromoCode.action";
import numeral from "numeral";

export default function SubTotal({ brandId, setStatus, cart, setCart }) {
	// const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	// const dispatch = useDispatch();

	const { shopifyApplyPromoCode, shopifyRemovePromoCode } = useSelector((state) => {
		return state;
	});

	function shippingRates() {
		return numeral(parseFloat(cart?.shippingLine?.amount)).format("0.00");
	}

	return (
		<>
			<div className="checkout-order-total">
				{(shopifyApplyPromoCode?.loading || shopifyRemovePromoCode?.loading) && <Spin size="medium" />}
				<div className="subtotal">
					<span>Subtotal</span>
					<span>{numeral(cart?.lineItemsSubtotalPrice?.amount).format("$0,0.0'")}</span>
					{/* <span>123</span> */}
				</div>
				{/* {cart?.discountApplications[0]?.applicable && (
					<div className="discount-main">
						<div className="discount-left">
							<div>discount</div>
							<i className="fa fa-tags"></i>
							<p>{cart?.discountApplications[0]?.code}</p>
						</div>
						<div className="discount-right">
							<a onClick={removeDiscountAndRefresh} href="#" className="remove-discount">
								<i className="fa fa-times"></i>
							</a>
							<div className="shipping tax">
								<b>${calculateDiscount()}</b>
							</div>
						</div>
					</div>
				)} */}
				{cart?.shippingLine && (
					<div className="shipping">
						<span>Shipping</span>
						<span>${shippingRates()}</span>
					</div>
				)}
				{cart?.totalTaxV2 && (
					<div className="tax shipping">
						<span>Tax</span>
						<span>{numeral(cart?.totalTaxV2.amount).format("$0,0.0'")}</span>
					</div>
				)}
				
				<div className="grand-total shipping">
					<span>Total</span>
					<span>{numeral(cart?.paymentDue?.amount).format("$0,0.0'")}</span>
				</div>
			</div>
		</>
	);
}
