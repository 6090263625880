import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getGenderCategory } from "../../../redux/actions/category.action";
import {
  getInterests,
  updateInterests,
} from "../../../redux/actions/interest.action";
import { createFeeds } from "../../../redux/actions/feeds.action";
import Swal from "sweetalert2";
import "../../../css/myinterest.scss";
import { Spin } from "antd";

export default function Interest() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();
  const [genderCategory, setGenderCategory] = useState([]);
  const [genCategoryId, setGenCategoryId] = useState([]);
  const [selectnew, setSelectnew] = useState(false)
  const { UpdateInterest } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
    return () => {
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  useEffect(() => {
    dispatch(getGenderCategory()).then((res) =>
      setGenderCategory(res.payload.data)
    );
    dispatch(getInterests(userInfo?.token)).then((res) =>
      setGenCategoryId(res?.payload?.message?.map(({ _id }) => _id) || [])
    );
  }, []);

  const toggleCategoryId = (_id) => {
    setSelectnew(true)
    setGenCategoryId(
      genCategoryId.includes(_id)
        ? genCategoryId?.filter((id) => id !== _id)
        : [...genCategoryId, _id]
    );
  };

  return (
    <div className="account-left-content">
      <div className="account-detail col-12 col-xl-6 col-lg-12 col-md-12">
        <div className="card-information-store">
          <div className="my-interest-filter">
            <div className="filter-hd">Select Department</div>
            <div className="filter-box">
              <Button
                onClick={() => setGenCategoryId([])}
                className={genCategoryId?.length === 0 && "active"}
                variant="primary"
              >
                All
              </Button>
              {genderCategory?.map(({ _id, name }) => (
                <Button
                  key={_id}
                  onClick={() => toggleCategoryId(_id)}
                  className={genCategoryId?.includes(_id) && "active"}
                  variant="primary"
                >
                  {name}
                </Button>
              ))}
            </div>
            <div className="filter-buttons">
              <Button onClick={() => setGenCategoryId([])} variant="secondary">
                Reset all
              </Button>

              {UpdateInterest?.loading ? (
                <Button>
                  <Spin indicator={antIcon} />
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() =>
                    dispatch(
                      updateInterests(userInfo?.token, genCategoryId)
                    ).then(
                      (res) =>
                        res?.payload?.success &&
                        dispatch(
                          createFeeds(userInfo?.token, selectnew ? true : false)
                        ) &&
                        Swal.fire({
                          icon: "success",
                          title: "Interest saved successfully",
                        })
                    )
                  }
                >
                  Apply
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
