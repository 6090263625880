import { GET_CHAT_LOGS_REQUEST, GET_CHAT_LOGS_SUCCESS, GET_CHAT_LOGS_ERROR } from "../../types/types";
export default function chatLogs(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_CHAT_LOGS_REQUEST:
			return {
				loading: true,
			};
		case GET_CHAT_LOGS_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_CHAT_LOGS_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}