// Libraries and Frameworks
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import numeral from "numeral";
import Swal from "sweetalert2";
// External modules and components
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Tabs, Empty, Spin, InputNumber, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Icons and Utilities
import {
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

// Assets
import wallet1 from "../../../images/brand-logos/wallet-icon.svg";
import wallet2 from "../../../images/brand-logos/balance-icon.svg";
import wallet3 from "../../../images/brand-logos/dollar-icon.svg";
import PPlogo from "../../../images/pp-logo.jpg";

// Redux actions
import {
  getCommissionReport,
  getOne,
  requestWithdraw,
  withdrawLimit
} from "../../../redux/actions/wallet.action";
import { disconnectPaypal } from "../../../redux/actions/payout.action";
import { getAccount } from "../../../redux/actions/account.action";
const { TabPane } = Tabs;

export default ({ pageStatus, handleLogin }) => {
  // Hooks and redux-related utilities
  const dispatch = useDispatch();
  const { commissionEarn, walletgetOne, commission, account, limitWithdraw } = useSelector(
    (state) => state
  );
  const [form] = Form.useForm();
  const navigate = useNavigate()
  // React state variables for UI/UX
  const [isChecked, setIsChecked] = useState(false)
  const [paymentRequestModal, setPaymentRequestModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [activeKey, setActiveKey] = useState();
  const [inputValue, setInputValue] = useState(null); // State to store the input value
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [status, setStatus] = useState("All");

  // Simple constants and configurations

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const limit = 15;

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  useEffect(() => {
    dispatch(withdrawLimit(userInfo?.token))
    dispatch(getOne(userInfo?.token, 1, limit, "All")).then((res) => { });
    dispatch(
      getCommissionReport(userInfo?.token, "", "", "all", "all", "all", "all")
    );
  }, []);

  const minamount = limitWithdraw?.payload?.data?.min_amount;
  const maxamount = limitWithdraw?.payload?.data?.max_amount;

  const handleSubmit = () => {
    if (inputValue <= totalWithdraw && inputValue <= maxamount && inputValue >= minamount) {
      Swal.fire({
        title: "Withdraw Amount",
        text: `Are you sure you want to Withdraw $${inputValue} ?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#010b40",
        cancelButtonColor: "#d33",
        imageAlt: "Withdraw Amount",
        reverseButtons: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(requestWithdraw(userInfo?.token, inputValue)).then((res) => {
            Swal.fire({
              icon: "success",
              title: res?.payload?.message,
            });
            dispatch(getOne(userInfo?.token, 1, limit, "All")).then(
              (res) => { }
            );
          });
          setModal(false);
          dispatch(
            getCommissionReport(
              userInfo?.token,
              "",
              "",
              "all",
              "all",
              "all",
              "all"
            )
          );
        } else {
          setModal(false);
        }
      });
      setInputValue();
    } else if (inputValue > totalWithdraw) {
      Swal.fire({
        icon: "error",
        title: "Provide valid amount.",
      });
    } else if (inputValue < maxamount) {
      Swal.fire({
        icon: "error",
        title: "Invalid amount: Please enter a value between $25 and $5,000.",
      });
    }
  };

  const handledelete = () => {
    Swal.fire({
      customClass: {
        icon: "paypal-confirm-modal",
      },
      title: `Are you sure you want Disconnect paypal?`,
      iconHtml: `<img src=${PPlogo}>`,
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#010b40",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(disconnectPaypal(userInfo?.token)).then(() => {
          dispatch(getAccount(userInfo?.token));
          dispatch(getOne(userInfo?.token, 1, limit, "All")).then((res) => { });
        });
        setModal(false);
        dispatch(
          getCommissionReport(
            userInfo?.token,
            "",
            "",
            "all",
            "all",
            "all",
            "all"
          )
        );
      } else {
        setModal(false);
      }
    });
  };

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(getOne(userInfo?.token, page + 1, limit, status)).then((res) => {
      setLoading(false);
      setTotalCount(res?.payload?.totalCount);
    });
  };

  const totalWithdraw =
    commission?.payload?.message?.available_to_withdraw[0]?.cashback_amount +
    commission?.payload?.message?.available_to_withdraw[0]?.creator_commission +
    commission?.payload?.message?.available_to_withdraw[0]
      ?.customer_commission -
    commission?.payload?.message?.withdraw_amount;

  const totalEarning =
    commission?.payload?.message?.pending_balance[0]?.cashback_amount +
    commission?.payload?.message?.pending_balance[0]?.creator_commission +
    commission?.payload?.message?.pending_balance[0]?.customer_commission;

  const LifetimeEarning =
    commission?.payload?.message?.lifetime_earning[0]?.cashback_amount +
    commission?.payload?.message?.lifetime_earning[0]?.creator_commission +
    commission?.payload?.message?.lifetime_earning[0]?.customer_commission;

  const onChange = (key) => {
    if (key === "1") {
      setStatus("All");
      setCurrentPage(0);
      dispatch(getOne(userInfo?.token, 1, limit, "All")).then((res) => { });
    }

    if (key === "2") {
      setStatus("Pending");
      setCurrentPage(0);
      dispatch(getOne(userInfo?.token, 1, limit, "Pending")).then((res) => { });
    }

    if (key === "3") {
      setStatus("Approved");
      setCurrentPage(0);
      dispatch(getOne(userInfo?.token, 1, limit, "Approved")).then((res) => { });
    }

    if (key === "4") {
      setCurrentPage(0);
      setStatus("Processing");
      dispatch(getOne(userInfo?.token, 1, limit, "Processing")).then(
        (res) => { }
      );
    }

    if (key === "5") {
      setCurrentPage(0);
      setStatus("Rejected");
      dispatch(getOne(userInfo?.token, 1, limit, "Rejected")).then((res) => { });
    }
  };

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  return (
    <>
      <div className="account-left-content">

        <div className="">
          <Button
            className="back-button 0 mb-3"
            onClick={() => {
              navigate("/account-settings");
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} /> Back
          </Button>
        </div>
        

        <div className="wallet-content-main">
          <div className="wallet-row-1">
            <div className="balance-box">
              <div className="inr-box-main">
                <div className="wallet-icon pp-logo">
                  <img className="pp-img" src={PPlogo} alt="" />
                </div>
                <div className="wallet-data">
                  <div className="wallet-detail">
                    <span className="box-title">Paypal</span>

                    {pageStatus === "Disconnect" ? (
                      <>
                        <div>Please Connect to Paypal </div>
                        <div className="withdraw mt-1">
                          <a
                            onClick={() => {
                              handleLogin();
                              setPaymentRequestModal(true);
                            }}
                            href="javascript:void"
                            className="mr-0"
                          >
                            Connect Paypal
                          </a>
                          <span></span>
                        </div>
                        <div className="pp-disconnected">Disconnected</div>
                      </>
                    ) : (
                      <>
                        <div
                          className="email-text"
                          title={account?.payload?.Data?.paypal_email}
                        >
                          {account?.payload?.Data?.paypal_email}
                        </div>
                        <div className="withdraw mt-1">
                          <a
                            onClick={() => {
                              handledelete();
                            }}
                            className="mr-0"
                          >
                            Disconnect Paypal
                          </a>
                          <span></span>
                        </div>
                        <div className="pp-connected">Connected</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="balance-box">
              <div className="inr-box-main">
                <div className="wallet-icon">
                  <img src={wallet3} alt="" className="filter-gray" />
                  {/* <FontAwesomeIcon className="filter-gray" icon={faSackDollar} /> */}
                </div>
                <div className="wallet-data">
                  <div className="wallet-detail">
                    <span className="box-title">Lifetime Earning</span>
                    <div className="box-ammount">
                      $
                      {LifetimeEarning
                        ? numeral(LifetimeEarning).format("0.00")
                        : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="balance-box">
              <div className="inr-box-main">
                <div className="wallet-icon">
                  <img src={wallet1} alt="" className="filter-gray" />
                </div>
                <div className="wallet-data">
                  <div className="wallet-detail">
                    <span className="box-title">Pending Balance</span>
                    <div className="box-ammount">
                      ${totalEarning ? numeral(totalEarning).format("0.00") : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="balance-box">
              <div className="inr-box-main">
                <div className="wallet-icon">
                  <img src={wallet1} alt="" className="filter-gray" />
                </div>
                <div className="wallet-data">
                  <div className="wallet-detail">
                    <span className="box-title">Withdraw Balance</span>
                    <div className="box-ammount">
                      ${commission?.payload?.message?.withdraw_amount ? numeral(commission?.payload?.message?.withdraw_amount).format("0.00") : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}



            <div className="balance-box">
              <div className="inr-box-main">
                <div className="wallet-icon">
                  <img src={wallet2} alt="" className="filter-gray" />
                </div>
                <div className="wallet-data">
                  <div className="wallet-detail">
                    <span className="box-title">Available to Withdraw</span>
                    <div className="box-ammount">
                      $
                      {totalWithdraw
                        ? numeral(totalWithdraw).format("0.00")
                        : 0}
                    </div>
                  </div>

                  <div className="withdraw btn-style2">
                    {commissionEarn?.payload?.data?.total_balance === 0 ? (
                      <></>
                    ) : (
                      <>
                        {pageStatus === "Disconnect" ? (
                          <></>
                        ) : (
                          <>
                            <Button
                              className="primary-btn mt-2"
                              onClick={() => {
                                setModal(true);
                                setInputValue(true);
                                setIsChecked(false)
                              }}
                              disabled={
                                isNaN(totalWithdraw) ||
                                totalWithdraw <= 0 || totalWithdraw <= minamount
                              }
                            >
                              Withdraw Amount
                            </Button>
                          </>
                        )}
                        <a href="javascript:void">Link Account</a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="withdraw-request">
            <h3>Withdraw Requests</h3>
            <div className="favourite-brands-container">
              <Tabs
                defaultActiveKey={"1"}
                activeKey={activeKey}
                onChange={onChange}
              >
                <TabPane tab="All Requests" key="1">
                  <div className="purchase-data">
                    <Table responsive size="sm" className="transactions-box">
                      <>
                        <thead>
                          <tr>
                            <th>S.#</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {walletgetOne?.loading ? (
                            <div className="antd-loading-ift">
                              <Spin size="large"></Spin>
                            </div>
                          ) : walletgetOne?.payload?.data?.length === 0 ? (
                            <tr>
                              <td colspan="12">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              </td>
                            </tr>
                          ) : (
                            walletgetOne?.payload?.data.map((item, i) => {
                              return (
                                <tr>
                                  <td>{i + 1 + currentPage * 15}</td>
                                  <td>
                                    {moment(item?.created_date).format(
                                      "MM-DD-YYYY"
                                    )}
                                  </td>

                                  {item?.withdraw_amount ? (
                                    <td>
                                      $
                                      {numeral(item?.withdraw_amount).format(
                                        "0"
                                      )}
                                    </td>
                                  ) : (
                                    <td>{"-"}</td>
                                  )}
                                  <td>{item?.status ? item?.status : "-"}</td>
                                </tr>
                              );
                            })
                          )}

                          {walletgetOne?.payload?.data?.length > 0 && (
                            <tr>
                              <td>
                                <b>Total</b>
                              </td>
                              <td>
                                {" "}
                                <b>
                                  {walletgetOne?.payload?.data?.length +
                                    currentPage * 15}
                                </b>
                              </td>
                              <td>
                                <b>
                                  {" "}
                                  {numeral(
                                    walletgetOne?.payload?.data.reduce(
                                      (a, b) => {
                                        return (
                                          Number(a) +
                                          Number(
                                            b.withdraw_amount
                                              ? b.withdraw_amount
                                              : 0.0
                                          )
                                        );
                                      },
                                      0
                                    )
                                  ).format("$0")}
                                </b>
                              </td>
                              <td></td>
                            </tr>
                          )}
                        </tbody>
                      </>
                    </Table>
                  </div>
                </TabPane>

                <TabPane tab="Pending" key="2">
                  <div className="purchase-data">
                    <Table
                      responsive="scrollable"
                      size="sm"
                      className="transactions-box"
                    >
                      <thead>
                        <tr>
                          <th>S.#</th>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {walletgetOne?.loading ? (
                          <div className="antd-loading-ift">
                            <Spin size="large"></Spin>
                          </div>
                        ) : walletgetOne?.payload?.data?.length === 0 ? (
                          <tr>
                            <td colspan="12">
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </td>
                          </tr>
                        ) : (
                          walletgetOne?.payload?.data.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1 + currentPage * 15}</td>
                                <td>
                                  {moment(item?.created_date).format(
                                    "MM-DD-YYYY"
                                  )}
                                </td>

                                {item?.withdraw_amount ? (
                                  <td>
                                    $
                                    {numeral(item?.withdraw_amount).format("0")}
                                  </td>
                                ) : (
                                  <td>{"-"}</td>
                                )}
                                <td>{item?.status ? item?.status : "-"}</td>
                              </tr>
                            );
                          })
                        )}

                        {walletgetOne?.payload?.data?.length > 0 && (
                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              {" "}
                              <b>
                                {walletgetOne?.payload?.data?.length +
                                  currentPage * 15}
                              </b>
                            </td>
                            <td>
                              <b>
                                {" "}
                                {numeral(
                                  walletgetOne?.payload?.data.reduce((a, b) => {
                                    return (
                                      Number(a) +
                                      Number(
                                        b.withdraw_amount
                                          ? b.withdraw_amount
                                          : 0.0
                                      )
                                    );
                                  }, 0)
                                ).format("$0")}
                              </b>
                            </td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </TabPane>

                <TabPane tab="In Process" key="4">
                  <div className="purchase-data">
                    <Table
                      responsive="scrollable"
                      size="sm"
                      className="transactions-box"
                    >
                      <thead>
                        <tr>
                          <th>S.#</th>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {walletgetOne?.loading ? (
                          <div className="antd-loading-ift">
                            <Spin size="large"></Spin>
                          </div>
                        ) : walletgetOne?.payload?.data?.length === 0 ? (
                          <tr>
                            <td colspan="12">
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </td>
                          </tr>
                        ) : (
                          walletgetOne?.payload?.data.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1 + currentPage * 15}</td>
                                <td>
                                  {moment(item?.created_date).format(
                                    "MM-DD-YYYY"
                                  )}
                                </td>

                                {item?.withdraw_amount ? (
                                  <td>
                                    $
                                    {numeral(item?.withdraw_amount).format("0")}
                                  </td>
                                ) : (
                                  <td>{"-"}</td>
                                )}
                                <td>{item?.status ? item?.status : "-"}</td>
                              </tr>
                            );
                          })
                        )}
                        {walletgetOne?.payload?.data?.length > 0 && (
                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              {" "}
                              <b>
                                {walletgetOne?.payload?.data?.length +
                                  currentPage * 15}
                              </b>
                            </td>
                            <td>
                              <b>
                                {" "}
                                {numeral(
                                  walletgetOne?.payload?.data.reduce((a, b) => {
                                    return (
                                      Number(a) +
                                      Number(
                                        b.withdraw_amount
                                          ? b.withdraw_amount
                                          : 0.0
                                      )
                                    );
                                  }, 0)
                                ).format("$0")}
                              </b>
                            </td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </TabPane>

                <TabPane tab="Successful" key="3">
                  <div className="purchase-data">
                    <Table
                      responsive="scrollable"
                      size="sm"
                      className="transactions-box"
                    >
                      <thead>
                        <tr>
                          <th>S.#</th>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {walletgetOne?.loading ? (
                          <div className="antd-loading-ift">
                            <Spin size="large"></Spin>
                          </div>
                        ) : walletgetOne?.payload?.data?.length === 0 ? (
                          <tr>
                            <td colspan="12">
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </td>
                          </tr>
                        ) : (
                          walletgetOne?.payload?.data.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1 + currentPage * 15}</td>
                                <td>
                                  {moment(item?.created_date).format(
                                    "MM-DD-YYYY"
                                  )}
                                </td>

                                {item?.withdraw_amount ? (
                                  <td>
                                    $
                                    {numeral(item?.withdraw_amount).format("0")}
                                  </td>
                                ) : (
                                  <td>{"-"}</td>
                                )}
                                <td>{item?.status ? item?.status : "-"}</td>
                              </tr>
                            );
                          })
                        )}

                        {walletgetOne?.payload?.data?.length > 0 && (
                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              {" "}
                              <b>
                                {walletgetOne?.payload?.data?.length +
                                  currentPage * 15}
                              </b>
                            </td>
                            <td>
                              <b>
                                {" "}
                                {numeral(
                                  walletgetOne?.payload?.data.reduce((a, b) => {
                                    return (
                                      Number(a) +
                                      Number(
                                        b.withdraw_amount
                                          ? b.withdraw_amount
                                          : 0.0
                                      )
                                    );
                                  }, 0)
                                ).format("$0")}
                              </b>
                            </td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </TabPane>

                <TabPane tab="Failed" key="5">
                  <div className="purchase-data">
                    <Table
                      responsive="scrollable"
                      size="sm"
                      className="transactions-box"
                    >
                      <thead>
                        <tr>
                          <th>S.#</th>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {walletgetOne?.loading ? (
                          <div className="antd-loading-ift">
                            <Spin size="large"></Spin>
                          </div>
                        ) : walletgetOne?.payload?.data?.length === 0 ? (
                          <tr>
                            <td colspan="12">
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </td>
                          </tr>
                        ) : (
                          walletgetOne?.payload?.data.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1 + currentPage * 15}</td>
                                <td>
                                  {moment(item?.created_date).format(
                                    "MM-DD-YYYY"
                                  )}
                                </td>

                                {item?.withdraw_amount ? (
                                  <td>
                                    $
                                    {numeral(item?.withdraw_amount).format("0")}
                                  </td>
                                ) : (
                                  <td>{"-"}</td>
                                )}
                                <td>{item?.status ? item?.status : "-"}</td>
                              </tr>
                            );
                          })
                        )}

                        {walletgetOne?.payload?.data?.length > 0 && (
                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              {" "}
                              <b>
                                {walletgetOne?.payload?.data?.length +
                                  currentPage * 15}
                              </b>
                            </td>
                            <td>
                              <b>
                                {" "}
                                {numeral(
                                  walletgetOne?.payload?.data.reduce((a, b) => {
                                    return (
                                      Number(a) +
                                      Number(
                                        b.withdraw_amount
                                          ? b.withdraw_amount
                                          : 0.0
                                      )
                                    );
                                  }, 0)
                                ).format("$0")}
                              </b>
                            </td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </TabPane>
              </Tabs>

              <div className="purchase-data referral-data">
                {walletgetOne?.payload?.data?.length ? (
                  <ReactPaginate
                    nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                    pageCount={Math.ceil(
                      walletgetOne?.payload?.totalCount / limit
                    )}
                    forcePage={currentPage}
                    previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                    pageClassName="page-item d-none"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item d-none"
                    breakLinkClassName="page-link"
                    containerClassName={"pagination custom-paginate"}
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                ) : (
                  ""
                )}
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="code-activated-popup"
        size="md"
        scrollable
        show={modal}
        onHide={() => setModal(false)}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form
            className="modal-form-style"
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            autoComplete="off"
          >
            <h3>Notice</h3>
            <p>
              A 2% processing fees will be charged by PayPal. Maximum amount that can be withdrawn is ${maxamount}. Thank you for your understanding.
            </p>

            <Checkbox onChange={handleCheckboxChange}>I have read and understood the above.</Checkbox>
            <div className="d-flex w-100 pt-3">
              <InputNumber
                className="flex-grow-1"
                addonBefore="$"
                // min={1}
                value={inputValue}
                onChange={(value) => setInputValue(value)}
                size="large"
                onKeyDown={(event) => {
                  if (inputValue === null && event.key === '0') {
                    event.preventDefault();
                  }
                }}
                // formatter={(value) => `${value}`}
                // parser={(value) => value.replace(".", "")}
                placeholder={`Enter amount between $${minamount} and $${maxamount}`}
                type="number"
              />
              <Button
                onClick={handleSubmit}
                className="submit-btn-style "
                htmlType="submit"
                disabled={inputValue < minamount || inputValue > maxamount || !isChecked}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
