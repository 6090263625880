import { OTP_LOGIN_REQUEST, OTP_LOGIN_SUCCESS, OTP_LOGIN_ERROR } from "../../types/types";
export default function otpLogged(state = "", action) {
  const { type, payload } = action;
  switch (type) {
    case OTP_LOGIN_REQUEST:
      return {
        loading: true,
      };
    case OTP_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case OTP_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    default:
      return state;
  }
}
