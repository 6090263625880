import React, { useState, useEffect } from "react";
import { Button, Form } from "antd";
import { Input, Checkbox, Spin } from "antd";
import "../../../css/account-setup.scss";
import FloatLabel from "../../../components/floatLabel";
import Swal from "sweetalert2";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { createCreditCard, getAllCreditCard, updateDefaultCard, stripepayment, stripePaylist, updatePayment, deletePayment } from "../../../redux/actions/payment.action";

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faArrowLeft, faArrowRight, faLessThan, faUser } from "@fortawesome/free-solid-svg-icons";

export default function Payment({ cart }) {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();
  const { addCreditCard, paymentList, defaultCard, paymentStripe, paymentUpdate, paymentDetach } = useSelector((state) => state);
  const [addCard, setAddCard] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cvc, setCvc] = useState("");
  //Billing Address
  const [checked, setChecked] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [billingZipCode, setBillingZipCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [singleCard, setSingleCard] = useState([]);
  const [form] = Form.useForm();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    dispatch(stripePaylist(userInfo?.token));
  }, []);

  useEffect(() => {
    if (cart) {
      setAddCard(true);
    }
  }, [cart]);

  // const onReset = () => {
  // 	setCardNumber()
  // 	setExpiryDate()
  // 	setCvc()
  // 	setZipCode()

  // 	setFirstName()
  // 	setLastName()
  // 	setStreetAddress()
  // 	setApartment()
  // 	setBillingZipCode()
  // 	setCity()
  // 	setState()

  // }

  const onFinish = async (values) => {
    if (userInfo.token) {
      dispatch(updatePayment(userInfo.token, singleCard.id, parseInt(values.expiryMonth), parseInt(values.expiryYear))).then((res) => {
        if (res?.payload?.success) {
          Swal.fire({
            icon: "success",
            title: "Card Updated Successfully!",
          });
          dispatch(stripePaylist(userInfo?.token));
          setAddCard(false);
        } else {
          Swal.fire({
            icon: "error",
            title: res?.payload?.data?.message?.raw?.message,
          });
        }
      });
    }
  };

  // const reset = (values) => {
  // 	if (userInfo.token) dispatch(getAllCreditCard(userInfo?.token, values?.cardNumber, values?.expirtyDate, values?.cvc, values?.zipCode ));
  // 	form.resetFields();
  //   };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const deleteCard = async () => {
    if (userInfo.token) {
      dispatch(deletePayment(userInfo.token, singleCard.id)).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Card Deleted Successfully!",
        });
        dispatch(stripePaylist(userInfo?.token));
        setAddCard(false);
      });
    }
  };

  const defaultCardpay = async () => {
    if (userInfo.token) {
      dispatch(updateDefaultCard(userInfo.token, singleCard.id)).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Default Card added Successfully!",
        });
        dispatch(stripePaylist(userInfo?.token));
        setAddCard(false);
      });
    }
  };

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  return (
    <>
      <div className="account-left-content">
      <div className="">
          <Button
            className="back-button mb-3"
            onClick={() => {
              navigate("/account-settings");
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} /> Back
          </Button>
        </div>
        <div className="account-detail col-12 col-xl-6 col-lg-8 col-md-12">
          <div className="card-information-store">
            {cart?.loading ? (
              <Spin className="d-flex justify-content-center" size="large" />
            ) : (
              <>
                {!cart && (
                  <span
                    className="add-card-detail"
                    onClick={() => {
                      dispatch(stripepayment(userInfo?.token, window.location.href)).then((res) => {
                        window.location.href = res?.payload?.message;
                      });
                    }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i> Add Credit Card
                  </span>
                )}
                <div className={`added-cards ${addCard ? "d-none" : "d-block"}`}>
                  {paymentList?.payload?.message?.data?.map((item, key) => {
                    return (
                      <span
                        key={key}
                        onClick={() => {
                          setAddCard(true);
                          setSingleCard(item);
                          form.setFieldsValue({
                            expiryMonth: item?.card?.exp_month,
                            expiryYear: item?.card?.exp_year,
                            cvc: "**** **** ****" + " " + item?.card?.last4,
                          });
                          setExpiryMonth(item?.card?.exp_month);
                          setExpiryYear(item?.card?.exp_year);
                          setCvc("**** **** ****" + item?.card?.last4);
                        }}
                        className="add-card-detail"
                      >
                        <i className="fa fa-cc-visa" style={{ color: "#00ADEF" }} aria-hidden="true"></i>
                        {defaultCard?.loading ? <Spin size="large" /> : "**** **** ****" + item?.card?.last4}
                        <span className="selected-cradit-card">
                          <i className={`fa ${item.is_default ? "fa-check-square-o" : ""}`} aria-hidden="true"></i>
                        </span>
                      </span>
                    );
                  })}
                </div>

                {paymentList?.loading && <Spin className="d-flex justify-content-center mt-4" size="large" />}
                <Form onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" form={form} size="large" name="user_login" className={`login-form ${addCard ? "d-block" : "d-none"}`} layout="vertical">
                  <>
                    <div className="cd-detail-box">
                      <FloatLabel label="Card Number" name="cvc" value={cvc}>
                        <Form.Item
                          name="cvc"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please insert card number",
                          //   },
                          //   {
                          //     message: "Please enter valid card number",
                          //     pattern: new RegExp(/^[0-9]+$/),
                          //   },
                          // ]}
                        >
                          <Input disabled value={cvc} size="large" placeholder="CVC" onChange={(e) => setCvc(e.target.value)} maxLength={3} />
                        </Form.Item>
                      </FloatLabel>
                    </div>
                    <div className="cd-detail-box cd-detail-box-gap">
                      <div className="cd-2col">
                        <FloatLabel label="Expiry Month" name="expiryMonth" value={expiryMonth}>
                          <Form.Item
                            type="number"
                            name="expiryMonth"
                            maxLength={2}
                            rules={[
                              {
                                required: true,
                                message: "Please insert expiry month",
                              },
                              {
                                message: "Please enter valid expiry month",
                                pattern: new RegExp(/^(0?[1-9]|1[0-2])$/),
                              },
                            ]}
                          >
                            <Input maxLength={2} value={expiryMonth} size="large" placeholder="MM" onChange={(e) => setExpiryMonth(e.target.value)} />
                          </Form.Item>
                        </FloatLabel>
                      </div>
                      <div className="cd-2col">
                        <FloatLabel label="Expiry Year" name="expiryYear" value={expiryYear}>
                          <Form.Item
                            name="expiryYear"
                            rules={[
                              {
                                required: true,
                                message: "Please insert expiry year",
                              },
                              {
                                message: "Please enter valid expiry year",
                                pattern: new RegExp(/^202[3-9]|20[3-9]\d|2[1-9]\d{2}$/),
                              },
                            ]}
                          >
                            <Input value={expiryYear} maxLength={4} size="large" placeholder="YYYY" onChange={(e) => setExpiryYear(e.target.value)} />
                          </Form.Item>
                        </FloatLabel>
                      </div>
                    </div>

                    <div className={`cd-detail-box ${checked ? "d-block" : "d-none"}`}>
                      {paymentUpdate?.loading ? (
                        <Button>
                          <Spin size="small" />
                        </Button>
                      ) : (
                        <Button htmlType="submit">Update</Button>
                      )}
                      {paymentDetach?.loading ? (
                        <Button>
                          <Spin size="small" />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            deleteCard();
                          }}
                        >
                          Delete
                        </Button>
                      )}
                      &nbsp;&nbsp;
                      {defaultCard?.loading ? (
                        <Button>
                          <Spin size="small" />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            defaultCardpay();
                          }}
                        >
                          Set as Default Card
                        </Button>
                      )}
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        onClick={() => {
                          form.resetFields();
                          setAddCard(false);
                          window.scrollTo(0, 0);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                </Form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
