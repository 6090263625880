import { PURCHASE_ORDER_REQUEST, PURCHASE_ORDER_SUCCESS, PURCHASE_ORDER_ERROR, POST_PURCHASES_REQUEST , POST_PURCHASES_SUCCESS , POST_PURCHASES_ERROR} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const customerorderHistory = (page, limit ,source, startDate, endDate, category, genderCategory, subCategory, brand, group) => (dispatch) => {
  dispatch({ type: PURCHASE_ORDER_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}customer/reports/getpurchases?page=${page}&limit=${limit}`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
		source:source,
      from_date: startDate,
      to_date: endDate,
      category: category,
      gen_category: genderCategory,
      sub_category: subCategory,
      brand: brand,
      group_by:group,
    },
  })
    .then((response) => {
      return dispatch({
        type: PURCHASE_ORDER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: PURCHASE_ORDER_ERROR,
        payload: error.response,
      });
    });
};

export const getpurchases = (sales_tracker_id) => (dispatch) => {
  dispatch({ type: POST_PURCHASES_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}customer/reports/getpurchasedetail`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      sales_tracker_id,
    },
  })
    .then((response) => {
      return dispatch({
        type: POST_PURCHASES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_PURCHASES_ERROR,
        payload: error.response,
      });
    });
};
