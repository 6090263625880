import { GET_PRODUCT_LIST_SUCCESS, GET_PRODUCT_LIST_ERROR, GET_PRODUCT_LIST_LOADMORE, GET_PRODUCT_LIST_REQUEST } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getProductList = (page, limit, title, loadMore) => (dispatch) => {
	if (!loadMore) if (!loadMore) dispatch({ type: GET_PRODUCT_LIST_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}public/data/products?page=${page}&limit=${limit}`,
		data: { title },
	})
		.then((response) => {
			if (loadMore) {
				return dispatch({
					type: GET_PRODUCT_LIST_LOADMORE,
					payload: response.data,
				});
			} else {
				return dispatch({
					type: GET_PRODUCT_LIST_SUCCESS,
					payload: response.data,
				});
			}
		})
		.catch((error) => {
			return dispatch({
				type: GET_PRODUCT_LIST_ERROR,
				payload: error.response,
			});
		});
};