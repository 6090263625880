import React, { useState, useEffect } from "react";
import { useDispatch , useSelector} from "react-redux";
import Carousel from "react-multi-carousel";
import { getFavouriteBrand } from "../../redux/actions/brand.action";
import "react-multi-carousel/lib/styles.css";
import { NavLink } from "react-router-dom";

export default function FavouriteBrand() {
	const dispatch = useDispatch();
	const { search } = useSelector((state) => {
		return state;
	});
	const [favouriteBrand, setFavouriteBrand] = useState([]);

	useEffect(() => {
		dispatch(getFavouriteBrand(search.payload)).then((res) => {
			setFavouriteBrand(res.payload.data);
		});
	}, [search.payload]);

	const brandlove = {
		superLargeDesktop: {
			breakpoint: { max: 3000, min: 1900 },
			items: 6,
			slidesToSlide: 3, // optional, default to 1.
		},
		desktop: {
			breakpoint: { max: 1899, min: 1025 },
			items: 4,
			slidesToSlide: 3, // optional, default to 1.
		},
		desktopSmaller: {
			breakpoint: { max: 1024, min: 600 },
			items: 3,
			slidesToSlide: 2, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 767, min: 0 },
			items: 1.4,
			slidesToSlide: 1, // optional, default to 1.
		},
	};

	return (
		<div className="brand_you_love_main">
			<div className="midd-width-ifti">
				<div className="brand_you_love">
					<div className="pik-header">
						<h3>Brands You'll Love</h3>
					</div>
					<div className="brand_love_caursol">
						{favouriteBrand && (
							<Carousel responsive={brandlove} containerClass="carousel-container row" removeArrowOnDeviceType={["tablet", "mobile"]}>
								{favouriteBrand?.map((item) => {
									let bioShopUrl = "#";
									if (item?.brandData.length > 0) {
										bioShopUrl = `/${item?.brandData[0]?.pixel_id}`;
									}
									return (
										<div className="brand-item-lv">
											<NavLink to={bioShopUrl} className="ft_box_link">
												<div className="lv-item">
													<div
														className="lv-item-image"
														style={{
															backgroundImage: "url(" + item.desktop_url + ")",
														}}
													></div>
													<span className="d-shop-now">Shop Now</span>
													<span className="lv-logo">
														<img src={item.logo_url} />
													</span>
												</div>
												<div className="lv-detail">
													<div className="lv-d1">
														<span className="lv-name">{item.title}</span>
													</div>
													<div className="lv-d2 mt-2">
														<span className="lv-off">{item.description}</span>
													</div>
												</div>
											</NavLink>
										</div>
									);
								})}
							</Carousel>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
