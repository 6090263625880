import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import { getUserNotifications, updateUserNotification } from "../../redux/actions/userNotification";
import outSideClick from "../../helper/outSideClick";
import InfiniteScroll from "react-infinite-scroll-component";
import { SOCKETURL } from "../../config";
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let userInfo = localStorage.getItem("userInfo");
if (userInfo !== "undefined") {
	userInfo = JSON.parse(userInfo);
} else {
	localStorage.removeItem("userInfo");
	window.location.reload();
}

export default function Notify() {
	const dispatch = useDispatch();
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const limit = 12;
	const [hasMore, sethasMore] = useState(true);
	const [data, setData] = useState([]);
	const didUnmount = useRef(false);
	const [displayNotification, setDisplayNotification] = useState(false);
	const divRef = useRef();
	const { notifications } = useSelector((state) => state);

	const handler = useCallback(() => {
		setDisplayNotification(false);
	}, []);
	outSideClick(divRef, handler);
	const { lastMessage } = useWebSocket(SOCKETURL, {
		shouldReconnect: (closeEvent) => !didUnmount.current,
		reconnectAttempts: 10,
		reconnectInterval: 3000,
	});


	useEffect(()=>{
		if(!displayNotification){
			handleNotifyClick();
		}

	},[displayNotification])

	useEffect(() => {
		if (userInfo?.token) {
			dispatch(getUserNotifications(userInfo.token, page, limit)).then((res) => {
				setPage((prev) => prev + 1);
				if (res?.payload?.success) {
					setData(res?.payload?.followerData);
					setCount(res?.payload?.unViewed);
				}
			});
		}
	}, []);

	useEffect(() => {
		if (lastMessage?.data) {
			let receivedMessage = JSON.parse(lastMessage.data);
			if (receivedMessage?.type === "followersAdd" && receivedMessage?.user_id === userInfo?._id) {
				dispatch(getUserNotifications(userInfo?.token, 1, limit)).then((res) => {
					if (res?.payload?.success) {
						setData(res?.payload?.followerData);
						setCount(res?.payload?.unViewed);
					}
				});
			}
		}
	}, [lastMessage]);

	const handleNotifyClick = async () => {
		if (count > 0) {
			await dispatch(updateUserNotification(userInfo?.token)).then(() => {
				dispatch(getUserNotifications(userInfo?.token, 1, limit)).then((res) => {
					setData(res?.payload?.followerData);
					setCount(0);
				});
			});
		}
	};

	const fetchMoreData = async () => {
		if (!hasMore) return;
		await dispatch(getUserNotifications(userInfo?.token, page, limit)).then((res) => {
			if (res?.payload?.followerData) {
				const newData = [...data, ...res?.payload?.followerData];
				setPage((prev) => prev + 1);
				setData(newData);
				if (notifications?.payload?.followers > newData?.length) {
					sethasMore(true);
				} else {
					sethasMore(false);
				}
			}
		});
	};

	const DropDown = () => {
		const BellIcon = (
			<div>
				<a className="ant-dropdown-trigger notification-scroll ">
					<div className="notification-area">
						<i className="fa fa-bell" aria-hidden="true"></i>
						<div className="notificatoin-count">{count}</div>
					</div>
				</a>
			</div>
		);

		return data?.length > 0 ? (
			<>
				{BellIcon}
				{displayNotification && (
					<div id="scrollableTarget" className={`drop-list-main scrollbar-style`}>
						<InfiniteScroll hasMore={hasMore} scrollableTarget="scrollableTarget" dataLength={data?.length} next={fetchMoreData}>
							<div className="drop-list">
								<ul>
									<li>
										{data?.map((item, index) => {
											const { name, pixel_id, account_type } = item?.userData?.[0] || {};
											const notifClass = `highlight-notification ${item?.viewed ? "read-notification" : "new-notification"}`;
											return (
												<NavLink key={index} className={notifClass} to={account_type === "influencer" ? `/${pixel_id}` : undefined}>
													<b>{name}</b> started following you.
												</NavLink>
											);
										})}
									</li>
								</ul>
							</div>
						</InfiniteScroll>
					</div>
				)}
			</>
		) : (
			BellIcon
		);
	};

	return (
		<div
			ref={divRef}
			onClick={() => {
				if (displayNotification) {
					handleNotifyClick();
				}
				setDisplayNotification(!displayNotification);
			}}
			// className="p-h-user"
		>
			<div className="notification-area-top">
				<DropDown />
			</div>
		</div>
	);
}
