import {
  GET_EVENT_VIEW_REQUEST,
  GET_EVENT_VIEW_SUCCESS,
  GET_EVENT_VIEW_ERROR,
  GET_PREV_VIEW_REQUEST,
  GET_PREV_VIEW_SUCCESS,
  GET_PREV_VIEW_ERROR,
  EVENT_VIEWS_REQUEST,
  EVENT_VIEWS_SUCCESS,
  EVENT_VIEWS_ERROR,
  INFLUENCER_VIEWS_REQUEST,
  INFLUENCER_VIEWS_SUCCESS,
  INFLUENCER_VIEWS_ERROR,
  GET_LIVE_VIEW_REQUEST,
  GET_LIVE_VIEW_SUCCESS,
  GET_LIVE_VIEW_ERROR,
} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export const getEventViews = (review_id) => (dispatch) => {
  dispatch({ type: GET_EVENT_VIEW_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}public/reviews/view`,
    data:{
      review: review_id,
      user: userInfo?._id,
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_EVENT_VIEW_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_EVENT_VIEW_ERROR,
        payload: error.response,
      });
    });
};

export const eventViews = (event_id) => (dispatch) => {
  dispatch({ type: EVENT_VIEWS_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}public/events/getviews/${event_id}`,
  })
    .then((response) => {
      return dispatch({
        type: EVENT_VIEWS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: EVENT_VIEWS_ERROR,
        payload: error.response,
      });
    });
};

export const getPreviews = (event_id) => (dispatch) => {
  dispatch({ type: GET_PREV_VIEW_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}public/events/view`,
    data:{
      event: event_id,
      user: userInfo?._id,
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_PREV_VIEW_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_PREV_VIEW_ERROR,
        payload: error.response,
      });
    });
};

export const viewReviews = (review_id) => (dispatch) => {
  dispatch({ type: INFLUENCER_VIEWS_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}public/reviews/getviews/${review_id}`,
  })
    .then((response) => {
      return dispatch({
        type: INFLUENCER_VIEWS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: INFLUENCER_VIEWS_ERROR,
        payload: error.response,
      });
    });
};


export const getLiveviews = (_id,token) => (dispatch) => {
  dispatch({ type: GET_LIVE_VIEW_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}events/geteventviews/${_id}`,
    headers: { Authorization: `Bearer ${token}` },
    data:{
      id: _id
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_LIVE_VIEW_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_LIVE_VIEW_ERROR,
        payload: error.response,
      });
    });
};