import { GET_INFLUENCERS_REQUEST, GET_INFLUENCERS_SUCCESS, GET_INFLUENCERS_ERROR, GET_INFLUENCERS_LOADMORE } from "../../types/types";

export default function influencers(state = { loading: false, payload: { message: { data: [] } } }, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_INFLUENCERS_REQUEST:
      return {
        ...state, // Preserve the existing state
        loading: true,
      };
    case GET_INFLUENCERS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case GET_INFLUENCERS_LOADMORE:
      return {
        ...state,
        loading: false,
        payload: {
          ...state.payload,
          message: {
            ...state.payload.message,
            data: [...state.payload.message.data, ...payload.message.data],
          },
        },
      };
    case GET_INFLUENCERS_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload ? payload : { message: { data: [] } },
      };

    default:
      return state;
  }
}

