// React
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";

// Antd
import { Tabs, Layout, Collapse } from "antd";

// Bootstrap
import { Button, Modal } from "react-bootstrap";

// Other external libraries
import numeral from "numeral";
import Swal from "sweetalert2";
import queryString from "query-string";
import { isNullOrUndefined } from "url/util";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Redux
import { useSelector, useDispatch, connect } from "react-redux";
import { getEventsBy_Id, getShopifyCart } from "../../redux/actions/liveEvent.action";
import { getProduct } from "../../redux/actions/shopify.getProduct.action";
import { getAllShippingAddress } from "../../redux/actions/shipping.action";
import { addToCart } from "../../redux/actions/shopify.addToCart.action";
import { setShippingRates } from "../../redux/actions/shopify.setShippingRates.action";
import { setShippingAddress } from "../../redux/actions/shopify.setShippingAddress.action";
import * as chatActions from "../../redux/actions/chat";
// import { openLogin } from "../../redux/actions/login.action";
import { getLiveviews } from "../../redux/actions/EventView.action";
import { openRegister } from "../../redux/actions/register.action";
import { salesTracker } from "../../redux/actions/salesTracker.action";
import { getLike } from "../../redux/actions/likesCount.action";

// Local components
import Video from "./video";
import ShippingAddressModal from "../account/component/shippingAddress";
import Chat from "../liveEvent/component/chat";
import Detail from "../liveEvent/component/detail";
import Cart from "../liveEvent/component/cart";
import Shop from "../liveEvent/component/shop";
import CartItem from "../../components/cart/cartItem";
import SubTotal from "../../components/cart/subTotal";
import ContinueShopping from "../../components/cart/continueShopping";
import Shipping from "../../components/cart/shipping";
import Payment from "../../components/cart/payment";
import Notify from "../../components/cart/notify";
// import PromoCodeForm from "../../components/cart/promoCodeForm";

// Local assets
import "../../css/live-events.scss";
import "../../css/checkout.scss";
import basket from "../../images/brand-logos/basket.png";
import closeicon from "../../images/brand-logos/close-icon.svg";

// Constants
import { SOCKETURL } from "../../config";

const { Panel } = Collapse;
const { TabPane } = Tabs;
let soruce1;
let titles1;
let tag1;
let price1;
let brand_id1;
let addBag = [];
let lists_item = [];
let tag = "https://dl1961trial.myshopify.com/23973099/checkouts/3063eb9af1269e7d245bd55bee2630ab?key=e08d0841d1a89ad19c0b444d6055d007";
const { Sider, Content } = Layout;

const PublishedContainer = ({ createChatToken }) => {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	let shareTracking = JSON.parse(localStorage?.getItem("shareTracking"));

	const dispatch = useDispatch();
	const { liveEventId, shopifyProduct, allShippingAddress, shopifyGetShippingRates } = useSelector((state) => {
		return state;
	});
	//

	const [height, setHeight] = useState(0);
	const [like, setLike] = useState([]);
	const [save, setSave] = useState([])

	const ref = useRef();
	useEffect(() => {
		const resizeObserver = new ResizeObserver((event) => {
			setHeight(event[0].contentBoxSize[0].blockSize);
		});

		if (ref) {
			resizeObserver.observe(ref.current);
		}
	}, [ref]);

	const [eventId, setEvent] = useState([]);
	const [viewCount, setViewCount] = useState("");
	const [shippingAddressModal, setShippingAddressModal] = useState(false);
	const params = useParams();
	const event_id = params.id;
	const [activeKey, setActiveKey] = useState("3");
	const [collapseKey, setCollapseKey] = useState("2");

	const [cart, setCart] = useState();
	const [brandId, setBrandID] = useState("");
	const [checkout, setCheckout] = useState(false);
	const [checkStatus, setStatus] = useState(true);
	const [clear, setClear] = useState(false);
	const [product, setProduct] = useState();
	const [stp1, setStp1] = useState(true);
	const [stp2, setStp2] = useState(false);
	const [stp3, setStp3] = useState(false);
	const [lineItem, setLineItem] = useState([]);
	const [quantity, setQty] = useState(1);
	const [collapsed, setCollapsed] = useState(false);
	const [value, setValue] = useState();
	const [chatStatus, setChatStatus] = useState(false);
	const [chat, setChat] = useState([]);
	const [successorder, setSuccessOrder] = useState(false);
	const [isShipTo, setIsShipTo] = useState("");
	const [shippingAddressId, setShippingAddressId] = useState("");
	const [updateShop, setUpdateShop] = useState(false);
	const [mode, setMode] = useState("");

	//SetCookies
	const queryParams = queryString.parse(window.location.search);

	//SetCookies

	const [eventAttributes, setEventAttributes] = useState({ event: "eventshop" });
	useEffect(() => {
		setEventAttributes({ brand: queryParams.brand, source: "eventshop" });
		if (shareTracking) {
			let source = "eventshop";

			if (event_id === shareTracking?.event_review_id) {
				source = "eventshare";
			}

			setEventAttributes({
				tracker: shareTracking?.tracker,
				brand: queryParams.brand,
				source: source,
				event_review_id: shareTracking.event_review_id,
			});
		}

		if (queryParams?.publisher_id) {
			if (userInfo?.token) {
				if (!isMobile()) {
					const shareTracking = JSON.parse(localStorage.getItem("shareTracking"));
					dispatch(salesTracker({ publisher_id: queryParams?.publisher_id ? queryParams?.publisher_id : shareTracking.publisher_id }, userInfo?.token)).then((res) => {
						localStorage.setItem("shareTracking", JSON.stringify({ tracker: res?.payload?.data?.tracker, brand: queryParams.brand, source: queryParams.event, event_review_id: event_id, event: queryParams?.event, referral: window.location.href }));
						setEventAttributes({ tracker: res?.payload?.data?.tracker, brand: queryParams.brand, source: "eventshare", event_review_id: event_id });
					});
				}
			} else {
				localStorage.setItem("shareTracking", JSON.stringify({ publisher_id: queryParams?.publisher_id, event: queryParams.event, event_review_id: event_id, referral: window.location.href }));
			}
		}
	}, []);

	useEffect(() => {
		document.body.classList.add("live-event-header-hide");
		return () => {
			document.body.classList.remove("live-event-header-hide");
		};
	}, []);

	useEffect(() => {
		if (window.innerWidth <= 1290) {
			setCollapsed(true);
		}
	}, []);

	useEffect(() => {
		if (event_id) {
			if (userInfo) {
				dispatch(getAllShippingAddress(userInfo?.token));
			}
			dispatch(getEventsBy_Id(event_id, userInfo?._id)).then((res) => {
				setEvent(res?.payload?.message);
				setBrandID(res?.payload?.message[0]?.brand[0]?._id);
			});

			dispatch(getLike(event_id)).then((res) => {
				if (res?.payload) {
					setLike((res?.payload));
				}
			});

			


		}
	}, [event_id]);

	useEffect(() => {
		if (userInfo) {
			dispatch(getShopifyCart(userInfo?.brand ? userInfo?.brand : brandId, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
				}
				setCart(res?.payload?.message);
			});
		}
	}, []);

	useEffect(() => {
		if (userInfo && updateShop) {
			let addressId = allShippingAddress?.payload?.message.filter((item) => item?.is_default === true);
			setIsShipTo(addressId[0]._id);
			dispatch(setShippingAddress(userInfo?.brand ? userInfo.brand : brandId, userInfo?.token, addressId[0]._id)).then((res) => {
				if (res?.payload?.data?.success === false) {
					Swal.fire({
						icon: "error",
						title: res?.payload?.data?.message,
					});
				} else {
					dispatch(getShopifyCart(userInfo?.brand ? userInfo.brand : brandId, userInfo?.token)).then((res) => {
						if (res?.payload?.message?.lineItems?.length > 0) {
							setStatus(false);
						} else {
							setStatus(true);
						}
						setCart(res?.payload?.message);
					});
				}

				onChangeShippingMethod("", value ? value : shopifyGetShippingRates?.payload?.message?.data?.node?.availableShippingRates?.shippingRates[0]?.handle);
			});
		}
	}, [updateShop]);

	useEffect(() => {
		if (queryParams?.page === "paymentBack") {
			onKeyChange("4");
			setCollapseKey("2");
		}
	}, []);

	//Socket work

	const didUnmount = useRef(false);
	const { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState, getWebSocket } = useWebSocket(SOCKETURL, {
		onOpen: () => console.log("opened"),

		shouldReconnect: (closeEvent) => {
			/*
			useWebSocket will handle unmounting for you, but this is an example of a 
			case in which you would not want it to automatically reconnect
		  */
			return didUnmount.current === false;
		},
		reconnectAttempts: 10,
		reconnectInterval: 10000000,
	});

	useEffect(() => {
		dispatch(getLiveviews(event_id, userInfo?.token)).then((res) => {
			getWebSocket().onmessage = (data) => {
				const views = JSON.parse(data?.data);
				setViewCount(views.content?.views ? views.content?.views : viewCount);
			};
			setTimeout(() => {
				getWebSocket().send(
					JSON.stringify({
						type: "liveEvent",
						content: {
							event_id,
							views: res?.payload?.message?.stream?.viewerCount,
						},
					})
				);
			}, 8000);
		});

		//ws.close()
		return () => {
			didUnmount.current = true;
		};
	}, []);

	const onKeyChange = (key) => {
		setActiveKey(key);
		setCheckout(false);

		if (key == "1" && eventId && userInfo?._id) {
			setChatStatus(true);
		}

		if (key === "4") {
			setCollapseKey("2");
			if (userInfo) {
				setSuccessOrder(false);
				dispatch(getShopifyCart(userInfo?.brand ? userInfo?.brand : brandId, userInfo?.token)).then((res) => {
					if (res?.payload?.message?.lineItems.length > 0) {
						setStatus(false);
					} else {
						setStatus(true);
					}
					setCart(res?.payload?.message);
				});
			}
		}
	};

	function isMobile() {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}

	const handleSuccessClick = () => {
		onKeyChange("3");

		if (stp2) {
			setStp1(true);
			setStp2(false);
			setStp3(false);
			setClear(false);
		} else if (stp3) {
			setStp1(false);
			setStp2(true);
			setStp3(false);
			setClear(false);
		}
	};

	const onCollapseChange = (key) => {
		setCollapseKey(key);
	};

	const buy = (brand_id, Product_id, price) => {
		setStp1(false);
		setStp2(true);
		price1 = price;
		brand_id1 = Product_id;
		dispatch(getProduct(brand_id, Product_id, userInfo?.token)).then((res) => {
			setProduct(res?.payload?.message);
		});
	};

	const addToBag = async (src, title, tag, clear, list) => {
		setStp1(false);
		setStp2(true);
		//setStp3(true);
		setClear(true);
		soruce1 = src;
		titles1 = title;
		tag1 = tag;

		if (list?.length > 1) {
			list?.selectedOptions.map((item) => {
				lists_item.push({
					name: item.name,
					value: item.value,
				});
			});
		}
		let prodId = list.hasOwnProperty("variants") ? list?.variants[0]?.id : list?.id;

		dispatch(addToCart(userInfo?.brand ? userInfo?.brand : brandId, checkStatus, quantity, prodId, lists_item, userInfo?.token, title, list)).then((res) => {
			
			if (res?.payload?.data?.success === false) {
				Swal.fire("Something Wrong!", "Your input is wrong", "error");
				return;
			}
			userInfo.brand = eventId[0]?.brand[0]?._id;
				userInfo.event = eventId[0];
				userInfo.eventAttributes = eventAttributes;
				const currentBrandId = userInfo?.brand ? userInfo?.brand : brandId;
				localStorage.setItem('brandId', currentBrandId);
			
				setBrandID(eventId[0]?.brand[0]?._id);
				localStorage.setItem("userInfo", JSON.stringify(userInfo));
				Notify(list?.data?.price, null, list, onKeyChange, title);


				if (stp2) {
					setStp1(true);
					setStp2(false);
					setStp3(false);
					setClear(false);
				} else if (stp3) {
					setStp1(false);
					setStp2(true);
					setStp3(false);
					setClear(false);
				}
				dispatch(getShopifyCart(userInfo?.brand ? userInfo?.brand : brandId, userInfo?.token)).then((res) => {
					if (res?.payload?.message?.lineItems.length > 0) {
						setStatus(false);
					} else {
						setStatus(true);
					}
					setCart(res?.payload?.message);
				});

				lists_item = [];
				soruce1 = "";
				titles1 = "";
				tag1 = "";
				brand_id1 = "";
				setQty(1);
			
		});
	};






	if (!isNullOrUndefined(soruce1) && clear) {
		addBag.push({
			source: soruce1,
			title: titles1,
			price: price1,
		});
	}

	const TabsShopping = () => {
		return (
			<>
				<Tabs className="tabs-stage-one" defaultActiveKey="1" activeKey={activeKey} onChange={onKeyChange}>
					<TabPane tab="Comments" key="1">
						<div className="login-before-chat">
							{chatStatus ? (
								<>{eventId[0]?.event_type === "SCHEDULED_EVENT" ? <Chat eventId={eventId} publishEvent={false} activeKey={activeKey} chat={chat} /> : <Chat publishEvent={true} eventId={eventId} activeKey={activeKey} chat={chat} />}</>
							) : (
								<>
									<div className="before-login-inner">
										<h1>Login to start comments</h1>
										<Button onClick={(e) => dispatch(openRegister(true))}>Login</Button>
									</div>
								</>
							)}
						</div>
					</TabPane>
					<TabPane tab="Details" key="2" activeKey={"3"}>
						<Detail event={eventId} />
					</TabPane>
					<TabPane tab="Shop" key="3">
						<Shop setBrandID={setBrandID} quantity={quantity} price={price1} setLineItem={setLineItem} addBag={addBag} setQty={setQty} clear={clear} brandId={userInfo?.brand ? userInfo.brand : brandId} shopifyProduct={shopifyProduct} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} setClear={setClear} liveEventId={liveEventId} userInfo={userInfo} eventId={eventId} lineItem={lineItem} product={product} buy={buy} addToBag={addToBag} />
					</TabPane>
					<TabPane
						tab={
							<div className="basket-icon">
								<span className="cart-count">{cart === undefined ? 0 : cart?.lineItems?.length}</span>
								<img src={basket} />
							</div>
						}
						key="4"
					>

{successorder ? <ContinueShopping handleSuccessClick={handleSuccessClick} /> : checkout ? <CheckoutStep /> : <Cart page={queryParams.page} onChangeShippingMethod={onChangeShippingMethod} promoCodeForm={<></>} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} brandId={userInfo?.brand ? userInfo.brand : brandId} setStatus={setStatus} setCart={setCart} setCheckout={setCheckout} cart={cart} />}

						{/* {successorder ? <ContinueShopping handleSuccessClick={handleSuccessClick} /> : checkout ? <CheckoutStep /> : <Cart page={queryParams.page} onChangeShippingMethod={onChangeShippingMethod} promoCodeForm={<PromoCodeForm brandId={brandId} setCart={setCart} setStatus={setStatus} cart={cart} activeKey={activeKey} eventId={eventId} />} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} brandId={userInfo?.brand ? userInfo.brand : brandId} setStatus={setStatus} setCart={setCart} setCheckout={setCheckout} cart={cart} />} */}
					</TabPane>
				</Tabs>
			</>
		);
	};

	const CheckoutStep = () => {
		return (
			<>
				<div className="checkout-frame">
					<div className="order-summery">
						<Collapse className="position-relative" accordion defaultActiveKey={["1"]} activeKey={collapseKey} onChange={onCollapseChange}>
							<span className="total-price-top">{numeral(cart?.paymentDue?.amount).format("$0,0.0'")}</span>
							<Panel header="Order Summary" key="1">
								<div className="ordered-products">
									<CartItem brandId={userInfo?.brand ? userInfo.brand : brandId} setStatus={setStatus} setCart={setCart} cart={cart} setCheckout={setCheckout} />
								</div>

								{/* <div className="checkout-discount-section">{<PromoCodeForm brandId={userInfo?.brand ? userInfo.brand : brandId} setCart={setCart} setStatus={setStatus} cart={cart} activeKey={activeKey} eventId={eventId} />}</div> */}

								<SubTotal brandId={userInfo?.brand ? userInfo.brand : brandId} setStatus={setStatus} cart={cart} setCart={setCart} />
							</Panel>

							<Panel header="Shipping" key="2" className="accordion-style2">
								<Shipping setMode={setMode} isShipTo={isShipTo} setIsShipTo={setIsShipTo} value={value} onChangeShippingMethod={onChangeShippingMethod} setCart={setCart} setStatus={setStatus} brandId={brandId} setShippingAddressModal={setShippingAddressModal} setShippingAddressId={setShippingAddressId} cart={cart} setCollapseKey={setCollapseKey} />
							</Panel>

							<Panel header="Payment" key="3" className="accordion-style2 bg-white">
								<Payment setEventAttributes={setEventAttributes} page={"paymentBack"} eventAttributes={eventAttributes} eventId={eventId} brandId={userInfo?.brand ? userInfo.brand : brandId} setSuccessOrder={setSuccessOrder} setStatus={setStatus} setCart={setCart} />
							</Panel>
						</Collapse>

						<div className="customer-information"></div>
					</div>
				</div>
			</>
		);
	};

	const onChangeShippingMethod = (e, handled) => {
		var handle = handled ? handled : e.target.value;
		setValue(handle);
		dispatch(setShippingRates(userInfo?.brand ? userInfo?.brand : brandId, handle, userInfo?.token)).then((res) => {
			dispatch(getShopifyCart(userInfo?.brand ? userInfo?.brand : brandId, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
				}
				setCart(res?.payload?.message);
			});
		});
	};

	return (
		<>
			<div ref={ref} className={`horizontal-camera-view ${eventId[0]?.orientation}`}>
				<Layout>
					<Layout className="site-layout">
						<Content
							className="site-layout-background"
							style={{
								margin: 0,
								padding: 0,
								minHeight: "calc(100vh - 40px)",
							}}
						>
							<Video like={like}  setLike={setLike} save={save} setSave={setSave} viewCount={viewCount} onKeyChange={onKeyChange} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} setCollapsed={setCollapsed} collapsed={collapsed} id={event_id} />
						</Content>
					</Layout>
					<Sider trigger={null} collapsible collapsed={collapsed} breakpoint="lg" collapsedWidth="0" onBreakpoint={(broken) => { }} onCollapse={(collapsed, type) => { }}>
						<div className="slide-pannel-new">
							{!collapsed && (
								<span className="close-slide-panel" onClick={() => setCollapsed(!collapsed)}>
									<i className="fa fa-angle-right" aria-hidden="true"></i>
								</span>
							)}
							{window.innerWidth > 768 && TabsShopping()}
						</div>
					</Sider>
				</Layout>
			</div>
			{height && (
				<div className="mobile-checkout-panel" style={{ paddingTop: height, height: `calc(100vh - 0px)` }}>
					{window.innerWidth <= 768 && TabsShopping()}
				</div>
			)}
			<Modal
				dialogClassName="modal-shipping-from-front"
				size="md"
				scrollable
				show={shippingAddressModal}
				onHide={() => {
					setMode("");
					setShippingAddressModal(false);
				}}
				centered
			>
				<span
					className="close-shipping-front"
					onClick={() => {
						setMode("");
						setShippingAddressModal(false);
					}}
				>
					<img src={closeicon} className="filter-gray" />
				</span>
				<Modal.Body>
					<ShippingAddressModal isShipTo={isShipTo} updateShop={updateShop} setUpdateShop={setUpdateShop} page={"review"} mode={mode} setCart={setCart} shippingAddressId={shippingAddressId} brandId={brandId} edit={true} setStatus={setStatus} />
				</Modal.Body>
			</Modal>
		</>
	);
};
export default connect(null, chatActions)(PublishedContainer);
