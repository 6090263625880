import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Radio, Spin } from "antd";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { LoadingOutlined } from "@ant-design/icons";
import { stripepayment } from "../../redux/actions/payment.action";
import { getShopifyCart } from "../../redux/actions/liveEvent.action";
import { updateShopifyAttribute } from "../../redux/actions/shopify.updateAttribute.action";
import { makePayment } from "../../redux/actions/payment.action";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default function Payment({ brandId, eventId, eventAttributes, setEventAttributes, setSuccessOrder, setStatus, setCart }) {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const dispatch = useDispatch();
	const [paymentValue, setPaymentValue] = useState("");
	const { paymentList, paymentMake } = useSelector((state) => {
		return state;
	});

	const onChangePaymentMethod = (e, handled) => {
		var handle = handled ? handled : e.target.value;
		setPaymentValue(handle);
	};

	return (
		<>
			<div className="customer-information">
				<div className="checkout-stage-two">
					{!paymentList?.payload?.is_payment_method || paymentList?.payload?.message?.data?.length == 0 ? (
						<div className="checkot-continue-btn">
							<Button
								onClick={() => {
									dispatch(stripepayment(userInfo?.token, window.location.href)).then((res) => {
										window.location.href = res?.payload?.message;
									});
								}}
							>
								<i className="fa fa-plus" aria-hidden="true"></i> Add Credit Card
							</Button>
						</div>
					) : (
						<>
							<div className="select-cradit-card">
								<h2>Payment</h2>
								<p>All transactions are secure and encrypted.</p>
								{paymentList?.payload?.message?.data && (
									<Radio.Group className="w-100" onChange={onChangePaymentMethod} value={paymentValue ? paymentValue : paymentList?.payload?.message?.data[0]?.id}>
										{paymentList?.payload?.message?.data?.map((item, key) => {
											return (
												<div className="filed-detail mb-2">
													<div className="contact-email">
														<span className="contact-2 d-flex align-items-center justify-content-between">
															<div className="d-flex align-items-center">
																<i className="fa fa-cc-visa d-flex align-items-center" style={{ color: "#00ADEF" }} aria-hidden="true"></i>
																&nbsp; &nbsp; &nbsp;
																<i className="d-flex align-items-left">**** **** ****{item?.card?.last4}</i>
															</div>
															<Radio className="d-flex align-items-center" key={key} value={item?.id}></Radio>
														</span>
														{/* <a href="#">Change</a> */}
													</div>
												</div>
											);
										})}
									</Radio.Group>
								)}
							</div>

							<div className="checkot-continue-btn">
								{paymentMake?.loading ? (
									<>
										<Button>
											<Spin indicator={antIcon} />
										</Button>
									</>
								) : (
									<Button
										onClick={() => {
											dispatch(updateShopifyAttribute(brandId, eventId[0]?._id, eventAttributes, userInfo?.token, eventId[0]?.category_id)).then((res) => {
												if (res?.payload?.success) {
													dispatch(makePayment(userInfo?.token, paymentValue ? paymentValue : paymentList?.payload?.message?.data[0]?.id, brandId)).then((res) => {
														if (res?.payload?.message) {
															setSuccessOrder(true);

															localStorage.removeItem("shareTracking");
															let updatedAttributes = { ...eventAttributes };
															delete updatedAttributes.tracker;
															setEventAttributes(updatedAttributes);

															delete userInfo.brand;
															localStorage.setItem("userInfo", JSON.stringify(userInfo));
															dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
																if (res?.payload?.message?.lineItems.length > 0) {
																	setStatus(false);
																} else {
																	setStatus(true);
																}
																setCart(res?.payload?.message);
															});
														} else {
															Swal.fire({
																icon: "error",
																title: "Please Enter Valid Zip Code",
															});
														}
													});
												}
											});
										}}
									>
										Confirm Order
									</Button>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}
