import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { getAllPublishedEvent } from "../../../redux/actions/liveShopping.action";
import { publishInfluencer } from "../../../redux/actions/influencer.action";
import { getAllLiveEvent } from "../../../redux/actions/liveShopping.action";
import { getAllUpComingEvent } from "../../../redux/actions/liveShopping.action";
import { useSelector, useDispatch } from "react-redux";
import List from "../../../components/HomeShows/List";
import queryString from "query-string";
import { getInterests } from "../../../redux/actions/interest.action";
import { useLocation } from "react-router-dom";

export default ({ filterPost, name }) => {
	const [currentShow, setCurrentShow] = useState(name);
	const [loadingSpinner, setLoadingSpinner] = useState(true);
	const [page, setPage] = useState(1);
	const queryParams = queryString.parse(window.location.search);
	const sortBy = queryParams.sortBy;
	let location = useLocation();
	const [dept, setDept] = useState("");

	const origin = location.pathname.startsWith("/") ? location.pathname.slice(1) : location.pathname;

	useLayoutEffect(() => {
		setCurrentShow(name);
	}, [location]);

	let userInfo = localStorage.getItem("userInfo");
	if (userInfo !== "undefined") {
		userInfo = JSON.parse(localStorage.getItem("userInfo"));
	} else {
		localStorage.removeItem("userInfo");
		window.location.reload();
	}

	const [size, onChangeSize] = useState(0);

	useLayoutEffect(() => {
		const updateSize = () => {
			const width = window.innerWidth;
			if (width <= 576) {
				onChangeSize(4); // small screens (mobile)
			} else if (width > 576 && width <= 992) {
				onChangeSize(6); // medium screens (tablet)
			} else {
				onChangeSize(12); // large screens (desktop and above)
			}
		};

		// Attach the event listener to the window resize event
		window.addEventListener("resize", updateSize);

		// Call the updateSize function to set the initial size
		updateSize();

		// Clean up by removing the event listener when the component is unmounted
		return () => {
			window.removeEventListener("resize", updateSize);
		};
	}, []);

	const dispatch = useDispatch();
	const { search, bioShopMenu, allLiveEvent, allUpcomingEvent, allPublishEvent, influencerReviews } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		if (bioShopMenu) {
			setLoadingSpinner(true);
			// if (userInfo?.token) {
			// 	dispatch(getInterests(userInfo?.token)).then((res) => {
			// 		const catId = res.payload.message.map(({ _id }) => _id) || [];
			// 		if (bioShopMenu) {
			// 			getApiData(catId).then(() => {
			// 				setPage(2);
			// 				setLoadingSpinner(false);
			// 			});
			// 		}
			// 	});
			// } else {
				getApiData().then(() => {
					setPage(2);
					setLoadingSpinner(false);
				});
			//}
		}
	}, [currentShow, queryParams.sortBy, search]);

	useEffect(() => {
		if (!bioShopMenu?.loading) {
			if (filterPost.genderCategoryId != null && filterPost.categoryId != null) {
				setLoadingSpinner(true);
				if (bioShopMenu?.payload?.message?.data?._id) {
					getApiData().then(() => {
						setPage(2);
						setLoadingSpinner(false);
					});
				}
			}
		}
	}, [filterPost.genderCategoryId, filterPost.categoryId, location]);

	function getApiData(deparmentId) {
		const department = deparmentId || filterPost.genderCategoryId;

		setDept(department);
		return dispatch(apiMapping[currentShow].apiCall(1, 12, department, filterPost.categoryId, "", bioShopMenu?.payload?.message?.data?._id, sortBy, search.payload));
	}

	function loadMore() {
		dispatch(apiMapping[currentShow].apiCall(page, 12, filterPost.genderCategoryId, filterPost.categoryId, "", bioShopMenu?.payload?.message?.data?._id, sortBy, search.payload, true)).then(() => setPage(page + 1));
	}

	function getData() {
		return apiMapping[currentShow].data;
	}

	const apiMapping = {
		review: { apiCall: publishInfluencer, data: influencerReviews },
		upcoming: { apiCall: getAllUpComingEvent, data: allUpcomingEvent },
		live: { apiCall: getAllLiveEvent, data: allLiveEvent },
		recorded: { apiCall: getAllPublishedEvent, data: allPublishEvent },
	};

	return (
		<>
			<div className="live-event-main">
				<div className="bioshop-live-event-container">
					<div className="live-shows">
						{loadingSpinner ? (
							<div className="all_influencers_reviews">
								<div className="event-container">
									<div className="influencers-col-box infl-box-gap">
										{new Array(size).fill().map((_, i) => {
											return <div className="event-col-box"></div>;
										})}
									</div>
								</div>
							</div>
						) : (
							<List origin={origin} category={filterPost.categoryId} department={dept} name={currentShow} loading={getData()?.loading} loadMore={loadMore} hasMore={getData().payload?.message?.next?.page ? true : false} post={getData().payload?.message?.data} />
						)}
					</div>
				</div>
			</div>
		</>
	);
};
