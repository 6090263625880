import React, { useEffect } from "react";
import "../../css/brand.scss";
import queryString from "query-string";

import { NavLink } from "react-router-dom";
import { redirectToAppOrStore, isAndroid, isMobile, isIOS, useScrollAnimation } from "../../helper/general";
import { APPLEAPP, ANDROIDAPP } from "../../config";
import { openRegister } from "../../redux/actions/register.action";
import homebanner1 from "../../images/banners/home-banner11.jpg";
import publicBannerBrand from "../../images/public-banner-brand.png";
import PhoneSpliteLiveNew from "../../images/Phone-splite-live-new.png";
import shop1 from "../../images/shopify.png";
import shop2 from "../../images/magento.png";
import { useDispatch } from "react-redux";

import shop3 from "../../images/sales-force.png";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export default function BrandsRetailer() {
	const queryParams = queryString.parse(window.location.search);
	const dispatch = useDispatch();

  useEffect(() => {
    const showJoinButton = document.querySelector(".login-signup");
    if (showJoinButton) {
      showJoinButton.classList.add("partner-page");
    }

    return () => {
      const hideJoinButton = document.querySelector(".login-signup");
      if (hideJoinButton) {
        hideJoinButton.classList.remove("partner-page");
      }
    };
  }, []);


  useScrollAnimation(".animated-text");

	useEffect(() => {
		if (!userInfo) {
			if (queryParams.join === "partner") {
				dispatch(openRegister(true, "step1"));
			}
		}
	}, []);

  const isMobileDevice = isMobile() || false; // Call the function to determine if it's a mobile device

	const handleMobileClick = (url) => {
		if (isMobileDevice) {
			redirectToAppOrStore();
			// Implement your mobile-specific logic here
			// You can also redirect to the URL if needed
			// window.location.href = url;
		} else {
			// For non-mobile devices, let the default link behavior occur
			window.location.href = url;
		}
	};
  return (
    <>
      <div class="brand-sec-banner home-banne new-style bg2" style={{ backgroundImage: `url(${homebanner1})` }}>
        <div class="brn-bnr-box-left">
          <div class="bnr-content">
            <div className="sub-hd d-none d-lg-block">The one-to-many shopping solution</div>
            <h1>
              <span className="big-siz">
                Go LIVE <br className="d-none d-lg-block" />
                with ORME
              </span>
            </h1>
            <p>
              Reach new audiences and boost sales through interactive shopping
              experiences that customers love. Leave static product pages behind
              and deliver shopping that inspires, educates and entertains.
            </p>

{ userInfo ? <NavLink to="/register-partner">Join Now</NavLink> :  <NavLink to="/register-partner">Get Started Now</NavLink>  }
           
          </div>
        </div>
        <div class="brn-bnr-box-right">
          <img class="public-home-img" src={publicBannerBrand} alt="" />
        </div>
      </div>
      <div class="brnd-sec-3 new secondary-style">
        <div class="midd-width-ifti">
          <div class="boost-sale-main">
            <div class="boost-sale-header new secondary">
              <h2 className="animated-text">Achieve incredible results</h2>
            </div>
            <div class="promote-brand-main ne mt-0">
              <div class="promote-brand-box animated-text">
                <div class="promote-content">
                  <h2>5:1</h2>
                  <p>
                    Return on <br className="d-none d-lg-block" />
                    Investment
                  </p>
                </div>
              </div>
              <div class="promote-brand-box animated-text">
                <div class="promote-content">
                  <h2>10x</h2>
                  <p>
                    More likely <br className="d-none d-lg-block" />
                    to Buy{" "}
                  </p>
                </div>
              </div>
              <div class="promote-brand-box animated-text">
                <div class="promote-content">
                  <h2>26%</h2>
                  <p>
                    Upselling <br className="d-none d-lg-block" />
                    Increase
                  </p>
                </div>
              </div>
              <div class="promote-brand-box animated-text">
                <div class="promote-content">
                  <h2>5x</h2>
                  <p>
                    Sales <br className="d-none d-lg-block" />
                    Growth
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bioshop-sec-main dark">
        <div class="mid-width">
          <div class="bioshop-container no-reverse w-initial">
            <div class="bioshop-box  d-flex justify-content-center">
              <img
                class="public-why-home-img"
                src={PhoneSpliteLiveNew}
                alt=""
              />
            </div>
            <div class="bioshop-box">
              <div class="bioshop-box-inner">
                <h2 className="mb-20 animated-text">The future of shopping is here</h2>
                <div className="animated-text">
                <div className="bio-hd-sub">
                  Bringing the instore <br className="d-none d-lg-block" />
                  experience online
                </div>
               
                <div className="bio-para ">
                  Shoppable video means increased add-to-cart rates and reduced
                  returns. Live chat interaction with viewers lets you answer
                  questions and highlight product features as if you were
                  instore with them. Interactive and highly engaging online
                  shopping reaches new audiences and keeps them coming back for
                  more. ORME makes live shopping possible for all brands and
                  retailers.
                </div>
                <h4 className="bio-hd-end">What are you waiting for?</h4>
                <div class="bioshop-link">
                  <NavLink to="/contact-us">Contact Us</NavLink>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bioshop-sec-main why-choose">
        <div class="mid-width">
          <div class="bioshop-container w-initial">
            <div class="bioshop-box full-width">
              <div class="bioshop-box-inner why-choose-inner max-initial max-full">
                <h2 className="text-center mb-50 animated-text">Why choose ORME?</h2>

                <div className="row animated-text">
                  <div className="col-lg-3 features-box">
                    <h3>
                      We handle <br className="d-none d-lg-block" />
                      the tech
                    </h3>
                    <p className="feature-para w-300">
                      Go LIVE with zero technical knowledge required - no code
                      to write or third-party apps to install
                    </p>
                  </div>
                  <div className="col-lg-3 features-box">
                    <h3>
                      Smooth buying <br className="d-none d-lg-block" />
                      experience
                    </h3>
                    <p className="feature-para w-300">
                      In-stream add-to-cart and frictionless checkout leads to
                      hassle-free sales and repeat customers
                    </p>
                  </div>

                  <div className="col-lg-3 features-box">
                    <h3>
                      Intelligent <br className="d-none d-lg-block" />
                      analytics
                    </h3>
                    <p className="feature-para w-300">
                      Measurable ROI and real-time statistics about orders,
                      sales and viewers let you know exactly what works
                    </p>
                  </div>
                  <div className="col-lg-3 features-box">
                    <h3>
                      Seamless <br className="d-none d-lg-block" />
                      visuals
                    </h3>
                    <p className="feature-para w-300 mb-10">
                      High-quality video on desktop and mobile mean a great
                      experience for customers, whatever their platform
                    </p>
                  </div>
                </div>

                <NavLink
                  to="/contact-us"
                  className="roud-link ml-auto mr-auto mt-3 d-table"
                >
                  Request User Guide
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bnr-section-2 bg-white d-inline-block">
        <div className="signup-border-content">
          <h2 className="mb-4 text-center animated-text">Sign Up with ORME Today!</h2>


		  { userInfo ? <NavLink to="/register-partner" className="roud-link ml-auto mr-auto mt-3 d-table" >Join Now</NavLink> :  <NavLink to="/register-partner" className="roud-link ml-auto mr-auto mt-3 d-table" >Get Started Now</NavLink>  }

          {/* <NavLink
            to="/register-partner"
            className="roud-link ml-auto mr-auto mt-3 d-table"
          >
            Get Started
          </NavLink> */}
        </div>

        
      <div className="video-section">

        <div class="mid-width">
          <div class="one-mark mt-0 btm-space">
            <video className="customer-video" loop autoPlay muted>
              <source
                src="https://d1hq75pouwojqi.cloudfront.net/01customer.mp4"
                type="video/mp4"
              ></source>
            </video>
            <div className="app-buttons new-style">


            {(!isMobileDevice || isIOS()) && (
						
										<a onClick={() => handleMobileClick(APPLEAPP)} href="javascript:void(0)">
											<i className="fa fa-apple" aria-hidden="true"></i> Download for iOS
										</a>
					
								)}

								{(!isMobileDevice || isAndroid()) && (
						
										<a onClick={() => handleMobileClick(ANDROIDAPP)} href="javascript:void(0)">
											<i className="fa fa-android" aria-hidden="true"></i> Download for Android
										</a>
				
								)}

           
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="brand-sec-6 integrated-section">
        <div className="midd-width-ifti">
          <div className="integrated-sec pt-0">
            <div className="boost-sale-header border-line">
              <h2 className="animated-text">Integrated By the Best</h2>
              <h4 className="col-md-8 m-auto animated-text theme-color">
                ORME platform is fully integrated with leading e-commerce
                platforms 
                giving access to real-time data.
              </h4>
            </div>
            <div className="plateforms animated-text">
              <div className="shop-logo">
                <img src={shop1} alt="" className="sl-1" />
              </div>
              <div className="shop-logo">
                <img src={shop2} alt="" className="sl-2" />
              </div>
              <div className="shop-logo">
                <img src={shop3} alt="" className="sl-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
