import {
  PAYOUT_CARD_REQUEST,
  PAYOUT_CARD_SUCCESS,
  PAYOUT_CARD_ERROR,
  DISCONNECT_PAYPAL_REQUEST,
  DISCONNECT_PAYPAL_SUCCESS,
  DISCONNECT_PAYPAL_ERROR,
} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const payoutWithdraw = (token, code) => (dispatch) => {
  dispatch({ type: PAYOUT_CARD_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}withdrawal/paypalRequest`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      code: code,
    },
  })
    .then((response) => {
      return dispatch({
        type: PAYOUT_CARD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: PAYOUT_CARD_ERROR,
        payload: error.response,
      });
    });
};

export const disconnectPaypal = (token) => (dispatch) => {
  dispatch({ type: DISCONNECT_PAYPAL_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}withdraw/disconnect`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return dispatch({
        type: DISCONNECT_PAYPAL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DISCONNECT_PAYPAL_ERROR,
        payload: error.response,
      });
    });
};
