import React, { useLayoutEffect, useRef, useState } from "react";
import Item from "./Item";
import InfiniteScroll from "react-infinite-scroll-component";
import "../../css/live-events.scss";
import "../../css/influencers_reviews.scss";

function NoDataMessage({ name }) {
  let message;
  switch (name) {
    case "feeds":
      message = "No Feeds events found.";
      break;
    case "reviews":
      message = "No Reviews at this time.";
      break;
    case "review":
      message = "No Reviews at this time.";
      break;
    case "recorded":
      message = "No Shows at this time.";
      break;
    case "live":
      message = "No Live Shows at this time.";
      break;
    case "upcoming":
      message = "No Upcoming Shows at this time.";
      break;
    case "reviewInfluencer":
      message = "No Reviews at this time.";
      break;
    case "share":
      message = "Shop is Empty.";
      break;
    case "savedevents":
      message = "No Saved shows";
      break;
    case "saved":
      message = "No Saved Reviews";
      break;
    default:
      message = `No data available for ${name}.`;
  }

  return <h3>{message}</h3>;
}

const NoData = ({ name }) => (
  <div className="no_data_main">
    <div className="midd-width-ifti">
      <div className="no_data_content">
        <div className="error_header">
          <NoDataMessage name={name} />
        </div>
      </div>
    </div>
  </div>
);

export default function List({ category, department, name, post = [], loadMore, hasMore, origin }) {
  const ref = useRef(null);

  const [size, onChangeSize] = useState(0);

  useLayoutEffect(() => {
    const updateSize = () => {
      const width = window.innerWidth;
      if (width <= 576) {
        onChangeSize(4); // small screens (mobile)
      } else if (width > 576 && width <= 992) {
        onChangeSize(6); // medium screens (tablet)
      } else {
        onChangeSize(12); // large screens (desktop and above)
      }
    };

    // Attach the event listener to the window resize event
    window.addEventListener("resize", updateSize);

    // Call the updateSize function to set the initial size
    updateSize();

    // Clean up by removing the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  const loader = (
    <div className="all_influencers_reviews">
      <div className="event-container">
        <div className="influencers-col-box infl-box-gap">
          {new Array(size).fill().map((_, i) => {
            return <div className="event-col-box"></div>;
          })}
        </div>
      </div>
    </div>
  );
  return (
    <div className="all_influencers_reviews" ref={ref}>
      {post.length ? (
        <InfiniteScroll dataLength={post.length} next={loadMore} hasMore={hasMore} loader={loader}>
          <div className="event-container">
            <div className="influencers-col-box">
              {post.map((item) => (
                <Item category={category} department={department} item={item} name={name} origin={origin} />
              ))}
            </div>
          </div>
        </InfiniteScroll>
      ) : (
        <NoData name={name} />
      )}
    </div>
  );
}