import {useState} from "react";
import Layout from "./component/layoutStreaming";
import LiveEvents from "./component/liveEvents";

export default () => {

	const [filterPost, setFilterPost] = useState({
		categoryId: [],
		subCategoryId: "",
		sortBy: "",
		eventType: "",
		applyFilter: "all",
	});
	return (
		<>
			<Layout  filterPost={filterPost} setFilterPost={setFilterPost}>
				<LiveEvents filterPost={filterPost} />
			</Layout>
		</>
	);
};