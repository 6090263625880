
import { POST_BRAND_REQUEST
, POST_BRAND_SUCCESS, POST_BRAND_ERROR } from "../../types/types";
export default function reportBrand(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case POST_BRAND_REQUEST
:
			return {
				loading: true,
			};
		case POST_BRAND_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case POST_BRAND_ERROR:
			return {
				...state,
				loading: false,
				payload: payload ? payload : [],
			};

		default:
			return state;
	}
}
