import React, {  useEffect } from "react";
import { Empty } from "antd";
import { Spin } from "antd";
import moment from "moment";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { getAllReviews } from "../../../redux/actions/influencer.action";
import { deleteReviewVideo } from "../../../redux/actions/influencer.action";
import { displayModal } from "../../../redux/actions/register.action";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../../../css/upload-video.scss";
import queryString from "query-string";

export default function ReviewList({ category, department, origin }) {
	const queryParams = queryString.parse(window.location.search);
	const navigate = useNavigate();
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const dispatch = useDispatch();
	const { infVideo, deleteReviews, account } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		dispatch(getAllReviews(userInfo?.token));
		return () => {};
	}, []);

	// This is componentWillUnmount
	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");

		return () => {
			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	// Handle the account reset confirmation with a SweetAlert modal.
	const delRev = (id, banner) => {
		const mainTextHtml = `Are You Sure You Want To Delete This Video?`;
		const combinedHtml = `<p>${mainTextHtml}</p>`;
		Swal.fire({
			html: combinedHtml,
			iconHtml: `<img src=${banner} height="100px" width="80px">`,
			customClass: {
				icon: "no-border",
			},
			cancelButtonText: "Cancel",
			showCancelButton: true,
			confirmButtonColor: "#d33",
			cancelButtonColor: "#010b40",
			confirmButtonText: "Delete",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(deleteReviewVideo(id)).then((res) => {
					dispatch(getAllReviews(userInfo?.token));
				});
			}
		});
	};

	return (
		<div className="account-left-content">
			<div className="favourite-brands-container">
				<div className="container ml-0">
					<div className="row">
						<div className=" col-lg-10 col-xl-8 p-0">
							<div className="heading-top-area">
								<h2>My Reviews</h2>
								<Button
									className="add-button"
									onClick={() => {
										if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
											dispatch(displayModal("register", true));
										} else {
											navigate("/upload-video");
										}
									}}
								>
									<FontAwesomeIcon className="calendar-icon" icon={faPlus} />
								</Button>
							</div>
						</div>
					</div>
				</div>

				<div className="row reviews-box-list">
					{infVideo?.loading || deleteReviews?.loading ? (
						<>
							<div className="antd-loading-ift">
								<Spin size="large"></Spin>
							</div>
						</>
					) : (
						<>
							{infVideo?.payload?.message?.data?.length === 0 ? (
								<div className="antd-loading-ift">
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
								</div>
							) : (
								infVideo?.payload?.message?.data?.map((item) => {
									return (
										<div className="col-12 col-xl-3 col-lg-4 col-md-6 ">
											<div className="reviews-box">
												<div className="prod-box">
													<div className="prod-image">
														<img src={item?.banner} />
														test
													</div>
													<div className="prod-name">
														<div className="prod-title">
															<span className="prod-name-inr">{item?.title}</span>
															<span className="prod-veriants-inr">Brand: {item?.brandData?.name}</span>
															<span className="prod-veriants-inr">Department: {item?.gen_category?.name}</span>
														</div>

														<div className="review-bottom">
															<div className="date-left">
																<FontAwesomeIcon className="calendar-icon" icon={faCalendarAlt} />
																<div className="date-text">{moment(item?.created_date).format("MM-DD-YYYY")}</div>
															</div>
															<div className="button-right">
																<Button
																	onClick={() => {
																		delRev(item?._id, item?.banner);
																	}}
																	className="primary-btn review-delete-btn"
																>
																	Delete
																</Button>
															</div>
														</div>
													</div>
												</div>
												<NavLink className="block-review-link" to={`/reviews/${item._id}/${item.brandData?._id}/${item?.influencer_id}?origin=${userInfo?.pixel_id}&department=${department}&category=${category}&brand=${origin !== "review" ? item.brandData?._id : ""}&pagelink=${"reviewlist"}&username${queryParams.sortBy ? `&sortBy=${queryParams.sortBy}` : ``}`}></NavLink>
											</div>
										</div>
									);
								})
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}
