import { GET_CREATOR_SALE_REQUEST,GET_CREATOR_SALE_SUCCESS,GET_CREATOR_SALE_ERROR} from "../../types/types";
export default function saleContent (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_CREATOR_SALE_REQUEST:
			return {
				loading: true,
			};
		case GET_CREATOR_SALE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_CREATOR_SALE_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		default:
			return state;
		}
}