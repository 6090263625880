import Layout from "./component/layout";
import WithdrawRequest from "./component/withdrawRequest";
export default () => {
	return (
		<>
			<Layout title="Withdraw Request">
				<WithdrawRequest />
			</Layout>
		</>
	);
};