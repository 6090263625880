import React, { useEffect, useState, useRef } from "react";
import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import HlsPlayer from "../../components/hlsPlayer/hlsPlayer";
import { createBrowserHistory } from "history";
import { Button } from "react-bootstrap";
import closeicon from "../../images/brand-logos/close-icon.svg";
import ShortUrl from "../../components/shortUrl/shortUrl";
// import { openLogin } from "../../redux/actions/login.action";
import { openRegister, displayModal } from "../../redux/actions/register.action";
import { getEventLikes } from "../../redux/actions/likesCount.action";
import { addUrl, shareVideo } from "../../redux/actions/bioShop.action";
import { saveEvent } from "../../redux/actions/save.action";
import { EyeOutlined } from "@ant-design/icons";
import Heart from "../../components/hearts/hearts";
import MultipleHeart from "../../components/hearts/multipleHearts";
import { useNavigate } from "react-router-dom";
import HeartLike from "../../components/hearts/heartLike";
import "../../css/hearts.scss";
import numeral from "numeral";
import avatarIcon from "../../images/avatar.png";
import queryString from "query-string";
export const history = createBrowserHistory();

export default (props) => {
	let userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const queryParams = queryString.parse(window.location.search);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showShare, setShowShare] = useState(false);
	const [isStopped, setIsStopped] = useState(true);
	const videoRef = useRef();

	const { followerBrand, prevEventviews, account } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		const interval = setInterval(() => {
			if (videoRef.current) {
				const videoCurrentTime = videoRef?.current?.currentTime * 3000;
				if (videoCurrentTime !== 0) {
					props?.setCurrentTimestamp(props.videoStartTime + videoCurrentTime);
				}
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [props?.event]);

	const Navigates = (num) => {
		if (props.collapsed == true) {
			props.setCollapsed(!props.collapsed);
		}
		props.onKeyChange(num);
	};

	const share = () => {
		let fullUrl = window.location.href;
		let url = fullUrl.split("/").slice(0, 4).join("/");
		if (!userInfo) {
			dispatch(openRegister(true));
		} else {
			if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
				dispatch(displayModal("register", true));
			} else {
				dispatch(addUrl(userInfo.token, shareAction(url))).then((res) => {
					dispatch(shareVideo(userInfo.token, shareAction(url)));
				});
				setShowShare(true);
				shareAction(url);
			}
		}
	};

	const shareAction = (url) => {
		const newUrl = `${url}/${props?.item?._id}`;
		const newRedirectUrl = new URL(newUrl);
		// newRedirectUrl.searchParams.set("pixel_id", userInfo?.pixel_id);
		newRedirectUrl.searchParams.set("publisher_id", userInfo?._id);
		newRedirectUrl.searchParams.set("advertiser_id", props?.item.brand_id);
		newRedirectUrl.searchParams.set("origin", queryParams?.origin);
		newRedirectUrl.searchParams.set("department", queryParams?.department);
		newRedirectUrl.searchParams.set("category", queryParams?.category);
		newRedirectUrl.searchParams.set("page", props?.item?.page);

		// newRedirectUrl.searchParams.set("category_id", props?.item?.category_id);
		newRedirectUrl.searchParams.set("event_id", props?.item?._id);
		// newRedirectUrl.searchParams.set("roiEvent", true);
		// newRedirectUrl.searchParams.set("brand", 1);
		newRedirectUrl.searchParams.set("event", "eventshare");
		return newRedirectUrl.toString();
	};

	const getUrlExtension = (url) => {
		const fileName = url.split("/").pop();
		const fileExtension = fileName.split(".").pop();
		return fileExtension;
	};

	return (
		<>
			<div className="lv-cntr App">
				<div className="brand-detail-overlay">
					<div className="brand-detail">
						<div className="author-name">
							<span className="auth-image">{props.item?.brand_profile ? <img alt="" src={props.item?.brand_profile} /> : <img className="img-fluid" src={avatarIcon} />}</span>
							<div className="auth-detail">
								<span className="auth-title">{props?.item?.title}</span>
								<div className="brand-followers-live-event">
									<span className="event-name">by {props.item?.brand_name}</span>
									<div className="brand-followers-event">
										{followerBrand?.payload?.message?.followers_count ? (
											<span>
												<strong>{numeral(followerBrand?.payload?.message?.followers_count).format("0a")}</strong> Followers
											</span>
										) : (
											<></>
										)}
										{followerBrand?.payload?.message?.follows_count ? (
											<span>
												<strong>{numeral(followerBrand?.payload?.message?.follows_count).format("0a")}</strong> Following
											</span>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="live-txt"> {"Recorded"}</div>
					{prevEventviews?.payload?.message ? (
						<div className="live-audiance">
							<EyeOutlined /> {prevEventviews?.payload?.message}
						</div>
					) : (
						<></>
					)}
				</div>

				<div className="close-live-event">
					{/* <NavLink to={`${queryParams.origin == "home" ? "/" : "/brand-events/" + queryParams.username}`} className="close-video">
              <img src={closeicon} className="filter-gray" />
            </NavLink> */}
					<a
						href="#"
						onClick={() => {
							//const validOrigins = ["review", "recorded", "live", "upcoming", "brand-reviews", "brand-recorded", "brand-live", "brand-upcoming"];
							if (queryParams?.origin) {
								navigate(`/${queryParams.origin}`);
							} else {
								navigate("/");
							}
						}}
						className="close-video"
					>
						<img src={closeicon} className="filter-gray" />
					</a>
				</div>

				<div className="drawer-buttons-outer drawer-for-recorded-events">
					<div className="dr-1 dr-detail">
						<Tooltip placement="left" title="Comments">
							<Button
								onClick={() => {
									Navigates("1");
								}}
							>
								<i className="fa fa-comment"></i>
							</Button>
						</Tooltip>
					</div>
					<div className="dr-1 dr-detail">
						<Tooltip placement="left" title="Details">
							<Button
								onClick={() => {
									Navigates("2");
								}}
							>
								<i className="fa fa-info" aria-hidden="true"></i>
							</Button>
						</Tooltip>
					</div>
					<div className="dr-1 dr-shop">
						<Tooltip placement="left" title="Shop">
							<Button
								onClick={() => {
									Navigates("3");

									//props.setCollapsed(!props.collapsed);
								}}
							>
								<i className="fa fa-dollar" aria-hidden="true"></i>
							</Button>
						</Tooltip>
					</div>
					<div className="dr-1 dr-cart">
						<Tooltip placement="left" title="Cart">
							<Button
								onClick={() => {
									Navigates("4");
								}}
							>
								<i className="fa fa-shopping-bag" aria-hidden="true"></i>
							</Button>
						</Tooltip>
					</div>
					<div className="dr-1 dr-share">
						<Tooltip placement="left" title="Share">
							<Button
								onClick={() => {
									share();
									// props.onKeyChange("3");
									// props.setCollapsed(!props.collapsed);
								}}
							>
								<i className="fa fa-share-alt" aria-hidden="true"></i>
							</Button>
						</Tooltip>
					</div>

					<div className={`dr-1 dr-share ${props?.isSaved ? "dr-share-count" : ""}`}>
						<Tooltip placement="left" title="Save">
							<Button
								onClick={() => {
									if (!userInfo) {
										dispatch(openRegister(true));
									} else {
										if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
											dispatch(displayModal("register", true));
										} else {
											dispatch(saveEvent(props?.item?._id)).then((res) => {
												props.setIsSaved(res?.payload?.is_saved);
											});
										}
									}
								}}
							>
								<i className="fa fa-bookmark" aria-hidden="true"></i>
							</Button>
						</Tooltip>
					</div>

					<div className={`dr-1 ${props?.like?.is_like ? "dr-like-count" : ""}`}>
						<Button
							onClick={() => {
								if (!userInfo) {
									dispatch(openRegister(true));
								} else {
									if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
										dispatch(displayModal("register", true));
									} else {
										dispatch(getEventLikes(userInfo?.token, props?.item?._id)).then((res) => {
											if (props?.like?.is_like) {
												props.setLike(res.payload);
											} else {
												props.setLike(res.payload);
											}
										});
										HeartLike();
									}
								}
							}}
						>
							<i className="fa fa-heart" aria-hidden="true"></i>
						</Button>
						<span className="count-number">{props?.like.message}</span>
					</div>
				</div>
				<div className="emoji-overlay"></div>
				<div className="custom-hearts">
					<Heart isStopped={isStopped} />
				</div>
				<div className="custom-hearts">
					<MultipleHeart />
				</div>

				{props?.item?.recording_url && (
					<div className="MiniPlayer">
						<div className="MiniPlayer-videoBox">
							<div className={`MinPlayer-video infl-review-video-height large ${props.item.orientation === "portrait" && "full-video-height"}`}>
								{getUrlExtension(props?.item?.recording_url) === "m3u8" ? (
									<HlsPlayer playerRef={videoRef} autoPlay={false} id={`video-${props.index}`} streamUrl={props.slideIndex === props.index && props?.item?.recording_url} />
								) : (
									<>
										<video id={`video-${props.index}`} controlsList="nodownload" preload="metadata" webkit-playsInline playsInline controls loop width="100%" height="100%">
											<source src={props?.item?.recording_url} type="video/mp4"></source>
										</video>
									</>
								)}
							</div>
						</div>
					</div>
				)}
			</div>

			<ShortUrl url={window.location.href} showShare={showShare} setShowShare={setShowShare} referral_percent={props?.item?.brand?.fee_structure?.referral_fee} />
		</>
	);
};
