import "../../css/live-events.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewComponent from "../../components/HomeShows/shows";
export default function LiveShopping({ name }) {
	return (
		<>
			<div className="live-event-main">
				<div className="midd-width-ifti">
					<div className="live-shows">
						<NewComponent name={name} />
					</div>
				</div>
			</div>
		</>
	);
}