import { SAVED_EVENTS_REQUEST, SAVED_EVENTS_SUCCESS, SAVED_EVENTS_ERROR, SAVED_EVENTS_LOADMORE } from "../../types/types";

export default function eventSaved(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case SAVED_EVENTS_REQUEST:
            return {
                loading: true,
            };
        case SAVED_EVENTS_SUCCESS:
            let page = 1;
            if (payload?.message?.next?.page) {
                page = payload?.message?.next?.page - 1;
            }
            payload?.message?.data.map((item) => {
                if (item)
                    item.page = page;
            });
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case SAVED_EVENTS_LOADMORE:

            let nextPage = payload?.message?.next?.page - 1;
            if (payload?.message?.next?.page) {
                payload?.message?.data?.map((item) => {
                    if (item)
                        item.page = nextPage;
                });
            } else {
                payload?.message?.data?.map((item) => {
                    if (item)
                        item.page = state.payload?.message.next.page;
                });
            }
            return {
                ...state,
                loading: false,
                payload: {
                    ...state.payload,
                    message: {
                        ...payload,
                        data: [...state.payload.message.data, ...payload?.message?.data],
                    },
                },
            };
        case SAVED_EVENTS_ERROR:
            return {
                ...state,
                loading: false,
                payload: payload ? payload : [],
            };
        default:
            return state;
    }
}