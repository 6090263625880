import { POST_REVIEW_CHAT_REQUEST, POST_REVIEW_CHAT_SUCCESS, POST_REVIEW_CHAT_ERROR } from "../../types/types";
export default function chatReview(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case POST_REVIEW_CHAT_REQUEST:
      return {
        loading: true,
      };
    case POST_REVIEW_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case POST_REVIEW_CHAT_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
