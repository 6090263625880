import { GET_BECOME_INFLUENCER_REQUEST, GET_BECOME_INFLUENCER_SUCCESS, GET_BECOME_INFLUENCER_ERROR, GET_INFLUENCER_PROFILE_SUCCESS, GET_INFLUENCER_PROFILE_ERROR, GET_INFLUENCER_PROFILE_LOADMORE, GET_INFLUENCERS_LOADMORE, GET_INFLUENCERS_REQUEST, GET_INFLUENCERS_SUCCESS, GET_INFLUENCERS_ERROR, GET_SHARED_REVIEWS_LOADMORE, GET_SHARED_REVIEWS_SUCCESS, GET_SHARED_REVIEWS_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getBecomeInfluencer =
	({ token, name, address, country, state, city, no_of_followers, zip }) =>
	(dispatch) => {
		dispatch({ type: GET_BECOME_INFLUENCER_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}mobile/private/becameInfluencer`,
			headers: { Authorization: `Bearer ${token}` },
			data: {
				name,
				address,
				country,
				state,
				city,
				no_of_followers,
				zip,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_BECOME_INFLUENCER_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_BECOME_INFLUENCER_ERROR,
					payload: error.response,
				});
			});
	};

export const influencerProfilemain =
	(page, limit, gen_Ids = [], cat_Ids = [], sub_cat_Ids = [], pixe_id, sort_by, title, loadMore) =>
	(dispatch) => {
		let endPoint = "public/reviews/getinfluencermedia";

		if (userInfo) {
			endPoint = "private/reviews/getinfluencermedia";
		}

		return axios({
			method: "POST",
			url: `${BASEURL}${endPoint}?page=${page}&limit=${limit}`,
			headers: { Authorization: `Bearer ${userInfo?.token}` },
			data: {
				gen_Ids: gen_Ids,
				cat_Ids: cat_Ids,
				sub_cat_Ids: sub_cat_Ids,
				influencer_id: pixe_id,
				sort_by: sort_by ? sort_by + "s" : "",
				title,
			},
		})
			.then((response) => {
				if (loadMore) {
					return dispatch({
						type: GET_INFLUENCER_PROFILE_LOADMORE,
						payload: response.data,
					});
				} else {
					return dispatch({
						type: GET_INFLUENCER_PROFILE_SUCCESS,
						payload: response.data,
					});
				}
			})
			.catch((error) => {
				return dispatch({
					type: GET_INFLUENCER_PROFILE_ERROR,
					payload: error.response,
				});
			});
	};

export const getInfluencers =
	(page, limit, gen_Ids = [], cat_Ids = [], sub_cat_Ids = [], pixe_id, sort_by, name, loadMore) =>
	(dispatch) => {
		if (!loadMore) dispatch({ type: GET_INFLUENCERS_REQUEST });

		return axios({
			method: "POST",
			url: `${BASEURL}public/user/getinfluencers?page=${page}&limit=${limit}`,
			data: {
				gen_Ids: gen_Ids,
				cat_Ids: cat_Ids,
				sub_cat_Ids: sub_cat_Ids,
				influencer_id: pixe_id,
				sort_by: sort_by,
				name: name ? name : "",
			},
		})
			.then((response) => {
				if (loadMore) {
					return dispatch({
						type: GET_INFLUENCERS_LOADMORE,
						payload: response.data,
					});
				} else {
					return dispatch({
						type: GET_INFLUENCERS_SUCCESS,
						payload: response.data,
					});
				}
			})
			.catch((error) => {
				return dispatch({
					type: GET_INFLUENCERS_ERROR,
					payload: error.response,
				});
			});
	};

export const sharedReviews =
	(page, limit, gen_Ids = [], cat_Ids = [], sub_cat_Ids = [], pixe_id, sort_by, name, loadMore) =>
	(dispatch) => {
		let endPoint = "public/reviews/getlikedreviews";

		if (userInfo) {
			endPoint = "private/reviews/getlikedreviews";
		}

		return axios({
			method: "POST",
			url: `${BASEURL}${endPoint}?page=${page}&limit=${limit}`,
			headers: { Authorization: `Bearer ${userInfo?.token}` },
			data: {
				gen_Ids: gen_Ids,
				cat_Ids: cat_Ids,
				sub_cat_Ids: sub_cat_Ids,
				influencer_id: pixe_id,
				sort_by: sort_by,
				name: name ? name : "",
			},
		})
			.then((res) => {
				let message = {
					data: res?.data?.message?.data?.map((it) => it?.review),
				};

				if (res?.data?.message?.next?.page) {
					message.next = res?.data?.message?.next;
				}

				if (res?.data?.message?.previous?.page) {
					message.previous = res?.data?.message?.previous;
				}

				const data = { message, total_records: res?.data?.message?.total };
				if (loadMore) {
					return dispatch({
						type: GET_SHARED_REVIEWS_LOADMORE,
						payload: data,
					});
				} else {
					return dispatch({
						type: GET_SHARED_REVIEWS_SUCCESS,
						payload: data,
					});
				}
			})
			.catch((error) => {
				return dispatch({
					type: GET_SHARED_REVIEWS_ERROR,
					payload: error.response,
				});
			});
	};
