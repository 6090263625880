import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBioShopMenu } from "../../../redux/actions/bioShop.action";
import "react-multi-carousel/lib/styles.css";
import "../../../css/bioshop.scss";
import FilterModal from "../../../components/HomeShows/FilterModal";
import Follower from "../../../components/follower/follower";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "react-bootstrap";
import avatarIcon from "../../../images/avatar.png";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export default ({ socialStore, children, setFilterPost, filterPost }) => {
	const dispatch = useDispatch();
	const { bioShopMenu } = useSelector((state) => {
		return state;
	});


	const params = useParams();
	let location = useLocation();
	const navigate = useNavigate();
	const [showFilter, setShowFilter] = useState(false);
	const [trending, setTrending] = useState(false);
	const path = location.pathname.slice(1, location.pathname.length);


	useEffect(() => {

		if (bioShopMenu?.length == 0 || bioShopMenu?.payload?.message?.data?.instagram_username !== params?.username) {
			dispatch(getBioShopMenu(params.username, userInfo?.token))
		}

		return () => {

		}

	}, [params.username]);




	useEffect(() => {
		if (window.location.search) {
			setTrending(true);
		} else {
			setTrending(false);
		}
	}, [window.location.search]);

	function ComponentBioShop() {
		return (
			<div className="bio_shop_body">
				<div className="bio_shop_header">
					<div className="bio_shop_header_left">
						<div className="brand-descrp">
							<div className="brand_logo">{bioShopMenu?.payload?.message?.data?.profile_image_url && <img src={bioShopMenu?.payload?.message?.data?.profile_image_url ? bioShopMenu?.payload?.message?.data?.profile_image_url : { avatarIcon }} />}</div>

							<div className="logo-right">
								<div className="current-brand-name">{bioShopMenu?.payload?.message?.data?.name}</div>
								{bioShopMenu?.payload?.message?.data?._id && <Follower bioShopMenu={bioShopMenu} isBrand={true} />}
							</div>
						</div>

						{bioShopMenu?.payload?.message?.data?.promo !== "KB0" && bioShopMenu?.payload?.message?.data?.promo && <div className="brand_name">GET {bioShopMenu?.payload?.message?.data?.discount} OFF USING KBSHOP APP</div>}
					</div>

					<div className="bio_shop_header_topmenu">
						<Button
							onClick={() => {
								setShowFilter(true);
							}}
						>
							Filters
						</Button>


						<Button

							className={path == `${params.username}` ? "active" : ""}
							onClick={() => {
								navigate(`/${params.username}`);
							}}
						>
							Reviews
						</Button>
						<Button
							className={path == `brand-recorded/${params.username}` ? "active" : ""}
							onClick={() => {
								navigate(`/brand-recorded/${params.username}`);
							}}
						>
							Shows
						</Button>
						<Button
							className={path == `brand-live/${params.username}` ? "active" : ""}
							onClick={() => {
								navigate(`/brand-live/${params.username}`);
							}}
						>
							Live
						</Button>
						<Button
							className={path == `brand-upcoming/${params.username}` ? "active" : ""}
							onClick={() => {
								navigate(`/brand-upcoming/${params.username}`);
							}}
						>
							Upcoming
						</Button>
						{/* <Button
							className={trending ? "active" : ""}
							onClick={() => {
								if (trending) {
									navigate(`${location.pathname}`);
								} else {
									navigate(`${location.pathname}?sortBy=like`);
								}
							}}
						>
							Trending
						</Button> */}

						<a href={bioShopMenu?.payload?.message?.data?.website_address} style={{ visibility: !bioShopMenu?.payload?.message?.data?.website_address ? "hidden" : "visible" }} target="_blank">
							Website
						</a>
					</div>
				</div>

				{bioShopMenu?.payload?.message?.data._id && children}
			</div>
		);
	}
	return (
		<>
			<div className={`${!socialStore ? "bio_shop_main_ift" : ""}`}>
				<div className={`${!socialStore ? "midd-width-ifti" : ""}`}>
					<div className={`${!socialStore ? "bio_container_main" : ""}`}>{ComponentBioShop()}</div>
				</div>
			</div>
			{bioShopMenu?.payload?.message?.data._id && <FilterModal filterPost={filterPost} setFilterPost={setFilterPost} showFilter={showFilter} setShowFilter={setShowFilter} />}
		</>
	);
};