

import { POST_UPLOAD_REVIEW_REQUEST, POST_UPLOAD_REVIEW_SUCCESS, POST_UPLOAD_REVIEW_ERROR } from "../../types/types";

export default function reviewUploadNew(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case POST_UPLOAD_REVIEW_REQUEST:
      return {
        loading: true,
      };
    case POST_UPLOAD_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case POST_UPLOAD_REVIEW_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    default:
      return state;
  }
}
