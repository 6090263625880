import { BASIC_PROFILE_IMAGE } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export const basicProfileImage = (formData) => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .put(`${BASEURL}users/revise/profileimage/${userInfo?._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo?.token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: BASIC_PROFILE_IMAGE,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};
