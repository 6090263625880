import { NUM_SIGN_IN_REQUEST, NUM_SIGN_IN_SUCCESS, NUM_SIGN_IN_ERROR, OTP_LOGIN_REQUEST, OTP_LOGIN_SUCCESS , OTP_LOGIN_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const numSign = (token, phone) => (dispatch) => {
  dispatch({ type: NUM_SIGN_IN_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}public/otp/gettoken`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
        phone    
    },
  })
    .then((response) => {
      return dispatch({
        type: NUM_SIGN_IN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: NUM_SIGN_IN_ERROR,
        payload: error.response,
      });
    });
};

export const otplogin = (user_id, otp) => (dispatch) => {
    dispatch({ type:OTP_LOGIN_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}auth/user/verifytoken`,
      data: {
        user_id:user_id, 
        otp:otp,
      },
    })
      .then((response) => {
        return dispatch({
          type: OTP_LOGIN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: OTP_LOGIN_ERROR,
          payload: error.response,
        });
      });
  };