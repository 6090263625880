import {
  REVIEW_SAVE_REQUEST, REVIEW_SAVE_SUCCESS, REVIEW_SAVE_ERROR
  } from "../../types/types";

  
  
  export default function saveReview(state = "", action) {
    const { type, payload } = action;
    switch (type) {
      case REVIEW_SAVE_REQUEST:
        return {
          loading: true,
          success: true,
        };
      case REVIEW_SAVE_SUCCESS:
        return {
          ...state,
          loading: false,
          success: false,
          payload: payload,
        };
  
      case REVIEW_SAVE_ERROR:
        return {
          ...state,
          loading: false,
          success: false,
          payload: payload,
        };
  
      default:
        return state;
    }
  }
  