import { POST_SALES_TRACKER_REQUEST, POST_SALES_TRACKER_SUCCESS, POST_SALES_TRACKER_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const salesTracker = (data, token) => (dispatch) => {
	dispatch({ type: POST_SALES_TRACKER_REQUEST });

	return axios({
		method: "POST",
		url: `${BASEURL}salestracker/save`,
		headers: { Authorization: `Bearer ${token}` },
		data,
	})
		.then((response) => {
			return dispatch({
				type: POST_SALES_TRACKER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_SALES_TRACKER_ERROR,
				payload: error.response,
			});
		});
};
