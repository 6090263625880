import Layout from "./component/layout";
import ReferralStats from "./component/referralStats";
export default () => {
	return (
		<>
			<Layout title="Referral Sales">
				<ReferralStats />
			</Layout>
		</>
	);
};
