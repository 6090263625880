import React, { useState, useRef, useEffect } from "react";
import { Button, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useStepsForm } from "sunflower-antd";
import SmsValidate from "./smsValidate";
import RegisterNew from "./registerNew";

import { getCustomerRegister } from "../../redux/actions/register.action";
import PhoneInput from "react-phone-number-input";

import "react-phone-number-input/style.css";
import "antd/dist/antd.css";
import { Spin } from "antd";
export default function Register() {
	let shareTracking = JSON.parse(localStorage.getItem("shareTracking"));
	const dispatch = useDispatch();
	const [value, setValue] = useState();
	const [errorCheck, setErrorCheck] = useState("");
	const [userId, setUserId] = useState("");
	const [flag, setFlag] = useState("");
	const allowedCountries = ["US", "PK"];
	const inputRef4 = useRef(null);

	const [loggedIn, setLoggedIn] = useState(false);
	const { customerRegister, openModal, country } = useSelector((state) => {
		return state;
	});


	useEffect(() => {
		if (openModal?.payload?.status) {
			setLoggedIn(true);
		} else {
			setLoggedIn(false);
		}
	}, [openModal]);

	const { current, formProps, submit, formLoading } = useStepsForm({
		async submit(values) {
			let data;
			if (!shareTracking?.tracker) {
				data = {
					...values,
					url: shareTracking?.referral,
					urlType: shareTracking?.event === "linkinbio" ? "linkinbio" : "kbsly",
					...(shareTracking?.event === "linkinbio" ? { publisher_pixel_id: shareTracking?.publisher_id } : { publisher_id: shareTracking?.publisher_id }),
				};
			} else {
				data = { ...values };
			}

			dispatch(getCustomerRegister(data)).then((res) => {
				if (res?.payload?.success) {
					setFlag(true);
					setUserId(res?.payload?.data?.user_id);
				} else {
					setErrorCheck(res?.payload?.data?.message);
				}
			});
		},
	});

	const handleKeyDown = (event, inputRef, nextInputRef, nextPlaceholder) => {
		if (event.key === "Enter") {
			event.preventDefault();
			inputRef.current.placeholder = nextPlaceholder;
			nextInputRef.current.focus();
		}
	};

	const formList = [
		<>
			<Form.Item name={["phone"]}>
				<PhoneInput
					className="flag-country"
					rules={[
						{
							type: "phone",
							required: true,
							message: "Please Enter Phone number",
						},
					]}
					international
					countries={country?.payload?.message?.map(i => i.code1)}
					countryCallingCodeEditable={false}
					defaultCountry="US"
					limitMaxLength={14}
					value={value}
					onChange={setValue}
					placeholder={`phone`}
					ref={inputRef4}
					onKeyDown={(event, loading) => {
						// if (event?.key === "Enter") {
						//   submit();
						// }
						handleKeyDown((loading = { formLoading }));
					}}
				/>
			</Form.Item>

			{customerRegister?.loading ? (
				<Button size="large" block className="w-100 light" type="primary">
					<Spin className="white-loader new-color" />
				</Button>
			) : (
				<Button
					size="large"
					block
					className="w-100"
					type="primary"
					loading={formLoading}
					onClick={() => {
						submit();
					}}
				>
					Submit
				</Button>
			)}

			<div role="alert" className="ant-form-item-explain-error mt-2">
				{errorCheck}
			</div>
		</>,
	];

	return (
		<>
			{loggedIn ? (
				<div className="login-signup-ift login-page">
					<RegisterNew />
				</div>
			) : flag ? (
				<SmsValidate userId={userId} />
			) : (
				<>
					<div className="login-signup-ift">
						<h2>Let's Get Started</h2>
						<div>
							<Form {...formProps}>{formList[current]}</Form>
						</div>
					</div>
				</>
			)}
		</>
	);
}
