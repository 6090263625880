import React from "react";
import { Menu, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, FlagOutlined } from "@ant-design/icons";
import { deleteInfluencerPost, deleteFavourites } from "../../redux/actions/influencer.action";
import { influencerProfilemain } from "../../redux/actions/becomeInfluencer.action";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { sharedReviews } from "../../redux/actions/becomeInfluencer.action";
import { likesSave } from "../../redux/actions/influencer.action";
import { getSave , saveEvent } from "../../redux/actions/save.action";
import { privateReviews , savedEvents} from "../../redux/actions/save.action";
const ActionDropdown = ({ id, filterPost, type }) => {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const dispatch = useDispatch();
	const queryParams = queryString.parse(window.location.search);
	const sortBy = queryParams.sortBy;
	const params = useParams();
	const department = filterPost?.genderCategoryId;
	const { bioShopMenu, search } = useSelector((state) => {
		return state;
	});

	const handleDelete = async (e) => {
		e.domEvent.stopPropagation();
		const result = await Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			iconHtml: "&#128465;", // Unicode for trash can
			showCancelButton: true,
			confirmButtonColor: "#d33",
			cancelButtonColor: "#3085d6",
			confirmButtonText: "Yes, delete it!",
		});

		if (result.isConfirmed) {
			if (type === "share") {
				dispatch(likesSave(userInfo?.token, id)).then((res) => {
					if (res?.payload?.success === true) {
						dispatch(sharedReviews(1, 12, department, filterPost?.categoryId, "", bioShopMenu?.payload?.message?.data?._id, sortBy, search.payload))
					}
				})
			} else if (type === "reviewInfluencer") {
				dispatch(deleteInfluencerPost(id)).then((res) => {
					if (res?.payload?.success === true) {
						dispatch(influencerProfilemain(1, 12, department, filterPost?.categoryId, "", bioShopMenu?.payload?.message?.data?._id, sortBy, search.payload))
					}
				});
			} else if (type === "saved") {
				dispatch(getSave(id)).then((res)=> {
					if (res?.payload?.success === true) {
						dispatch(privateReviews(1, 12, "" , userInfo?._id))
					}
				})
			} else if (type === "saved-event") {
				dispatch(saveEvent(id)).then((res)=> {
					if (res?.payload?.success === true) {
						dispatch(savedEvents(1, 12, "" , userInfo?._id))
					}
				})
			}
			Swal.fire("Deleted!", "Your post has been deleted.", "success");
		}
	};

	const menu = (
		<Menu onClick={(e) => e.domEvent.stopPropagation()}>
			<Menu.Item key="delete" icon={<DeleteOutlined style={{ color: "red" }} />} onClick={handleDelete}>
				Delete
			</Menu.Item>
			{/* <Menu.Item key="report" icon={<FlagOutlined style={{ color: "blue" }} />} onClick={() => console.log("Report Clicked")}>
				Report
			</Menu.Item> */}
		</Menu>
	);

	return (
		<Dropdown overlay={menu} overlayClassName="ant-left-area-dropdown">
			<a className="ant-dropdown-link" href="#" onClick={(e) => e.stopPropagation()}>
				...
			</a>
		</Dropdown>
	);
};

export default ActionDropdown;
