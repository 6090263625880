import { SHOPIFY_UPDATE_CART_REQUEST, SHOPIFY_UPDATE_CART_SUCCESS, SHOPIFY_UPDATE_CART_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const updateShopifycart = (token, line_item) => (dispatch) => {
	dispatch({ type: SHOPIFY_UPDATE_CART_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}checkout/updateincart`,
		headers: { Authorization: `Bearer ${token}` },

		data: {
			brand:line_item?.brand,
			lineItem:{
				id:line_item.id,
				quantity:line_item.quantity,
				
			},
		},
	})
		.then((response) => {
			return dispatch({
 
				type: SHOPIFY_UPDATE_CART_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SHOPIFY_UPDATE_CART_ERROR,
				payload: error.response,
			});
		});
};