import { Form, Button, Spin } from "antd";
import { useState, useEffect, useRef } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import OtpInput from "react-otp-input";
import { useSelector, useDispatch } from "react-redux";
import { Statistic } from "antd";
import { otplogin } from "../../redux/actions/otp.action";
import { postLogin } from "../../redux/actions/login.action";
import { salesTracker } from "../../redux/actions/salesTracker.action";
import { useParams } from "react-router-dom";
import { getAccount } from "../../redux/actions/account.action";
import RegisterNew from "./registerNew";

const antIcon = (
	<LoadingOutlined
		style={{
			fontSize: 24,
		}}
		spin
	/>
);

const CodeInputForm = ({ userId, phone, setSignUp }) => {
	const dispatch = useDispatch();
	const formRef = useRef(null);
	const [form] = Form.useForm();
	const [time, setTime] = useState(120);
	const [resendEmail, setResendEmail] = useState(false);
	const [errors, setErros] = useState("");
	const [loggedIn, setLoggedIn] = useState(false);
	const params = useParams();

	const { authenticUser, otpLogged } = useSelector((state) => {
		return state;
	});



	const isMobileDevice = isMobile() || false; // Call the function to determine if it's a mobile device

	function isMobile() {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}

	const onFinish = (values) => {
		if (resendEmail) {
			dispatch(postLogin(phone)).then((res) => {
				if (res?.payload?.success) {
					setTime(120);
					setResendEmail(false);
				}
			});
		} else {
			if (values?.digit?.split("").length === 6) {
				dispatch(otplogin(userId, values?.digit)).then((res) => {
					if (res?.payload?.success) {
						localStorage.setItem("userInfo", JSON.stringify(res?.payload?.data));
						let shareTracking = JSON.parse(localStorage.getItem("shareTracking"));
						const saveData = () => {
							if (shareTracking) {
								let data = shareTracking.event === "linkinbio" ? { publisher_pixel_id: shareTracking?.publisher_id } : { publisher_id: shareTracking?.publisher_id };

								setTimeout(() => {
									dispatch(salesTracker(data, res?.payload?.data?.token)).then((res) => {
										if (!isMobileDevice) {
											localStorage.setItem(
												"shareTracking",
												JSON.stringify({
													tracker: res?.payload?.data?.tracker,
													event: shareTracking?.event,
													event_review_id: params?.id,
												})
											);
											window.location.reload();
										}

										if (isMobileDevice) {
											setSignUp(true);
										}
									});
								}, 1000);
							}

							if (!shareTracking) {
								if (isMobileDevice) {
									setSignUp(true);
								} else {
									window.location.reload();
								}
							}
						};

						dispatch(getAccount(res?.payload?.data?.token)).then((res) => {
							if ((res?.payload?.Data?.first_name === "" && res?.payload?.Data?.last_name === "") || res?.payload?.Data?.email === "") {
								setLoggedIn(true);
							} else {
								saveData();
							}
						});
					} else {
						setErros("Please Enter Valid OTP code");
						form.resetFields("");
					}
				});
			}
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	useEffect(() => {
		if (time > 0) {
			const interval = setInterval(() => {
				setTime((prevTime) => prevTime - 1);
				if (time === 1) {
					setResendEmail(true);
				}
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [time]);
	const formattedTime = new Date(time * 1000).toISOString().substr(14, 5);
	const [otp, setOtp] = useState("");

	const handleChange = (enteredOtp) => {
		setOtp(enteredOtp);
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter" || event.keyCode === 13) {
			form.submit();
		}
	};

	return (
		<div className="login-signup-ift login-page">
			{loggedIn ? (
				<RegisterNew />
			) : (
				<Form ref={formRef} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
					<h2>
						Let's get Started<br></br>
						<span className="enter-otp-code">Please enter your OTP code</span>
					</h2>

					{errors && <p className="text-danger text-center">{errors}</p>}
					<div className="enter-otp code-spin-hide">
						<Form.Item name="digit" onKeyDown={handleKeyPress}>
							<OtpInput onKeyPress={handleKeyPress} inputType="number" shouldAutoFocus inputStyle="inputStyle" data-index="" value={otp} onChange={handleChange} numInputs={6} renderSeparator={<span></span>} renderInput={(props) => <input {...props} />} />
						</Form.Item>
					</div>

					<Form.Item className="otp-timer">
						<Statistic value={formattedTime} />
						{resendEmail ? (
							<Button disabled={time >= 1 && true} type="primary" htmlType="submit" size="large" block>
								Resend OTP
							</Button>
						) : (
							<>
								{authenticUser?.loading ? (
									<Button type="primary" htmlType="button" size="large" block>
										<Spin indicator={antIcon} />
									</Button>
								) : (
									<>
										{otpLogged?.loading ? (
											<Button type="primary" htmlType="button" size="large" block>
												<Spin indicator={antIcon} />
											</Button>
										) : (
											<Button type="primary" htmlType="submit" size="large" block>
												Submit
											</Button>
										)}
									</>
								)}
							</>
						)}
					</Form.Item>
				</Form>
			)}
		</div>
	);
};

export default CodeInputForm;
