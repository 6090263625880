import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Checkbox,
  Spin,
  notification,
  Tooltip,
} from "antd";
import inficon from "../../../images/brand-logos/hOuVCU.png";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { getAccount } from "../../../redux/actions/account.action";
import { getBecomeInfluencer } from "../../../redux/actions/becomeInfluencer.action";
import { Modal } from "react-bootstrap";
import Terms from "../../../components/terms & conditions/termsconditions";

let userInfo = localStorage.getItem("userInfo");
if (userInfo !== "undefined") {
  userInfo = JSON.parse(localStorage.getItem("userInfo"));
} else {
  localStorage.removeItem("userInfo");
  window.location.reload();
}

export default function BecomeInfluncer({ id }) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showBioLink, setShowBioLink] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [url, setUrl] = useState(null);
  const [showinfoModal, setInfoModal] = useState(false);
  const [profmodal, setProfmodal] = useState(false);
  const { account, becomeInfluencer } = useSelector((state) => {
    return state;
  });

  const onFinish = async (values) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    dispatch(getBecomeInfluencer({ ...values, token: userInfo?.token })).then(
      (res) => {
        if (res?.payload?.success) {
          setShowBioLink(true);
          Swal.fire({
            icon: "success",
            title: "Request is approved!",
          }).then(() => {
            if (userInfo?.token) {
              setTimeout(() => {
                dispatch(getAccount(userInfo?.token));
              }, 1000);
            }
            // .then((res) => {
            //   localStorage.setItem("userInfo", JSON.stringify(res?.payload?.data));
            //   window.location.reload();
            // })
          });
        }
      }
    );
  };

  const onFinishFailed = (async) => {};
  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
    return () => {
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  useEffect(() => {
    if (account?.payload?.Data?.pixel_id) {
      const url =
        window.location.origin + "/" + account?.payload?.Data?.pixel_id;
      setUrl(url);
    }
  }, [account]);

  useEffect(() => {
    if (
      account?.payload?.Data?.name === "" ||
      account?.payload?.Data?.email === ""
    ) {
      Swal.fire({
        title: "Please Complete Your Profile First",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/myaccount");
        }
      });
    } else {
    }
  }, []);

  useLayoutEffect(() => {
    if (account?.payload?.Data?.became_influencer === "approved") {
      setShowBioLink(true);
    }
  }, [account]);

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  const copyToClipboard = (url) => {
    let textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    notification.success({
      message: "Copied to Clipboard!",
    });
  };

  const influencer = () => {
    navigate(`/review/${account?.payload?.Data?.pixel_id}`);
  };

  return (
    <>
      <div className="account-left-content">
        <div className="account-detail col-12 col-xl-6 col-lg-8 col-md-12">
          <div className="bcm-inf-main">
            <div className="pr-op-block pro-inf-block">
              <div className="inf-cret-main">
                <div className="inf-cret-left">
                  {userInfo?.became_influencer === "approved" ? (
                    <>
                      <p>
                        Copy and add below link in your Instagram bio.
                        <Tooltip placement="rightTop" title="View Instructions">
                          <span>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              href=""
                              onClick={() => {
                                setInfoModal(true);
                              }}
                            ></i>
                          </span>
                        </Tooltip>
                      </p>
                      <p className="w-75 pb-3">
                        This is a unique link, directing your followers straight to your ORME profile. You can also share this link on other social platforms.
                        </p>
                    </>
                  ) : (
                    <>
                      <p>
                        As an Influencer you will get an access to a unique
                        Profile Link that can be added to Instagram Bio and a
                        feature to repost videos on Instagram.
                        <Tooltip placement="rightTop" title="View Instructions">
                          <span>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              href=""
                              onClick={() => {
                                setInfoModal(true);
                              }}
                            ></i>
                          </span>
                        </Tooltip>
                      </p>
                    </>
                  )}
                </div>
                <div className="inf-cret-right mb-3">
                  <img src={inficon} alt="" />
                </div>
              </div>

              {!showBioLink && (
                <div className="instagram-user-connect-new">
                  <Form
                    layout={"vertical"}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                  >
                    <Form.Item
                      label="Instagram Username"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Instagram Username",
                        },
                      ]}
                    >
                      <Input placeholder="" size="large" />
                    </Form.Item>
                    <Form.Item
                      label="No of Followers"
                      name="no_of_followers"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Number of Followers",
                        },
                      ]}
                    >
                      <InputNumber placeholder="" size="large" />
                    </Form.Item>
                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error("Should accept agreement")
                                ),
                        },
                      ]}
                      {...tailFormItemLayout}
                    >
                      <Checkbox>
                        By clicking Next, you agree to our{" "}
                        <Link
                          href=""
                          onClick={() => {
                            setShowCustomerModal(true);
                          }}
                        >
                          terms and conditions
                        </Link>
                      </Checkbox>
                    </Form.Item>

                    <div className="bcm-inf-btn">
                      <Form.Item
                        wrapperCol={{
                          offset: 0,
                          span: 24,
                        }}
                      >
                        {becomeInfluencer?.loading ? (
                          <Button>
                            <Spin indicator={antIcon} />
                          </Button>
                        ) : (
                          <Button type="primary" htmlType="submit" size="large">
                            Next
                          </Button>
                        )}
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              )}

              {showBioLink && (
                <div className="add-link-bio-infl">
                  {userInfo?.became_influencer === "" ? 
                  <h4>Add below link in your instagram bio</h4>
                  :
                  <></>
                  }
                  <div className="your-copy-link">
                    <div className="item-a">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => influencer()}
                      >
                        {url}
                      </a>
                    </div>
                    <div
                      className="item-b"
                      onClick={() => copyToClipboard(url)}
                    >
                      Copy
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal
          dialogClassName="code-activated-popup"
          size="xl"
          scrollable={false}
          show={showCustomerModal}
          onHide={() => setShowCustomerModal(false)}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="filter-container">
              <h3>Terms of Service</h3>
            </div>
            <div className="d-flex justify-content-between mt-5">
              <Terms />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          dialogClassName="code-activated-popup"
          scrollable={false}
          show={showinfoModal}
          onHide={() => setInfoModal(false)}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>Instructions</h3>
            <ul className="insta-instructions">
              <li>Launch the Instagram application on your mobile device.</li>
              <li>
                Tap on the profile icon located at the bottom right corner of
                the screen.
              </li>
              <li>Access the "Edit Profile" section.</li>
              <li>Tap on the "Add Link" button.</li>
              <li>
                A prompt labeled "Add External Link" will appear. Tap on it to
                proceed.
              </li>
              <li>
                Paste your ORME profile link in “URL” field and enter its
                “Title”.
              </li>
              <li>Save the changes.</li>
            </ul>
            <p className="remember-text">
              *Remember to verify that the link works correctly by tapping on it
              in your bio.
            </p>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
