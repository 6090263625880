// Imports
import { UPDATE_INTEREST_REQUEST, UPDATE_INTEREST_SUCCESS, UPDATE_INTEREST_ERROR, GET_INTEREST_REQUEST, GET_INTEREST_SUCCESS, GET_INTEREST_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

// Fetches user's interests
export const getInterests = (token) => (dispatch) => {
	dispatch({ type: GET_INTEREST_REQUEST });

	// API request to validate the user
	return (
		axios({
			method: "GET",
			url: `${BASEURL}users/receive/getinterested`,
			headers: { Authorization: `Bearer ${token}` },
		})
			// Success: dispatches action with response data
			.then((response) => {
				return dispatch({
					type: GET_INTEREST_SUCCESS,
					payload: response.data,
				});
			})
			// Error: dispatches action with error response
			.catch((error) => {
				return dispatch({
					type: GET_INTEREST_ERROR,
					payload: error.response,
				});
			})
	);
};

// Updates user's interests
export const updateInterests = (token, categories) => (dispatch) => {
	dispatch({ type: UPDATE_INTEREST_REQUEST });

	// API request to update user profile
	return (
		axios({
			method: "POST",
			url: `${BASEURL}users/receive/saveinterested`,
			headers: { Authorization: `Bearer ${token}` },
			data: {
				gen_IDs: categories,
			},
		})
			// Success: dispatches action with response data
			.then((response) => {
				return dispatch({
					type: UPDATE_INTEREST_SUCCESS,
					payload: response.data,
				});
			})
			// Error: dispatches action with error response
			.catch((error) => {
				return dispatch({
					type: UPDATE_INTEREST_ERROR,
					payload: error.response,
				});
			})
	);
};
