import { POST_SAVE_REVIEWS_SUCCESS, POST_SAVE_REVIEWS_ERROR, POST_SAVE_REVIEWS_REQUEST, POST_SAVE_GET_REQUEST, POST_SAVE_GET_SUCCESS, POST_SAVE_GET_ERROR, GET_SAVE_REVIW_REQUEST, GET_SAVE_REVIW_SUCCESS, GET_SAVE_REVIW_ERROR, REVIEW_SAVE_REQUEST, REVIEW_SAVE_SUCCESS, REVIEW_SAVE_ERROR, SAVED_REVIEWS_REQUEST, SAVED_REVIEWS_ERROR, SAVED_REVIEWS_SUCCESS, SAVED_REVIEWS_LOADMORE, SAVED_EVENTS_ERROR, SAVED_EVENTS_LOADMORE, SAVED_EVENTS_SUCCESS } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const saveEvent = (event_id) => (dispatch) => {
	dispatch({ type: POST_SAVE_REVIEWS_REQUEST });

	return axios({
		method: "POST",
		url: `${BASEURL}save/save`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: { event_id },
	})
		.then((response) => {
			return dispatch({
				type: POST_SAVE_REVIEWS_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_SAVE_REVIEWS_ERROR,
				payload: error.response,
			});
		});
};

export const getSaveAction = (event_id) => (dispatch) => {
	dispatch({ type: POST_SAVE_GET_REQUEST });

	return axios({
		method: "POST",
		url: `${BASEURL}save/get/${event_id}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: { event_id },
	})
		.then((response) => {
			return dispatch({
				type: POST_SAVE_GET_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_SAVE_GET_ERROR,
				payload: error.response,
			});
		});
};

export const getSave = (review_id) => (dispatch) => {
	dispatch({ type: GET_SAVE_REVIW_REQUEST });

	return axios({
		method: "POST",
		url: `${BASEURL}reviewsave/save`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: { review_id },
	})
		.then((response) => {
			return dispatch({
				type: GET_SAVE_REVIW_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_SAVE_REVIW_ERROR,
				payload: error.response,
			});
		});
};

export const getReviewSaved = (review_id) => (dispatch) => {
	dispatch({ type: REVIEW_SAVE_REQUEST });

	return axios({
		method: "POST",
		url: `${BASEURL}reviewsave/get/${review_id}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: { review_id },
	})
		.then((response) => {
			return dispatch({
				type: REVIEW_SAVE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: REVIEW_SAVE_ERROR,
				payload: error.response,
			});
		});
};

export const privateReviews = (page, limit, loadMore, influencer_id) => (dispatch) => {
	return axios({
		method: "POST",
		url: `${BASEURL}private/reviews/getsavedreviews?page=${page}&limit=${limit}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {
			influencer_id: influencer_id,
		},
	})
		.then((res) => {
			let message = {
				data: res?.data?.message?.data?.map((it) => it?.review),
			};

			if (res?.data?.message?.next?.page) {
				message.next = res?.data?.message?.next;
			}

			if (res?.data?.message?.previous?.page) {
				message.previous = res?.data?.message?.previous;
			}

			const data = { message, total_records: res?.data?.message?.total };


			if (loadMore) {
				return dispatch({
					type: SAVED_REVIEWS_LOADMORE,
					payload: data,
				});
			} else {
				return dispatch({
					type: SAVED_REVIEWS_SUCCESS,
					payload: data,
				});
			}
		})
		.catch((error) => {
			return dispatch({
				type: SAVED_REVIEWS_ERROR,
				payload: error.response,
			});
		});
};

export const savedEvents = (page, limit, loadMore, influencer_id) => (dispatch) => {
	return axios({
		method: "POST",
		url: `${BASEURL}private/events/getsavedevents?page=${page}&limit=${limit}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {
			influencer_id: influencer_id,
		},
	})
		.then((res) => {
			let message = {
				data: res?.data?.message?.data?.map((it) => it?.event),
			};

			if (res?.data?.message?.next?.page) {
				message.next = res?.data?.message?.next;
			}

			if (res?.data?.message?.previous?.page) {
				message.previous = res?.data?.message?.previous;
			}

			const data = { message, total_records: res?.data?.message?.total };

			if (loadMore) {
				return dispatch({
					type: SAVED_EVENTS_LOADMORE,
					payload: data,
				});
			} else {
				return dispatch({
					type: SAVED_EVENTS_SUCCESS,
					payload: data,
				});
			}
		})
		.catch((error) => {
			return dispatch({
				type: SAVED_EVENTS_ERROR,
				payload: error.response,
			});
		});
};
