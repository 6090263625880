import { BASEURL } from "../../config";
import axios from "axios";
import {
	GET_CATEGORY_WISE_INFLUENCER_REQUEST,
	GET_CATEGORY_WISE_INFLUENCER_SUCCESS,
	GET_CATEGORY_WISE_INFLUENCER_ERROR,
	GET_ALL_INFLUENCER_REQUEST,
	GET_ALL_INFLUENCER_SUCCESS,
	GET_ALL_INFLUENCER_ERROR,
	POST_ALL_INFLUENCER_REQUEST,
	POST_ALL_INFLUENCER_SUCCESS,
	POST_ALL_INFLUENCER_ERROR,
	POST_SINGLE_INFLUENCER_REQUEST,
	POST_SINGLE_INFLUENCER_SUCCESS,
	POST_SINGLE_INFLUENCER_ERROR,
	POST_REVIEW_GET_REQUEST,
	POST_REVIEW_GET_SUCCESS,
	POST_REVIEW_GET_ERROR,
	POST_REVIEW_LIKE_REQUEST,
	POST_REVIEW_LIKE_SUCCESS,
	POST_REVIEW_LIKE_ERROR,
	POST_ALL_INFLUENCER_SUCCESS_LOADMORE,
	POST_NEW_INFLUENCER_SUCCESS_LOADMORE,
	POST_NEW_INFLUENCER_SUCCESS,
	POST_NEW_INFLUENCER_ERROR,
	POST_REVIEW_DEL_REQUEST,
	POST_REVIEW_DEL_SUCCESS,
	POST_REVIEW_DEL_ERROR,
	POST_FAVOURITES_DEL_REQUEST,
	POST_FAVOURITES_DEL_SUCCESS,
	POST_FAVOURITES_DEL_ERROR,
	POST_COUNT_FOLLOWERS_REQUEST,
	POST_COUNT_FOLLOWERS_SUCCESS,
	POST_COUNT_FOLLOWERS_ERROR,
	POST_REVIEWS_REQUEST,
	POST_REVIEWS_SUCCESS,
	POST_REVIEWS_ERROR,
	DELETE_REVIEWS_REQUEST,
	DELETE_REVIEWS_SUCCESS,
	DELETE_REVIEWS_ERROR,
	POST_CONTENT_REQUEST,
	POST_CONTENT_SUCCESS,
	POST_CONTENT_ERROR,
	POST_UPLOAD_REQUEST,
	POST_UPLOAD_SUCCESS,
	POST_UPLOAD_ERROR,
	POST_UPLOAD_REVIEW_REQUEST,
	POST_UPLOAD_REVIEW_SUCCESS,
	POST_UPLOAD_REVIEW_ERROR,
} from "../types/types";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getAllInfluencer =
	(page = 1, limit = 12, name) =>
	(dispatch) => {
		dispatch({ type: GET_ALL_INFLUENCER_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}mobile/category/getAllInfluencerForWebCustomer`,
			headers: {
				Accept: "application/json",
			},
			data: {
				name: name === "all" ? "" : name,
				account_type: "influencer",
			},
			params: {
				limit,
				page,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_ALL_INFLUENCER_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_ALL_INFLUENCER_ERROR,
					payload: error.response,
				});
			});
	};

export const getCategoryWiseInfluencer =
	(categoryId, key, name, page = 1, limit = 100) =>
	(dispatch) => {
		let categoryUrl = `public/data/categoryWiseInfluencerAndBrand`;
		if (key === "subcategory") {
			categoryUrl = `mobile/category/getInfluencerSubCategoryWise?limit=${limit}&page=${page}`;
		}
		dispatch({ type: GET_CATEGORY_WISE_INFLUENCER_REQUEST });
		return axios({
			method: "POST",
			url: BASEURL + categoryUrl,
			headers: {
				Accept: "application/json",
			},
			data: {
				account_type: "influencer",
				categoryId: categoryId,
				name: name ? name : "",
				key: key,
			},
			params: {
				limit,
				page,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_CATEGORY_WISE_INFLUENCER_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_CATEGORY_WISE_INFLUENCER_ERROR,
					payload: error.response,
				});
			});
	};

export const publishInfluencer =
	(page, limit, gen_Ids = [], cat_Ids = [], sub_cat_Ids = [], brand, sort_by, title, loadMore, user_id) =>
	(dispatch) => {
		let endPoint = "public/reviews/published";

		if (brand || !userInfo) {
			endPoint = "public/reviews/published";
		}
		if (userInfo) {
			endPoint = "private/reviews/published";
		}

		return axios({
			method: "POST",
			url: `${BASEURL}${endPoint}?page=${page}&limit=${limit}`,
			headers: { Authorization: `Bearer ${userInfo?.token}` },
			data: { gen_Ids: gen_Ids, cat_Ids: cat_Ids, sub_cat_Ids: sub_cat_Ids, brand: brand == "1" ? "" : brand, sort_by: sort_by, title, user_id: user_id },
			//data: { user_id: user_id },
		})
			.then((response) => {
				if (loadMore) {
					return dispatch({
						type: POST_ALL_INFLUENCER_SUCCESS_LOADMORE,
						payload: response.data,
					});
				} else {
					return dispatch({
						type: POST_ALL_INFLUENCER_SUCCESS,
						payload: response.data,
					});
				}
			})
			.catch((error) => {
				return dispatch({
					type: POST_ALL_INFLUENCER_ERROR,
					payload: error.response,
				});
			});
	};

// new

export const brandinfReviews =
	(page, limit, gen_Ids = [], cat_Ids = [], sub_cat_Ids = [], brand, sort_by, title, loadMore, user_id) =>
	(dispatch) => {
		return axios({
			method: "POST",
			url: `${BASEURL}public/reviews/published?page=${page}&limit=${limit}`,
			headers: { Authorization: `Bearer ${userInfo?.token}` },
			data: { gen_Ids: gen_Ids, cat_Ids: cat_Ids, sub_cat_Ids: sub_cat_Ids, brand: brand == "1" ? "" : brand, sort_by: sort_by, title, user_id: user_id },
		})
			.then((response) => {
				if (loadMore) {
					return dispatch({
						type: POST_NEW_INFLUENCER_SUCCESS_LOADMORE,
						payload: response.data,
					});
				} else {
					return dispatch({
						type: POST_NEW_INFLUENCER_SUCCESS,
						payload: response.data,
					});
				}
			})
			.catch((error) => {
				return dispatch({
					type: POST_NEW_INFLUENCER_ERROR,
					payload: error.response,
				});
			});
	};

export const publishSingle = (review_id, brand_id) => (dispatch) => {
	dispatch({ type: POST_SINGLE_INFLUENCER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}public/reviews/getonereview`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {
			review_id: review_id,
			brand_id: brand_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_SINGLE_INFLUENCER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_SINGLE_INFLUENCER_ERROR,
				payload: error.response,
			});
		});
};

export const likesGet = (review_id) => (dispatch) => {
	dispatch({ type: POST_REVIEW_GET_REQUEST });
  
	const headers = userInfo ? { Authorization: `Bearer ${userInfo.token}` } : {};
  
	return axios({
	  method: "POST",
	  url: `${BASEURL}reviewlikes/get/${review_id}`,
	  headers: headers,
	  data: {
		review_id,
	  },
	})
	  .then((response) => {
		return dispatch({
		  type: POST_REVIEW_GET_SUCCESS,
		  payload: response.data,
		});
	  })
	  .catch((error) => {
		return dispatch({
		  type: POST_REVIEW_GET_ERROR,
		  payload: error.response,
		});
	  });
  };
  
export const likesSave = (token, review_id) => (dispatch) => {
	dispatch({ type: POST_REVIEW_LIKE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}reviewlikes/save`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			review_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_REVIEW_LIKE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_REVIEW_LIKE_ERROR,
				payload: error.response,
			});
		});
};

export const deleteInfluencerPost = (_id) => (dispatch) => {
	dispatch({ type: POST_REVIEW_DEL_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}reviews/deleteReviewByInfluencer`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {
			_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_REVIEW_DEL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_REVIEW_DEL_ERROR,
				payload: error.response,
			});
		});
};

export const deleteFavourites = (_id) => (dispatch) => {
	dispatch({ type: POST_FAVOURITES_DEL_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}reviews/deleteReviewFromFavorite`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {
			_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_FAVOURITES_DEL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_FAVOURITES_DEL_ERROR,
				payload: error.response,
			});
		});
};

export const likeCount = (id, type) => (dispatch) => {
	dispatch({ type: POST_COUNT_FOLLOWERS_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}public/reviews/profilecount`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: type === "influencer" ? { influencer_id: id } : { brand_id: id },
	})
		.then((response) => {
			return dispatch({
				type: POST_COUNT_FOLLOWERS_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_COUNT_FOLLOWERS_ERROR,
				payload: error.response,
			});
		});
};

export const getAllReviews = (token) => (dispatch) => {
	dispatch({ type: POST_REVIEWS_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}reviews/getAllInfluncerReviews`,
		headers: { Authorization: `Bearer ${token}` },
		// data: type === 'influencer' ? { influencer_id: id } : { brand_id: id }
	})
		.then((response) => {
			return dispatch({
				type: POST_REVIEWS_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_REVIEWS_ERROR,
				payload: error.response,
			});
		});
};

export const deleteReviewVideo = (_id) => (dispatch) => {
	dispatch({ type: DELETE_REVIEWS_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}reviews/deleteReviewByInfluencer`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: { _id: _id },
	})
		.then((response) => {
			return dispatch({
				type: DELETE_REVIEWS_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_REVIEWS_ERROR,
				payload: error.response,
			});
		});
};

export const getRekognitionConfig = () => (dispatch) => {
	dispatch({ type: POST_CONTENT_REQUEST });

	return axios({
		method: "POST",
		url: `${BASEURL}reviews/getContentModerationSetting`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: POST_CONTENT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_CONTENT_ERROR,
				payload: error.response,
			});
		});
};

export const uploadReview = (data) => (dispatch) => {
	dispatch({ type: POST_UPLOAD_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}reviews/uploadreview2`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: data,
	})
		.then((response) => {
			return dispatch({
				type: POST_UPLOAD_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_UPLOAD_ERROR,
				payload: error.response,
			});
		});
};

export const uploadReviewnew = (data) => (dispatch) => {
	dispatch({ type: POST_UPLOAD_REVIEW_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}reviews/uploadreview`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: data,
	})
		.then((response) => {
			return dispatch({
				type: POST_UPLOAD_REVIEW_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_UPLOAD_REVIEW_ERROR,
				payload: error.response,
			});
		});
};
