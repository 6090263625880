import { GET_HOT_DEAL_REQUEST, GET_HOT_DEAL_SUCCESS, GET_HOT_DEAL_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getHotDeal = (title) => (dispatch) => {
	dispatch({ type: GET_HOT_DEAL_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}public/data/getHotDeals`,
		data: {title},
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_HOT_DEAL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_HOT_DEAL_ERROR,
				payload: error.response,
			});
		});
};
