import Layout from "./component/layout";
import ShareEarn from "./component/shareearn";

export default () => {
	return (
		<>
			<Layout title="Share & Earn">
				<ShareEarn />
			</Layout>
		</>
	);
};
