import { POST_NEW_INFLUENCER_REQUEST, POST_NEW_INFLUENCER_SUCCESS, POST_NEW_INFLUENCER_ERROR, POST_NEW_INFLUENCER_SUCCESS_LOADMORE } from "../../types/types";

export default function newinfluencerReviews(state = { loading: true }, action) {
	const { type, payload } = action;

	switch (type) {
		case POST_NEW_INFLUENCER_REQUEST:
			return {
				...state,
				loading: true,
			};
		case POST_NEW_INFLUENCER_SUCCESS:
			let page = 1;
			if(payload?.message?.next?.page){
			 page =	payload?.message?.next?.page - 1
			}
			payload?.message?.data.map((item) => {
				item.page = page;
			});
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case POST_NEW_INFLUENCER_SUCCESS_LOADMORE:
			let nextPage = payload?.message?.next?.page - 1;
			if (payload?.message?.next?.page) {
				payload?.message?.data?.map((item) => {
					item.page = nextPage;
				});
			} else {
				payload?.message?.data?.map((item) => {
					item.page = state?.payload?.message?.next?.page;
				});
			}

			return {
				...state,
				loading: false,
				payload: {
					...state.payload.message,
					message: {
						...payload.message,
						data: [...state.payload.message.data, ...payload.message.data],
					},
				},
			};
		case POST_NEW_INFLUENCER_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}
