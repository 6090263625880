import React from "react";
import { Button, Modal } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import avatarIcon from "../../images/avatar.png";
import { NavLink } from "react-router-dom";

const ProfileImage = ({ url }) => (url ? <img src={url} alt="Profile" /> : <img className="img-fluid" src={avatarIcon} alt="Default Avatar" />);

const FollowButton = ({ userData, isFollowing, onClick, isCustomer, title, bioShopMenu, userInfo }) => {
	const userIdMatch = bioShopMenu?.payload?.message?.data?._id === userInfo?._id;

	let buttonText = "Follow";
	let disabled = isCustomer;
	if (isFollowing) {
		buttonText = "Following";
	}

	if (userIdMatch && title === "Followers") {
		buttonText = "Remove";
		disabled = false;
	} else if (userIdMatch && title === "Following") {
		buttonText = "Following";
	} else if (title === "Following") {
		buttonText = isFollowing ? "Following" : "Follow";
	}

	if (userData?._id === userInfo?._id) {
		disabled = true;
	}

	return (
		<Button disabled={disabled} onClick={onClick} className="follow-btn">
			{buttonText}
		</Button>
	);
};

const FollowersModal = ({ hasMore, fetchMoreData, title, show, handleClose, modalData, executeFollowAction, unFollow, bioShopMenu, userInfo }) => {
	return (
		<div className="modal-simple">
			<Modal dialogClassName="code-activated-popup" size="md" scrollable show={show} onHide={handleClose} centered>
				<Modal.Header closeButton />
				<h3 className="text-center pt-3">{title}</h3>
				<Modal.Body className="followers-modal scrollbar-style" id="scrollableTarget">
					
					{modalData.length > 0 && (
						<InfiniteScroll
							scrollableTarget="scrollableTarget"
							dataLength={modalData?.length}
							next={fetchMoreData}
							hasMore={hasMore} // should be dynamically calculated, e.g. based on whether there are more items to fetch from server
						>
							<div className="modal-followers-list-main ">
								{modalData?.map((item) => {
									const userData = item?.userData[0];
									const isCustomer = userData?.account_type === "customer";
									const isFollowing = item?.is_follow;
									const name = userData?.first_name && userData?.last_name ? userData?.first_name + " " + userData?.last_name : userData.name ? userData.name : userData.pixel_id;

									const handleFollowClick = () => {
										executeFollowAction(userData?._id, userInfo?._id, "", title === "Followers" ? "follower" : "following");
									};

									const handleUnfollowClick = () => {
										let name = userData?.first_name || userData?.last_name ? userData?.first_name + userData?.last_name : userData.name ? userData.name : userData.pixel_id;
										unFollow(userData?._id, userInfo?._id, title === "Followers" ? "follower" : "following", name, userData?.profile_image_url);
									};

									return (
										<div className="modal-followers-list" key={userData?._id}>
											<div className="followers-thumb-area">
												<div className="followers-thumb">
													<ProfileImage url={userData?.profile_image_url} />
												</div>
												<NavLink to={isCustomer ? "#" : `/${userData?.pixel_id}`}>
													<div className="user-info-text">
														<h3>{name}</h3>
													</div>
												</NavLink>
											</div>
											<div className="follow-btn-right">
												<FollowButton userData={userData} isFollowing={isFollowing} onClick={isFollowing ? handleUnfollowClick : handleFollowClick} isCustomer={isCustomer} title={title} bioShopMenu={bioShopMenu} userInfo={userInfo} />
											</div>
										</div>
									);
								})}
							</div>
						</InfiniteScroll>
					)}
				</Modal.Body>
			</Modal>
		</div>
	);
};
export default FollowersModal;
