import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Spin, Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import avatarIcon from "../../../images/avatar.png";
import InfiniteScroll from "react-infinite-scroll-component";

// Import your Redux action for fetching influencers
import { getInfluencers } from "../../../redux/actions/becomeInfluencer.action";

const ReviewList = ({ setDisplaySearch }) => {
	const dispatch = useDispatch();
	const [page, setPage] = useState(2);
	const { influencers, isLoading, hasMore } = useSelector((state) => ({
		isLoading: state?.influencers?.loading,
		influencers: state.influencers,
		hasMore: state.influencers.payload?.message?.total_records !== state?.influencers?.payload?.message?.data?.length ? true : false,
	}));

	const loadMore = () => dispatch(getInfluencers(page, 6, "", "", "", undefined, "", "", true)).then(() => setPage(page + 1));

	return (
		<>
			<div className="search-hd-row">
				<h4 className="tab-hd">User Shop</h4>
				<Link onClick={() => setDisplaySearch(false)} to="/influencers">
					<a className="tab-view-more-link">View More</a>
				</Link>
			</div>
			<div className="search-tab-scroll">
				<div id="review-target" className="tab-product-list-main position-relative scrollbar-style-product">
					{isLoading ? (
						<div className="antd-loading-ift">
							<Spin size="medium" />
						</div>
					) : (
						<>
							{influencers?.payload?.message?.data?.length > 0 ? (
								<InfiniteScroll scrollableTarget="review-target" dataLength={influencers?.payload?.message?.data?.length} next={loadMore} hasMore={hasMore}>
									{influencers?.payload?.message?.data?.map((item, key) => (
										<Link onClick={() => setDisplaySearch(false)} to={`/${item?.pixel_id}`} className="tab-product-list-item reviews" key={key}>
											<span className="review-image">
												<figure className={`${item?.profile_image_url ? "" : "avatar-bg"} review-user-img`}>
													<img src={item?.profile_image_url || avatarIcon} alt="Reviewer" />
												</figure>
											</span>
											<div className="tab-product-info">
												<div className="tab-product-name">{item?.name}</div>
												<div className="info-1">{item?.no_of_followers} Followers</div>
											</div>
											<div className="tab-add-product-right">
												<button type="button" className="tab-add-product-button large-size">
													<FontAwesomeIcon icon={faArrowRight} />
												</button>
											</div>
										</Link>
									))}
								</InfiniteScroll>
							) : (
								<div className="antd-loading-ift">
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No User found</span>} />
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default ReviewList;
