export const GET_ALL_PROFILE_REQUEST = "GET_ALL_PROFILE_REQUEST";
export const GET_ALL_PROFILE_SUCCESS = "GET_ALL_PROFILE_SUCCESS";
export const GET_ALL_PROFILE_ERROR = "GET_ALL_PROFILE_ERROR";

export const GET_POPULAR_CATEGORIES_REQUEST = "GET_POPULAR_CATEGORIES_REQUEST";
export const GET_POPULAR_CATEGORIES_SUCCESS = "GET_POPULAR_CATEGORIES_SUCCESS";
export const GET_POPULAR_CATEGORIES_ERROR = "GET_POPULAR_CATEGORIES_ERROR";

export const GET_PARENT_CATEGORIES_REQUEST = "GET_PARENT_CATEGORIES_REQUEST";
export const GET_PARENT_CATEGORIES_SUCCESS = "GET_PARENT_CATEGORIES_SUCCESS";
export const GET_PARENT_CATEGORIES_ERROR = "GET_PARENT_CATEGORIES_ERROR";

export const GET_ALL_BRAND_REQUEST = "GET_ALL_BRAND_REQUEST";
export const GET_ALL_BRAND_SUCCESS = "GET_ALL_BRAND_SUCCESS";
export const GET_ALL_BRAND_ERROR = "GET_ALL_BRAND_ERROR";
export const GET_ALL_BRAND_LOADMORE = "GET_ALL_BRAND_LOADMORE";

export const GET_ALL_INFLUENCER_REQUEST = "GET_ALL_INFLUENCER_REQUEST";
export const GET_ALL_INFLUENCER_SUCCESS = "GET_ALL_INFLUENCER_SUCCESS";
export const GET_ALL_INFLUENCER_ERROR = "GET_ALL_INFLUENCER_ERROR";

export const GET_FEATURE_BRAND_REQUEST = "GET_FEATURE_BRAND_REQUEST";
export const GET_FEATURE_BRAND_SUCCESS = "GET_FEATURE_BRAND_SUCCESS";
export const GET_FEATURE_BRAND_ERROR = "GET_FEATURE_BRAND_ERROR";

export const GET_USER_BRAND_REQUEST = "GET_USER_BRAND_REQUEST";
export const GET_USER_BRAND_SUCCESS = "GET_USER_BRAND_SUCCESS";
export const GET_USER_BRAND_ERROR = "GET_USER_BRAND_ERROR";

export const GET_SUB_CATEGORIES_REQUEST = "GET_SUB_CATEGORIES_REQUEST";
export const GET_SUB_CATEGORIES_SUCCESS = "GET_SUB_CATEGORIES_SUCCESS";
export const GET_SUB_CATEGORIES_ERROR = "GET_SUB_CATEGORIES_ERROR";

export const GET_POPULAR_INFLUENCER_REQUEST = "GET_POPULAR_INFLUENCER_REQUEST";
export const GET_POPULAR_INFLUENCER_SUCCESS = "GET_POPULAR_INFLUENCER_SUCCESS";
export const GET_POPULAR_INFLUENCER_ERROR = "GET_POPULAR_INFLUENCER_ERROR";

export const GET_ALL_CATEGORIES_REQUEST = "GET_ALL_CATEGORIES_REQUEST";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR";

export const POST_SAVE_CATEGORIES_REQUEST = "POST_SAVE_CATEGORIES_REQUEST";
export const POST_SAVE_CATEGORIES_SUCCESS = "POST_SAVE_CATEGORIES_SUCCESS";
export const POST_SAVE_CATEGORIES_ERROR = "POST_SAVE_CATEGORIES_ERROR";

export const GET_CATEGORIES_SETUP_REQUEST = "GET_CATEGORIES_SETUP_REQUEST";
export const GET_CATEGORIES_SETUP_SUCCESS = "GET_CATEGORIES_SETUP_SUCCESS";
export const GET_CATEGORIES_SETUP_ERROR = "GET_CATEGORIES_SETUP_ERROR";

export const GET_SUB_CATEGORIES = "GET_SUB_CATEGORIES";

export const VERIFY_CATEGORIES_REQUEST = "VERIFY_CATEGORIES_REQUEST";
export const VERIFY_CATEGORIES_SUCCESS = "VERIFY_CATEGORIES_SUCCESS";
export const VERIFY_CATEGORIES_ERROR = "VERIFY_CATEGORIES_ERROR";

export const GET_CATEGORY_WISE_BRAND_REQUEST = "GET_CATEGORY_WISE_BRAND_REQUEST";
export const GET_CATEGORY_WISE_BRAND_SUCCESS = "GET_CATEGORY_WISE_BRAND_SUCCESS";
export const GET_CATEGORY_WISE_BRAND_ERROR = "tGET_CATEGORY_WISE_BRAND_ERROR";

export const GET_CATEGORY_WISE_INFLUENCER_REQUEST = "GET_CATEGORY_WISE_INFLUENCER_REQUEST";
export const GET_CATEGORY_WISE_INFLUENCER_SUCCESS = "GET_CATEGORY_WISE_INFLUENCER_SUCCESS";
export const GET_CATEGORY_WISE_INFLUENCER_ERROR = "GET_CATEGORY_WISE_INFLUENCER_ERROR";

export const GET_BIO_SHOP_MENU_REQUEST = "GET_BIO_SHOP_MENU_REQUEST";
export const GET_BIO_SHOP_MENU_SUCCESS = "GET_BIO_SHOP_MENU_SUCCESS";
export const GET_BIO_SHOP_MENU_ERROR = "GET_BIO_SHOP_MENU_ERROR";

export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_ERROR = "POST_LOGIN_ERROR";

export const GET_BIO_SHOP_ALL_POST_REQUEST = "GET_BIO_SHOP_ALL_POST_REQUEST";
export const GET_BIO_SHOP_ALL_POST_SUCCESS = "GET_BIO_SHOP_ALL_POST_SUCCESS";
export const GET_BIO_SHOP_ALL_POST_ERROR = "GET_BIO_SHOP_ALL_POST_ERROR";

export const GET_BIO_SHOP_ALL_LINK_REQUEST = "GET_BIO_SHOP_ALL_LINK_REQUEST";
export const GET_BIO_SHOP_ALL_LINK_SUCCESS = "GET_BIO_SHOP_ALL_LINK_SUCCESS";
export const GET_BIO_SHOP_ALL_LINK_ERROR = "GET_BIO_SHOP_ALL_LINK_ERROR";

export const GET_BIO_SHOP_LINK_CLINK_REQUEST = "GET_BIO_SHOP_LINK_CLINK_REQUEST";
export const GET_BIO_SHOP_LINK_CLINK_SUCCESS = "GET_BIO_SHOP_LINK_CLINK_SUCCESS";
export const GET_BIO_SHOP_LINK_CLINK_ERROR = "GET_BIO_SHOP_LINK_CLINK_ERROR";
export const POST_COUNTRY_REQUEST = "POST_COUNTRY_REQUEST";
export const POST_COUNTRY_SUCCESS = "POST_COUNTRY_SUCCESS";
export const POST_COUNTRY_ERROR = "POST_COUNTRY_ERROR";

export const POST_STATE_REQUEST = "POST_STATE_REQUEST";
export const POST_STATE_SUCCESS = "POST_STATE_SUCCESS";
export const POST_STATE_ERROR = "POST_STATE_ERROR";

export const POST_CITY_REQUEST = "POST_CITY_REQUEST";
export const POST_CITY_SUCCESS = "POST_CITY_SUCCESS";
export const POST_CITY_ERROR = "POST_CITY_ERROR";

export const POST_REGISTER_REQUEST = "POST_REGISTER_REQUEST";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_ERROR = "POST_REGISTER_ERROR";

export const GET_BIO_SHOP_CATEGORY_POST_REQUEST = "GET_BIO_SHOP_CATEGORY_POST_REQUEST";
export const GET_BIO_SHOP_CATEGORY_POST_SUCCESS = "GET_BIO_SHOP_CATEGORY_POST_SUCCESS";
export const GET_BIO_SHOP_CATEGORY_POST_ERROR = "GET_BIO_SHOP_CATEGORY_POST_ERROR";

export const GET_ADD_TO_FAVOURITE_REQUEST = "GET_ADD_TO_FAVOURITE_REQUEST";
export const GET_ADD_TO_FAVOURITE_SUCCESS = "GET_ADD_TO_FAVOURITE_SUCCESS";
export const GET_ADD_TO_FAVOURITE_ERROR = "GET_ADD_TO_FAVOURITE_ERROR";

export const GET_ADD_TO_URL_SUCCESS = "GET_ADD_TO_URL_SUCCESS";
export const GET_ADD_TO_URL_ERROR = "GET_ADD_TO_URL_ERROR";
export const GET_ADD_TO_URL_REQUEST = "GET_ADD_TO_URL_REQUEST";

export const POST_LOGIN_OPEN_REQUEST = "POST_LOGIN_OPEN_REQUEST";
export const POST_LOGIN_OPEN_SUCCESS = "POST_LOGIN_OPEN_SUCCESS";
export const POST_LOGIN_OPEN_ERROR = "POST_LOGIN_OPEN_ERROR";

export const GET_BANNER_REQUEST = "GET_BANNER_REQUEST";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_ERROR = "GET_BANNER_ERROR";

export const GET_FAVOURITE_BRAND_REQUEST = "GET_FAVOURITE_BRAND_REQUEST";
export const GET_FAVOURITE_BRAND_SUCCESS = "GET_FAVOURITE_BRAND_SUCCESS";
export const GET_FAVOURITE_BRAND_ERROR = "GET_FAVOURITE_BRAND_ERROR";

export const GET_HOT_DEAL_REQUEST = "GET_HOT_DEAL_REQUEST";
export const GET_HOT_DEAL_SUCCESS = "GET_HOT_DEAL_SUCCESS";
export const GET_HOT_DEAL_ERROR = "GET_HOT_DEAL_ERROR";

export const GET_CATEGORY_BANNER_REQUEST = "GET_CATEGORY_BANNER_REQUEST";
export const GET_CATEGORY_BANNER_SUCCESS = "GET_CATEGORY_BANNER_SUCCESS";
export const GET_CATEGORY_BANNER_ERROR = "GET_CATEGORY_BANNER_ERROR";

export const GET_CATEGORY_FAVOURITE_BRAND_REQUEST = "GET_CATEGORY_FAVOURITE_BRAND_REQUEST";
export const GET_CATEGORY_FAVOURITE_BRAND_SUCCESS = "GET_CATEGORY_FAVOURITE_BRAND_SUCCESS";
export const GET_CATEGORY_FAVOURITE_BRAND_ERROR = "GET_CATEGORY_FAVOURITE_BRAND_ERROR";

export const GET_CATEGORY_FEATURE_BRAND_REQUEST = "GET_CATEGORY_FEATURE_BRAND_REQUEST";
export const GET_CATEGORY_FEATURE_BRAND_SUCCESS = "GET_CATEGORY_FEATURE_BRAND_SUCCESS";
export const GET_CATEGORY_FEATURE_BRAND_ERROR = "GET_CATEGORY_FEATURE_BRAND_ERROR";

export const GET_CHANGE_PASSWORD_REQUEST = "GET_CHANGE_PASSWORD_REQUEST";
export const GET_CHANGE_PASSWORD_SUCCESS = "GET_CHANGE_PASSWORD_SUCCESS";
export const GET_CHANGE_PASSWORD_ERROR = "GET_CHANGE_PASSWORD_ERROR";

export const POST_SELECTED_COUNTRY_REQUEST = "POST_SELECTED_COUNTRY_REQUEST";
export const POST_SELECTED_COUNTRY_SUCCESS = "POST_SELECTED_COUNTRY_SUCCESS";
export const POST_SELECTED_COUNTRY_ERROR = "POST_SELECTED_COUNTRY_ERROR";

export const POST_BRAND_REGISTER_REQUEST = "POST_BRAND_REGISTER_REQUEST";
export const POST_BRAND_REGISTER_SUCCESS = "POST_BRAND_REGISTER_SUCCESS";
export const POST_BRAND_REGISTER_ERROR = "POST_BRAND_REGISTER_ERROR";

export const GET_BIO_SHOP_VIEW_COUNT_REQUEST = "GET_BIO_SHOP_VIEW_COUNT_REQUEST";
export const GET_BIO_SHOP_VIEW_COUNT_SUCCESS = "GET_BIO_SHOP_VIEW_COUNT_SUCCESS";
export const GET_BIO_SHOP_VIEW_COUNT_ERROR = "GET_BIO_SHOP_VIEW_COUNT_ERROR";
export const POST_PASSWORD_MODAL_OPEN_REQUEST = "POST_PASSWORD_MODAL_OPEN_REQUEST";
export const POST_PASSWORD_MODAL_OPEN_SUCCESS = "POST_PASSWORD_MODAL_OPEN_SUCCESS";
export const POST_PASSWORD_MODAL_OPEN_ERROR = "POST_PASSWORD_MODAL_OPEN_ERROR";

export const GET_ACCOUNT_REQUEST = "GET_ACCOUNT_REQUEST";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_ERROR = "GET_ACCOUNT_ERROR";

export const GET_UPDATE_ACCOUNT_REQUEST = "GET_UPDATE_ACCOUNT_REQUEST";
export const GET_UPDATE_ACCOUNT_SUCCESS = "GET_UPDATE_ACCOUNT_SUCCESS";
export const GET_UPDATE_ACCOUNT_ERROR = "GET_UPDATE_ACCOUNT_ERROR";

export const GET_BECOME_INFLUENCER_REQUEST = "GET_BECOME_INFLUENCER_REQUEST";
export const GET_BECOME_INFLUENCER_SUCCESS = "GET_BECOME_INFLUENCER_SUCCESS";
export const GET_BECOME_INFLUENCER_ERROR = "GET_BECOME_INFLUENCER_ERROR";

export const GET_REMOVE_FAVOURITE_LIST_REQUEST = "GET_REMOVE_FAVOURITE_LIST_REQUEST";
export const GET_REMOVE_FAVOURITE_LIST_SUCCESS = "GET_REMOVE_FAVOURITE_LIST_SUCCESS";
export const GET_REMOVE_FAVOURITE_LIST_ERROR = "GET_REMOVE_FAVOURITE_LIST_ERROR";

export const GET_REMOVE_FAVOURITE_REQUEST = "GET_REMOVE_FAVOURITE_REQUEST";
export const GET_REMOVE_FAVOURITE_SUCCESS = "GET_REMOVE_FAVOURITE_SUCCESS";
export const GET_REMOVE_FAVOURITE_ERROR = "GET_REMOVE_FAVOURITE_ERROR";

export const GET_FAVOURITE_LIST_REQUEST = "GET_FAVOURITE_LIST_REQUEST";
export const GET_FAVOURITE_LIST_SUCCESS = "GET_FAVOURITE_LIST_SUCCESS";
export const GET_FAVOURITE_LIST_ERROR = "GET_FAVOURITE_LIST_ERROR";

export const GET_FAVOURITE_BRAND_LIST_REQUEST = "GET_FAVOURITE_BRAND_LIST_REQUEST";
export const GET_FAVOURITE_BRAND_LIST_SUCCESS = "GET_FAVOURITE_BRAND_LIST_SUCCESS";
export const GET_FAVOURITE_BRAND_LIST_ERROR = "GET_FAVOURITE_BRAND_LIST_ERROR";

export const GET_REMOVE_FAVOURITE_BRAND_REQUEST = "GET_REMOVE_FAVOURITE_BRAND_REQUEST";
export const GET_REMOVE_FAVOURITE_BRAND_SUCCESS = "GET_REMOVE_FAVOURITE_BRAND_SUCCESS";
export const GET_REMOVE_FAVOURITE_BRAND_ERROR = "GET_REMOVE_FAVOURITE_BRAND_ERROR";

export const GET_ADD_TO_FAVOURITE_BRAND_REQUEST = "GET_ADD_TO_FAVOURITE_BRAND_REQUEST";
export const GET_ADD_TO_FAVOURITE_BRAND_SUCCESS = "GET_ADD_TO_FAVOURITE_BRAND_SUCCESS";
export const GET_ADD_TO_FAVOURITE_BRAND_ERROR = "GET_ADD_TO_FAVOURITE_BRAND_ERROR";

export const GET_FAVOURITE_INFLUENCER_LIST_REQUEST = "GET_FAVOURITE_INFLUENCER_LIST_REQUEST";
export const GET_FAVOURITE_INFLUENCER_LIST_SUCCESS = "GET_FAVOURITE_INFLUENCER_LIST_SUCCESS";
export const GET_FAVOURITE_INFLUENCER_LIST_ERROR = "GET_FAVOURITE_INFLUENCER_LIST_ERROR";

export const GET_PURCHASE_REPORT_REQUEST = "GET_PURCHASE_REPORT_REQUEST";
export const GET_PURCHASE_REPORT_SUCCESS = "GET_PURCHASE_REPORT_SUCCESS";
export const GET_PURCHASE_REPORT_ERROR = "GET_PURCHASE_REPORT_ERROR";

export const POST_REGISTER_OPEN_REQUEST = "POST_REGISTER_OPEN_REQUEST";
export const POST_REGISTER_OPEN_SUCCESS = "POST_REGISTER_OPEN_SUCCESS";
export const POST_REGISTER_OPEN_ERROR = "POST_REGISTER_OPEN_ERROR";

export const GET_COMMISSION_REPORT_REQUEST = "GET_COMMISSION_REPORT_REQUEST";
export const GET_COMMISSION_REPORT_SUCCESS = "GET_COMMISSION_REPORT_SUCCESS";
export const GET_COMMISSION_REPORT_ERROR = "GET_COMMISSION_REPORT_ERROR";

export const GET_COMMISSION_EARN_REPORT_REQUEST = "GET_COMMISSION_REPORT_REQUEST";
export const GET_COMMISSION_EARN_REPORT_SUCCESS = "GET_COMMISSION_EARN_REPORT_SUCCESS";
export const GET_COMMISSION_EARN_REPORT_ERROR = "GET_COMMISSION_EARN_REPORT_ERROR";

export const POST_CONTACT_REQUEST = "POST_CONTACT_REQUEST";
export const POST_CONTACT_SUCCESS = "POST_CONTACT_SUCCESS";
export const POST_CONTACT_ERROR = "POST_CONTACT_ERROR";

export const GET_ALL_WITHDRAW_REQUEST = "GET_ALL_WITHDRAW_REQUEST";
export const GET_ALL_WITHDRAW_SUCCESS = "GET_ALL_WITHDRAW_SUCCESS";
export const GET_ALL_WITHDRAW_ERROR = "GET_ALL_WITHDRAW_ERROR";

export const POST_WITHDRAW_REQUEST = "POST_WITHDRAW_REQUEST";
export const POST_WITHDRAW_SUCCESS = "POST_WITHDRAW_SUCCESS";
export const POST_WITHDRAW_ERROR = "POST_WITHDRAW_ERROR";

export const GET_SUB_CATEGORY_WISE_BRAND_REQUEST = "GET_SUB_CATEGORY_WISE_BRAND_REQUEST";
export const GET_SUB_CATEGORY_WISE_BRAND_SUCCESS = "GET_SUB_CATEGORY_WISE_BRAND_SUCCESS";
export const GET_SUB_CATEGORY_WISE_BRAND_ERROR = "GET_SUB_CATEGORY_WISE_BRAND_ERROR";

export const GET_BRAND_WALLET_REQUEST = "GET_BRAND_WALLET_REQUEST";
export const GET_BRAND_WALLET_SUCCESS = "GET_BRAND_WALLET_SUCCESS";
export const GET_BRAND_WALLET_ERROR = "GET_BRAND_WALLET_ERROR";

export const GET_LIVE_EVENT_REQUEST = "GET_LIVE_EVENT_REQUEST";
export const GET_LIVE_EVENT_SUCCESS = "GET_LIVE_EVENT_SUCCESS";
export const GET_LIVE_EVENT_ERROR = "GET_LIVE_EVENT_ERROR";

export const ALL_UPCOMING_EVENT_REQUEST = "ALL_UPCOMING_EVENT_REQUEST";
export const ALL_UPCOMING_EVENT_SUCCESS = "ALL_UPCOMING_EVENT_SUCCESS";
export const ALL_UPCOMING_EVENT_SUCCESS_MORE = "ALL_UPCOMING_EVENT_SUCCESS_MORE";

export const ALL_UPCOMING_EVENT_ERROR = "ALL_UPCOMING_EVENT_ERROR";

export const UPCOMING_EVENT_REQUEST = "UPCOMING_EVENT_REQUEST";
export const UPCOMING_EVENT_SUCCESS = "UPCOMING_EVENT_SUCCESS";
export const UPCOMING_EVENT_ERROR = "UPCOMING_EVENT_ERROR";

export const ALL_LIVE_EVENT_REQUEST = "ALL_LIVE_EVENT_REQUEST";
export const ALL_LIVE_EVENT_SUCCESS = "ALL_LIVE_EVENT_SUCCESS";
export const ALL_LIVE_EVENT_SUCCESS_MORE = "ALL_LIVE_EVENT_SUCCESS_MORE";
export const ALL_LIVE_EVENT_ERROR = "ALL_LIVE_EVENT_ERROR";

export const LIVE_EVENT_REQUEST = "LIVE_EVENT_REQUEST";
export const LIVE_EVENT_SUCCESS = "LIVE_EVENT_SUCCESS";
export const LIVE_EVENT_ERROR = "LIVE_EVENT_ERROR";

export const GET_EVENT_ID_REQUEST = "GET_EVENT_ID_REQUEST";
export const GET_EVENT_ID_SUCCESS = "GET_EVENT_ID_SUCCESS";
export const GET_EVENT_ID_ERROR = "GET_EVENT_ID_ERROR";

export const BRAND_LIVE_EVENT_REQUEST = "BRAND_LIVE_EVENT_REQUEST";
export const BRAND_LIVE_EVENT_SUCCESS = "BRAND_LIVE_EVENT_SUCCESS";
export const BRAND_LIVE_EVENT_ERROR = "BRAND_LIVE_EVENT_ERROR";

export const GET_MULTIPLE_SUB_CATEGORIES_REQUEST = "GET_MULTIPLE_SUB_CATEGORIES_REQUEST";
export const GET_MULTIPLE_SUB_CATEGORIES_SUCCESS = "GET_MULTIPLE_SUB_CATEGORIES_SUCCESS";
export const GET_MULTIPLE_SUB_CATEGORIES_ERROR = "GET_MULTIPLE_SUB_CATEGORIES_ERROR";

export const SHOPIFY_PROD_REQUEST = "SHOPIFY_PROD_REQUEST";
export const SHOPIFY_PROD_SUCCESS = "SHOPIFY_PROD_SUCCESS";
export const SHOPIFY_PROD_ERROR = "SHOPIFY_PROD_ERROR";

export const SHOPIFY_CART_REQUEST = "SHOPIFY_CART_REQUEST";
export const SHOPIFY_CART_SUCCESS = "SHOPIFY_CART_SUCCESS";
export const SHOPIFY_CART_ERROR = "SHOPIFY_CART_ERROR";

export const SHOPIFY_GET_CART_REQUEST = "SHOPIFY_GET_CART_REQUEST";
export const SHOPIFY_GET_CART_SUCCESS = "SHOPIFY_GET_CART_SUCCESS";
export const SHOPIFY_GET_CART_ERROR = "SHOPIFY_GET_CART_ERROR";

export const SHOPIFY_CHECKOUT_REQUEST = "SHOPIFY_CHECKOUT_REQUEST";
export const SHOPIFY_CHECKOUT_SUCCESS = "SHOPIFY_CHECKOUT_SUCCESS";
export const SHOPIFY_CHECKOUT_ERROR = "SHOPIFY_CHECKOUT_ERROR";

export const SHOPIFY_REMOVE_CART_REQUEST = "SHOPIFY_REMOVE_CART_REQUEST";
export const SHOPIFY_REMOVE_CART_SUCCESS = "SHOPIFY_REMOVE_CART_SUCCESS";
export const SHOPIFY_REMOVE_CART_ERROR = "SHOPIFY_REMOVE_CART_ERROR";

export const ADD_CREDIT_CARD_REQUEST = "ADD_CREDIT_CARD_REQUEST";
export const ADD_CREDIT_CARD_SUCCESS = "ADD_CREDIT_CARD_SUCCESS";
export const ADD_CREDIT_CARD_ERROR = "ADD_CREDIT_CARD_ERROR";

export const GET_ALL_CREDIT_CARD_REQUEST = "GET_ALL_CREDIT_CARD_REQUEST";
export const GET_ALL_CREDIT_CARD_SUCCESS = "GET_ALL_CREDIT_CARD_SUCCESS";
export const GET_ALL_CREDIT_CARD_ERROR = "GET_ALL_CREDIT_CARD_ERROR";

export const SET_DEFAULT_CREDIT_CARD_REQUEST = "SET_DEFAULT_CREDIT_CARD_REQUEST";
export const SET_DEFAULT_CREDIT_CARD_SUCCESS = "SET_DEFAULT_CREDIT_CARD_SUCCESS";
export const SET_DEFAULT_CREDIT_CARD_ERROR = "SET_DEFAULT_CREDIT_CARD_ERROR";

export const CREATE_SHIPPING_ADDRESS_REQUEST = "CREATE_SHIPPING_ADDRESS_REQUEST";
export const CREATE_SHIPPING_ADDRESS_SUCCESS = "CREATE_SHIPPING_ADDRESS_SUCCESS";
export const CREATE_SHIPPING_ADDRESS_ERROR = "CREATE_SHIPPING_ADDRESS_ERROR";

export const GET_ALL_SHIPPING_ADDRESS_REQUEST = "GET_ALL_SHIPPING_ADDRESS_REQUEST";
export const GET_ALL_SHIPPING_ADDRESS_SUCCESS = "GET_ALL_SHIPPING_ADDRESS_SUCCESS";
export const GET_ALL_SHIPPING_ADDRESS_ERROR = "GET_ALL_SHIPPING_ADDRESS_ERROR";

export const SET_DEFAULT_SHIPPING_ADDRESS_REQUEST = "SET_DEFAULT_SHIPPING_ADDRESS_REQUEST";
export const SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS = "SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS";
export const SET_DEFAULT_SHIPPING_ADDRESS_ERROR = "SET_DEFAULT_SHIPPING_ADDRESS_ERROR";

export const SHOPIFY_SET_SHIPPING_ADDRESS_REQUEST = "SHOPIFY_SET_SHIPPING_ADDRESS_REQUEST";
export const SHOPIFY_SET_SHIPPING_ADDRESS_SUCCESS = "SHOPIFY_SET_SHIPPING_ADDRESS_SUCCESS";
export const SHOPIFY_SET_SHIPPING_ADDRESS_ERROR = "SHOPIFY_SET_SHIPPING_ADDRESS_ERROR";

export const SHOPIFY_GET_SHIPPING_RATES_REQUEST = "SHOPIFY_GET_SHIPPING_RATES_REQUEST";
export const SHOPIFY_GET_SHIPPING_RATES_SUCCESS = "SHOPIFY_GET_SHIPPING_RATES_SUCCESS";
export const SHOPIFY_GET_SHIPPING_RATES_ERROR = "SHOPIFY_GET_SHIPPING_RATES_ERROR";

export const SHOPIFY_SET_SHIPPING_RATES_REQUEST = "SHOPIFY_SET_SHIPPING_RATES_REQUEST";
export const SHOPIFY_SET_SHIPPING_RATES_SUCCESS = "SHOPIFY_SET_SHIPPING_RATES_SUCCESS";
export const SHOPIFY_SET_SHIPPING_RATES_ERROR = "SHOPIFY_SET_SHIPPING_RATES_ERROR";

export const SHOPIFY_APPLY_PROMO_REQUEST = "SHOPIFY_APPLY_PROMO_REQUEST";
export const SHOPIFY_APPLY_PROMO_SUCCESS = "SHOPIFY_APPLY_PROMO_SUCCESS";
export const SHOPIFY_APPLY_PROMO_ERROR = "SHOPIFY_APPLY_PROMO_ERROR";

export const SHOPIFY_REMOVE_PROMO_CODE_REQUEST = "SHOPIFY_REMOVE_PROMO_CODE_REQUEST";
export const SHOPIFY_REMOVE_PROMO_CODE_SUCCESS = "SHOPIFY_REMOVE_PROMO_CODE_SUCCESS";
export const SHOPIFY_REMOVE_PROMO_CODE_ERROR = "SHOPIFY_REMOVE_PROMO_CODE_ERROR";

export const SHOPIFY_COMPLETE_CHECKOUT_REQUEST = "SHOPIFY_COMPLETE_CHECKOUT_REQUEST";
export const SHOPIFY_COMPLETE_CHECKOUT_SUCCESS = "SHOPIFY_COMPLETE_CHECKOUT_SUCCESS";
export const SHOPIFY_COMPLETE_CHECKOUT_ERROR = "SHOPIFY_COMPLETE_CHECKOUT_ERROR";

export const SHOPIFY_UPDATE_ATTRIBUTE_REQUEST = "SHOPIFY_UPDATE_ATTRIBUTE_REQUEST";
export const SHOPIFY_UPDATE_ATTRIBUTE_SUCCESS = "SHOPIFY_UPDATE_ATTRIBUTE_SUCCESS";
export const SHOPIFY_UPDATE_ATTRIBUTE_ERROR = "SHOPIFY_UPDATE_ATTRIBUTE_ERROR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const SHOPIFY_EMPTY_CART_REQUEST = "SHOPIFY_EMPTY_CART_REQUEST";
export const SHOPIFY_EMPTY_CART_SUCCESS = "SHOPIFY_EMPTY_CART_SUCCESS";
export const SHOPIFY_EMPTY_CART_ERROR = "SHOPIFY_EMPTY_CART_ERROR";

export const ALL_PUBLISH_EVENT_REQUEST = "ALL_PUBLISH_EVENT_REQUEST";
export const ALL_PUBLISH_EVENT_SUCCESS = "ALL_PUBLISH_EVENT_SUCCESS";
export const ALL_PUBLISH_EVENT_SUCCESS_LOADMORE = "ALL_PUBLISH_EVENT_SUCCESS_LOADMORE";
export const ALL_PUBLISH_EVENT_ERROR = "ALL_PUBLISH_EVENT_ERROR";

export const GET_EVENT_VIEW_REQUEST = "GET_EVENT_VIEW_REQUEST";
export const GET_EVENT_VIEW_SUCCESS = "GET_EVENT_VIEW_SUCCESS";
export const GET_EVENT_VIEW_ERROR = "GET_EVENT_VIEW_ERROR";

export const GET_CHAT_TOKEN = "GET_CHAT_TOKEN";

export const GET_CHAT_REQUEST = "GET_CHAT_REQUEST";
export const GET_CHAT_SUCCESS = "GET_CHAT_SUCCESS";
export const GET_CHAT_ERROR = "GET_CHAT_ERROR";

export const SAVE_CHAT_REQUEST = "SAVE_CHAT_REQUEST";
export const SAVE_CHAT_SUCCESS = "SAVE_CHAT_SUCCESS";
export const SAVE_CHAT_ERROR = "SAVE_CHAT_ERROR";

export const GET_EVENT_LIKES_REQUEST = "GET_EVENT_LIKES_REQUEST";
export const GET_EVENT_LIKES_SUCCESS = "GET_EVENT_LIKES_SUCCESS";
export const GET_EVENT_LIKES_ERROR = "GET_EVENT_LIKES_ERROR";

export const GET_LIKES_REQUEST = "GET_LIKES_REQUEST";
export const GET_LIKES_SUCCESS = "GET_LIKES_SUCCESS";
export const GET_LIKES_ERROR = "GET_LIKES_ERROR";

export const GET_INSTAGRAM_URL_REQUEST = "GET_INSTAGRAM_URL_REQUEST";
export const GET_INSTAGRAM_URL_SUCCESS = "GET_INSTAGRAM_URL_SUCCESS";
export const GET_INSTAGRAM_URL_ERROR = "GET_INSTAGRAM_URL_ERROR";

export const GET_INSTAGRAM_DATA_REQUEST = "GET_INSTAGRAM_DATA_REQUEST";
export const GET_INSTAGRAM_DATA_SUCCESS = "GET_INSTAGRAM_DATA_SUCCESS";
export const GET_INSTAGRAM_DATA_ERROR = "GET_INSTAGRAM_DATA_ERROR";

export const UPDATE_ACCESS_TOKEN_REQUEST = "UPDATE_ACCESS_TOKEN_REQUEST";
export const UPDATE_ACCESS_TOKEN_SUCCESS = "UPDATE_ACCESS_TOKEN_SUCCESS";
export const UPDATE_ACCESS_TOKEN_ERROR = "UPDATE_ACCESS_TOKEN_ERROR";

export const DISCONNECT_INSTAGRAM_REQUEST = "DISCONNECT_INSTAGRAM_REQUEST";
export const DISCONNECT_INSTAGRAM_SUCCESS = "DISCONNECT_INSTAGRAM_SUCCESS";
export const DISCONNECT_INSTAGRAM_ERROR = "DISCONNECT_INSTAGRAM_ERROR";

export const SET_UPDATED_SHIPPING_REQUEST = "SET_UPDATED_SHIPPING_REQUEST";
export const SET_UPDATED_SHIPPING_SUCCESS = "SET_UPDATED_SHIPPING_SUCCESS";
export const SET_UPDATED_SHIPPING_ERROR = "SET_UPDATED_SHIPPING_ERROR";

export const POST_DELETE_SHIPPING_REQUEST = "POST_DELETE_SHIPPING_REQUEST";
export const POST_DELETE_SHIPPING_SUCCESS = "POST_DELETE_SHIPPING_SUCCESS";
export const POST_DELETE_SHIPPING_ERROR = "POST_DELETE_SHIPPING_ERROR";

export const GET_REFERRAL_STATS_REQUEST = "GET_REFERRAL_STATS_REQUEST";
export const GET_REFERRAL_STATS_SUCCESS = "GET_REFERRAL_STATS_SUCCESS";
export const GET_REFERRAL_STATS_ERROR = "GET_REFERRAL_STATS_ERROR";

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_ERROR = "GET_DASHBOARD_ERROR";

export const GET_DASHBOARD_EVENT_REQUEST = "GET_DASHBOARD_EVENT_REQUEST";
export const GET_DASHBOARD_EVENT_SUCCESS = "GET_DASHBOARD_EVENT_SUCCESS";
export const GET_DASHBOARD_EVENT_ERROR = "GET_DASHBOARD_EVENT_ERROR";

export const GET_DASHBOARD_SOCIAL_REQUEST = "GET_DASHBOARD_SOCIAL_REQUEST";
export const GET_DASHBOARD_SOCIAL_SUCCESS = "GET_DASHBOARD_SOCIAL_SUCCESS";
export const GET_DASHBOARD_SOCIAL_ERROR = "GET_DASHBOARD_SOCIAL_ERROR";

// NEW
export const GET_REFRESH_INSTAGRAM_REQUEST = "GET_REFRESH_INSTAGRAM_REQUEST";
export const GET_REFRESH_INSTAGRAM_SUCCESS = "GET_REFRESH_INSTAGRAM_SUCCESS";
export const GET_REFRESH_INSTAGRAM_ERROR = "GET_REFRESH_INSTAGRAM_ERROR";

export const GET_GALLERY_POST_REQUEST = "GET_GALLERY_POST_REQUEST";
export const GET_GALLERY_POST_SUCCESS = "GET_GALLERY_POST_SUCCESS";
export const GET_GALLERY_POST_ERROR = "GET_GALLERY_POST_ERROR";

export const GET_REFRESH_FACEBOOK_REQUEST = "GET_REFRESH_FACEBOOK_REQUEST";
export const GET_REFRESH_FACEBOOK_SUCCESS = "GET_REFRESH_FACEBOOK_SUCCESS";
export const GET_REFRESH_FACEBOOK_ERROR = "GET_REFRESH_FACEBOOK_ERROR";

export const GET_INSTAGRAM_MEDIA_REQUEST = "GET_INSTAGRAM_MEDIA_REQUEST";
export const GET_INSTAGRAM_MEDIA_SUCCESS = "GET_INSTAGRAM_MEDIA_SUCCESS";
export const GET_INSTAGRAM_MEDIA_ERROR = "GET_INSTAGRAM_MEDIA_ERROR";

export const GET_INSTAGRAM_NEXTMEDIA_REQUEST = "GET_INSTAGRAM_NEXTMEDIA_REQUEST";
export const GET_INSTAGRAM_NEXTMEDIA_SUCCESS = "GET_INSTAGRAM_NEXTMEDIA_SUCCESS";
export const GET_INSTAGRAM_NEXTMEDIA_ERROR = "GET_INSTAGRAM_NEXTMEDIA_ERROR";

export const POST_PUBLISH_INSTAGRAM_REQUEST = "POST_PUBLISH_INSTAGRAM_REQUEST";
export const POST_PUBLISH_INSTAGRAM_SUCCESS = "POST_PUBLISH_INSTAGRAM_SUCCESS";
export const POST_PUBLISH_INSTAGRAM_ERROR = "POST_PUBLISH_INSTAGRAM_ERROR";

export const GET_RETRIEVE_POST_REQUEST = "GET_RETRIEVE_POST_REQUEST";
export const GET_RETRIEVE_POST_SUCCESS = "GET_RETRIEVE_POST_SUCCESS";
export const GET_RETRIEVE_POST_ERROR = "GET_RETRIEVE_POST_ERROR";

export const GET_REVISE_POST_REQUEST = "GET_REVISE_POST_REQUEST";
export const GET_REVISE_POST_SUCCESS = "GET_REVISE_POST_SUCCESS";
export const GET_REVISE_POST_ERROR = "GET_REVISE_POST_ERROR";

export const POST_REMOVE_REQUEST = "POST_REMOVE_REQUEST";
export const POST_REMOVE_SUCCESS = "POST_REMOVE_SUCCESS";
export const POST_REMOVE_ERROR = "POST_REMOVE_ERROR";

export const SUB_CATEGORIES_REQUEST = "SUB_CATEGORIES_REQUEST";
export const SUB_CATEGORIES_SUCCESS = "SUB_CATEGORIES_SUCCESS";
export const SUB_CATEGORIES_ERROR = "SUB_CATEGORIES_ERROR";

export const POST_RESERVE_REQUEST = "POST_RESERVE_REQUEST";
export const POST_RESERVE_SUCCESS = "POST_RESERVE_SUCCESS";
export const POST_RESERVE_ERROR = "POST_RESERVE_ERROR";

//media gallery
export const GET_MEDIA_SUMMARY = "GET_MEDIA_SUMMARY";

export const GET_INSTA_POST = "GET_INSTA_POST";
export const GET_SCHEDULE_POST = "GET_SCHEDULE_POST";

export const POST_CREATE_MEDIA_REQUEST = "POST_CREATE_MEDIA_REQUEST";
export const POST_CREATE_MEDIA_SUCCESS = "POST_CREATE_MEDIA_SUCCESS";
export const POST_CREATE_MEDIA_ERROR = "POST_CREATE_MEDIA_ERROR";

export const DELETE_MEDIA_REQUEST = "DELETE_MEDIA_REQUEST";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const DELETE_MEDIA_ERROR = "DELETE_MEDIA_ERROR";

export const CLEAR_NEW_BIO_POSTS = "CLEAR_NEW_BIO_POSTS";
export const GET_NEW_BIO_POST = "GET_NEW_BIO_POST";

export const GET_URL_REQUEST = "GET_URL_REQUEST";
export const GET_URL_SUCCESS = "GET_URL_SUCCESS";
export const GET_URL_ERROR = "GET_URL_ERROR";

export const GET_LINK = "GET_LINK";

export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_ERROR = "GET_EVENT_ERROR";

export const POST_PUBLISH_REQUEST = "POST_PUBLISH_REQUEST";
export const POST_PUBLISH_SUCCESS = "POST_PUBLISH_SUCCESS";
export const POST_PUBLISH_ERROR = "POST_PUBLISH_ERROR";

export const POST_RECEIVE_REQUEST = "POST_RECEIVE_REQUEST";
export const POST_RECEIVE_SUCCESS = "POST_RECEIVE_SUCCESS";
export const POST_RECEIVE_ERROR = "POST_RECEIVE_ERROR";

export const POST_NEW_BRAND_REQUEST = "POST_NEW_BRAND_REQUEST";
export const POST_NEW_BRAND_SUCCESS = "POST_NEW_BRAND_SUCCESS";
export const POST_NEW_BRAND_ERROR = "POST_NEW_BRAND_ERROR";

export const POST_REVISE_STATUS_REQUEST = "POST_REVISE_STATUS_REQUEST";
export const POST_REVISE_STATUS_SUCCESS = "POST_REVISE_STATUS_SUCCESS";
export const POST_REVISE_STATUS_ERROR = "POST_REVISE_STATUS_ERROR";

export const POST_PUBLISH_SHOP_REQUEST = "POST_PUBLISH_SHOP_REQUEST";
export const POST_PUBLISH_SHOP_SUCCESS = "POST_PUBLISH_SHOP_SUCCESS";
export const POST_PUBLISH_SHOP_ERROR = "POST_PUBLISH_SHOP_ERROR";

export const GET_FACEBOOK_REQUEST = "GET_FACEBOOK_REQUEST";
export const GET_FACEBOOK_SUCCESS = "GET_FACEBOOK_SUCCESS";
export const GET_FACEBOOK_ERROR = "GET_FACEBOOK_ERROR";

export const POST_FACEBOOK_USER_REQUEST = "POST_FACEBOOK_USER_REQUEST";
export const POST_FACEBOOK_USER_SUCCESS = "POST_FACEBOOK_USER_SUCCESS";
export const POST_FACEBOOK_USER_ERROR = "POST_FACEBOOK_USER_ERROR";

export const GET_FACEBOOK_ACCESS_REQUEST = "GET_FACEBOOK_ACCESS_REQUEST";
export const GET_FACEBOOK_ACCESS_SUCCESS = "GET_FACEBOOK_ACCESS_SUCCESS";
export const GET_FACEBOOK_ACCESS_ERROR = "GET_FACEBOOK_ACCESS_ERROR";

export const GET_FACEBOOK_PAGES_REQUEST = "GET_FACEBOOK_PAGES_REQUEST";
export const GET_FACEBOOK_PAGES_SUCCESS = "GET_FACEBOOK_PAGES_SUCCESS";
export const GET_FACEBOOK_PAGES_ERROR = "GET_FACEBOOK_PAGES_ERROR";

export const FACEBOOK_RESET_REQUEST = "FACEBOOK_RESET_REQUEST";
export const FACEBOOK_RESET_SUCCESS = "FACEBOOK_RESET_SUCCESS";
export const FACEBOOK_RESET_ERROR = "FACEBOOK_RESET_ERROR";

export const FACEBOOK_TOKEN_REQUEST = "FACEBOOK_TOKEN_REQUEST";
export const FACEBOOK_TOKEN_SUCCESS = "FACEBOOK_TOKEN_SUCCESS";
export const FACEBOOK_TOKEN_ERROR = "FACEBOOK_TOKEN_ERROR";

export const GET_TOKEN_REQUEST = "GET_TOKEN_REQUEST";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_ERROR = "GET_TOKEN_ERROR";

export const POST_SELECTED_PAGES_REQUEST = "POST_SELECTED_PAGES_REQUEST";
export const POST_SELECTED_PAGES_SUCCESS = "POST_SELECTED_PAGES_SUCCESS";
export const POST_SELECTED_PAGES_ERROR = "POST_SELECTED_PAGES_ERROR";

export const POST_FACEBOOK_MEDIA_REQUEST = "POST_FACEBOOK_MEDIA_REQUEST";
export const POST_FACEBOOK_MEDIA_SUCCESS = "POST_FACEBOOK_MEDIA_SUCCESS";
export const POST_FACEBOOK_MEDIA_ERROR = "POST_FACEBOOK_MEDIA_ERROR";

export const GET_USER_INFO = "GET_USER_INFO";
export const BASIC_PROFILE_IMAGE = "BASIC_PROFILE_IMAGE";

export const POST_NOTIFY_REQUEST = "POST_NOTIFY_REQUEST";
export const POST_NOTIFY_SUCCESS = "POST_NOTIFY_SUCCESS";
export const POST_NOTIFY_ERROR = "POST_NOTIFY_ERROR";

export const POST_ALL_INFLUENCER_REQUEST = "POST_ALL_INFLUENCER_REQUEST";
export const POST_ALL_INFLUENCER_SUCCESS = "POST_ALL_INFLUENCER_SUCCESS";
export const POST_ALL_INFLUENCER_SUCCESS_LOADMORE = "POST_ALL_INFLUENCER_SUCCESS_LOADMORE";
export const POST_ALL_INFLUENCER_ERROR = "POST_ALL_INFLUENCER_ERROR";

export const POST_SINGLE_INFLUENCER_REQUEST = "POST_SINGLE_INFLUENCER_REQUEST";
export const POST_SINGLE_INFLUENCER_SUCCESS = "POST_SINGLE_INFLUENCER_SUCCESS";
export const POST_SINGLE_INFLUENCER_ERROR = "POST_SINGLE_INFLUENCER_ERROR";

export const POST_FOLLOWERS_REQUEST = "POST_FOLLOWERS_REQUEST";
export const POST_FOLLOWERS_SUCCESS = "POST_FOLLOWERS_SUCCESS";
export const POST_FOLLOWERS_ERROR = "POST_FOLLOWERS_ERROR";

export const SHOPIFY_UPDATE_CART_REQUEST = "SHOPIFY_UPDATE_CART_REQUEST";
export const SHOPIFY_UPDATE_CART_SUCCESS = "SHOPIFY_UPDATE_CART_SUCCESS";
export const SHOPIFY_UPDATE_CART_ERROR = "SHOPIFY_UPDATE_CART_ERROR";

export const STRIPE_PAYMENT_METHOD_REQUEST = "STRIPE_PAYMENT_METHOD_REQUEST";
export const STRIPE_PAYMENT_METHOD_SUCCESS = "STRIPE_PAYMENT_METHOD_SUCCESS";
export const STRIPE_PAYMENT_METHOD_ERROR = "STRIPE_PAYMENT_METHOD_ERROR";

export const STRIPE_PAYMENT_LIST_REQUEST = "STRIPE_PAYMENT_LIST_REQUEST";
export const STRIPE_PAYMENT_LIST_SUCCESS = "STRIPE_PAYMENT_LIST_SUCCESS";
export const STRIPE_PAYMENT_LIST_ERROR = "STRIPE_PAYMENT_LIST_ERROR";

export const STRIPE_PAYMENT_UPDATE_REQUEST = "STRIPE_PAYMENT_UPDATE_REQUEST";
export const STRIPE_PAYMENT_UPDATE_SUCCESS = "STRIPE_PAYMENT_UPDATE_SUCCESS";
export const STRIPE_PAYMENT_UPDATE_ERROR = "STRIPE_PAYMENT_UPDATE_ERROR";

export const STRIPE_PAYMENT_DETACH_REQUEST = "STRIPE_PAYMENT_DETACH_REQUEST";
export const STRIPE_PAYMENT_DETACH_SUCCESS = "STRIPE_PAYMENT_DETACH_SUCCESS";
export const STRIPE_PAYMENT_DETACH_ERROR = "STRIPE_PAYMENT_DETACH_ERROR";

export const STRIPE_MAKE_PAYMENT_REQUEST = "STRIPE_MAKE_PAYMENT_REQUEST";
export const STRIPE_MAKE_PAYMENT_SUCCESS = "STRIPE_MAKE_PAYMENT_SUCCESS";
export const STRIPE_MAKE_PAYMENT_ERROR = "STRIPE_MAKE_PAYMENT_ERROR";

export const POST_NUMBER_REQUEST = "POST_NUMBER_REQUEST";
export const POST_NUMBER_SUCCESS = "POST_NUMBER_SUCCESS";
export const POST_NUMBER_ERROR = "POST_NUMBER_ERROR";

export const POST_REVIEW_MESSAGE_REQUEST = "POST_REVIEW_MESSAGE_REQUEST";
export const POST_REVIEW_MESSAGE_SUCCESS = "POST_REVIEW_MESSAGE_SUCCESS";
export const POST_REVIEW_MESSAGE_ERROR = "POST_REVIEW_MESSAGE_ERROR";

export const POST_REVIEW_CHAT_REQUEST = "POST_REVIEW_CHAT_REQUEST";
export const POST_REVIEW_CHAT_SUCCESS = "POST_REVIEW_CHAT_SUCCESS";
export const POST_REVIEW_CHAT_ERROR = "POST_REVIEW_CHAT_ERROR";

export const POST_REVIEW_GET_REQUEST = "POST_REVIEW_GET_REQUEST";
export const POST_REVIEW_GET_SUCCESS = "POST_REVIEW_GET_SUCCESS";
export const POST_REVIEW_GET_ERROR = "POST_REVIEW_GET_ERROR";

export const POST_REVIEW_LIKE_REQUEST = "POST_REVIEW_LIKE_REQUEST";
export const POST_REVIEW_LIKE_SUCCESS = "POST_REVIEW_LIKE_SUCCESS";
export const POST_REVIEW_LIKE_ERROR = "POST_REVIEW_LIKE_ERROR";

export const PURCHASE_ORDER_REQUEST = "PURCHASE_ORDER_REQUEST";
export const PURCHASE_ORDER_SUCCESS = "PURCHASE_ORDER_SUCCESS";
export const PURCHASE_ORDER_ERROR = "PURCHASE_ORDER_ERROR";

export const POST_PURCHASES_REQUEST = "POST_PURCHASES_REQUEST";
export const POST_PURCHASES_SUCCESS = "POST_PURCHASES_SUCCESS";
export const POST_PURCHASES_ERROR = "POST_PURCHASES_ERROR";

export const GET_REFERRAL_SUMMARY_REQUEST = "GET_REFERRAL_SUMMARY_REQUEST";
export const GET_REFERRAL_SUMMARY_SUCCESS = "GET_REFERRAL_SUMMARY_SUCCESS";
export const GET_REFERRAL_SUMMARY_ERROR = "GET_REFERRAL_SUMMARY_ERROR";

export const POST_BRAND_REQUEST = "POST_BRAND_REQUEST";
export const POST_BRAND_SUCCESS = "POST_BRAND_SUCCESS";
export const POST_BRAND_ERROR = "POST_BRAND_ERROR";

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR";

export const GET_GENDER_CATEGORY_REQUEST = "GET_GENDER_CATEGORY_REQUEST";
export const GET_GENDER_CATEGORY_SUCCESS = "GET_GENDER_CATEGORY_SUCCESS";
export const GET_GENDER_CATEGORY_ERROR = "GET_GENDER_CATEGORY_ERROR";

export const NUM_SIGN_IN_REQUEST = "NUM_SIGN_IN_REQUEST";
export const NUM_SIGN_IN_SUCCESS = "NUM_SIGN_IN_SUCCESS";
export const NUM_SIGN_IN_ERROR = "NUM_SIGN_IN_ERROR";

export const OTP_LOGIN_REQUEST = "OTP_LOGIN_REQUEST";
export const OTP_LOGIN_SUCCESS = "OTP_LOGIN_SUCCESS";
export const OTP_LOGIN_ERROR = "OTP_LOGIN_ERROR";

export const GET_PREV_VIEW_REQUEST = "GET_PREV_VIEW_REQUEST";
export const GET_PREV_VIEW_SUCCESS = "GET_PREV_VIEW_SUCCESS";
export const GET_PREV_VIEW_ERROR = "GET_PREV_VIEW_ERROR";

export const EVENT_VIEWS_REQUEST = "EVENT_VIEWS_REQUEST";
export const EVENT_VIEWS_SUCCESS = "EVENT_VIEWS_SUCCESS";
export const EVENT_VIEWS_ERROR = "EVENT_VIEWS_ERROR";

export const INFLUENCER_VIEWS_REQUEST = "INFLUENCER_VIEWS_REQUEST";
export const INFLUENCER_VIEWS_SUCCESS = "INFLUENCER_VIEWS_SUCCESS";
export const INFLUENCER_VIEWS_ERROR = "INFLUENCER_VIEWS_ERROR";

export const GET_LIVE_VIEW_REQUEST = "GET_LIVE_VIEW_REQUEST";
export const GET_LIVE_VIEW_SUCCESS = "GET_LIVE_VIEW_SUCCESS";
export const GET_LIVE_VIEW_ERROR = "GET_LIVE_VIEW_ERROR";

export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_ERROR = "SEARCH_ERROR";

export const GET_DELETE_ACCOUNT_REQUEST = "GET_DELETE_ACCOUNT_REQUEST";
export const GET_DELETE_ACCOUNT_SUCCESS = "GET_DELETE_ACCOUNT_SUCCESS";
export const GET_DELETE_ACCOUNT_ERROR = "GET_DELETE_ACCOUNT_ERROR";

export const UPDATE_INTEREST_REQUEST = "UPDATE_INTEREST_REQUEST";
export const UPDATE_INTEREST_SUCCESS = "UPDATE_INTEREST_SUCCESS";
export const UPDATE_INTEREST_ERROR = "UPDATE_INTEREST_ERROR";

export const GET_INTEREST_REQUEST = "GET_INTEREST_REQUEST";
export const GET_INTEREST_SUCCESS = "GET_INTEREST_SUCCESS";
export const GET_INTEREST_ERROR = "GET_INTEREST_ERROR";

export const GET_INFLUENCER_PROFILE_REQUEST = "GET_INFLUENCER_PROFILE_REQUEST";
export const GET_INFLUENCER_PROFILE_SUCCESS = "GET_INFLUENCER_PROFILE_SUCCESS";
export const GET_INFLUENCER_PROFILE_ERROR = "GET_INFLUENCER_PROFILE_ERROR";
export const GET_INFLUENCER_PROFILE_LOADMORE = "GET_INFLUENCER_PROFILE_LOADMORE";

export const GET_INFLUENCERS_REQUEST = "GET_INFLUENCERS_REQUEST ";
export const GET_INFLUENCERS_SUCCESS = "GET_INFLUENCERS_SUCCESS";
export const GET_INFLUENCERS_ERROR = "GET_INFLUENCERS_ERROR";
export const GET_INFLUENCERS_LOADMORE = "GET_INFLUENCERS_LOADMORE";

export const POST_SALES_TRACKER_REQUEST = "POST_SALES_TRACKER_REQUEST";
export const POST_SALES_TRACKER_SUCCESS = "POST_SALES_TRACKER_SUCCESS";
export const POST_SALES_TRACKER_ERROR = "POST_SALES_TRACKER_ERROR";

export const GET_CREATOR_SALE_REQUEST = "GET_CREATOR_SALE_REQUEST";
export const GET_CREATOR_SALE_SUCCESS = "GET_CREATOR_SALE_SUCCESS";
export const GET_CREATOR_SALE_ERROR = "GET_CREATOR_SALE_ERROR";

export const GET_CREATOR_SUMMARY_REQUEST = "GET_CREATOR_SUMMARY_REQUEST";
export const GET_CREATOR_SUMMARY_SUCCESS = "GET_CREATOR_SUMMARY_SUCCESS";
export const GET_CREATOR_SUMMARY_ERROR = "GET_CREATOR_SUMMARY_ERROR";

export const GET_FOLLOWERS_REQUEST = "GET_FOLLOWERS_REQUEST";
export const GET_FOLLOWERS_SUCCESS = "GET_FOLLOWERS_SUCCESS";
export const GET_FOLLOWERS_ERROR = "GET_FOLLOWERS_ERROR";

export const CREATE_FOLLOWERS_REQUEST = "CREATE_FOLLOWERS_REQUEST";
export const CREATE_FOLLOWERS_SUCCESS = "CREATE_FOLLOWERS_REQUEST";
export const CREATE_FOLLOWERS_ERROR = "CREATE_FOLLOWERS_ERROR";

export const FOLLOWERS_STATUS_REQUEST = "FOLLOWERS_STATUS_REQUEST";
export const FOLLOWERS_STATUS_SUCCESS = "FOLLOWERS_STATUS_SUCCESS";
export const FOLLOWERS_STATUS_ERROR = "FOLLOWERS_STATUS_ERROR";

export const GET_UNFOLLOW_REQUEST = "GET_UNFOLLOW_REQUEST";
export const GET_UNFOLLOW_SUCCESS = "GET_UNFOLLOW_SUCCESS";
export const GET_UNFOLLOW_ERROR = "GET_UNFOLLOW_ERROR";

export const GET_FOLLOWING_REQUEST = "GET_FOLLOWING_REQUEST";
export const GET_FOLLOWING_SUCCESS = "GET_FOLLOWING_SUCCESS";
export const GET_FOLLOWING_ERROR = "GET_FOLLOWING_ERROR";

export const GET_WITHDRAW_REQUEST = "GET_WITHDRAW_REQUEST";
export const GET_WITHDRAW_SUCCESS = "GET_WITHDRAW_SUCCESS";
export const GET_WITHDRAW_ERROR = "GET_WITHDRAW_ERROR";

export const GET_ONE_REQUEST = "GET_ONE_REQUEST";
export const GET_ONE_SUCCESS = "GET_ONE_SUCCESS";
export const GET_ONE_ERROR = "GET_ONE_ERROR";

export const GET_CHAT_LOGS_REQUEST = "GET_CHAT_LOGS_REQUEST";
export const GET_CHAT_LOGS_SUCCESS = "GET_CHAT_LOGS_SUCCESS";
export const GET_CHAT_LOGS_ERROR = "GET_CHAT_LOGS_ERROR";

export const PAYOUT_CARD_REQUEST = "PAYOUT_CARD_REQUEST";
export const PAYOUT_CARD_SUCCESS = "PAYOUT_CARD_SUCCESS";
export const PAYOUT_CARD_ERROR = "PAYOUT_CARD_ERROR";

export const DISCONNECT_PAYPAL_REQUEST = "DISCONNECT_PAYPAL_REQUEST";
export const DISCONNECT_PAYPAL_SUCCESS = "DISCONNECT_PAYPAL_SUCCESS";
export const DISCONNECT_PAYPAL_ERROR = "DISCONNECT_PAYPAL_ERROR";

export const GET_FEEDS_REQUEST = "GET_FEEDS_REQUEST";
export const GET_FEEDS_SUCCESS = "GET_FEEDS_SUCCESS";
export const GET_FEEDS_ERROR = "GET_FEEDS_ERROR";

export const POST_NEW_INFLUENCER_REQUEST = "POST_NEW_INFLUENCER_REQUEST";
export const POST_NEW_INFLUENCER_SUCCESS = "POST_NEW_INFLUENCER_SUCCESS";
export const POST_NEW_INFLUENCER_SUCCESS_LOADMORE = "POST_NEW_INFLUENCER_SUCCESS_LOADMORE";
export const POST_NEW_INFLUENCER_ERROR = "POST_NEW_INFLUENCER_ERROR";

export const NEWALL_PUBLISH_EVENT_REQUEST = "NEWALL_PUBLISH_EVENT_REQUEST";
export const NEWALL_PUBLISH_EVENT_SUCCESS = "NEWALL_PUBLISH_EVENT_SUCCESS";
export const NEWALL_PUBLISH_EVENT_SUCCESS_LOADMORE = "NEWALL_PUBLISH_EVENT_SUCCESS_LOADMORE";
export const NEWALL_PUBLISH_EVENT_ERROR = "NEWALL_PUBLISH_EVENT_ERROR";

export const CREATE_FEEDS_REQUEST = "CREATE_FEEDS_REQUEST";
export const CREATE_FEEDS_SUCCESS = "CREATE_FEEDS_SUCCESS";
export const CREATE_FEEDS_ERROR = "CREATE_FEEDS_ERROR";

export const GET_USER_NOTIFICATION_REQUEST = "GET_USER_NOTIFICATION_REQUEST";
export const GET_USER_NOTIFICATION_SUCCESS = "GET_USER_NOTIFICATION_SUCCESS";
export const GET_USER_NOTIFICATION_ERROR = "GET_USER_NOTIFICATION_ERROR";

export const UPDATE_USER_NOTIFICATION_REQUEST = "UPDATE_USER_NOTIFICATION_REQUEST";
export const UPDATE_USER_NOTIFICATION_SUCCESS = "UPDATE_USER_NOTIFICATION_SUCCESS";
export const UPDATE_USER_NOTIFICATION_ERROR = "UPDATE_USER_NOTIFICATION_ERROR";

export const POST_SHARE_VIDEO_REQUEST = "POST_SHARE_VIDEO_REQUEST";
export const POST_SHARE_VIDEO_SUCCESS = "POST_SHARE_VIDEO_SUCCESS";
export const POST_SHARE_VIDEO_ERROR = "POST_SHARE_VIDEO_ERROR";

export const GET_SHARED_REVIEWS_REQUEST = "GET_SHARED_REVIEWS_REQUEST";
export const GET_SHARED_REVIEWS_LOADMORE = "GET_SHARED_REVIEWS_LOADMORE";
export const GET_SHARED_REVIEWS_SUCCESS = "GET_SHARED_REVIEWS_SUCCESS";
export const GET_SHARED_REVIEWS_ERROR = "GET_SHARED_REVIEWS_ERROR";

export const POST_REVIEW_DEL_REQUEST = "POST_REVIEW_DEL_REQUEST";
export const POST_REVIEW_DEL_SUCCESS = "POST_REVIEW_DEL_SUCCESS";
export const POST_REVIEW_DEL_ERROR = "POST_REVIEW_DEL_ERROR";

export const POST_FAVOURITES_DEL_REQUEST = "POST_FAVOURITES_DEL_REQUEST";
export const POST_FAVOURITES_DEL_SUCCESS = "POST_FAVOURITES_DEL_SUCCESS";
export const POST_FAVOURITES_DEL_ERROR = "POST_FAVOURITES_DEL_ERROR";

export const POST_COUNT_FOLLOWERS_REQUEST = "POST_COUNT_FOLLOWERS_REQUEST";
export const POST_COUNT_FOLLOWERS_SUCCESS = "POST_COUNT_FOLLOWERS_SUCCESS";
export const POST_COUNT_FOLLOWERS_ERROR = "POST_COUNT_FOLLOWERS_ERROR";

export const POST_REVIEWS_REQUEST = "POST_REVIEWS_REQUEST";
export const POST_REVIEWS_SUCCESS = "POST_REVIEWS_SUCCESS";
export const POST_REVIEWS_ERROR = "POST_REVIEWS_ERROR";

export const DELETE_REVIEWS_REQUEST = "DELETE_REVIEWS_REQUEST";
export const DELETE_REVIEWS_SUCCESS = "DELETE_REVIEWS_SUCCESS";
export const DELETE_REVIEWS_ERROR = "DELETE_REVIEWS_ERROR";

export const GET_BRANDS_PRODUCTS_REQUEST = "GET_BRANDS_PRODUCTS_REQUEST";
export const GET_BRANDS_PRODUCTS_SUCCESS = "GET_BRANDS_PRODUCTS_SUCCESS";
export const GET_BRANDS_PRODUCTS_ERROR = "GET_BRANDS_PRODUCTS_ERROR";

export const GET_BRANDS_LIST_REQUEST = "GET_BRANDS_LIST_REQUEST";
export const GET_BRANDS_LIST_SUCCESS = "GET_BRANDS_LIST_SUCCESS";
export const GET_BRANDS_LIST_ERROR = "GET_BRANDS_LIST_ERROR";

export const POST_CONTENT_REQUEST = "POST_CONTENT_REQUEST";
export const POST_CONTENT_SUCCESS = "POST_CONTENT_SUCCESS";
export const POST_CONTENT_ERROR = "POST_CONTENT_ERROR";

export const POST_UPLOAD_REQUEST = "POST_UPLOAD_REQUEST";
export const POST_UPLOAD_SUCCESS = "POST_UPLOAD_SUCCESS";
export const POST_UPLOAD_ERROR = "POST_UPLOAD_ERROR";

export const GET_SKU = "GET_SKU";

export const POST_UPLOAD_REVIEW_REQUEST = "POST_UPLOAD_REVIEW_REQUEST";
export const POST_UPLOAD_REVIEW_SUCCESS = "POST_UPLOAD_REVIEW_SUCCESS";
export const POST_UPLOAD_REVIEW_ERROR = "POST_UPLOAD_REVIEW_ERROR";

export const POST_PARTNER_REQUEST = "POST_PARTNER_REQUEST";
export const POST_PARTNER_SUCCESS = "POST_PARTNER_SUCCESS";
export const POST_PARTNER_ERROR = "POST_PARTNER_ERROR";

export const GET_WITHDRAW_LIMIT_REQUEST = "GET_WITHDRAW_LIMIT_REQUEST";
export const GET_WITHDRAW_LIMIT_SUCCESS = "GET_WITHDRAW_LIMIT_SUCCESS";
export const GET_WITHDRAW_LIMIT_ERROR = "GET_WITHDRAW_LIMIT_ERROR";

export const GET_FEED_REQUEST = "GET_FEED_REQUEST";
export const GET_FEED_SUCCESS = "GET_FEED_SUCCESS";
export const GET_FEED_ERROR = "GET_FEED_ERROR";
export const GET_FEED_SUCCESS_LOADMORE = "GET_FEED_SUCCESS_LOADMORE";

export const POST_SAVE_REVIEWS_REQUEST = "POST_SAVE_REVIEWS_REQUEST";
export const POST_SAVE_REVIEWS_SUCCESS = "POST_SAVE_REVIEWS_SUCCESS";
export const POST_SAVE_REVIEWS_ERROR = "POST_SAVE_REVIEWS_ERROR";

export const POST_SAVE_GET_REQUEST = "POST_SAVE_GET_REQUEST";
export const POST_SAVE_GET_SUCCESS = "POST_SAVE_GET_SUCCESS";
export const POST_SAVE_GET_ERROR = "POST_SAVE_GET_ERROR";

export const GET_SAVE_REVIW_REQUEST = "GET_SAVE_REVIW_REQUEST";
export const GET_SAVE_REVIW_SUCCESS = "GET_SAVE_REVIW_SUCCESS";
export const GET_SAVE_REVIW_ERROR = "GET_SAVE_REVIW_ERROR";

export const REVIEW_SAVE_REQUEST = "REVIEW_SAVE_REQUEST";
export const REVIEW_SAVE_SUCCESS = "REVIEW_SAVE_SUCCESS";
export const REVIEW_SAVE_ERROR = "REVIEW_SAVE_ERROR";

export const SAVED_REVIEWS_REQUEST = "SAVED_REVIEWS_REQUEST";
export const SAVED_REVIEWS_SUCCESS = "SAVED_REVIEWS_SUCCESS";
export const SAVED_REVIEWS_ERROR = "SAVED_REVIEWS_ERROR";
export const SAVED_REVIEWS_LOADMORE = "SAVED_REVIEWS_LOADMORE";

export const SAVED_EVENTS_REQUEST = "SAVED_EVENTS_REQUEST";
export const SAVED_EVENTS_SUCCESS = "SAVED_EVENTS_SUCCESS";
export const SAVED_EVENTS_ERROR = "SAVED_EVENTS_ERROR";
export const SAVED_EVENTS_LOADMORE = "SAVED_EVENTS_LOADMORE";

export const GET_PRODUCT_LIST_REQUEST = "GET_PRODUCT_LIST_REQUEST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_ERROR = "GET_PRODUCT_LIST_ERROR";
export const GET_PRODUCT_LIST_LOADMORE = "GET_PRODUCT_LIST_LOADMORE";

export const POST_VERIFICATION_REQUEST = "POST_VERIFICATION_REQUEST";
export const POST_VERIFICATION_SUCCESS = "POST_VERIFICATION_SUCCESS";
export const POST_VERIFICATION_ERROR = "POST_VERIFICATION_ERROR";

export const OPEN_MODAL_REQUEST = "OPEN_MODAL_REQUEST";
export const OPEN_MODAL_SUCCESS = "OPEN_MODAL_SUCCESS";
export const OPEN_MODAL_ERROR = "OPEN_MODAL_ERROR";