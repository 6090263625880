import Layout from "./component/layout";
import ReviewList from "./component/reviewList";

export default () => {
	return (
		<>
			<Layout title="Create">
				<ReviewList />
			</Layout>
		</>
	);
};
