//local

// export const BASEURL = "http://172.16.1.85:9090/v1/";
// export const BASEURL1 = "http://172.16.1.85:9090/";

// export const BASEURL = "http://172.16.1.58:9090/v1/";
// export const BASEURL1 = "http://172.16.1.58:9090/";

// export const BASEURL = "http://172.16.1.157:9090/v1/";
// export const BASEURL1 = "http://172.16.1.157:9090/";
// export const ENVIRONMENT = "ormelive";

//Production
export const BASEURL = "https://backend.ormelive.com/v1/";
export const BASEURL1 = "https://backend.ormelive.com/";
export const ENVIRONMENT = "ormedev";
// export const BASEURL = "https://dev2backend.ormelive.com/v1/";
// export const BASEURL1 = "https://dev2backend.ormelive.com/";
// export const ENVIRONMENT = "ormedev";

export const TEMPURL = "https://kbsly.com/v1/";
export const SOCKETURL = "wss://socket.ormelive.com/";
//Paypal Client Id
export const CLIENTID = "Af5UGE6UC3Ppm2zvbsHISulXPam7nrviktpdJKXHpWfv8sRBx5fGkMZCAjTyeG-zzlVC8tHBPvxfl3S-"
export const PAYPALLINK = "https://www.sandbox.paypal.com"

export const ACCESSKEYID =  "AKIA5J2GFTZP5IDWBEHL"
export const SECREATACCESSKEY = "zccvgvAoYF1L4NWAqgFin2/ab0Xzf8d1EiTzJmZ1"
export const BUCKET = "orme-content-moderation"

export const BUCKETNEW = "dev-orme-input-reviews"
export const ADDRESSKEY = "AIzaSyBM9jRinywKKvKDOc8Oikw2rOYDaRkgEEQ"

export const ANDROIDAPP = "https://play.google.com/store/apps/details?id=com.orme"
export const APPLEAPP = "https://apps.apple.com/us/app/orme/id6448742926"