import { useState, useCallback, useEffect } from "react";
import { SmileOutlined, TagFilled } from "@ant-design/icons";
import { InputNumber, notification, Spin, Space } from "antd";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";
import { updateShopifycart } from "../../redux/actions/shopify.updateCart.action";
import { getShopifyCart } from "../../redux/actions/liveEvent.action";
import { rmvCart } from "../../redux/actions/shopifyRemoveCart.action";
import Swal from "sweetalert2";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export default function CartProduct({ brandId, setStatus, setCart, cart, setCheckout }) {
	const dispatch = useDispatch();
	const [removingProducts, setRemovingProducts] = useState({});

	useEffect(() => {
		console.log("cart", cart);
	}, [cart]);

	const removeBtn = (rmvId) => {
		setRemovingProducts((prev) => ({ ...prev, [rmvId]: true }));

		dispatch(rmvCart(brandId, rmvId, userInfo?.token)).then(() => {
			setRemovingProducts((prev) => ({ ...prev, [rmvId]: false }));

			notification.open({
				message: "PRODUCT REMOVED",
				description: "Product has been removed from your cart",
				icon: <SmileOutlined style={{ color: "#108ee9" }} />,
			});

			if (cart?.lineItems?.length === 1) {
				delete userInfo.brand;
				localStorage.setItem("userInfo", JSON.stringify(userInfo));
			}

			dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
					setCheckout(false);
				}
				setCart(res?.payload?.message);
			});
		});
	};

	return (
		<>
			{cart?.lineItems?.map((item) => {
				const variant = item?.variant?.title;
				const discountProduct = item?.discountAllocations ? item?.discountAllocations[0] : 0;
				item.brand = brandId;
				return (
					<div className="list-col">
						<div className="prod-box">
							<ImageProduct imageSource={item?.variant?.image?.src} />
							<div className="prod-name">
								<NameProduct title={item?.title} variant={variant} />
								<PriceProduct item={item} discountProduct={discountProduct} />

								<DiscountProduct discountProduct={discountProduct} />
								<ControlProduct item={item} removingProducts={removingProducts} setStatus={setStatus} setCart={setCart} removeBtn={removeBtn} brandId={brandId} dispatch={dispatch} />
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
}

const ImageProduct = ({ imageSource }) => (
	<div className="prod-image">
		<img src={imageSource} alt="product" />
	</div>
);

const NameProduct = ({ title, variant }) => (
	<div className="prod-title">
		<span className="prod-name-inr">{title}</span>
		<div className="variant-list">{variant && variant !== "Default Title" && <span className="prod-veriants-inr">{variant}</span>}</div>
	</div>
);

const PriceProduct = ({ item, discountProduct }) => (
	<div className="prod-price-cont">
		<PriceProductDetail price={item?.variant?.price?.amount} discountPrice={discountProduct?.allocatedAmount?.amount / item?.quantity} isDiscount={discountProduct?.allocatedAmount?.length > 0 ? true : false} />
		<PriceProductDetail price={item?.variant?.price?.amount * item.quantity} discountPrice={item?.variant?.price?.amount * item.quantity - discountProduct?.allocatedAmount?.amount} isDiscount={discountProduct?.allocatedAmount?.length > 0 ? true : false} />
	</div>
);

const PriceProductDetail = ({ price, discountPrice, isDiscount }) => (
	<div className="prod-price">
		<span className="prod-inr-price">
			<span className={isDiscount && "original-price"}>{numeral(price).format("$0,0.0'")}</span>
			{isDiscount && <span>{numeral(discountPrice).format("$0,0.0'")}</span>}
		</span>
	</div>
);

const DiscountProduct = ({ discountProduct }) =>
	discountProduct?.allocatedAmount?.length > 0 && (
		<div className="prod-discount new">
			<TagFilled className="discount-tag" />
			<div className="discount-amount">
				{discountProduct?.discountApplication?.title}(-$
				{discountProduct?.allocatedAmount?.amount})
			</div>
		</div>
	);

const ControlProduct = ({ item, removeBtn, brandId, setStatus, setCart, dispatch, removingProducts }) => (
	<div className="qty-rmv-btn">
		<Space>
			<ControlQuantity brandId={brandId} setStatus={setStatus} setCart={setCart} item={item} dispatch={dispatch} />
		</Space>

		<ButtonRemove removingProducts={removingProducts} itemId={item?.id} removeBtn={removeBtn} />
	</div>
);

const ControlQuantity = ({ item, brandId, setStatus, setCart, dispatch }) => {
	const [debouncedValue, setDebouncedValue] = useState(item.quantity);

	useEffect(() => {
		// Update debouncedValue when item.quantity changes
		setDebouncedValue(item.quantity);
	}, [item.quantity]);

	const debounce = (func, delay) => {
		let timeoutId;
		return (...args) => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				func(...args);
			}, delay);
		};
	};

	const handleKeyDown = (e) => {
		// Use a regular expression to match digits (0-9)
		const regex = /^[0-9]+$/;

		// Check if the pressed key is not a number or doesn't match the regex
		if (!regex.test(e.key)) {
			// Prevent the default action (i.e., prevent the key from being entered)
			e.preventDefault();
		}
	};

	const handleQuantityChange = (qty) => {
		if (qty > item?.variant?.inventory_quantity) {
			Swal.fire("Out of Stock!", "Quantity is Greater than Stock Quantity", "error");
		} else {
			if (qty > 0) {
				setDebouncedValue(qty);
				debounceUpdateCart(qty);
			}
		}
	};

	const debounceUpdateCart = useCallback(
		debounce((qty) => {
			dispatch(updateShopifycart(userInfo?.token, { ...item, quantity: qty })).then(() => {
				dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
					const status = !(res?.payload?.message?.lineItems.length > 0);
					setStatus(status);
					setCart(res?.payload?.message);
					notification.open({
						message: "PRODUCT UPDATED",
						description: "Product has been updated from your cart",
						icon: <SmileOutlined style={{ color: "#108ee9" }} />,
					});
				});
			});
		}, 500),
		[]
	);

	return (
		<InputNumber
			onKeyPress={handleKeyDown} // Use the custom handler
			onChange={handleQuantityChange}
			size="medium"
			min={1}
			max={100000}
			value={debouncedValue}
			defaultValue={debouncedValue}
		/>
	);
};

const ButtonRemove = ({ itemId, removeBtn, removingProducts }) => {
	const isLoading = removingProducts[itemId];

	return (
		<a href="javascript:void" className="remove-btn" onClick={() => removeBtn(itemId)}>
			{isLoading ? <Spin size="small" /> : "Remove"}
		</a>
	);
};
