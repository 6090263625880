import React from "react";
import { NavLink } from "react-router-dom";
import avatar from "../../images/avatar.png";
import numeral from "numeral";
import { useSelector } from "react-redux";

export default function Item({ item }) {
  const { followingUser } = useSelector((state) => {
		return state;
	});

  return (
    <NavLink to={`/${item?.pixel_id}`} className="influencer-column">
      <div className="influencer-detail-col">
        <div className="influencer-thumb-main">
          {item?.profile_image_url ? (
            <>
              <div className="influencer-thumb">
                <img src={item?.profile_image_url} />
              </div>
            </>
          ) : (
            <>
              <div className="influencer-thumb">
                <img src={avatar} />
              </div>
            </>
          )}
        </div>
        <div className="influencer-name-main">
          <span className="influencer-full-name">{item?.first_name + " " + item?.last_name}</span>
          <span className="influencer-handle">{item?.pixel_id}</span>
          {/* <span className="influencer-handle">@lindseymartin</span> */}
        </div>
        <div className="influencer-follow-main">
          {/* <span>
            <strong>1k </strong>Following
          </span> */}
          <span>
            <strong>{numeral(item?.no_of_followers).format("0a")} </strong>Followers
          </span>
          <span>
            <strong>{numeral(item?.no_of_following).format("0a")} </strong>Following
          </span>
        </div>
      </div>
    </NavLink>
  );
}