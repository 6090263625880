import React, { useEffect, useState, useRef } from "react";
import { Spin, Empty, notification } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import "../../../css/chat.scss";
import ScrollToBottom from "react-scroll-to-bottom";
import { saveRefchat, getRefchat } from "../../../redux/actions/chat";
import { getAccount } from "../../../redux/actions/account.action";
import useWebSocket, { ReadyState } from "react-use-websocket";
import {  displayModal } from "../../../redux/actions/register.action";

import { SOCKETURL } from "../../../config";

function Chat({ reviewId, publishEvent }) {
	const dispatch = useDispatch();
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const [isbann, setisBann] = useState(userInfo?.is_banned);
	const [comments, setComments] = useState([]);
	const [value, setValue] = useState("");
	useEffect(() => {
		if (userInfo?.token && account?.length === 0) dispatch(getAccount(userInfo?.token));
	}, []);
	const { messageReview, account, bancheck } = useSelector((state) => {
		return state;
	});

	const didUnmount = useRef(false);
	//Socket Config
	const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(SOCKETURL, {
		shouldReconnect: (closeEvent) => {
			/*
        useWebSocket will handle unmounting for you, but this is an example of a 
        case in which you would not want it to automatically reconnect
      */
			return didUnmount.current === false;
		},
		reconnectAttempts: 10,
		reconnectInterval: 3000,
	});

	useEffect(() => {
		dispatch(getRefchat(reviewId, userInfo?.token)).then((res) => {
			if (res.payload.success) {
				setComments(res?.payload?.message?.data);
			} else {
				notification.error({
					message: res.payload.data.message,
					className: "toast-error",
				});
			}
		});
	}, [reviewId]);

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const receivedMessage = JSON.parse(lastMessage.data);
      // Check if the received message is not the same as the message you just sent
      if (receivedMessage.type !== "eventmessage" || receivedMessage.reviewId !== reviewId) {
        dispatch(getRefchat(reviewId, userInfo.token)).then((res) => {
          setComments(res?.payload?.message?.data);
        });
      }
    }
  }, [lastMessage]);

	const handleSubmit = async (e) => {
    e.preventDefault();
    if ((account?.payload?.Data?.first_name === "" && account?.payload?.Data?.last_name === "") || account?.payload?.Data?.email === "") {
      dispatch(displayModal("register", true));
		} else {

			let trimmedValue = value.trimStart();
			if (!trimmedValue) return;
			const message = {
				author: account?.payload?.Data?.name,
				user_id: userInfo?._id,
				content: value,
				datetime: moment().format(),
			};

			const deleteMessage = {
				type: "eventmessage",
				reviewId: reviewId,
			};

			sendMessage(JSON.stringify(deleteMessage));

			// Wait for a short delay (adjust this if needed) to give the server time to respond
			await new Promise((resolve) => setTimeout(resolve, 500));

			dispatch(saveRefchat(reviewId, message, userInfo?.token)).then((res) => {
				dispatch(getRefchat(reviewId, userInfo.token)).then((res) => {
					setComments(res?.payload?.message?.data);
				});
			});
			setValue("");
		}
	};

	const handleChange = (e) => {
		setValue(e.target.value);
	};

	if (messageReview.loading && comments?.length === 0) {
		return (
			<div className="load-container-main">
				<div className="load-container">
					<Spin size="medium"></Spin>
				</div>
			</div>
		);
	}

	return (
		<div className="live-chat-comp">
			<div id="frame">
				<div className="content">
					<ScrollToBottom className="messages">
						<ul className="message-ul">
							<>
								{!comments?.length > 0 ? (
									<div className="align-items-center justify-content-center brand-description">
										<Empty description={false} />
									</div>
								) : (
									comments?.map((it) => {
										return (
											<li className={it.accountType}>
												<div className="message-box-main">
													<div className="msg-content">
														<span className="user-name">{it?.author === "Admin" ? "Longaberger" : it?.author || account?.payload?.Data?.first_name + " " + account?.payload?.Data?.last_name}</span>
														<span className="user-message">{it?.content}</span>
													</div>
												</div>
											</li>
										);
									})
								)}
							</>
						</ul>
					</ScrollToBottom>
					{bancheck?.loading ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								marginTop: "5px",
							}}
						>
							<Spin size="medium"></Spin>
						</div>
					) : (
						<>
							{!isbann ? (
								!publishEvent && (
									<div className="message-input">
										<div className="logged-in-name">
											Logged in as <span>{account?.payload?.Data?.first_name + " " + account?.payload?.Data?.last_name}</span>
										</div>
										<div className="wrap">
											<form onSubmit={handleSubmit}>
												<input value={value} type="text" placeholder="Write your message..." onChange={handleChange} />
												<button onSubmit={handleSubmit} className="submit">
													<i className="fa fa-paper-plane" aria-hidden="true"></i>
												</button>
											</form>
										</div>
									</div>
								)
							) : (
								<div style={{ marginTop: "1rem", textAlign: "center" }}>
									<span>You have been banned by the admin.</span>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}
export default Chat;
