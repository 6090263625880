import React from "react";

export default function termsconditions() {
  return (
    <div className="text-justify">
      <p>General Terms of Use for Influencers This agreement (the “Agreement”) governs the use of the website and associated services (collectively, the “Services”) offered by ORME LIVE INC. (the “Company”) to influencers (the “Influencer”) who wish to use the Services in accordance with the terms and conditions, privacy policy mentioned on website and these terms below:</p>
      <h6>1. Acceptance of Terms</h6>
      <p> By accessing or using the Services, the Influencer agrees to be bound by this Agreement and all applicable laws and regulations. The Company reserves the right to modify this Agreement and any policies governing the Services at any time without notice. The Influencer’s continued use of the Services after any such modifications constitutes acceptance of the modified terms.</p>

      <h6>2. License to Use</h6>
      <p>The Company grants the Influencer a non-exclusive, non-transferable, revocable license to access and use the Services, subject to the terms and conditions of this Agreement. 3. Intellectual Property The Company retains all right, title, and interest in and to the Services, including all related intellectual property rights. The Influencer acknowledges that the Services are protected by copyright, trademark, and other laws of the United States and foreign countries. The Influencer agrees not to remove, alter, or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services.</p>

      <h6>3. Intellectual Property</h6>
      <p>The Company retains all right, title, and interest in and to the Services, including all related intellectual property rights. The Influencer acknowledges that the Services are protected by copyright, trademark, and other laws of the United States and foreign countries. The Influencer agrees not to remove, alter, or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services.</p>

      <h6>4. Sharing of Content </h6>
      <p>The Company permits the Influencer to share content from the website to their own Instagram account, provided that the Influencer agrees to the following terms:</p>

      <p className="mb-1">a. The Influencer acknowledges that during the course of this collaboration, they may have access to and be permitted to share content, including but not limited to videos created by other influencers associated with the Company.</p>

      <p className="mb-1">b. The Influencer shall credit the Company by tagging the Company’s Instagram account in the shared content.</p>
      <p className="mb-1">c. The Company grants the Influencer a non-exclusive, revocable, non-transferable license to share the content on other platforms or other promotional channels for the purpose of promoting brands and products. </p>
      <p className="mb-1">d. The Influencer shall not modify, alter or edit any content shared from the website and not use the shared content for any other commercial purpose without the Company’s prior written consent.</p>
      <p className="mb-1">e. The Influencer hereby grants the Company and users of the services a non- exclusive, worldwide, royalty-free license to use, display, reproduce, and distribute any user-generated content (UGC) created by the Influencer and related to the promotion of brands and products.</p>
      <p className="mb-1">f. The Influencer acknowledges that they retain ownership of the UGC and have the right to use it for their own purposes, subject to the limitations set forth in this Agreement.</p>
      <p>g. The Company shall have the right to sublicense the UGC to third parties, solely for the purpose of promoting brands and products.</p>
     
      <h6>5. Termination </h6>
      <p>The Company may terminate this Agreement at any time for any reason without notice. Upon termination, the Influencer’s license to use the Services will be automatically revoked.</p>

      <h6>6. Disclaimers </h6>
      <p>THE SERVICES ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>

      <h6>7. Limitation of Liability</h6>
      <p>IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH THE SERVICES.</p>

      <h6>8. Entire Agreement</h6>
      <p>This Agreement represents the entire understanding between the Company and the Influencer regarding the use of the Services and supersedes all prior or contemporaneous communications and proposals, whether oral or written, between the parties.</p>

      <h6>9. Contact Information </h6>
      <p>For any questions or concerns regarding this Agreement, please contact the Company at <a href="mailto:support@ORMELIVE.com">support@ORMELIVE.com</a>.</p>
    </div>
  );
}
