import React, { useEffect, useState, useRef } from "react";
import { Spin, Empty } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import "../../../css/chat.scss";
import ScrollToBottom from "react-scroll-to-bottom";
import { saveChat, getChat } from "../../../redux/actions/chat";
import { getAccount } from "../../../redux/actions/account.action";
import useWebSocket from "react-use-websocket";
import { SOCKETURL } from "../../../config";

function Chatnew({ chat, publishEvent, eventId, activeKey }) {
	const dispatch = useDispatch();
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const [isBann, setisBann] = useState(userInfo?.is_banned);
	const [comments, setComments] = useState([]);
	const [value, setValue] = useState("");
	const didUnmount = useRef(false);
	//Socket Config
	const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(SOCKETURL, {
		shouldReconnect: (closeEvent) => {
			/*
        useWebSocket will handle unmounting for you, but this is an example of a 
        case in which you would not want it to automatically reconnect
      */
			return didUnmount.current === false;
		},
		reconnectAttempts: 10,
		reconnectInterval: 3000,
	});

	//   const MAX_RETRIES = 5; // Maximum number of reconnection attempts
	//   const RECONNECT_INTERVAL = 3000; // Time interval (in milliseconds) between reconnection attempts
	//   let reconnectAttempts = 0;

	useEffect(() => {
		if (userInfo?.token && account?.length === 0) dispatch(getAccount(userInfo?.token));
	}, []);

	const { messageReview, account, bancheck } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		if (lastMessage && lastMessage.data) {
			dispatch(getChat(eventId[0]?._id, userInfo.token)).then((res) => {
				setComments(res?.payload?.message?.data);
			});
		}
	}, [lastMessage]);

	useEffect(() => {
		if (activeKey === "1") {
			if (eventId) {
				dispatch(getChat(eventId[0]?._id, userInfo.token)).then((res) => {
					setComments(res?.payload?.message?.data);
				});
			}
		}
	}, [activeKey, eventId]);

	useEffect(() => {
		return () => {
			didUnmount.current = true;
		};
	}, []);

	useEffect(() => {
		setComments(chat);
	}, [chat]);

	const handleSubmit = (e) => {
		e.preventDefault();
		let trimmedValue = value.trimStart();
		if (!trimmedValue) return;

		const message = {
			author: account?.payload?.Data?.name ? account?.payload?.Data?.name : userInfo?.first_name + userInfo?.last_name,
			user_id: userInfo?._id,
			content: value,
			datetime: moment().format(),
		};
		const deleteMessage = {
			type: "eventmessage",
			messageId: eventId[0]?._id,
		};

		sendMessage(JSON.stringify(deleteMessage));

		dispatch(saveChat(eventId[0]?._id, message, userInfo?.token)).then((res) => {
			dispatch(getChat(eventId[0]?._id, userInfo.token)).then((res) => {
				setComments(res?.payload?.message?.data);
			});
		});
		setValue("");
	};
	const handleChange = (e) => {
		setValue(e.target.value);
	};

	if (messageReview.loading && comments?.length === 0) {
		return (
			<div className="load-container-main">
				<div className="load-container">
					<Spin size="medium"></Spin>
				</div>
			</div>
		);
	}

	return (
		<div className="live-chat-comp">
			<div id="frame">
				<div className="content">
					<ScrollToBottom className="messages">
						<ul className="message-ul">
							<>
								{!comments?.length > 0 ? (
									<div className="align-items-center justify-content-center brand-description">
										<Empty description={false} />
									</div>
								) : (
									comments.map((it) => {
										return (
											<li className={it.accountType}>
												<div className="message-box-main">
													<div className="msg-content">
														<span className="user-name">{it?.author === "Admin" ? "Longaberger" : it?.author || userInfo?.first_name + " " + userInfo?.last_name}</span>
														<span className="user-message">{it?.content}</span>
													</div>
												</div>
											</li>
										);
									})
								)}
							</>
						</ul>
					</ScrollToBottom>
					{bancheck?.loading ? (
						<div style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
							<Spin size="medium"></Spin>
						</div>
					) : (
						<>
							{!isBann ? (
								!publishEvent && (
									<div className="message-input">
										<div className="logged-in-name">
											Logged in as <span>{userInfo?.first_name + " " + userInfo?.last_name}</span>
										</div>
										<div className="wrap">
											<form onSubmit={handleSubmit}>
												<input value={value} type="text" placeholder="Write your message..." onChange={handleChange} />
												<button onSubmit={handleSubmit} className="submit">
													<i className="fa fa-paper-plane" aria-hidden="true"></i>
												</button>
											</form>
										</div>
									</div>
								)
							) : (
								<div style={{ marginTop: "1rem", textAlign: "center" }}>
									<span>You have been banned by the admin.</span>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}
export default Chatnew;
