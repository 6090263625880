import React, { useEffect, useState } from "react";
import { Drawer, Collapse } from "antd";
import { Modal } from "react-bootstrap";
import { getShopifyCart } from "../../redux/actions/liveEvent.action";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";
import queryString from "query-string";
import PromoCodeForm from "../cart/promoCodeForm";
import Cart from "../../pages/liveEvent/component/cart";
import CartItem from "../../components/cart/cartItem";
import SubTotal from "../../components/cart/subTotal";
import ContinueShopping from "../../components/cart/continueShopping";
import ShippingAddressModal from "../../pages/account/component/shippingAddress";
import Shipping from "../../components/cart/shipping";
import { setShippingRates } from "../../redux/actions/shopify.setShippingRates.action";
import Payment from "../../components/cart/payment";
import basket from "../../images/brand-logos/basket.png";
import closeicon from "../../images/brand-logos/close-icon.svg";
import "../../css/cart_drawer.scss";

const { Panel } = Collapse;

export default function CartDrawer() {
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
	const queryParams = queryString.parse(window.location.search);
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [shippingAddressModal, setShippingAddressModal] = useState(false);
	const [checkStatus, setStatus] = useState(true);
	const [cart, setCart] = useState();
	const [eventId, setEvent] = useState([]);
	const [checkout, setCheckout] = useState(false);
	const [activeKey, setActiveKey] = useState("3");
	const [collapseKey, setCollapseKey] = useState("2");
	const [clear, setClear] = useState(false);
	const [stp1, setStp1] = useState(true);
	const [stp2, setStp2] = useState(false);
	const [stp3, setStp3] = useState(false);
	const [value, setValue] = useState();
	const [successorder, setSuccessOrder] = useState(false);
	const [isShipTo, setIsShipTo] = useState("");
	const [shippingAddressId, setShippingAddressId] = useState("");
	const [eventAttributes, setEventAttributes] = useState({ event: "eventshop" });
	const [mode, setMode] = useState("");
	const [updateShop, setUpdateShop] = useState(false);
    const [brand,setBrand] = useState('');
	const { getShopifyLiveCart } = useSelector((state) => {
		return state;
	});

	const showDrawer = () => {
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};

	useEffect(() => {
		if (userInfo) {
			dispatch(getShopifyCart(userInfo?.brand, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
				}
				if (res?.payload?.message) {
					setCart(res?.payload?.message);
				}
			});
		}
	}, []);



	useEffect(() => {
       
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        setUserInfo(userInfo);
		const cart = getShopifyLiveCart?.payload?.message;
		setStatus(!(cart?.lineItems.length > 0));
		setCart(cart);
		setEvent([userInfo?.event]);
        setBrand(userInfo?.brand)
		setEventAttributes(userInfo?.eventAttributes);
	}, [getShopifyLiveCart]);

	const onCollapseChange = (key) => {
		setCollapseKey(key);
	};

	const onKeyChange = (key) => {
		setActiveKey(key);
		setCheckout(false);

		if (key === "3") {
			setCollapseKey("1");
		}
	};

	const handleSuccessClick = () => {
		setVisible(false);
        setSuccessOrder(false)
		onKeyChange("3");

		if (stp2) {
			setStp1(true);
			setStp2(false);
			setStp3(false);
			setClear(false);
		} else if (stp3) {
			setStp1(false);
			setStp2(true);
			setStp3(false);
			setClear(false);
		}
	};

	const CheckoutStep = () => {
		return (
			<>
				<div className="checkout-frame">
					<div className="order-summery">
						<Collapse className="position-relative" accordion defaultActiveKey={["1"]} activeKey={collapseKey} onChange={onCollapseChange}>
							<span className="total-price-top">{numeral(cart?.paymentDue?.amount).format("$0,0.0'")}</span>
							<Panel header="Order Summary" key="1">
								<div className="ordered-products">
									<CartItem brandId={brand} setStatus={setStatus} setCart={setCart} cart={cart} setCheckout={setCheckout} />
								</div>
								{/* <div className="checkout-discount-section">{<PromoCodeForm brandId={brand} setCart={setCart} setStatus={setStatus} cart={cart} activeKey={activeKey} eventId={eventId} />}</div> */}
								<SubTotal brandId={brand} setStatus={setStatus} cart={cart} setCart={setCart} />
							</Panel>

							<Panel header="Shipping" key="2" className="accordion-style2">
								<Shipping setMode={setMode} isShipTo={isShipTo} setIsShipTo={setIsShipTo} value={value} onChangeShippingMethod={onChangeShippingMethod} setCart={setCart} setStatus={setStatus} brandId={brand} setShippingAddressModal={setShippingAddressModal} setShippingAddressId={setShippingAddressId} cart={cart} setCollapseKey={setCollapseKey} />
							</Panel>

							<Panel header="Payment" key="3" className="accordion-style2 bg-white">
								<Payment setEventAttributes={setEventAttributes} page={"paymentBack"} eventAttributes={eventAttributes} eventId={eventId} brandId={brand} setSuccessOrder={setSuccessOrder} setStatus={setStatus} setCart={setCart} />
							</Panel>
						</Collapse>

						<div className="customer-information"></div>
					</div>
				</div>
			</>
		);
	};

	const onChangeShippingMethod = (e, handled) => {
		var handle = handled ? handled : e.target.value;
		setValue(handle);
		dispatch(setShippingRates(brand, handle, userInfo?.token)).then((res) => {
			dispatch(getShopifyCart(brand, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
				}
				setCart(res?.payload?.message);
			});
		});
	};

	return (
		<>
			<div className="cart-area-top">
				<div>
					<a className="ant-dropdown-trigger notification-scroll" onClick={showDrawer}>
						<div className="cart-area">
							<i className="fa fa-shopping-cart"></i>
							<div className="cart-count">{getShopifyLiveCart?.payload?.message?.lineItems?.length ? getShopifyLiveCart?.payload?.message?.lineItems?.length : "0"}</div>
						</div>
					</a>
				</div>
			</div>

			<Drawer
				className="cart-drawer-main"
				title={
					<div className="basket-icon">
						<span className="cart-count">{cart === undefined ? 0 : cart?.lineItems?.length}</span>
						<img src={basket} />
					</div>
				}
				placement="right"
				onClose={onClose}
				visible={visible}
			>
				{successorder ? <ContinueShopping handleSuccessClick={handleSuccessClick} /> : checkout ? <CheckoutStep /> : <Cart page={queryParams.page} onChangeShippingMethod={onChangeShippingMethod} promoCodeForm={<PromoCodeForm brandId={brand} setCart={setCart} setStatus={setStatus} cart={cart} activeKey={activeKey} eventId={eventId} />} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} brandId={brand} setStatus={setStatus} setCart={setCart} setCheckout={setCheckout} cart={cart} />}
			</Drawer>

			<Modal
				dialogClassName="modal-shipping-from-front"
				size="md"
				scrollable
				show={shippingAddressModal}
				onHide={() => {
					setMode("");
					setShippingAddressModal(false);
				}}
				centered
			>
				<span
					className="close-shipping-front"
					onClick={() => {
						setMode("");
						setShippingAddressModal(false);
					}}
				>
					<img src={closeicon} className="filter-gray" />
				</span>
				<Modal.Body>
					<ShippingAddressModal isShipTo={isShipTo} updateShop={updateShop} setUpdateShop={setUpdateShop} page={"review"} mode={mode} setCart={setCart} shippingAddressId={shippingAddressId} brandId={brand} edit={true} setStatus={setStatus} />
				</Modal.Body>
			</Modal>
		</>
	);
}
