
import { GET_SAVE_REVIW_REQUEST, GET_SAVE_REVIW_SUCCESS, GET_SAVE_REVIW_ERROR, } from "../../types/types";


export default function getSaveReview(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_SAVE_REVIW_REQUEST:
			return {
				loading: true,
			};
		case GET_SAVE_REVIW_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case GET_SAVE_REVIW_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		default:
			return state;
	}
}
