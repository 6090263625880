import {
  GET_SHARED_REVIEWS_REQUEST,
  GET_SHARED_REVIEWS_SUCCESS,
  GET_SHARED_REVIEWS_ERROR,
  GET_SHARED_REVIEWS_LOADMORE,
} from "../../types/types";

export default function sharedViews(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SHARED_REVIEWS_REQUEST:
      return {
        loading: true,
      };
    case GET_SHARED_REVIEWS_SUCCESS:
      let page = 1;
      if (payload?.message?.next?.page) {
        page = payload?.message?.next?.page - 1;
      }

      payload?.message?.data.map((item) => {
        if (item)
          item.page = page;
      });

      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case GET_SHARED_REVIEWS_LOADMORE:
      let nextPage = payload?.message?.next?.page - 1;
      if (payload?.message?.next?.page) {
        payload?.message?.data?.map((item) => {
          if (item)
            item.page = nextPage;
        });
      } else {

        payload?.message?.data?.map((item) => {
          if (item)
            item.page = state.payload.message.next.page;
        });
      }
      return {
        ...state,
        loading: false,
        payload: {
          ...state.payload,
          message: {
            ...payload,
            data: [...state.payload.message.data, ...payload?.message?.data],
          },
        },
      };
    case GET_SHARED_REVIEWS_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload ? payload : [],
      };

    default:
      return state;
  }
}
