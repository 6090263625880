import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Select, Spin, Upload } from "antd";
import { Row } from "react-bootstrap";
import Swal from "sweetalert2";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket, faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { getGenderCategory } from "../../../redux/actions/category.action";
import { getRekognitionConfig, uploadReview, uploadReviewnew } from "../../../redux/actions/influencer.action";
import { getBrandlist } from "../../../redux/actions/brand.action";
import ProductsFilter from "../component/productsFilter";
import { ACCESSKEYID, BUCKET, BUCKETNEW, SECREATACCESSKEY } from "../../../config";
import "react-dropzone-uploader/dist/styles.css";
import "../../../css/upload-review.scss";
import 'antd/dist/antd.css'

const { Option } = Select;
// Configure AWS SDK
AWS.config.update({
	accessKeyId: ACCESSKEYID,
	secretAccessKey: SECREATACCESSKEY,
	region: "us-east-1",
});

const s3 = new AWS.S3();

export default function UploadVid() {
	const dispatch = useDispatch();
	const [submit, setSubmit] = useState(false);
	const [title, setTitle] = useState("");
	const [category, setCategory] = useState();
	const [categoryList, setCategoryList] = useState([]);
	const [sku, setSku] = useState([]);
	const [brand, setBrand] = useState();
	const [brandList, setBrandList] = useState(false);
	const [video, setVideo] = useState();
	const [fileList, setFileList] = useState([]);
	const [drag, setDrag] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentLoading, setCurrentLoading] = useState(false);
	const [thumbnail, setThumbnail] = useState("");
	const [rekognitionConfig, SetrekognitionConfig] = useState("");
	const [isModeratingContent, setIsModeratingContent] = useState(false);
	const [loadCategory, setLoadCategory] = useState(false);

	const [loadBrand, setLoadBrand] = useState(true);


	const navigate = useNavigate();

	const { Dragger } = Upload;
	const [videofiles, setVideoFiles] = useState([]);

	useEffect(() => {
		dispatch(getBrandlist()).then((res) => {
			setBrandList(res?.payload?.message);
			setLoadBrand(false)
		});
	}, []);

	// This is componentWillUnmount
	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
		return () => {
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (isModeratingContent) {
				// Send the beacon
				e.preventDefault();
				e.returnValue = ""; // Necessary for the event to trigger
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [isModeratingContent]);

	const props = {
		accept: "video/mp4",
		multiple: false,
		id: "uploadVideoFile",
		beforeUpload: (file) => {
			setVideo(file);
			generateThumbnail(file); // Generate thumbnail when a new video is selected
			let ort;
			const url = URL.createObjectURL(file);
			const $video = document.createElement("video");
			$video.src = url;
			$video.addEventListener("loadedmetadata", function () {
				if (this.videoWidth > this.videoHeight) {
					ort = "landscape";
					// Display an error message for landscape videos
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Only portrait videos are allowed!",
					});
					setFileList([]);
					return false; // Prevent the upload
				} else {
					ort = "portrait";
				}

				setFileList([file]);
				setVideoFiles([{ orientation: ort, file }]);
				return true; // Proceed with the upload
			});
			return false; // This is important to prevent the default behavior
		},
		onRemove: () => {
			removeVideo();
		},
		onDrop(e) {
			// console.log('Dropped files', e.dataTransfer.files);
		},
		fileList,
	};

	useEffect(() => {
		dispatch(getRekognitionConfig()).then((data) => {
			setLoading(true)
			SetrekognitionConfig(data?.payload);
			setLoading(false)
		});
	}, []);

	const removeVideo = () => {
		let arr = [...videofiles];
		let newarr = [...fileList];
		setVideoFiles(arr.filter((f) => f.file.uid !== video.uid));
		setFileList(newarr.filter((f) => f.uid !== video.uid));

		setSubmit(false);
	};

	const handleBrand = (value, option) => {
		setBrand(option?.key);
		setLoadCategory(true)
		dispatch(getGenderCategory()).then((res) => {
			setCategoryList(res?.payload?.data);
			setLoadCategory(false)
		}).catch((error) => {
			setLoadCategory(false)
		});
	};

	const reset = () => {
		removeVideo();
		setLoading(false);
		setCurrentLoading(false);
		setThumbnail("");
		setSku([])
	};

	const handleCategory = (value, option) => {
		setCategory(option?.key);
	};

	const generateThumbnail = (file) => {
		const video = document.createElement("video");
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");

		video.src = URL.createObjectURL(file);

		video.addEventListener("loadedmetadata", () => {
			// Seek to a specific time (0 seconds in this case)
			video.currentTime = 0.5; // or any other time in seconds
		});

		video.addEventListener("timeupdate", () => {
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;
			context.drawImage(video, 0, 0, canvas.width, canvas.height);
			setThumbnail(canvas.toDataURL("image/jpeg")); // Store the thumbnail as base64
			video.removeEventListener("timeupdate", this);
		});
	};

	async function postVideo(fileName, under_review) {
		setCurrentLoading(under_review ? "Requesting" : "Posting");

		dispatch(
			uploadReview({
				source: "app",
				title: title,
				media_type: "VIDEO",
				description: "",
				orientation: "portrait",
				brand_id: brand,
				department_id: category,
				banner: thumbnail,
				products: sku,
				fileName: fileName,
				under_review: under_review,
			})
		).then(() => {
			Swal.fire({
				title: under_review ? "Content Review Request Submitted" : "Successfully Posted",
				icon: "success",
			});
			setIsModeratingContent(false);
			navigate("/reviews-list");
			reset();
		});
	}

	function getRekognitionData(jobId, rekognition, fileName, rekognitionConfig) {
		rekognition?.getContentModeration({ JobId: jobId }, (error2, data2) => {
			if (!error2) {
				if (data2?.JobStatus == "IN_PROGRESS") {
					getRekognitionData(jobId, rekognition, fileName, rekognitionConfig);
				} else {
					const nuditites = data2?.ModerationLabels?.filter((it) => it?.ModerationLabel?.Name == "Explicit Nudity" || it?.ModerationLabel?.ParentName == "Explicit Nudity").map((itSub) => itSub?.ModerationLabel?.Confidence);
					const violences = data2?.ModerationLabels?.filter((it) => it?.ModerationLabel?.Name == "Violence" || it?.ModerationLabel?.ParentName == "Violence").map((itSub) => itSub?.ModerationLabel?.Confidence);
					const hateSymbols = data2?.ModerationLabels?.filter((it) => it?.ModerationLabel?.Name == "Hate Symbols" || it?.ModerationLabel?.ParentName == "Hate Symbols").map((itSub) => itSub?.ModerationLabel?.Confidence);
					const alcohols = data2?.ModerationLabels?.filter((it) => it?.ModerationLabel?.Name == "Alcohol" || it?.ModerationLabel?.ParentName == "Alcohol").map((itSub) => itSub?.ModerationLabel?.Confidence);
					const gamblings = data2?.ModerationLabels?.filter((it) => it?.ModerationLabel?.Name == "Gambling" || it?.ModerationLabel?.ParentName == "Gambling").map((itSub) => itSub?.ModerationLabel?.Confidence);
					// add new
					const suggestives = data2?.ModerationLabels?.filter((it) => it?.ModerationLabel?.Name == "Suggestive" || it?.ModerationLabel?.ParentName == "Suggestive").map((itSub) => itSub?.ModerationLabel?.Confidence);
					const visually_disturbings = data2?.ModerationLabels?.filter((it) => it?.ModerationLabel?.Name == "Visually Disturbing" || it?.ModerationLabel?.ParentName == "Visually Disturbing").map((itSub) => itSub?.ModerationLabel?.Confidence);
					const rude_gestures = data2?.ModerationLabels?.filter((it) => it?.ModerationLabel?.Name == "Rude Gestures" || it?.ModerationLabel?.ParentName == "Rude Gestures").map((itSub) => itSub?.ModerationLabel?.Confidence);
					const drugs = data2?.ModerationLabels?.filter((it) => it?.ModerationLabel?.Name == "Drugs" || it?.ModerationLabel?.ParentName == "Drugs").map((itSub) => itSub?.ModerationLabel?.Confidence);
					const tobaccos = data2?.ModerationLabels?.filter((it) => it?.ModerationLabel?.Name == "Tobacco" || it?.ModerationLabel?.ParentName == "Tobacco").map((itSub) => itSub?.ModerationLabel?.Confidence);
					const rekognitions = {
						nudity: nuditites?.length > 0 ? Math.max(...nuditites) : 0,
						violence: violences?.length > 0 ? Math.max(...violences) : 0,
						hateSymbol: hateSymbols?.length > 0 ? Math.max(...hateSymbols) : 0,
						alcohol: alcohols?.length > 0 ? Math.max(...alcohols) : 0,
						gambling: gamblings?.length > 0 ? Math.max(...gamblings) : 0,
						// add new
						suggestive: suggestives?.length > 0 ? Math.max(...suggestives) : 0,
						visually_disturbing: visually_disturbings?.length > 0 ? Math.max(...visually_disturbings) : 0,
						rude_gesture: rude_gestures?.length > 0 ? Math.max(...rude_gestures) : 0,
						drug: drugs?.length > 0 ? Math.max(...drugs) : 0,
						tobacco: tobaccos?.length > 0 ? Math.max(...tobaccos) : 0,
					};
					if (
						rekognitions.nudity <= rekognitionConfig.nudity &&
						rekognitions.violence <= rekognitionConfig.violence &&
						rekognitions.hateSymbol <= rekognitionConfig.hate_speech &&
						rekognitions.alcohol <= rekognitionConfig.alcohol &&
						rekognitions.gambling <= rekognitionConfig.gambling &&
						// add new
						rekognitions.suggestive <= rekognitionConfig.suggestive &&
						rekognitions.visually_disturbing <= rekognitionConfig.visually_disturbing &&
						rekognitions.rude_gesture <= rekognitionConfig.rude_gestures &&
						rekognitions.drug <= rekognitionConfig.drugs &&
						rekognitions.tobacco <= rekognitionConfig.tobacco
					) {
						postVideo(fileName, false);
					} else {
						Swal.fire({ icon: "error", title: "Content Rejected", text: "Our community guidelines strictly prohibit Nudity , Suggestive , Violence Visually Disturbing , Rude Gestures , Drugs , Tobacco , Alcohol , Gambling & Hate Symbols" });
						removeVideo();
						setLoading(false);
						setCurrentLoading(false);
						setIsModeratingContent(false);
					}
				}
			} else {
				console.log("err2", error2);
			}
		});
	}

	async function videoRekognition(fileName, rekognitionConfig) {
		setCurrentLoading("Checking content against community guidelines...");

		const rekognition = new AWS.Rekognition({
			region: "us-east-1",
			credentials: {
				accessKeyId: ACCESSKEYID,
				secretAccessKey: SECREATACCESSKEY,
			},
		});
		rekognition.startContentModeration(
			{
				Video: {
					S3Object: {
						Bucket: BUCKET,
						Name: "uploads/" + fileName,
					},
				},
			},
			(error, data) => {
				if (!error) {
					getRekognitionData(data?.JobId, rekognition, fileName, rekognitionConfig);
				} else {
					setLoading(false);
					Swal.fire("Error", "Checking content failed", "error");
				}
			}
		);
	}

	// Function to check the duration of a video file
	function checkVideoDuration(file) {
		return new Promise((resolve, reject) => {
			const videoElement = document.createElement("video");
			videoElement.src = URL.createObjectURL(file);
			videoElement.addEventListener("loadedmetadata", () => {
				// Duration in seconds
				const duration = videoElement.duration;
				const videoUploadingTime = rekognitionConfig?.videoSetting?.video_uploading_time;
				if (duration > videoUploadingTime) {
					resolve(true);
				} else {
					resolve(false);
				}
			});
			videoElement.addEventListener("error", () => {
				reject(new Error("Error loading video metadata"));
			});
		});
	}

	async function onSubmit(fields) {
		setLoading(true);
		// Form field validation
		if (!title) {
			Swal.fire("Oops...", "Title is required", "error");
			setLoading(false);
			return;
		}
		if (!brand) {
			Swal.fire("Oops...", "Brand is required", "error");
			setLoading(false);
			return;
		}
		if (!sku || sku.length === 0) {
			Swal.fire("Oops...", "Product is required", "error");
			setLoading(false);
			return;
		}
		if (!fileList[0]) {
			Swal.fire("Oops...", "Please select a video", "error");
			setLoading(false);
			return;
		}
		if (!category) {
			Swal.fire("Oops...", "Please select a department", "error");
			setLoading(false);
			return;
		}

		const videoFile = fileList[0];

		try {
			const isLongerThan60Seconds = await checkVideoDuration(videoFile);
			if (isLongerThan60Seconds) {
				Swal.fire("Error", "Video duration exceeds 60 seconds", "error");
				setLoading(false);
				return;
			}
		} catch (error) {
			Swal.fire("Error", "Failed to check video duration", "error");
			setLoading(false);
			return;
		}

		if (rekognitionConfig?.data?.content_moderation) {
			try {
				setCurrentLoading("Uploading");
				setIsModeratingContent(true);

				const fileName = `review_${uuidv4()}`;
				const params = {
					Bucket: BUCKET,
					Key: `uploads/${fileName}`,
					Body: fields.video,
					ContentType: fields.video.type,
				};

				s3.upload(params, function (err, data) {
					if (err) {
						Swal.fire("Upload failed", "Please try again", "error");
						setLoading(false);
					} else {
						videoRekognition(fileName, rekognitionConfig?.data);
					}
				});
			} catch (error) {
				Swal.fire("Error", "An error occurred", "error");
				setLoading(false);
			}
		} else {
			dispatch(
				uploadReviewnew({
					source: "app",
					title: title,
					media_type: "VIDEO",
					description: "",
					orientation: "portrait",
					brand_id: brand,
					department_id: category,
					banner: thumbnail,
					products: sku,
				})
			).then((res) => {
				if (res.payload.file_name) {
					try {
						setCurrentLoading("Uploading");
						const fileName = `${res?.payload?.file_name}`;
						const params = {
							Bucket: BUCKETNEW,
							Key: `uploads/${fileName}`,
							Body: fields.video,
							ContentType: fields.video.type,
						};

						s3.upload(params, function (err, data) {
							if (err) {
								Swal.fire("Upload failed", "Please try again", "error");
								setLoading(false);
								setIsModeratingContent(false);
							} else {
								Swal.fire({
									title: "Successfully Posted",
									icon: "success",
								});
								setLoading(false);
								setIsModeratingContent(false);
								navigate("/reviews-list");
								reset();
							}
						});
					} catch (error) {
						Swal.fire("Error", "An error occurred", "error");
						setLoading(false);
					}
				}
			});
		}
	}

	return (
		<div className="account-left-content">
			<div className="favourite-brands-container">
				<div className="container ml-0">
					<div className="row">
						<div className=" col-lg-10 col-xl-8 p-0">
							<div className="heading-top-area">
								<h2>Upload Reviews</h2>
								<Button style={{ background: "#000080", border: "#000080", color: "#fff" }} onClick={() => navigate("/reviews-list")}>
									My Reviews
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="container m-0">
					<Row>
						<div className="upload-box-main col-lg-10 col-xl-8 p-0">
							<div className=" brand-section dash_block_profile dash_content_profile width-data">
								<div className="upload_area">
									<h4>Upload Review Video</h4>
									<p className="text-muted text-center">
										MP4 Files Are Allowed
										<br />
										The maximum allowed duration is 60 seconds.
									</p>
								</div>

								<div className="row">
									<div className="col-lg-6 mb-2">
										<Input
											placeholder="Title"
											size="large"
											onChange={(e) => {
												setTitle(e.target.value); // Update the title state with the input value
											}}
											className="input-style"
										/>
									</div>

									<div className="col-lg-6 mb-2">
										<Select
											className="w-100 mb-2 select-style dark-field"
											
											placeholder="Select Brand"
											size="large"
											onChange={(value, option) => handleBrand(value, option)}
											value={brand}
											disabled={!brandList?.length < 0}
											loading={loadBrand}

										>
											{brandList?.length > 0 && brandList?.map((item, i) => (
												<Option key={item?._id} value={item?.item?._id}>
													{item?.brand_name}
												</Option>
											))}
										</Select>

									</div>

									<div className="col-lg-6 mb-2">
										<ProductsFilter brandId={brand} setSku={setSku} />
									</div>

									<div className="col-lg-6 mb-2">
										<Select className="w-100 mb-2 select-style dark-field" placeholder="Select Department" size="large" focus={false} onBlur={false} onChange={handleCategory} value={category} disabled={!brand} loading={loadCategory}  >
											{categoryList?.length > 0 &&
												categoryList?.map((item) => {
													return (
														<Option key={item?._id} value={item?._id}>
															{item?.name}
														</Option>
													);
												})}
										</Select>
									</div>
								</div>

								<Dragger {...props} className="upload_area_2 mb-20 position-relative uploadWidth full-width">
									<FontAwesomeIcon icon={faCloudArrowUp} className="mt-0" />
									<h4>{drag ? "Drop Here" : "Drag & Drop Your Media Here"}</h4>
									<h4>Or</h4>
									<Button className="select-btn">
										<FontAwesomeIcon icon={faArrowUpFromBracket} />
										Select File
									</Button>
								</Dragger>

								{submit && fileList.length === 0 && (
									<div role="alert" className="ant-form-item-explain-error select-file-error-txt">
										Please Select Video File
									</div>
								)}

								{fileList?.length !== 0 && (
									<div className="d-flex justify-content-center btn-style-main mt-3">
										<Button
											className="primary-btn"
											onClick={() => {
												// Assuming the video file is the first item in the fileList array
												const videoFile = fileList[0] || null; // Fetch the video file

												onSubmit({
													video: videoFile, // Pass the video file here
												});
											}}
											loading={loading}
										>
											{currentLoading ? currentLoading : "Upload"}
										</Button>
									</div>
								)}
							</div>
						</div>
					</Row>
				</div>
			</div>
		</div>
	);
}
