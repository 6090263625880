import React, { useState, useRef, useEffect } from "react";
import { Button, Form } from "antd";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useStepsForm } from "sunflower-antd";
import SmsValidate from "./smsValidate";
import { getCustomerRegister } from "../../redux/actions/register.action";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "antd/dist/antd.css";
import { Spin } from "antd";
import logo from "../../images/orme-logo.svg";

export default function Register({ setSignUp }) {
	function isNumberOrStringNumber(value) {
		if (typeof value === "number" && !isNaN(value)) {
			return true;
		}

		if (typeof value === "string") {
			return !isNaN(parseFloat(value)) && isFinite(value);
		}

		return false;
	}

	const dispatch = useDispatch();
	const [value, setValue] = useState();
	const [errorCheck, setErrorCheck] = useState("");
	const [userId, setUserId] = useState("");
	const [flag, setFlag] = useState("");
	const allowedCountries = ["US", "PK"];
	const inputRef4 = useRef(null);

	let shareTracking = JSON.parse(localStorage.getItem("shareTracking"));
	let queryParams = new URLSearchParams(window.location.search);
	var params = window.location.pathname.split("/").filter((segment) => segment);

	useEffect(() => {
		let publisher_id = isNumberOrStringNumber(window.location.pathname.split("/").pop()) ? window.location.pathname.split("/").pop() : "";
		if (queryParams.has("publisher_id")) {
			if (isMobile()) {
				localStorage.removeItem("shareTracking");
			}
			localStorage.setItem(
				"shareTracking",
				JSON.stringify({
					publisher_id: queryParams.get("publisher_id"),
					brand: queryParams.get("brand"),
					event: queryParams.get("event"),
					source: queryParams.get("event"),
					referral: window.location.href,
					event_review_id: params[0],
				})
			);
		} else {
			localStorage.setItem(
				"shareTracking",
				JSON.stringify({
					publisher_id: publisher_id,
					event: "linkinbio",
					referral: window.location.href,
				})
			);
		}
	}, []);

	const { customerRegister } = useSelector((state) => {
		return state;
	});
	const isMobileDevice = isMobile() || false; // Call the function to determine if it's a mobile device

	function isMobile() {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}

	const { current, formProps, submit, formLoading } = useStepsForm({
		async submit(values) {
			let data;
			if (!shareTracking?.tracker) {
				data = {
					...values,
					url: shareTracking?.referral,
					urlType: shareTracking?.event === "linkinbio" ? "linkinbio" : "kbsly",
					...(shareTracking?.event === "linkinbio" ? { publisher_pixel_id: shareTracking?.publisher_id } : { publisher_id: shareTracking?.publisher_id }),
				};
			} else {
				data = { ...values };
			}

			dispatch(getCustomerRegister(data)).then((res) => {
				if (res?.payload?.success) {
					setFlag(true);
					setUserId(res?.payload?.data?.user_id);
				} else {
					setErrorCheck(res?.payload?.data?.message);
				}
			});
		},
	});

	const handleKeyDown = (event, inputRef, nextInputRef, nextPlaceholder) => {
		if (event.key === "Enter") {
			event.preventDefault();
			inputRef.current.placeholder = nextPlaceholder;
			nextInputRef.current.focus();
		}
	};

	const formList = [
		<>
			<Form.Item name={["phone"]}>
				<PhoneInput
					className="flag-country"
					rules={[
						{
							type: "phone",
							required: true,
							message: "Please Enter Phone number",
						},
					]}
					international
					countries={allowedCountries}
					countryCallingCodeEditable={false}
					defaultCountry="US"
					limitMaxLength={14}
					value={value}
					onChange={setValue}
					placeholder={`phone`}
					ref={inputRef4}
					onKeyDown={(event, loading) => {
						if (event?.key === "Enter") {
							submit();
						}

						handleKeyDown((loading = { formLoading }));
					}}
				/>
			</Form.Item>

			{customerRegister?.loading ? (
				<Button size="large" block className="w-100 light" type="primary">
					<Spin className="white-loader" />
				</Button>
			) : (
				<Button
					size="large"
					block
					className="w-100"
					type="primary"
					loading={formLoading}
					onClick={() => {
						submit();
					}}
				>
					Submit
				</Button>
			)}

			<div role="alert" className="ant-form-item-explain-error mt-2">
				{errorCheck}
			</div>
		</>,
	];

	return (
		<>
			<Modal
				backdropClassName="custom-backdrop"
				dialogClassName="filter-modal-box"
				show={true}
				// scrollable
				onHide={() => {}}
				centered
			>
				<Modal.Body>
					{flag ? (
						<div className="mobile-style">
							<SmsValidate IsMobile={isMobileDevice ? true : false} userId={userId} setSignUp={setSignUp} />
						</div>
					) : (
						<>
							<div className="login-signup-ift mobile-style">
								<img className="logo-mob" src={logo} alt="logo" />

								<h2>Let's Get Started</h2>
								<p>Enter your mobile number</p>
								<div className="mob-form">
									<Form {...formProps}>{formList[current]}</Form>
								</div>
							</div>
						</>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}
