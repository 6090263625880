import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Tabs, Layout, Collapse } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import numeral from "numeral";
import { Button, Modal } from "react-bootstrap";
import { LoadingOutlined } from "@ant-design/icons";
import Slider from "react-slick";
import Swal from "sweetalert2";
import { salesTracker } from "../../redux/actions/salesTracker.action";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/live-events.scss";
import "../../css/checkout.scss";
import Video from "./video";
import ShippingAddressModal from "../account/component/shippingAddress";
import queryString from "query-string";
import Chat from "../liveEvent/component/chat";
import Chatnew from "../publishedEvent/component/chatnew";
import Detail from "../liveEvent/component/detail";
import Cart from "../liveEvent/component/cart";
import Shop from "../liveEvent/component/shop";
import CartItem from "../../components/cart/cartItem";
import SubTotal from "../../components/cart/subTotal";
import Shipping from "../../components/cart/shipping";
import Payment from "../../components/cart/payment";
import ContinueShopping from "../../components/cart/continueShopping";
import Notify from "../../components/cart/notify";
import PromoCodeForm from "../../components/cart/promoCodeForm";

import { getEventsBy_Id, getShopifyCart } from "../../redux/actions/liveEvent.action";
import { getAllPublishedEvent } from "../../redux/actions/liveShopping.action";
import { savedEvents } from "../../redux/actions/save.action";
import { getProduct } from "../../redux/actions/shopify.getProduct.action";
import { getAllShippingAddress } from "../../redux/actions/shipping.action";
import { addToCart } from "../../redux/actions/shopify.addToCart.action";
import { setShippingRates } from "../../redux/actions/shopify.setShippingRates.action";
import { getPreviews, eventViews } from "../../redux/actions/EventView.action";
import { setShippingAddress } from "../../redux/actions/shopify.setShippingAddress.action";
import * as chatActions from "../../redux/actions/chat";
import { getLike } from "../../redux/actions/likesCount.action";
import { getSaveAction } from "../../redux/actions/save.action";

// import { openLogin } from "../../redux/actions/login.action";
import { openRegister } from "../../redux/actions/register.action";

import closeicon from "../../images/brand-logos/close-icon.svg";
import basket from "../../images/brand-logos/basket.png";
import { isNullOrUndefined } from "url/util";

const { Panel } = Collapse;
const { TabPane } = Tabs;
let soruce1;
let titles1;
let tag1;
let price1;
let brand_id1;
let addBag = [];
let lists_item = [];
let tag = "https://dl1961trial.myshopify.com/23973099/checkouts/3063eb9af1269e7d245bd55bee2630ab?key=e08d0841d1a89ad19c0b444d6055d007";
const { Sider, Content } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const PublishedContainer = () => {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	let shareTracking = JSON.parse(localStorage?.getItem("shareTracking"));
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { liveEventId, shopifyProduct, allShippingAddress, shopifyGetShippingRates } = useSelector((state) => {
		return state;
	});
	//
	const params = useParams();
	const queryParams = queryString.parse(window.location.search);
	const [like, setLike] = useState([]);
	const [mode, setMode] = useState("");

	const [height, setHeight] = useState(0);
	const ref = useRef();
	useEffect(() => {
		const resizeObserver = new ResizeObserver((event) => {
			setHeight(event[0].contentBoxSize[0].blockSize);
		});

		if (ref) {
			resizeObserver.observe(ref.current);
		}
	}, [ref]);

	const [eventId, setEvent] = useState([]);
	const [shippingAddressModal, setShippingAddressModal] = useState(false);
	const [infliencerReviewData, setInfluencerReviewData] = useState([]);
	const event_id = params.id;
	const [activeKey, setActiveKey] = useState("3");
	const [collapseKey, setCollapseKey] = useState("2");
	const [cart, setCart] = useState();
	const [brandId, setBrandID] = useState("");
	const [checkout, setCheckout] = useState(false);
	const [checkStatus, setStatus] = useState(true);
	const [clear, setClear] = useState(false);
	const [product, setProduct] = useState();
	const [stp1, setStp1] = useState(true);
	const [stp2, setStp2] = useState(false);
	const [stp3, setStp3] = useState(false);
	const [lineItem, setLineItem] = useState([]);
	const [quantity, setQty] = useState(1);
	const [collapsed, setCollapsed] = useState(false);
	const [value, setValue] = useState();
	const [chatStatus, setChatStatus] = useState(false);
	const [successorder, setSuccessOrder] = useState(false);
	const [isShipTo, setIsShipTo] = useState("");
	const [shippingAddressId, setShippingAddressId] = useState("");
	const [updateShop, setUpdateShop] = useState(false);
	const [isSaved, setIsSaved] = useState("");

	const [eventAttributes, setEventAttributes] = useState({ event: "eventshop" });
	const [videoStartTime, setVideoStartTime] = useState("");
	const [currentTimestamp, setCurrentTimestamp] = useState("");

	const [nextPage, setNextPage] = useState(0);
	const [next, setNext] = useState("");

	const [previousPage, setPreviousPage] = useState(0);
	const [previous, setPrevious] = useState("");

	const [firstLoad, setFirstLoad] = useState(false);
	const [secondLoad, setSecondLoad] = useState(false);
	const [usedPages, setUsedPages] = useState([]);
	const [disableNext, setDisableNext] = useState(false);
	const [fetchDataAgain, setFetchDataAgain] = useState(false);
	const [lastRow, setLastRow] = useState([]);

	const limit = 12;

	const addPages = (num) => {
		setUsedPages((prevPages) => {
			// Check if the number already exists
			if (!prevPages.includes(num)) {
				// Return a new array with the new number
				return [...prevPages, num];
			}
			// If the number already exists, return the existing array
			return prevPages;
		});
	};

	const smartEventFetch = (allEventParams, newEventParams) => {
		return (dispatch) => {
			if (queryParams?.origin?.includes("saved-events")) {
				return dispatch(savedEvents(...newEventParams));
			} else {
				return dispatch(getAllPublishedEvent(...allEventParams));
			}
		};
	};

	useEffect(() => {
		document.body.classList.add("live-event-header-hide");
		return () => {
			document.body.classList.remove("live-event-header-hide");
		};
	}, []);

	useEffect(() => {
		setEventAttributes({ brand: queryParams.brand, source: "eventshop" });
		if (shareTracking) {
			let source = "eventshop";

			if (event_id === shareTracking?.event_review_id) {
				source = "eventshare";
			}

			setEventAttributes({
				tracker: shareTracking?.tracker,
				brand: queryParams.brand,
				source: source,
				event_review_id: shareTracking.event_review_id,
			});
		}

		if (queryParams?.publisher_id) {
			if (userInfo?.token) {
				if (!isMobile()) {
					const shareTracking = JSON.parse(localStorage.getItem("shareTracking"));
					dispatch(salesTracker({ publisher_id: queryParams?.publisher_id ? queryParams?.publisher_id : shareTracking.publisher_id }, userInfo?.token)).then((res) => {
						localStorage.setItem("shareTracking", JSON.stringify({ tracker: res?.payload?.data?.tracker, brand: queryParams.brand, source: queryParams.event, event_review_id: event_id, event: queryParams?.event, referral: window.location.href }));
						setEventAttributes({ tracker: res?.payload?.data?.tracker, brand: queryParams.brand, source: "eventshare", event_review_id: event_id });
					});
				}
			} else {
				localStorage.setItem(
					"shareTracking",
					JSON.stringify({
						publisher_id: queryParams?.publisher_id,
						event: queryParams.event,
						event_review_id: event_id,
						referral: window.location.href,
					})
				);
			}
		}
	}, []);

	useEffect(() => {
		dispatch(getPreviews(event_id, userInfo?.token)).then((res) => {
			dispatch(eventViews(event_id));
		});
	}, []);

	useEffect(() => {
		if (window.innerWidth <= 1290) {
			setCollapsed(true);
		}
	}, []);

	useEffect(() => {
		if (event_id) {
			if (userInfo) {
				dispatch(getAllShippingAddress(userInfo?.token));
			}

			dispatch(getEventsBy_Id(event_id, userInfo?._id)).then((res) => {
				setEvent(res?.payload?.message);
				setBrandID(res?.payload?.message[0]?.brand[0]?._id);

				setVideoStartTime(new Date(res?.payload?.message[0]?.started_at).getTime());
				setCurrentTimestamp(new Date(res?.payload?.message[0]?.started_at).getTime());
			});

			dispatch(getLike(event_id)).then((res) => {
				if (res?.payload) setLike(res?.payload);
			});

			dispatch(getSaveAction(event_id)).then((res) => {
				if (res?.payload) setIsSaved(res?.payload?.is_saved);
			});
		}
	}, [event_id]);

	useEffect(() => {
		if (event_id && eventId) {
			let params = [queryParams?.page, limit, getQueryParamsData("department"), getQueryParamsData("category"), null, queryParams.origin !== "recorded" && queryParams.brand, queryParams.sortBy, null, null, userInfo?._id];
			let params1 = [queryParams?.page, limit, false, userInfo?._id];

			dispatch(smartEventFetch(params, params1)).then((res) => {
				addPages(parseFloat(queryParams?.page));
				let data = [...res?.payload?.message?.data, eventId[0]];
				const uniqueData = data.filter((item, index, array) => array.findIndex((otherItem) => otherItem?._id === item?._id) === index);
				setInfluencerReviewData(uniqueData);
				setNext("");
				setPrevious("");
				if (!res?.payload?.message?.next) {
					let lastRow = res?.payload?.message?.data[res?.payload?.message?.data.length - 1];
					if (lastRow?._id === event_id) {
						setDisableNext(true);
					}
				}

				if (res?.payload?.message?.next) {
					setNext(res?.payload?.message?.next);
					setNextPage(res?.payload?.message?.next?.page);
					setPrevious(res?.payload?.message?.previous);
					setPreviousPage(res?.payload?.message?.previous?.page);

					const last2Ids = res?.payload?.message?.data.slice(-2).map((list) => list._id);
					if (last2Ids?.includes(event_id)) {
						setFetchDataAgain("next");
					}
				}

				if (res?.payload?.message?.previous) {
					setLastRow(uniqueData[uniqueData.length - 1]);
					setPrevious(res?.payload?.message?.previous);
					setPreviousPage(res?.payload?.message?.previous?.page);
					const first2Ids = res?.payload?.message?.data.slice(0, 2).map((list) => list._id);
					if (first2Ids?.includes(event_id)) {
						setFetchDataAgain("previous");
					}
				}
				setFirstLoad(true);
			});
		}
	}, [event_id]);

	const getQueryParamsData = (param) => {
		if (queryParams[param] === "") {
			return null;
		}

		if (queryParams[param] === "null" || queryParams[param] === "undefined") {
			return null;
		}
		return queryParams[param]?.split(",");
	};

	useEffect(() => {
		if (infliencerReviewData?.length) {
			let page = parseFloat(queryParams?.page);
			let data = infliencerReviewData;

			const handleData = (key, newData) => {
				setSlideIndex(key);
				setTimeout(() => {
					slider.current.slickGoTo(key);
					const currentVideo = document.getElementById(`video-${key}`);
					if (currentVideo && currentVideo.paused) {
						currentVideo.play();
					}
				}, 100);
				setInfluencerReviewData(newData);
			};

			if (fetchDataAgain) {
				page += fetchDataAgain === "next" ? 1 : -1;
				if (page > 0) {
					let params = [page, limit, getQueryParamsData("department"), getQueryParamsData("category"), null, queryParams.origin !== "recorded" && queryParams.brand, queryParams.sortBy, null, null, userInfo?._id];
					let params1 = [page, limit, false, userInfo?._id];

					dispatch(smartEventFetch(params, params1)).then((res) => {
						if (res?.payload?.message?.next) {
							if (!usedPages.includes(res?.payload?.message?.next?.page)) {
								setNext(res?.payload?.message?.next);
								setNextPage(res?.payload?.message?.next?.page);
							}
						}

						if (res?.payload?.message?.previous) {
							setLastRow(res?.payload?.message?.data[res?.payload?.message?.data.length - 1]);
							if (!usedPages.includes(res?.payload?.message?.next?.page)) {
								setPrevious(res?.payload?.message?.previous);
								setPreviousPage(res?.payload?.message?.previous?.page);
							}
						}

						addPages(page);
						const newData = fetchDataAgain === "next" ? [...data, ...res?.payload?.message?.data] : res?.payload?.message?.data.concat(data);
						const key = newData.findIndex((item) => item?._id === event_id);
						handleData(key, newData);
					});
				}
			} else if (fetchDataAgain === false) {
				const key = data.findIndex((item) => item?._id === event_id);
				handleData(key, data);
			}
		}
	}, [firstLoad]);

	useEffect(() => {
		if (secondLoad) {
			if (infliencerReviewData?.length) {
				const key = infliencerReviewData.findIndex((item) => item?._id === secondLoad);
				setSlideIndex(key);
				setTimeout(() => {
					slider.current.slickGoTo(key);
					const currentVideo = document.getElementById(`video-${key}`);
					if (currentVideo) {
						if (currentVideo.paused) {
							currentVideo.play();
						}
					}
				}, 1000);
			}
		}
	}, [secondLoad]);

	useEffect(() => {
		if (userInfo) {
			dispatch(getShopifyCart(userInfo?.brand ? userInfo?.brand : brandId, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
				}
				setCart(res?.payload?.message);
			});
		}
	}, []);

	useEffect(() => {
		if (queryParams?.pageback === "paymentBack") {
			onKeyChange("4");
			setCollapseKey("2");
		}
	}, []);

	useEffect(() => {
		if (userInfo && updateShop) {
			let addressId = allShippingAddress?.payload?.message.filter((item) => item?.is_default === true);
			setIsShipTo(addressId[0]._id);
			dispatch(setShippingAddress(userInfo?.brand ? userInfo.brand : brandId, userInfo?.token, addressId[0]._id)).then((res) => {
				if (res?.payload?.data?.success === false) {
					Swal.fire({
						icon: "error",
						title: res?.payload?.data?.message,
					});
				} else {
					dispatch(getShopifyCart(userInfo?.brand ? userInfo.brand : brandId, userInfo?.token)).then((res) => {
						if (res?.payload?.message?.lineItems?.length > 0) {
							setStatus(false);
						} else {
							setStatus(true);
						}
						setCart(res?.payload?.message);
					});
				}

				onChangeShippingMethod("", value ? value : shopifyGetShippingRates?.payload?.message?.data?.node?.availableShippingRates?.shippingRates[0]?.handle);
			});
		}
	}, [updateShop]);

	function isMobile() {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}

	const handleSuccessClick = () => {
		onKeyChange("3");

		if (stp2) {
			setStp1(true);
			setStp2(false);
			setStp3(false);
			setClear(false);
		} else if (stp3) {
			setStp1(false);
			setStp2(true);
			setStp3(false);
			setClear(false);
		}
	};

	const onKeyChange = (key, brand) => {
		setActiveKey(key);
		setCheckout(false);

		if (key == "1" && eventId && userInfo?._id) {
			setChatStatus(true);
		}

		if (key === "4") {
			setCollapseKey("2");
			if (userInfo) {
				setSuccessOrder(false);
				dispatch(getShopifyCart(userInfo.brand ? userInfo.brand : brand, userInfo?.token)).then((res) => {
					if (res?.payload?.message?.lineItems.length > 0) {
						setStatus(false);
					} else {
						setStatus(true);
					}
					setCart(res?.payload?.message);
				});
			}
		}
	};

	const onCollapseChange = (key) => {
		setCollapseKey(key);
	};

	const buy = (brand_id, Product_id, price) => {
		setStp1(false);
		setStp2(true);
		price1 = price;
		brand_id1 = Product_id;
		dispatch(getProduct(brand_id, Product_id, userInfo?.token)).then((res) => {
			setProduct(res?.payload?.message);
		});
	};

	// const addToBag = async (src, title, tag, clear, list, status, brand) => {
	// 	setStp1(false);
	// 	setStp2(true);
	// 	setClear(true);
	// 	soruce1 = src;
	// 	titles1 = title;
	// 	tag1 = tag;

	// 	if (list?.length > 1) {
	// 		list?.selectedOptions.map((item) => {
	// 			lists_item.push({
	// 				name: item.name,
	// 				value: item.value,
	// 			});
	// 		});
	// 	}

	// 	let prodId = list.hasOwnProperty("variants") ? list?.variants[0]?.id : list?.id;

	// 	dispatch(addToCart(eventId[0]?.brand?._id, status ? status : checkStatus, quantity, prodId, lists_item, userInfo?.token, title, list, { review_id: infliencerReviewData?.[0]?._id, influencer_id: infliencerReviewData?.[0]?.influencer_id })).then((res) => {
	// 		if (res?.payload?.data?.success === false) {
	// 			Swal.fire("Something Wrong!", "Your input is wrong", "error");
	// 			return;
	// 		}
	// 		userInfo.brand = eventId[0]?.brand?._id;
	// 		setBrandID(eventId[0]?.brand?._id);
	// 		userInfo.event = eventId[0];
	// 		userInfo.eventAttributes = eventAttributes;
	// 		localStorage.setItem("userInfo", JSON.stringify(userInfo));

	// 		Notify(list?.data?.price, null, list, onKeyChange, title);

	// 		if (stp2) {
	// 			setStp1(true);
	// 			setStp2(false);
	// 			setStp3(false);
	// 			setClear(false);
	// 		} else if (stp3) {
	// 			setStp1(false);
	// 			setStp2(true);
	// 			setStp3(false);
	// 			setClear(false);
	// 		}

	// 		dispatch(getShopifyCart(eventId[0]?.brand?._id, userInfo?.token)).then((res) => {
	// 			if (res?.payload?.message?.lineItems.length > 0) {
	// 				setStatus(false);
	// 			} else {
	// 				setStatus(true);
	// 			}
	// 			setCart(res?.payload?.message);
	// 		});

	// 		lists_item = [];
	// 		soruce1 = "";
	// 		titles1 = "";
	// 		tag1 = "";
	// 		brand_id1 = "";
	// 		setQty(1);
	// 	});
	// };

	const addToBag = async (src, title, tag, clear, list, status, brand) => {
		setStp1(false);
		setStp2(true);
		//setStp3(true);
		setClear(true);
		soruce1 = src;
		titles1 = title;
		tag1 = tag;

		if (list?.length > 1) {
			list?.selectedOptions.map((item) => {
				lists_item.push({
					name: item.name,
					value: item.value,
				});
			});
		}
		let prodId = list.hasOwnProperty("variants") ? list?.variants[0]?.id : list?.id;

		dispatch(addToCart(eventId[0]?.brand[0]?._id, status ? status : checkStatus, quantity, prodId, lists_item, userInfo?.token, title, list)).then((res) => {
			if (res?.payload?.data?.success === false) {
				Swal.fire("Something Wrong!", "Your input is wrong", "error");
				return;
			}
			userInfo.brand = eventId[0]?.brand[0]?._id;
			userInfo.event = eventId[0];
			userInfo.eventAttributes = eventAttributes;
			setBrandID(eventId[0]?.brand[0]?._id);
			localStorage.setItem("userInfo", JSON.stringify(userInfo));

			Notify(list?.data?.price, null, list, onKeyChange, title);

			if (stp2) {
				setStp1(true);
				setStp2(false);
				setStp3(false);
				setClear(false);
			} else if (stp3) {
				setStp1(false);
				setStp2(true);
				setStp3(false);
				setClear(false);
			}
			dispatch(getShopifyCart(brand ? brand : brandId, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
				}
				setCart(res?.payload?.message);
			});

			lists_item = [];
			soruce1 = "";
			titles1 = "";
			tag1 = "";
			brand_id1 = "";
			setQty(1);
		});
	};

	if (!isNullOrUndefined(soruce1) && clear) {
		addBag.push({
			source: soruce1,
			title: titles1,
			price: price1,
		});
	}

	const TabsShopping = () => {
		return (
			<>
				<Tabs className="tabs-stage-one" defaultActiveKey="1" activeKey={activeKey} onChange={onKeyChange}>
					<TabPane tab="Comments" key="1">
						<div className="login-before-chat">
							{chatStatus ? (
								<>{eventId[0]?.event_type === "SCHEDULED_EVENT" ? <Chat currentTimestamp={currentTimestamp} publishEvent={true} activeKey={activeKey} eventId={eventId} /> : <Chatnew activeKey={activeKey} eventId={eventId} />}</>
							) : (
								<>
									<div className="before-login-inner">
										<h1>Login to start comments</h1>
										<Button onClick={(e) => dispatch(openRegister(true))}>Login</Button>
										{/* <Button onClick={(e) => dispatch(openLogin(true))}>Login</Button> */}
									</div>
								</>
							)}
						</div>
					</TabPane>

					<TabPane tab="Details" key="2" activeKey={"3"}>
						<Detail event={eventId} />
					</TabPane>

					<TabPane tab="Shop" key="3">
						<Shop setBrandID={setBrandID} setStatus={setStatus} quantity={quantity} price={price1} setLineItem={setLineItem} addBag={addBag} setQty={setQty} clear={clear} brandId={userInfo?.brand ? userInfo?.brand : brandId} shopifyProduct={shopifyProduct} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} setClear={setClear} liveEventId={liveEventId} userInfo={userInfo} eventId={eventId} lineItem={lineItem} product={product} buy={buy} addToBag={addToBag} />
					</TabPane>

					<TabPane
						tab={
							<div className="basket-icon">
								<span className="cart-count">{cart === undefined ? 0 : cart?.lineItems?.length}</span>
								<img src={basket} />
							</div>
						}
						key="4"
					>
						{successorder ? <ContinueShopping handleSuccessClick={handleSuccessClick} /> : checkout ? <CheckoutStep /> : <Cart page={queryParams?.pageback} onChangeShippingMethod={onChangeShippingMethod} promoCodeForm={<></>} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} brandId={userInfo?.brand ? userInfo?.brand : brandId} setStatus={setStatus} setCart={setCart} setCheckout={setCheckout} cart={cart} />}

						{/* {successorder ? <ContinueShopping handleSuccessClick={handleSuccessClick} /> : checkout ? <CheckoutStep /> : <Cart page={queryParams?.pageback} onChangeShippingMethod={onChangeShippingMethod} promoCodeForm={<PromoCodeForm brandId={userInfo?.brand ? userInfo?.brand : brandId} setCart={setCart} setStatus={setStatus} cart={cart} activeKey={activeKey} eventId={eventId} />} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} brandId={userInfo?.brand ? userInfo?.brand : brandId} setStatus={setStatus} setCart={setCart} setCheckout={setCheckout} cart={cart} />} */}
					</TabPane>
				</Tabs>
			</>
		);
	};

	const CheckoutStep = () => {
		return (
			<>
				<div className="checkout-frame">
					<div className="order-summery">
						<Collapse className="position-relative" accordion defaultActiveKey={["1"]} activeKey={collapseKey} onChange={onCollapseChange}>
							<span className="total-price-top">{numeral(cart?.paymentDueV2?.amount).format("$0,0.0'")}</span>
							<Panel header="Order Summary" key="1">
								<div className="ordered-products">
									<CartItem brandId={userInfo?.brand ? userInfo?.brand : brandId} setStatus={setStatus} setCart={setCart} cart={cart} setCheckout={setCheckout} />
								</div>

								<div className="checkout-discount-section">
									<PromoCodeForm brandId={userInfo?.brand ? userInfo?.brand : brandId} setCart={setCart} setStatus={setStatus} cart={cart} activeKey={activeKey} eventId={eventId} />
								</div>
								<SubTotal brandId={userInfo?.brand ? userInfo?.brand : brandId} setStatus={setStatus} cart={cart} setCart={setCart} />
							</Panel>
							<Panel header="Shipping" key="2" className="accordion-style2">
								<Shipping setMode={setMode} isShipTo={isShipTo} setIsShipTo={setIsShipTo} value={value} onChangeShippingMethod={onChangeShippingMethod} setCart={setCart} setStatus={setStatus} brandId={brandId} setShippingAddressModal={setShippingAddressModal} setShippingAddressId={setShippingAddressId} cart={cart} setCollapseKey={setCollapseKey} />
							</Panel>

							<Panel header="Payment" key="3" className="accordion-style2 bg-white">
								<Payment setEventAttributes={setEventAttributes} page={"paymentBack"} eventAttributes={eventAttributes} eventId={eventId} brandId={userInfo?.brand ? userInfo?.brand : brandId} setSuccessOrder={setSuccessOrder} setStatus={setStatus} setCart={setCart} />
							</Panel>
						</Collapse>

						<div className="customer-information"></div>
					</div>
				</div>
			</>
		);
	};

	const onChangeShippingMethod = (e, handled) => {
		var handle = handled ? handled : e.target.value;
		setValue(handle);
		dispatch(setShippingRates(userInfo?.brand ? userInfo?.brand : brandId, handle, userInfo?.token)).then((res) => {
			dispatch(getShopifyCart(userInfo?.brand ? userInfo?.brand : brandId, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
				}
				setCart(res?.payload?.message);
			});
		});
	};

	const resetCart = () => {
		if (queryParams?.origin === "home") {
			navigate("/");
		} else if (queryParams?.origin === "influencers") {
			navigate(`/influencers`);
		} else if (queryParams?.origin === "bioshop-review") {
			navigate(`/${queryParams?.username}`);
		} else if (queryParams?.origin === "bioshop") {
			navigate(`/brand-events/${queryParams?.username}`);
		} else {
			navigate("/");
		}
		//  });
	};

	const [disableButton, setDisableButton] = useState(false);
	const slider = useRef();
	const [slideIndex, setSlideIndex] = useState(0);

	const goUp = () => {
		const nextVideoId = infliencerReviewData?.find((item, key) => key === slideIndex - 1);

		const goUpInner = () => {
			setDisableButton(true);
			setDisableNext(false);
			setLineItem([]);
			handleSteps();
			if (nextVideoId?._id) {
				fetchVideoData();
				handleChatAndLike();
				handlePagination();
			}

			adjustSlideIndex();
			setTimeout(handleVideoPausePlay, 500);
		};

		const handleSteps = () => {
			if (stp2) {
				setStp1(true);
				setStp2(false);
				setStp3(false);
			} else if (stp3) {
				setStp1(false);
				setStp2(true);
				setStp3(false);
			}
			setClear(false);
		};

		const handleVideoPausePlay = () => {
			const currentVideo = document.getElementById(`video-${slideIndex}`);
			const nextVideo = document.getElementById(`video-${slideIndex - 1}`);
			currentVideo?.pause();
			if (nextVideo?.paused) {
				nextVideo.play();
			}
			setDisableButton(false);
		};

		const adjustSlideIndex = () => {
			if (slideIndex !== 0) {
				setSlideIndex(slideIndex - 1);
			}

			const key = infliencerReviewData.findIndex((item) => item?._id === nextVideoId?._id);

			setSlideIndex(key);
			slider.current.slickGoTo(key);
		};

		const fetchVideoData = () => {
			dispatch(getPreviews(nextVideoId?._id, userInfo?.token)).then(() => {
				dispatch(eventViews(nextVideoId?._id));
			});

			dispatch(getEventsBy_Id(nextVideoId?._id, userInfo?._id)).then((res) => {
				setEvent(res?.payload?.message);
				setBrandID(res?.payload?.message[0]?.brand[0]?._id);
				setVideoStartTime(new Date(res?.payload?.message[0]?.started_at).getTime());
				setCurrentTimestamp(new Date(res?.payload?.message[0]?.started_at).getTime());
			});
		};

		const handleChatAndLike = () => {
			if (userInfo?._id) {
				setChatStatus(true);
				dispatch(getLike(nextVideoId?._id)).then((res) => {
					setLike(res?.payload);
				});

				dispatch(getSaveAction(nextVideoId?._id)).then((res) => {
					if (res?.payload) setIsSaved(res?.payload?.is_saved);
				});
			}
		};

		const handlePagination = () => {
			if (previous) {
				addPages(previousPage);
				setPrevious("");
				setNext("");
				if (!usedPages.includes(previousPage)) {
					let params = [previousPage, limit, getQueryParamsData("department"), getQueryParamsData("category"), null, queryParams.origin !== "recorded" && queryParams.brand, queryParams.sortBy, null, null, userInfo?._id];
					let params1 = [previousPage, limit, false, userInfo?._id];

					dispatch(smartEventFetch(params, params1)).then((res) => {
						setInfluencerReviewData([...res?.payload?.message?.data, ...infliencerReviewData]);
						setSecondLoad(nextVideoId?._id);

						if (res?.payload?.message?.next) {
							setNext(res?.payload?.message?.next);
							setNextPage(res?.payload?.message?.next?.page);
						}

						if (res?.payload?.message?.previous) {
							setPrevious(res?.payload?.message?.previous);
							setPreviousPage(res?.payload?.message?.previous?.page);
						}
					});
				}
			}
		};
		goUpInner();
	};

	const goDown = () => {
		const isAtLastSlide = slideIndex === infliencerReviewData.length - 1;
		const nextSlideIndex = slideIndex + 1;
		const nextVideoId = infliencerReviewData[nextSlideIndex]?._id;

		const handleStepsTransition = () => {
			if (stp2) {
				setStp1(true);
				setStp2(false);
			} else if (stp3) {
				setStp1(false);
				setStp2(true);
			}
			setStp3(false);
			setClear(false);
		};

		const handleVideoPlayPause = () => {
			const prevVideo = document.getElementById(`video-${slideIndex}`);
			const nextVideo = document.getElementById(`video-${nextSlideIndex}`);

			prevVideo?.pause();
			if (nextVideo?.paused) {
				nextVideo.play();
			}
		};

		const fetchDataAndUpdate = (videoId) => {
			dispatch(getPreviews(videoId, userInfo?.token));
			dispatch(eventViews(videoId));
			dispatch(getEventsBy_Id(videoId, userInfo?._id)).then((res) => {
				setEvent(res?.payload?.message);
				setBrandID(res?.payload?.message[0]?.brand[0]?._id);
			});
			dispatch(getLike(videoId)).then((res) => {
				setLike(res?.payload);
			});

			dispatch(getSaveAction(videoId)).then((res) => {
				if (res?.payload) setIsSaved(res?.payload?.is_saved);
			});
		};

		const goDownInner = () => {
			setDisableButton(true);
			setLineItem([]);

			handleStepsTransition();

			setTimeout(() => {
				handleVideoPlayPause();
				setDisableButton(false);
			}, 500);

			let lastRow = infliencerReviewData[infliencerReviewData.length - 2];
			if (lastRow?._id === nextVideoId) {
				setDisableNext(true);
			}

			if (next) {
				addPages(nextPage);
				if (!usedPages.includes(nextPage)) {
					let params = [nextPage, limit, getQueryParamsData("department"), getQueryParamsData("category"), null, queryParams.origin !== "recorded" && queryParams.brand, queryParams.sortBy, null, null, userInfo?._id];
					let params1 = [nextPage, limit, false, userInfo?._id];
					dispatch(smartEventFetch(params, params1)).then((res) => {
						setLastRow(res?.payload?.message?.data[res?.payload?.message?.data.length - 1]);
						const newData = [...infliencerReviewData, ...res?.payload?.message?.data];
						setInfluencerReviewData(newData);
						setPrevious(res?.payload?.message?.previous);
						setPreviousPage(res?.payload?.message?.previous?.page);
						setNext(res?.payload?.message?.next);
						setNextPage(res?.payload?.message?.next?.page);
						if (!res?.payload?.message?.next) setNext("");
					});
				}
			}

			const key = infliencerReviewData?.findIndex((item) => item?._id === nextVideoId);
			setSlideIndex(key);
			slider.current.slickGoTo(key);
			//			slider.current.slickNext();

			if (nextVideoId) {
				fetchDataAndUpdate(nextVideoId);
			}

			if (!isAtLastSlide) {
				setSlideIndex(nextSlideIndex);
			}
		};

		goDownInner();
	};

	function LeftNavButton({ style }) {
		return (
			<button disabled={disableButton} onClick={goUp} style={{ ...style, display: "block" }} type="button" data-role="none" className={`slick-arrow slick-prev ${slideIndex === 0 && "slick-disabled"}`}>
				Previous
			</button>
		);
	}

	function RightNavButton({ style }) {
		return (
			<button disabled={disableButton} onClick={goDown} style={{ ...style, display: "block" }} type="button" data-role="none" className={`slick-arrow slick-next ${disableNext ? "slick-disabled" : ""}`}>
				Next
			</button>
		);
	}

	const onSwipeEvent = (swipeDirection) => {
		if (swipeDirection === "up") {
			goDown();
		} else {
			goUp();
		}
	};

	const influencerreviewsslider = {
		className: "slider review-slider-height",
		dots: true,
		arrows: true,
		infinite: false,
		nextArrow: <RightNavButton />,
		prevArrow: <LeftNavButton />,
		centerMode: false,
		slidesToShow: 1,
		vertical: true,
		speed: 200, // try adjusting this value
		verticalSwiping: true,
		slidesToScroll: 1,
		initialSlide: infliencerReviewData?.findIndex((item) => item?._id == event_id),
	};

	return (
		<>
			<div ref={ref} className={`horizontal-camera-view ${eventId[0]?.orientation}`}>
				<Layout>
					<Layout className="site-layout">
						<Content
							className="site-layout-background"
							style={{
								margin: 0,
								padding: 0,
								minHeight: "calc(100vh - 40px)",
							}}
						>
							<div className="infl-rev-main-container infl-rev-new-layout">
								<div className="rev-container">
									<div className="rev-video-container">
										<div className="rev-video-box-main">
											{infliencerReviewData?.length && (
												<Slider onSwipe={onSwipeEvent} ref={(c) => (slider.current = c)} {...influencerreviewsslider}>
													{infliencerReviewData.map((item, index) => {
														return <Video videoStartTime={videoStartTime} currentTimestamp={currentTimestamp} setCurrentTimestamp={setCurrentTimestamp} slideIndex={slideIndex} emptyCart={resetCart} onKeyChange={onKeyChange} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} setCollapsed={setCollapsed} collapsed={collapsed} index={index} item={item} id={item?.id} like={like} isSaved={isSaved} setIsSaved={setIsSaved} setLike={setLike} />;
													})}
												</Slider>
											)}
										</div>
									</div>
								</div>
							</div>
						</Content>
					</Layout>
					<Sider
						trigger={null}
						collapsible
						collapsed={collapsed}
						breakpoint="lg"
						collapsedWidth="0"
						onBreakpoint={(broken) => {
							console.log(broken);
						}}
						onCollapse={(collapsed, type) => {
							console.log(collapsed, type);
						}}
					>
						<div className="slide-pannel-new">
							{!collapsed && (
								<span className="close-slide-panel" onClick={() => setCollapsed(!collapsed)}>
									<i className="fa fa-angle-right" aria-hidden="true"></i>
								</span>
							)}
							{window.innerWidth > 768 && TabsShopping()}
						</div>
					</Sider>
				</Layout>
			</div>
			{height && (
				<div className="mobile-checkout-panel" style={{ paddingTop: height, height: `calc(100vh - 0px)` }}>
					{window.innerWidth <= 768 && TabsShopping()}
				</div>
			)}

			<Modal
				dialogClassName="modal-shipping-from-front"
				size="md"
				scrollable
				show={shippingAddressModal}
				onHide={() => {
					setMode("");
					setShippingAddressModal(false);
				}}
				centered
			>
				<span
					className="close-shipping-front"
					onClick={() => {
						setMode("");
						setShippingAddressModal(false);
					}}
				>
					<img src={closeicon} className="filter-gray" />
				</span>
				<Modal.Body>
					<ShippingAddressModal isShipTo={isShipTo} updateShop={updateShop} setUpdateShop={setUpdateShop} page={"review"} mode={mode} setCart={setCart} shippingAddressId={shippingAddressId} brandId={brandId} edit={true} setStatus={setStatus} />
				</Modal.Body>
			</Modal>
		</>
	);
};

export default connect(null, chatActions)(PublishedContainer);
