// React and third-party library imports
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import Swal from 'sweetalert2';
// Internal imports (e.g., actions and assets)
import { deleteAccount } from '../../../redux/actions/account.action';
import UserRemove from '../../../images/delete-account-icon.png';

// The DeleteAccount component provides UI for deleting a user's account.
export default function DeleteAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Retrieve user information from local storage.
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // Use the useSelector hook to access the Redux store state.
  const { account } = useSelector((state) => state);

  // Capitalize the first letter of each word in a string.
  const capitalizeWords = (str) => {
    if (!str) return '';
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  // Retrieve the user's name and capitalize it.
  const str = account?.payload?.Data?.name;
  const capitalizedUserName = capitalizeWords(str);

    // Add classes to the body element on component mount.
    useEffect(() => {
      document.body.classList.add('bioshop-body', 'shopper-bioshop-body');
      return () => {
        // Remove classes from the body element on component unmount.
        document.body.classList.remove('bioshop-body', 'shopper-bioshop-body');
      };
    }, []);


  // Handle the account reset confirmation with a SweetAlert modal.
  const handleReset = () => {
    // Generate HTML content for the SweetAlert modal.
    const profileImageHtml = account?.payload?.Data?.profile_image_url
      ? `<img src="${account.payload.Data.profile_image_url}" style="max-width: 100px; display: block; margin: 0 auto;">`
      : `<i className="fa fa-user-circle-o" aria-hidden="true" style="font-size: 4em; display: block; margin: 10 auto;"></i>`;

    const userNameHtml = str
      ? `<p style="text-align: center; margin-top: 20px;">${capitalizedUserName}</p>`
      : '';

    const mainTextHtml = `Are You Sure You Want to Delete Your Account? You Won't be Able To Revert This!`;

    const combinedHtml = `${profileImageHtml}${userNameHtml}<p>${mainTextHtml}</p>`;

    // Display the SweetAlert modal.
    Swal.fire({
      title: '',
      html: combinedHtml,
      cancelButtonText: 'Cancel',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#010b40',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };

  // Dispatch the deleteAccount action and handle the response.
  const handleDelete = () => {
    dispatch(deleteAccount(userInfo?.token, userInfo?._id)).then(() => {
      localStorage.removeItem('userInfo');
      window.location.reload(false);
      navigate(`/`);
    });
  };



  // Render the DeleteAccount component UI.
  return (
    <div className="account-left-content">
      <div className="favourite-brands-container h-100 d-flex align-items-center justify-content-center">
        <div className="new-dash-content  ml-0 p-0">
          <div className="container ml-0 p-0">
            <div className="row dash-boxes text-center">
              <div className="col-12 mb-3">
                <img className="mb-4" src={UserRemove} alt="Remove" width={90} />
                <h2 className="delete-hd">
                  Are You Sure You Want to Delete Your Account?
                </h2>
                <p>
                  This Action is Not Reversible And it Will Remove All Your Data From Our Servers
                </p>
                <p className="support-txt">
                  For Support Please Contact{' '}
                  <a href="mailto:suport@ormelive.com">support@ormelive.com</a>
                </p>
                <Button onClick={handleReset} className="delete-btn">
                  Yes Delete My Account
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
