import avatarIcon from "../../../images/avatar.png";
import { Empty } from "antd";
let userInfo = localStorage.getItem("userInfo");
export default function Detail({ event }) {
  return (
    <>
      <div className="brand-detail">
        <div className="author-name">
          {/* {console.log(event[0]?.influencer?.profile_image_url , 'abc')} */}
          <span className="auth-image">{event && event[0]?.influencer?.profile_image_url ? <img alt="" src={event && event[0]?.influencer?.profile_image_url} /> : <img className="img-fluid" src={avatarIcon} />}</span>
          <div className="auth-detail">
            <span className="auth-title">{event && event[0]?.title}</span>
            <span className="event-name">by {event[0]?.influencer?.name ? event[0]?.influencer?.name : event[0]?.brand?.brand_name}</span>
          </div>
        </div>
      </div>
      {event && event[0]?.notes ? (
        <div className="brand-description">
          <p>{event && event[0]?.notes}</p>
        </div>
      ) : (
        <div className="align-items-center justify-content-center brand-description">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </>
  );
}
