import {
    POST_SAVE_REVIEWS_ERROR,
    POST_SAVE_REVIEWS_REQUEST, POST_SAVE_REVIEWS_SUCCESS,
  } from "../../types/types";

  
  
  export default function savePost(state = "", action) {
    const { type, payload } = action;
    switch (type) {
      case POST_SAVE_REVIEWS_REQUEST:
        return {
          loading: true,
          success: true,
        };
      case POST_SAVE_REVIEWS_SUCCESS:
        return {
          ...state,
          loading: false,
          success: false,
          payload: payload,
        };
  
      case POST_SAVE_REVIEWS_ERROR:
        return {
          ...state,
          loading: false,
          success: false,
          payload: payload,
        };
  
      default:
        return state;
    }
  }
  