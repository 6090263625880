import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Spin, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { openPasswordModal } from "../../../redux/actions/changePassword.action";
import { getAccount, updateAccount } from "../../../redux/actions/account.action";
import placeholderImage from "../../../images/placeholder-image.jpg";
import { basicProfileImage } from "../../../redux/actions/profileImageBasic.action";
import Swal from "sweetalert2";
import ChangePassword from "../../../pages/auth/changePassword";
import "../../../css/account-setup.scss";
import { useNavigate } from "react-router-dom";
import PasswordSetting from "../../../components/passwordSetting/passwordSetting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default () => {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const dispatch = useDispatch();
	const { account, accountUpdate } = useSelector((state) => state);

	const [image, setImage] = useState([]);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const onFinish = async (values) => {
		if (userInfo?.token) {
			dispatch(updateAccount({ ...values, token: userInfo?.token })).then(() => {
				Swal.fire({
					icon: "success",
					title: "Profile updated!",
				});
				dispatch(getAccount(userInfo?.token)).then(() => {
					navigate("/account-settings");
				});
			});
		}
	};

	const onFinishFailed = (errorInfo) => {};

	useEffect(() => {
		dispatch(openPasswordModal(false));
		//getCountryFunc();
	}, []);

	useEffect(() => {
		if (userInfo?.token && account?.length === 0) dispatch(getAccount(userInfo?.token));
	}, []);

	useEffect(() => {
		if (account) {
			form.setFieldsValue({
				name: account?.payload?.Data?.name,
				gender: account?.payload?.Data?.gender,
				country: account?.payload?.Data?.country,
				state: account?.payload?.Data?.state,
				city: account?.payload?.Data?.city,
				account_num: account?.payload?.Data?.pixel_id,
				zip: account?.payload?.Data?.zip,
				nick_name: account?.payload?.Data?.nick_name,
				first_name: account?.payload?.Data?.first_name,
				last_name: account?.payload?.Data?.last_name,
				email: account?.payload?.Data?.email,
				phone: account?.payload?.Data?.phone,
			});
		}
	}, [account]);

	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	}, []);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	const clearImage = () => {
		setImage([]);
	};

	const uploadImage = async () => {
		setLoading(true);
		var formData = new FormData();
		formData.append("image", image?.[0]);
		formData.append("instagram_username", form.getFieldValue().username);
		dispatch(basicProfileImage(formData))
			.then((res) => {
				if (res.success) {
					// dispatch(getUserInfo()).then((res) => {

					// });
					dispatch(getAccount(userInfo.token)).then(() => {
						setImage([]);
						notification.success({
							message: "Image Has Been Uploaded!",
							className: "toast-success",
						});
						setLoading(false);
					});
				}
			})
			.catch((err) => {
				setLoading(false);
				setImage([]);
			});
	};

	const onChangeInputImage = (e) => {
		if (e.target.files.length === 1) {
			if (e.target.files[0]?.type === "image/jpeg" || e.target.files[0]?.type === "image/webp" || e.target.files[0]?.type === "image/png" || e.target.files[0]?.type === "image/svg+xml") {
				const files = [];
				const reader = new FileReader();
				files.push(e.target.files[0]);
				reader.onloadend = () => {
					files[0].preview = reader.result;
					files[0].toUpload = true;
					setImage(files);
				};
				reader.readAsDataURL(e.target.files[0]);
			} else {
				notification.error({
					message: "We Only Support PNG, WEBP, Or JPG Image!",
					className: "toast-error",
				});
			}
		}
	};

	return (
		<>
			<div className="account-left-content">
				<div className="">
					<Button
						className="back-button mb-3"
						onClick={() => {
							navigate("/account-settings");
						}}
					>
						<FontAwesomeIcon icon={faAngleLeft} /> Back
					</Button>
				</div>
				<div className="account-detail col-12 col-xl-6 col-lg-12 col-md-12">
					<div className="pr-op-block">
						<div className="user-profile-image">
							<div className="prof-img">
								<span className="profile-pic">
									<img alt="..." src={image?.[0]?.preview ? image?.[0]?.preview : account?.payload?.Data?.profile_image_url ? account?.payload?.Data?.profile_image_url : placeholderImage} style={{ width: "76px", height: "76px" }} className="circle profile-icon" />
								</span>
								<span className="profile-name mt-1">Profile Image</span>
							</div>
							<div className="porf-btns">
								<input accept=".jpg, .jpeg, .png, .webp, .gif" onChange={onChangeInputImage} onClick={(e) => (e.target.value = null)} id="fileupload5" type="file" name="file" className="d-none" />
								<label htmlFor="fileupload5">Change Image</label>
								{loading ? (
									<Button>
										<Spin indicator={antIcon} />
									</Button>
								) : (
									<Button onClick={uploadImage} disabled={image.length > 0 ? false : true} loading={loading}>
										Save
									</Button>
								)}

								<Button onClick={clearImage} disabled={image.length > 0 ? false : true} type="button" color="default">
									Cancel
								</Button>
							</div>
						</div>

						<Form layout={"vertical"} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
							<div className="profile-form-main">
								<div className="profile-form-col">
									<Form.Item label="Account Number" name="account_num">
										<Input disabled placeholder="" size="large" />
									</Form.Item>
								</div>

								<div className="profile-form-col">
									<Form.Item
										label="First Name"
										name="first_name"
										rules={[
											{
												required: true,
												message: "Please input your First name",
											},
										]}
									>
										<Input placeholder="" size="large" />
									</Form.Item>
								</div>
								<div className="profile-form-col">
									<Form.Item
										label="Last Name"
										name="last_name"
										rules={[
											{
												required: true,
												message: "Please input your Last name",
											},
										]}
									>
										<Input placeholder="" size="large" />
									</Form.Item>
								</div>

								<div className="profile-form-col">
									<Form.Item
										label="Email"
										name="email"
										className="mb-1"
										rules={[
											{
												type: "email",
												required: true,
												message: "Please Enter Email",
											},
										]}
									>
										<Input placeholder="" size="large" />
										
									</Form.Item>
									<div className="verify-email-area d-flex justify-content-start align-items-center">
											{account?.payload?.Data?.email_verified ? <FontAwesomeIcon className="verify-icon" icon={faCheckCircle} /> : <FontAwesomeIcon className="verify-icon red" icon={faCircleXmark} />}
											<div className="verify-text">Email Verified</div>
										</div>
								</div>
								<div className="profile-form-col">
									<Form.Item
										label="Telephone Number"
										name="phone"
										rules={[
											{
												type: "phone",
												required: true,
												message: "Please Enter Email",
											},
										]}
									>
										<Input disabled maxLength={12} placeholder="" size="large" />
									</Form.Item>
								</div>

								<div className="profile-form-button">
									{accountUpdate.loading ? (
										<Form.Item>
											<Button>
												<Spin indicator={antIcon} />
											</Button>
										</Form.Item>
									) : (
										<Form.Item>
											<Button htmlType="submit">Save</Button>
										</Form.Item>
									)}
									<Form.Item>
										<Button
											onClick={() => {
												navigate("/account-settings");
												dispatch(openPasswordModal(false));
											}}
											htmlType="submit"
										>
											Cancel
										</Button>
									</Form.Item>
								</div>
							</div>
						</Form>
					</div>
					<PasswordSetting />
				</div>
			</div>
			<ChangePassword />
		</>
	);
};
