import { GET_DASHBOARD_REQUEST, 
  GET_DASHBOARD_SUCCESS, 
  GET_DASHBOARD_ERROR, 
  GET_DASHBOARD_EVENT_REQUEST, 
  GET_DASHBOARD_EVENT_SUCCESS, 
  GET_DASHBOARD_EVENT_ERROR,
  GET_DASHBOARD_SOCIAL_REQUEST,
  GET_DASHBOARD_SOCIAL_SUCCESS,
  GET_DASHBOARD_SOCIAL_ERROR,
 } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export const getDashboard = (st_date, end_date) => (dispatch) => {
  dispatch({ type: GET_DASHBOARD_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}user/dashboard/receive/socialstore`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      from_date: st_date,
      to_date: end_date,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_DASHBOARD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_DASHBOARD_ERROR,
        payload: error.response,
      });
    });
};

export const getDashboardEvent = (st_date, end_date, token) => (dispatch) => {
  dispatch({ type: GET_DASHBOARD_EVENT_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}customer/reports/dashboard/getdashboard`, // user/dashboard/receive/customersocialstore
    headers: { Authorization: `Bearer ${token}` },
    data: {
      from_date: st_date,
      to_date: end_date,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_DASHBOARD_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_DASHBOARD_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const getDashboardSocial = (st_date, end_date, token) => (dispatch) => {
  dispatch({ type: GET_DASHBOARD_SOCIAL_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}user/dashboard/receive/customersocialstore`, 
    headers: { Authorization: `Bearer ${token}` },
    data: {
      from_date: st_date,
      to_date: end_date,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_DASHBOARD_SOCIAL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_DASHBOARD_SOCIAL_ERROR,
        payload: error.response,
      });
    });
};
