import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { DatePicker, Spin, Select, Empty } from "antd";
import { getOne } from "../../../redux/actions/wallet.action";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import numeral from "numeral";
const { Option } = Select;

const { RangePicker } = DatePicker;

export default () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  // const [totalEvent, setTotalEvent] = useState();
  const [totalCount , setTotalCount] = useState()
  const [currentPage, setCurrentPage] = useState(0);

  const { walletgetOne } = useSelector((state) => {
    return state;
  });

  const limit = 15;
  const upperLimit = (currentPage + 1) * limit;

  useEffect(() => {
    setLoading(true);
    dispatch(getOne(userInfo?.token, 1, limit)).then((res) => {
      setLoading(false);
    });
  }, []);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   setCurrentPage(0);
  //   dispatch(
  //     customerorderHistory(
  //       1,
  //       limit,
  //       source,
  //       startDate,
  //       endDate,
  //       category,
  //       genderCategory,
  //       Subcategory,
  //       brand,
  //       groupBy
  //     )
  //   ).then((res) => {
  //     setLoading(false);
  //     setTotalEvent(res?.payload?.message?.total_records);
  //   });
  //   setSubmit(groupBy);
  // };

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(getOne(userInfo?.token, page + 1, limit)).then((res) => {
      setLoading(false);
      setTotalCount(res?.payload?.totalCount);
    });
  };

  useEffect(() => {
    document.body.classList.add("bioshop-body", "shopper-bioshop-body");
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      //  componentWillUnmount.current = true;
      document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
    };
  }, []);

  function DefaultTable() {
    let data = walletgetOne?.payload?.data;
    if (data) {
      return (
        <>
          <Table responsive className="transactions-box table">
            <thead className="table_heading">
              <tr>
                <th>S.#</th>
                <th>Date</th>
                <th>Withdraw Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {walletgetOne?.loading ? (
                <div className="antd-loading-ift">
                  <Spin size="large"></Spin>
                </div>
              ) : data?.length === 0 ? (
                <tr>
                  <td colspan="12">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </td>
                </tr>
              ) : (
                data.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1 + currentPage * 15}</td>
                      <td>{moment(item?.created_date).format("MM-DD-YYYY")}</td>

                      {item?.withdraw_amount ? (
                        <td>${numeral(item?.withdraw_amount).format('0')}</td>
                      ) : (
                        <td>{"-"}</td>
                      )}
                      <td>{item?.status ? item?.status : "-"}</td>
                    </tr>
                  );
                })
              )}

              {walletgetOne?.payload?.data?.length > 0 && (
                <tr>
                  <td>
                    <b>Total</b>
                  </td>
                  <td>
                    {" "}
                    <b>
                      {walletgetOne?.payload?.data?.length + currentPage * 15}
                    </b>
                  </td>
                  <td>
                    <b>
                      {" "}
                      {numeral(
                        data.reduce((a, b) => {
                          return (
                            Number(a) +
                            Number(b.withdraw_amount ? b.withdraw_amount : 0.0)
                          );
                        }, 0)
                      ).format("$0")}
                    </b>
                  </td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      );
    }
  }

  return (
    <>
      <div className="account-left-content">
        <div className="purchase-content-main ">
          {loading ? (
            <div className="antd-loading-ift">
              <tr>
                <Spin size="large" />
              </tr>
            </div>
          ) : (
            <>
              {!walletgetOne?.payload?.data?.length === 0 ? (
                <tr>
                  <td colspan="5">
                    <Empty
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  </td>
                </tr>
              ) : (
                <>
                  <div className="purchase-data referral-data">
                    <DefaultTable />
                    {walletgetOne?.payload?.data?.length ? (
                      <ReactPaginate
                        nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                        pageCount={Math.ceil(walletgetOne?.payload?.totalCount / limit)}
                        forcePage={currentPage}
                        previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                        pageClassName="page-item d-none"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item d-none"
                        breakLinkClassName="page-link"
                        containerClassName={"pagination custom-paginate"}
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )}
                    <br></br>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
