import Layout from "./component/layout";
import Interest from "./component/myInterest";
export default () => {
	return (
		<>
			<Layout title="My Interest">
				<Interest />
			</Layout>
		</>
	);
};