import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import numeral from "numeral";
import Swal from "sweetalert2";
import { useParams, useNavigate } from "react-router-dom";
import {
  getFollowers,
  statusFollowers,
  makeFollowers,
  unfollow,
  followingUser,
} from "../../redux/actions/followers.action";
import { openRegister } from "../../redux/actions/register.action";
import FollowerModal from "../../components/follower/followerModal";
import avatarIcon from "../../images/avatar.png";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const FollowerSection = ({ bioShopMenu, isBrand }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const { influencerProf, countLiked } = useSelector((state) => {
    return state;
  });

  // States
  const [followStatus, setFollowStatus] = useState(false);
  const [followers, setFollowers] = useState(0);
  const [followerData, setFollowerData] = useState([]);
  const [following, setFollowing] = useState(0);
  const [followingData, setFollowingData] = useState([]);
  const [followerModal, setFollowerModal] = useState(false);
  const [followerTitle, setFollowerTitle] = useState("Followers");
  const [modalData, setModalData] = useState([]);
  //fetchMore
  const [hasMore, sethasMore] = useState(true);
  const [pageFollower, setPageFollower] = useState(2);
  const [pageFollowing, setPageFollowing] = useState(2);

  useEffect(() => {
    const id = bioShopMenu?.payload?.message?.data?._id;
    dispatch(getFollowers(id, userInfo?._id)).then((res) => {
      setFollowers(res?.payload?.followers);
      setFollowerData(res?.payload?.followerData);
    });
    dispatch(followingUser(id, userInfo?._id)).then((res) => {
      setFollowing(res?.payload?.following);
      setFollowingData(res?.payload?.data);
    });
    if (userInfo) {
      dispatch(statusFollowers(id, userInfo?._id)).then((res) =>
        setFollowStatus(res?.payload?.status)
      );
    }
  }, [params.username]);

  const unFollow = (
    currentUserId,
    targetUserId,
    followType,
    targetUserName,
    targetUserImage,
    mainType
  ) => {
    let title =
      bioShopMenu?.payload?.message?.data?._id === targetUserId &&
        followerTitle === "Followers"
        ? "Remove"
        : "UnFollow";

    // Configuration for Swal modal
    const swalConfig = {
      customClass: {
        container: "followModal",
      },
      title: `${title} @${targetUserName}?`,
      showCancelButton: true,
      confirmButtonText: title,
      cancelButtonText: "Cancel",
      confirmButtonColor: "#010b40",
      cancelButtonColor: "#d33",
      iconHtml: targetUserImage
        ? `<img  src=${targetUserImage} />`
        : `<img  className="img-fluid" src=${avatarIcon} />`,
      imageAlt: "profile image",
      reverseButtons: false,
    };

    Swal.fire(swalConfig).then((result) => {
      if (result.isConfirmed) {
        let currentUser = currentUserId;
        let targetUser = targetUserId;
        if (
          bioShopMenu?.payload?.message?.data?._id === targetUserId &&
          followerTitle === "Followers"
        ) {
          currentUser = targetUserId;
          targetUser = currentUserId;
        }

        dispatch(unfollow(currentUser, targetUser)).then(() => {
          // If mainType is 'main', set FollowStatus to false
          if (mainType === "main") {
            setFollowStatus(false);
          }

          // Update state based on the followType passed to the function
          if (followType === "follower") {
            dispatch(
              getFollowers(
                bioShopMenu?.payload?.message?.data?._id,
                userInfo?._id
              )
            ).then((res) => {
              setFollowers(res?.payload?.followers);
              setFollowerData(res?.payload?.followerData);
              setModalData(res?.payload?.followerData);
            });
          }

          if (followType === "following") {
            dispatch(
              followingUser(
                bioShopMenu?.payload?.message?.data?._id,
                userInfo?._id
              )
            ).then((res) => {
              setFollowing(res?.payload?.following);
              setFollowingData(res?.payload?.data);
              setModalData(res?.payload?.data);
            });
          }
        });
      }
    });
  };

  const executeFollowAction = (
    currentUserId,
    targetUserId,
    mainFollowType,
    followDetailType
  ) => {
    // Check if there is user information. If not, prompt to register.
    if (!userInfo) {
      dispatch(openRegister(true));
      return false;
    }

    // Make the current user follow the target user.
    dispatch(makeFollowers(currentUserId, targetUserId)).then(() => {
      // If mainFollowType is 'main', set FollowStatus to true.
      if (mainFollowType === "main") {
        setFollowStatus(true);
      }

      // Update state based on the followDetailType.
      if (followDetailType === "following") {
        dispatch(
          followingUser(bioShopMenu?.payload?.message?.data?._id, userInfo?._id)
        ).then((res) => {
          setFollowing(res?.payload?.following);
          setFollowingData(res?.payload?.data);
          setModalData(res?.payload?.data);
        });
      }

      if (followDetailType === "follower") {
        dispatch(
          getFollowers(bioShopMenu?.payload?.message?.data?._id, userInfo?._id)
        ).then((res) => {
          setFollowers(res?.payload?.followers);
          setFollowerData(res?.payload?.followerData);
          setModalData(res?.payload?.followerData);
        });
      }
    });

    return false;
  };

  const fetchMorePost = async () => {
    if (!hasMore) return;
    let result;
    const id = bioShopMenu?.payload?.message?.data?._id;
    const userId = userInfo?._id;

    if (followerTitle === "Following") {
      result = await dispatch(followingUser(id, userId, pageFollowing));
      if (result?.payload?.data) {
        sethasMore(followingData?.length < following ? true : false);
        return result.payload.data;
      }
    }

    if (followerTitle === "Followers") {
      result = await dispatch(getFollowers(id, userId, pageFollower));
      if (result?.payload?.followerData) {
        sethasMore(followerData?.length < followers ? true : false);
        return result.payload.followerData;
      }
    }
  };

  const fetchMoreData = async () => {
    const postData = await fetchMorePost();
    if (!postData) return;

    const updateState = (data, setter, pageSetter) => {
      const newData = [...data, ...postData];
      setModalData(newData);
      setter(newData);
      pageSetter((prev) => prev + 1);
    };

    if (followerTitle === "Followers")
      updateState(followerData, setFollowerData, setPageFollower);
    if (followerTitle === "Following")
      updateState(followingData, setFollowingData, setPageFollowing);
  };

  return (
    <>
      <div className="brand-followers d-flex align-items-center">
        <span
          onClick={() => {
            if (followers > 0) {
              setFollowerModal(true);
              setModalData(followerData);
              setFollowerTitle("Followers");
            }
          }}
        >
          <strong>{numeral(followers && followers).format("0a")}</strong>{" "}
          Followers
        </span>
        {!isBrand && (
          <span
            onClick={() => {
              if (following > 0) {
                setFollowerModal(true);
                setModalData(followingData);
                setFollowerTitle("Following");
              }
            }}
          >
            <strong>{numeral(following && following).format("0a")}</strong>{" "}
            Following
          </span>
        )}

        {countLiked?.payload?.message && countLiked?.payload?.message !== "0" ? (
          <>
            <span>
              <strong>{countLiked?.payload?.message} </strong>
               Likes
            </span>
          </>
        ) :
          <></>}

        <span className="btn-style-small">
          {followStatus ? (
            <Button
              onClick={() => {
                unFollow(
                  bioShopMenu?.payload?.message?.data?._id,
                  userInfo?._id,
                  "follower",
                  bioShopMenu?.payload?.message?.data?.name,
                  bioShopMenu?.payload?.message?.data?.profile_image_url,
                  "main"
                );
              }}
              className="primary-btn"
            >
              Following
            </Button>
          ) : (
            bioShopMenu?.payload?.message?.data?._id !== userInfo?._id && (
              <Button
                onClick={() => {
                  executeFollowAction(
                    bioShopMenu?.payload?.message?.data?._id,
                    userInfo?._id,
                    "main",
                    "follower"
                  );
                }}
                className="primary-btn"
              >
                Follow
              </Button>
            )
          )}
        </span>
      </div>
      <FollowerModal
        hasMore={hasMore}
        fetchMoreData={fetchMoreData}
        title={followerTitle}
        show={followerModal}
        handleClose={() => setFollowerModal(false)}
        modalData={modalData}
        executeFollowAction={executeFollowAction}
        unFollow={unFollow}
        bioShopMenu={bioShopMenu}
        userInfo={userInfo}
      />
    </>
  );
};

export default FollowerSection;
