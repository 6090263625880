import Layout from "./component/layout";
import AccountSettings from "./component/accountSettings";

export default () => {
	return (
		<>
			<Layout title="Account Settings">
				<AccountSettings />
			</Layout>
		</>
	);
};
