import { GET_PRODUCT_LIST_SUCCESS, GET_PRODUCT_LIST_REQUEST, GET_PRODUCT_LIST_LOADMORE, GET_PRODUCT_LIST_ERROR } from "../../types/types";

export default function productList(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_PRODUCT_LIST_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case GET_PRODUCT_LIST_LOADMORE:






			return {
				...state,
				loading: false,
				payload: {
					total: state.payload.total,
					data: [...state?.payload?.data, ...payload?.data],

				},
			};
		case GET_PRODUCT_LIST_ERROR:
			return {
				...state,
				loading: false,
				payload: payload ? payload : [],
			};

		default:
			return state;
	}
}



